import React, { useState, useRef, useEffect } from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactTooltip from 'react-tooltip';
import TrackingShipGreen from "../../assets/icons/tracking-ship-green.svg";
import TrackingShipRed from "../../assets/icons/tracking-ship-red.svg";
import TrackingShipBlue from "../../assets/icons/cargo-ship-blue.svg";
import LocationBlue from "../../assets/icons/location-blue.svg";
import FlagGrey from "../../assets/icons/flag-grey.svg";
import FlagBlue from "../../assets/icons/flag-blue.svg";
import ExportIcon from "../../assets/icons/export-arrow.svg";
import ImportIcon from "../../assets/icons/import-arrow.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import CompletedIcon from "../../assets/icons/completed-icon.svg";
import ShareIcon from "../../assets/icons/share-icon.svg";
import RefreshIcon from "../../assets/icons/refresh-icon.svg";
import { countries } from "country-flags-svg";
import EmissionCloud from '../../assets/icons/co2-1.svg'
import redirectURL from "../redirectURL";
import Modal from "react-responsive-modal";
import copyIcon from "../../assets/icons/copyIcon.png";
import { Redirect } from 'react-router-dom';
// import InfoIcon from '../../assets/icons/info.png';
// import Pagination from 'react-bootstrap/Pagination';
// import { Tooltip } from 'react-tooltip'
var moment = require('moment');


const ContainerTileImports = (props) => {

    console.log(props, "tile props");

    const [openCloseModal, setOpenCloseModal] = useState(false)
    const [forceCloseReason, setForceCloseReason] = useState()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deletionReason, setDeletionReason] = useState()
    const [openShareModal, setShareModal] = useState(false)
    const [emailID, setEmailID] = useState()
    const [responseMessage, setResponseMessage] = useState()
    const [show, setShow] = useState(false)
    const [basicType, setBasicType] = useState("default")
    const [basicTitle, setBasicTitle] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [closeCurrentID, setCloseCurrentID] = useState({ id: "", operation: "" })

    let currentItems = []
    let ITEMS_PER_PAGE = 10;
    if (props.tilesPerPage.label == "ALL" && Array.isArray(props.container_data))
        ITEMS_PER_PAGE = props.container_data.length
    else
        ITEMS_PER_PAGE = props.tilesPerPage.value
    let indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    let indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    currentItems = (props.container_data == undefined ? [] : props.container_data.slice(indexOfFirstItem, indexOfLastItem))

    const getFlagIcon = (countryName) => {
        let country_name = "";
        var srcFlag = countries.filter((eachCountry) => {
            if (eachCountry.iso2 === countryName) {
                country_name = eachCountry.name;
                return eachCountry;
            }
        });
        if (srcFlag && srcFlag[0].flag) {
            return {
                img: srcFlag[0].flag,
                name: country_name,
            };
        } else {
            return "";
        }
    };

    function getValue(inputString) {
        if (inputString === "")
            return ""
        var words = inputString.slice(0, 10) + "...";
        return words;
    }

    const redirectToTrackingDetails = (each) => {
        localStorage.setItem("each", JSON.stringify(each))
        window.location.href = "/tracking_details_imports"
    };

    const shareTrackingDetails = (container_no) => {

    }

    const forceCloseTracking = (e) => {
        if (closeCurrentID.operation === "forceclose" && closeCurrentID.id != "") {
            e.preventDefault();
            let payload = {
                Mid: closeCurrentID.id,
                reason: forceCloseReason,
                email: localStorage.getItem('email')
            };

            redirectURL.post("/consignments/forceCloseTrackingImports", payload)
                .then((resp) => {
                    if (resp.data.status === "success") {
                        setOpenCloseModal(false)
                        setShow(true);
                        setBasicType("success");
                        setBasicTitle(resp.data.message);
                    }
                    else {
                        setShow(true);
                        setBasicType("danger");
                        setBasicTitle(resp.data.message);
                    }
                })
                .catch((err) => {
                    setShow(true);
                    setBasicType("danger");
                    setBasicTitle("Failed to closing tracking");
                    console.log("forceCloseTracking err", err);
                });
        }
    };

    const refreshData = () => {
        props.trackingDataFun()
    }

    const deleteTracking = (e) => {

        if (closeCurrentID.operation === "delete" && closeCurrentID.id != "") {
            e.preventDefault();
            let payload = {
                Mid: closeCurrentID.id,
                reason: deletionReason,
                email: localStorage.getItem('email')
            };

            redirectURL.post("/consignments/deleteTrackingImports", payload)
                .then((resp) => {
                    if (resp.data.status === "success") {
                        setOpenDeleteModal(false)
                        setShow(true);
                        setBasicType("success");
                        setBasicTitle(resp.data.message);
                    }
                    else {
                        setShow(true);
                        setBasicType("danger");
                        setBasicTitle(resp.data.message);
                    }
                })
                .catch((err) => {
                    setShow(true);
                    setBasicType("danger");
                    setBasicTitle("Failed to closing tracking");
                    console.log("forceCloseTracking err", err);
                });
        }
    };


    const shareURL = (e) => {

        if (closeCurrentID.operation === "share" && closeCurrentID.id != "") {
            e.preventDefault();
            let payload = {
                url: window.location.origin + "/container-tracking-imports?id=" + closeCurrentID.id,
                emailID: emailID,
                container_no: closeCurrentID.id
            };

            redirectURL.post("/consignments/shareTracking", payload)
                .then((resp) => {
                    if (resp.data.status === "success") {
                        setShareModal(false)
                        setShow(true);
                        setBasicType("success");
                        setBasicTitle("Email Sent Successfully!!");
                    }
                    else {
                        setShow(true);
                        setBasicType("danger");
                        setBasicTitle("Failed to send email");
                    }
                })
                .catch((err) => {
                    setShow(true);
                    setBasicType("danger");
                    setBasicTitle("Failed to send email");
                });
        }
    };
    const closeAlert = () => {
        setShow(false);
        setCloseCurrentID({ id: "", operation: "" })
        if (closeCurrentID.operation !== "share")
            window.location.href = "/container-tracking-imports"
    };

    const handleCopyClick = (textToCopy) => {

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                alert("Tracking Details copied");
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    }


    return (
        <>
            <Modal open={openCloseModal} onClose={e => setOpenCloseModal(false)}
                center
                classNames={{ modal: "subcounter-modal-size" }}>
                <div className="">
                    <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>
                        Are you sure to want to close this tracking ?
                    </h5>

                    <form onSubmit={forceCloseTracking}>
                        <div className="pt-3 ml-4">
                            <div className="">
                                <label>Reason</label>
                            </div>

                            <textarea
                                name=""
                                id=""
                                cols="65"
                                value={forceCloseReason}
                                onChange={(e) => setForceCloseReason(e.target.value)}
                                rows="3"
                                placeholder="Reason for Tracking Closing"
                                style={{
                                    resize: "none",
                                    borderRadius: "10px",
                                    paddingLeft: "10px",
                                    paddingTop: "5px",
                                    width: "97%"
                                }}
                                required
                            ></textarea>
                        </div>

                        <div className="button-box txt-center">
                            <button className="btn btn-outline-dark mr-2" type="button" onClick={e => setOpenCloseModal(false)}>
                                Close
                            </button>
                            <button
                                className="btn btn-primary" type="submit">
                                Complete
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>


            <Modal open={openDeleteModal} onClose={e => setOpenDeleteModal(false)}
                center
                classNames={{ modal: "subcounter-modal-size" }}>
                <div className="" style={{ height: "220px" }}>
                    <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>
                        Are you sure to want to delete this tracking ?
                    </h5>
                    <form onSubmit={deleteTracking}>
                        <div className="pt-2 ml-4">
                            <div className="">
                                <label>Reason</label>
                            </div>
                            <textarea
                                name=""
                                id=""
                                cols="65"
                                value={deletionReason}
                                onChange={(e) => setDeletionReason(e.target.value)}
                                rows="3"
                                placeholder="Reason for Tracking Deletion"
                                style={{
                                    resize: "none",
                                    borderRadius: "10px",
                                    paddingLeft: "10px",
                                    paddingTop: "5px",
                                    width: "97%"
                                }}
                                required
                            ></textarea>
                        </div>

                        <div className="button-box txt-center">
                            <button className="btn btn-outline-dark mr-2" type="button" onClick={e => setOpenDeleteModal(false)}>
                                Close
                            </button>
                            <button
                                className="btn btn-primary"
                                type="submit">
                                Delete
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>


            <Modal
                open={openShareModal}
                onClose={e => setShareModal(false)}
                center
                classNames={{ modal: "subcounter-modal-size" }}>
                <div className="" style={{ height: "0px" }}>
                    <h5 className="modal-heading" style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>Share tracking details</h5>
                </div>
                <div className="pl-3" style={{ marginTop: "63px" }}>
                    <form onSubmit={shareURL}>
                        <p>
                            Tracking Url for Container No <b>{closeCurrentID.id}</b> is:
                        </p>
                        <span className="show-n" id="clipboardMsg">
                            Link Copied to clipboard
                        </span>
                        <input
                            type="text"
                            id="trackingURL"
                            placeholder="Text to be copied"
                            value={window.location.origin + "/container-tracking-imports?id=" + closeCurrentID.id}
                            style={{
                                width: "95%",
                                borderRadius: "10px",
                                paddingLeft: "6px",
                                userSelect: "none",
                                cursor: "default",
                                height: "39px",
                                border: "1px solid #d9d9d9",
                            }}
                            readOnly
                            disabled />
                        <button
                            onClick={() => handleCopyClick(window.location.origin + "/container-tracking-imports?id=" + closeCurrentID.id)}
                            style={{
                                borderRadius: "0px 10px 10px 0px",
                                border: "none",
                                height: "37px",
                                position: "relative",
                                right: "29px",
                                background: "none",
                                outline: "none",
                                top: "-2px",
                            }}
                        >
                            <img
                                src={copyIcon}
                                alt=""
                                width="16px"
                                className="cursor-class"
                                style={{ filter: "invert(1)" }}
                            />
                        </button>

                        <div className="mt-3 mb-2">
                            <input
                                type="email"
                                name=""
                                id=""
                                placeholder="Enter Email addresses"
                                style={{
                                    borderRadius: "10px",
                                    height: "39px",
                                    width: "95%",
                                    paddingLeft: "10px",
                                    outline: "none",
                                }}
                                value={emailID}
                                required
                                onChange={(e) => setEmailID(e.target.value)}
                            />
                            <span style={{ position: "absolute", right: "29px" }}>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{
                                        width: "93px",
                                        height: "39px",
                                        borderRadius: "0px 10px 10px 0px",
                                        outline: "none",
                                    }}>
                                    Send
                                </button>
                            </span>
                        </div>
                    </form>
                </div>
            </Modal>

            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            ></SweetAlert>

            {
                <div>
                    {
                        (!currentItems || currentItems.length === 0 || currentItems === undefined) ?
                            <>
                                <div
                                    className="card py-2 pr-3"
                                    style={{
                                        borderRadius: "18px",
                                        textAlign: "center",
                                        height: "50px",
                                        marginTop: "40px",
                                        marginBottom: "100px"
                                    }}
                                >
                                    No data found.
                                </div>
                            </> :
                            <>
                                {currentItems.map((each, index) => {

                                    var loadingport = []
                                    if (each.loading_port != "" && each.loading_port != undefined) {
                                        loadingport = each.loading_port;
                                    }
                                    each.loading_port = loadingport;
                                    let {
                                        _id,
                                        booking_no = "NA",
                                        container_no = "NA",
                                        discharge_port_code = "NA",
                                        last_event_data = "NA",
                                        last_port_ata = "NA",
                                        last_port_eta = "NA",
                                        loading_port_atd = "NA",
                                        loading_port_code = "NA",
                                        loading_port_etd = "NA",
                                        mbl_number = "NA",
                                        movement_type = "NA",
                                        shipment_no = "NA",
                                        shipping_line = "NA",
                                        updated_date = "NA",
                                        total_containers = "NA",
                                        url = "NA",
                                        delay_message = "NA",
                                        delay_status = "NA", // ["On Time", "Early", "Delay"]
                                        last_port = "NA",
                                        loading_port = "NA", // current_port(comes from last_event_location) === last_port, it does mean that it reached the port
                                        first_destination_event = "NA", //for arrival Dates
                                        last_origin_event = "NA", // for departure dates
                                    } = each;

                                    // console.log("first_destination_event bl", first_destination_event)
                                    // console.log("first_destination_event bl", last_origin_event)

                                    let image = 'NA';
                                    let imgStatus = true;
                                    try {
                                        let MyModule = require(`../../assets/shippinglines/${each.carrier_scac_name}.png`)
                                    } catch (err) {
                                        imgStatus = false
                                    }

                                    let shipIcon;
                                    let info_msg = "Port to Port emission calculation based on GLEC framework using default factors"
                                    let progressLine;
                                    let textDecoration;
                                    let { event_group, status, location } = last_event_data;
                                    let s_etd = "NA";
                                    let a_etd = "NA";
                                    let p_eta = "NA";
                                    let a_eta = "NA";
                                    let delay_text = "";
                                    let delay_state = ""
                                    let updated_at = "NA";
                                    let dstnIcon = {};
                                    let srcIcon = {};
                                    let eventLabel = "NA"
                                    let imgPos = "0%";
                                    let barPos = "0%";
                                    let emissionIcon = EmissionCloud

                                    if (each.status !== "NEW" || each.status !== "PENDING" || each.status !== "UNTRACKABLE") {
                                        eventLabel = (each.hasOwnProperty("force_close")) ? each.force_close : "NA"

                                        if (eventLabel === 1) {
                                            eventLabel = "Force Closed"
                                        }else if((each.hasOwnProperty("tracking_deleted")) == 1){
                                            eventLabel = "Deleted"
                                        }
                                        else if (each.status === "COMPLETED") {
                                            eventLabel = each.events[each.events.length - 1].searates_description
                                        }
                                        else {
                                            eventLabel = last_event_data.searates_description
                                        }
                                        if (delay_status === "On Time") {
                                            shipIcon = TrackingShipBlue;
                                            progressLine = "tracking-progress-blue-line";
                                            textDecoration = "ontime-text";
                                        } else if (delay_status === "Early") {
                                            shipIcon = TrackingShipGreen;
                                            progressLine = "tracking-progress-green-line";
                                            textDecoration = "early-text";
                                        }else if (delay_status === "Delay") {
                                            shipIcon = TrackingShipRed;
                                            progressLine = "tracking-progress-red-line";
                                            textDecoration = "delay-text";
                                        }
                                         else {
                                            //Delay
                                            shipIcon = TrackingShipBlue;
                                            progressLine = "tracking-progress-blue-line";
                                            textDecoration = "ontime-text";
                                        }


                                        // let hours = moment().diff(
                                        //     moment().utcOffset("-05:30"),
                                        //     "hours"
                                        // );

                                        // if (hours > 24) {
                                        //     let dayTime = moment().diff(
                                        //         moment(created_date).utcOffset("-05:30"),
                                        //         "days"
                                        //     );
                                        //     if (dayTime === 1) {
                                        //         updated_at = `Updated ${dayTime} day ago`;
                                        //     } else {
                                        //         updated_at = `Updated ${dayTime} days ago`;
                                        //     }
                                        // } else {
                                        //     if (hours === 1) {
                                        //         updated_at = `Updated ${hours} hour ago`;
                                        //     } else {
                                        //         updated_at = `Updated ${hours} hours ago`;
                                        //     }
                                        // }
                                        if (each.status === "NEW" || each.status === "PENDING" || each.status === "UNTRACKABLE")
                                            updated_at = ""

                                        updated_date=moment(updated_date).format("Do MMM, YYYY")




                                        if (status != "COMPLETED") {
                                            if (event_group === "origin") {
                                                barPos = "0%";
                                                imgPos = "0%";
                                            } else if (
                                                event_group === "in_transit" ||
                                                event_group === "transshipment"
                                            ) {
                                                barPos = "33%";
                                                imgPos = "31%";
                                            } else if (event_group === "destination") {
                                                barPos = "66%";
                                                imgPos = "62%";
                                            } else {
                                                barPos = "100%";
                                                imgPos = "95%";
                                            }
                                        }
                                        if (status === "COMPLETED" || last_port === location || each.status === "COMPLETED") {
                                            barPos = "100%";
                                            imgPos = "95%";
                                        }


                                        if (each !== undefined) {
                                            if (each.loading_port_etd) {
                                                s_etd = moment(each.loading_port_etd).format("Do MMM, YYYY")
                                            }


                                            if (each.loading_port_atd) {
                                                a_etd = moment(each.loading_port_atd).format("Do MMM, YYYY")
                                            }

                                            if (each.last_port_eta) {
                                                p_eta = moment(each.last_port_eta).format("Do MMM, YYYY")
                                            }

                                            if (each.last_port_ata) {
                                                a_eta = moment(each.last_port_ata).format("Do MMM, YYYY")
                                            }

                                        }


                                        try {
                                            const delay = (moment(each.last_port_eta)).diff(moment(each.last_port_ata));
                                            const duration = moment.duration(delay)
                                            const days = duration.days();
                                            const hours2 = duration.hours();
                                            if (each.last_port_eta === "" || each.last_port_ata === "") {
                                                delay_state = "NA"
                                                delay_text = "NA"
                                            }
                                            if (days < 0) {
                                                if (Math.abs(days) === 1) {
                                                    delay_text += "Delay by " + Math.abs(days) + " day"
                                                    delay_state = "Delay"
                                                }
                                                else {
                                                    delay_text += "Delay by " + Math.abs(days) + " days"
                                                    delay_state = "Delay"
                                                }
                                                if (hours2 !== 0)
                                                    delay_text = delay_text + " " + Math.abs(hours2) + " hour(s)"
                                            }
                                            else if (days > 0) {
                                                if (Math.abs(days) === 1) {
                                                    delay_text += "Early by " + Math.abs(days) + " day"
                                                    delay_state = "Early"
                                                }
                                                else {
                                                    delay_text += "Early by " + Math.abs(days) + " days"
                                                    delay_state = "Early"
                                                }
                                                if (hours2 !== 0)
                                                    delay_text = delay_text + " " + Math.abs(hours2) + " hour(s)"
                                            }
                                            else {
                                                if (days === 0) {
                                                    if (hours2 < 0) {
                                                        delay_text += "Delay by " + Math.abs(hours2) + " hour(s)"
                                                        delay_state = "Delay"
                                                    }
                                                    else if (hours2 > 0) {
                                                        delay_text += "Early by " + Math.abs(hours2) + " hour(s)"
                                                        delay_state = "Early"
                                                    }
                                                    else if (hours2 === 0) {
                                                        delay_text += "On Time"
                                                        delay_state = "On Time"
                                                    }
                                                }
                                            }
                                        } catch (err) {

                                        }
                                        if (each.hasOwnProperty("force_close") && each.force_close === 1) {
                                            delay_text = "Force Closed"
                                            delay_state = "Delay"
                                        }
                                        if (delay_state === "Early") {
                                            shipIcon = TrackingShipGreen;
                                            progressLine = "tracking-progress-green-line";
                                            textDecoration = "early-text";
                                        } else if (delay_state === "Delay") {
                                            //Delay
                                            shipIcon = TrackingShipRed;
                                            progressLine = "tracking-progress-red-line";
                                            textDecoration = "delay-text";
                                        }
                                        else {
                                            shipIcon = TrackingShipBlue;
                                            progressLine = "tracking-progress-blue-line";
                                            textDecoration = "ontime-text";
                                        }


                                        each["delay_text"] = delay_text
                                        each["delay_state"] = delay_state


                                        if (loading_port_code) {
                                            srcIcon = getFlagIcon(loading_port_code.slice(0, 2));
                                        }


                                        if (discharge_port_code) {
                                            dstnIcon = getFlagIcon(discharge_port_code.slice(0, 2));
                                        }
                                    }
                                    if (each.status === "NEW" || each.status === "PENDING") {
                                        eventLabel = "Newly Added"
                                        barPos = "0%";
                                        imgPos = "-1%";
                                    }
                                    else if (each.status === "UNTRACKABLE") {
                                        eventLabel = "Untrackable"
                                        barPos = "0%";
                                        imgPos = "-1%";
                                    }

                                    return (
                                        <div
                                            key={each._id}
                                            className="card py-2 pr-3 d-flex flex-row justify-content-between align-items-center"
                                            style={{
                                                borderRadius: "18px",
                                            }}
                                        >
                                            <div className="d-flex justify-content-start align-items-start">
                                                {movement_type === "Import" ? (
                                                    <>
                                                        <div
                                                            className="d-flex flex-column justify-content-center align-items-center p-2"
                                                            style={{ background: "#171c8f" }}
                                                        >
                                                            <img
                                                                style={{
                                                                    width: "10px",
                                                                    marginBottom: "5px",
                                                                }}
                                                                src={ImportIcon}
                                                            />
                                                            <div
                                                                style={{
                                                                    color: "white",
                                                                    fontSize: "10px",
                                                                    fontWeight: 700,
                                                                }}
                                                            >
                                                                IM
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (movement_type === "Export" ? ((
                                                    <>
                                                        <div
                                                            className="d-flex flex-column justify-content-center align-items-center p-2"
                                                            style={{ background: "#171c8f" }}
                                                        >
                                                            <img
                                                                style={{
                                                                    width: "10px",
                                                                    marginBottom: "5px",
                                                                }}
                                                                src={ExportIcon}
                                                            />
                                                            <div
                                                                style={{
                                                                    color: "white",
                                                                    fontSize: "10px",
                                                                    fontWeight: 700,
                                                                }}
                                                            >
                                                                EX
                                                            </div>
                                                        </div>
                                                    </>
                                                )) : <></>)}
                                            </div>
                                            <div className="" style={{ width: "7%" }}>
                                                {(imgStatus) ?
                                                    <img
                                                        src={require(`../../assets/shippinglines/${each.carrier_scac_name}.png`)}
                                                        alt={`${each.carrier_scac_name}`}
                                                        style={{ width: "100%" }}
                                                    /> :
                                                    <CustomSVG text={shipping_line} height="5vw" width="7vw" />
                                                }
                                            </div>

                                            <div className="d-flex flex-column justify-content-start eachCard mr-5">
                                                <div className="d-flex flex-row justify-content-between">
                                                    <div style={{ color: "#858796", fontSize: "10px" }}>
                                                        BL No:
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: "10px",
                                                            fontWeight: 600,
                                                            marginLeft: "8px",
                                                        }}
                                                    >
                                                        {mbl_number == "" ? "NA" : mbl_number}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between">
                                                    <div style={{ color: "#858796", fontSize: "10px" }}>
                                                        Booking No:
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: "10px",
                                                            fontWeight: 600,
                                                            marginLeft: "8px",
                                                        }}
                                                    >
                                                        {booking_no == "" ? "NA" : booking_no}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between">
                                                    <div style={{ color: "#858796", fontSize: "10px" }}>
                                                        Container No:
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: "10px",
                                                            fontWeight: 600,
                                                            marginLeft: "8px",
                                                        }}
                                                    >
                                                        {container_no == "" ? "NA" : container_no}
                                                    </div>
                                                </div>
                                            </div>

                                            <ReactTooltip />

                                            <div
                                                className="d-flex flex-row justify-content-center align-items-center cursor-class tilebl"
                                                data-tip={(each.status === "UNTRACKABLE" || each.status === "PENDING" || each.status === "NEW") ? "" : "Click for Tracking details"}
                                                onClick={() => {
                                                    if (each.status === "UNTRACKABLE" || each.status === "NEW" || each.status === "PENDING") { }
                                                    else
                                                        redirectToTrackingDetails(each);
                                                }}
                                            >
                                                <div className="align-items-center ml-1 portpos">
                                                    <div className="row textAlignCenter">
                                                        <div className="col-12" style={{ whiteSpace: "nowrap" }}>
                                                            <img className="portind" src={LocationBlue}></img>
                                                            <br />
                                                            {srcIcon.hasOwnProperty("img") &&
                                                                srcIcon.hasOwnProperty("name") ? (
                                                                <>
                                                                    <img
                                                                        src={srcIcon.img}
                                                                        alt="SRC"
                                                                        className="flag_class"
                                                                        style={{
                                                                            transform: `${srcIcon.name === "China" ? "rotateX(45deg)" : ""
                                                                                }`,
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {(((loading_port !== "" && loading_port !== undefined && loading_port !== null) ? loading_port.length : 0) + ((srcIcon.name != "" && srcIcon.name !== undefined && srcIcon.name !== null) ? srcIcon.name.length : 0) > 27) ? (
                                                                <>
                                                                    {/* <Tooltip id="info_name" /> */}
                                                                    <div className="col-12 portxt info_name"
                                                                        // data-tooltip-id="info_name"
                                                                        data-tip={loading_port + ", " + (srcIcon.hasOwnProperty("name") ? srcIcon.name : "")}
                                                                        style={{ fontSize: "10px", fontWeight: 600, marginTop: "0px" }}
                                                                    >{(getValue(loading_port))}<br />{(getValue(srcIcon.hasOwnProperty("name") ? srcIcon.name : ""))}</div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="col-12 portxt"
                                                                        style={{ fontSize: "10px", fontWeight: 600, marginTop: "0px" }}
                                                                    >{loading_port}<br />{srcIcon.hasOwnProperty("name") ? srcIcon.name : ""}</div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="current-activity">
                                                    <span
                                                        className={`${textDecoration}`}
                                                        style={{
                                                            fontSize: "10px",
                                                            padding: "2px 5px",
                                                            position: "relative",
                                                            top: "-11px",
                                                            borderRadius: "3px",
                                                            fontWeight: "bolder",
                                                            left: "-53px",
                                                        }}
                                                    >
                                                        {eventLabel}
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-row tracking-background-progress">
                                                    <div
                                                        style={{ "--highlight": barPos }}
                                                        className={progressLine}
                                                    ></div>

                                                    <div
                                                        className="tracking-image-background"
                                                        style={{ "--highlight2": imgPos }}
                                                    >
                                                        <img
                                                            className=""
                                                            src={shipIcon}
                                                            width="23px"
                                                        ></img>
                                                    </div>
                                                </div>
                                                {/* <div className="d-flex flex-row" style={{ position: "absolute", top: "20px", left: "95px" }}>
                                                        <img
                                                            className=""
                                                            src={emissionIcon}
                                                            width="40px"
                                                        ></img>
                                                        <span style={{ marginLeft: "10px", lineHeight: "30px", fontSize: "12px" }}>
                                                            {each.emissions.co2e.value ? (parseFloat(each.emissions.co2e.value).toFixed(2) + " " + each.emissions.co2e.unit) : "NA"}
                                                        </span>

                                                         <Tooltip id="info_card_tracking" /> 
                                                        <img src={InfoIcon} className="info_card_tracking" data-tooltip-id="info_card_tracking" alt="" width="15px" data-tip={info_msg} />
                                                    </div> */}
                                                <div className="d-flex flex-column justify-content-center align-items-center ml-1 portdes">
                                                    <div className="row textAlignCenter location-flag">
                                                        <div className="col-12" style={{ whiteSpace: "nowrap" }}>
                                                            <img src={(each.status === "COMPLETED") ? FlagBlue : FlagGrey} className="desflg"></img>
                                                            <br />

                                                            {dstnIcon.hasOwnProperty("img") &&
                                                                dstnIcon.hasOwnProperty("name") ? (
                                                                <>
                                                                    <img
                                                                        src={dstnIcon.img}
                                                                        alt="DSTN"
                                                                        className="flag_class"
                                                                        style={{
                                                                            transform: `${dstnIcon.name === "China"
                                                                                ? "rotateX(45deg)"
                                                                                : ""
                                                                                }`,
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                        <div className="col-12" style={{ whiteSpace: "nowrap" }}>
                                                            {(((last_port != "" && last_port != undefined) ? last_port.length : 0) + ((dstnIcon.name != "" && dstnIcon.name != undefined) ? dstnIcon.name.length : 0) > 27) ? (
                                                                <>
                                                                    {/* <Tooltip id="info_name" /> */}
                                                                    <div className="col-12 portxt info_name"
                                                                        // data-tooltip-id="info_name"
                                                                        data-tip={last_port + ", " + (dstnIcon.hasOwnProperty("name") ? dstnIcon.name : "")}
                                                                        style={{ fontSize: "10px", fontWeight: 600, marginTop: "0px" }}
                                                                    >{(getValue(last_port))}<br />{(getValue(dstnIcon.hasOwnProperty("name") ? dstnIcon.name : ""))}</div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="col-12 portxt"
                                                                        style={{ fontSize: "10px", fontWeight: 600, marginTop: "0px" }}
                                                                    >{last_port}<br />{(dstnIcon.hasOwnProperty("name") ? dstnIcon.name : "")}</div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className="d-flex flex-column justify-content-start">
                                                <div className="d-flex flex-row justify-content-between mb-1" title="Estimated Time of Departure">
                                                    <div style={{ color: "#858796", fontSize: "10px" }}>
                                                        ETD:
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: "10px",
                                                            marginLeft: "10px",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {s_etd}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between mb-1" title="Actual Time of Departure">
                                                    <div style={{ color: "#858796", fontSize: "10px" }}>
                                                        ATD:
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: "10px",
                                                            marginLeft: "10px",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {a_etd}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between mb-1" title="Estimated Time of Arrival">
                                                    <div style={{ color: "#858796", fontSize: "10px" }}>
                                                        ETA:
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: "10px",
                                                            marginLeft: "10px",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {p_eta}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between" title="Actual Time of Arrival">
                                                    <div style={{ color: "#858796", fontSize: "10px" }}>
                                                        ATA:
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: "10px",
                                                            marginLeft: "10px",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {a_eta}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-between">
                                                <div className="d-flex flex-row justify-content-between mb-3">
                                                    <div
                                                        className={
                                                            "cursor-class " +
                                                            `${(last_event_data.status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "NEW" || each.status === "PENDING" || each.status === "UNTRACKABLE")
                                                                ? "trip-done"
                                                                : ""
                                                            }`
                                                        }
                                                        onClick={() => {
                                                            if (last_event_data.status !== "COMPLETED") {
                                                                if ((each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "UNTRACKABLE") { }
                                                                else {
                                                                    setOpenCloseModal(true)
                                                                    setCloseCurrentID({ id: each._id, operation: "forceclose" })
                                                                }
                                                            }
                                                        }}
                                                        // data-tooltip-id={`close_${shipment_no}`}
                                                        data-tip="Mark as complete"
                                                    >
                                                        <img src={CompletedIcon}></img>
                                                    </div>

                                                    {(last_event_data.status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "NEW" || each.status === "PENDING" || each.status === "UNTRACKABLE") ? (
                                                        ""
                                                    ) : (
                                                        // <Tooltip id={`edit_${shipment_no}`} />
                                                        <></>
                                                    )}
                                                    <div
                                                        className={
                                                            "cursor-class " +
                                                            `${(last_event_data.status === "COMPLETED" || (each.hasOwnProperty("tracking_deleted") ? each.tracking_deleted : "") === 1 || each.status === "PENDING" || each.status === "NEW")
                                                                ? "trip-done"
                                                                : ""
                                                            }`
                                                        }
                                                        onClick={() => {
                                                            if (last_event_data.status !== "COMPLETED") {
                                                                if ((each.hasOwnProperty("tracking_deleted") ? each.tracking_deleted : "") === 1) { }
                                                                else {
                                                                    setOpenDeleteModal(true)
                                                                    setCloseCurrentID({ id: each._id, operation: "delete" })
                                                                }
                                                            }
                                                        }}
                                                        // data-tooltip-id={`delete_${shipment_no}`}
                                                        data-tip="Delete Tracking"
                                                    >
                                                        <img src={DeleteIcon}></img>
                                                    </div>
                                                    {(last_event_data.status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW") ? (
                                                        ""
                                                    ) : (
                                                        // <Tooltip id={`delete_${shipment_no}`} />
                                                        <></>
                                                    )}
                                                    <div
                                                        className="cursor-class"
                                                        onClick={() => {
                                                            setShareModal(true)
                                                            setCloseCurrentID({ id: each.container_no, operation: "share" })
                                                        }}
                                                        data-tip="Sharing Tracking Details">
                                                        <img src={ShareIcon}></img>
                                                    </div>
                                                    {/* <Tooltip id={`share_${shipment_no}`} /> */}
                                                </div>
                                                <div style={{ width: "120px", textAlign: "center" }}>
                                                    <div
                                                        className={`${textDecoration} py-1 px-3`}
                                                        style={{
                                                            fontSize: "10px",
                                                            fontWeight: 600,
                                                            borderRadius: "14px",
                                                        }}
                                                    >
                                                        {delay_text}
                                                    </div>
                                                </div>
                                            </div>
                                            {(last_event_data.status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW" || each.status === "UNTRACKABLE") ? (
                                                <>
                                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                                        <label style={{ fontSize: "8px", marginBottom: "0.1rem" }}>
                                                            {
                                                                ((each.hasOwnProperty("force_close") ? each.force_close : "") === 1) ?
                                                                    "Trip was Force Closed" :
                                                                    ((each.status === "PENDING" || each.status === "NEW") ? "Newly Added" : (each.status === "UNTRACKABLE" ? "Untrackable" : ((each.last_event_data.event_group === "transshipment") ? "In Transshipment" : "Trip is Completed")))
                                                            }
                                                        </label>
                                                        <label style={{ fontSize: "8px" }}>
                                                            {updated_date}
                                                        </label>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div
                                                        className="d-flex flex-column justify-content-center align-items-center"
                                                        onClick={refreshData}
                                                    >
                                                        <img
                                                            className={
                                                                "mb-3 cursor-class " +
                                                                `${(last_event_data.status === "COMPLETED" || (each.hasOwnProperty("force_close") ? each.force_close : "") === 1 || each.status === "PENDING" || each.status === "NEW" || each.status === "UNTRACKABLE")
                                                                    ? "trip-done"
                                                                    : ""
                                                                }`
                                                            }
                                                            // data-tooltip-id={`refresh_${shipment_no}`}
                                                            data-tip="Refresh Tracking"
                                                            src={RefreshIcon}
                                                        />
                                                        {last_event_data.status === "COMPLETED" ? (
                                                            ""
                                                        ) : (
                                                            // <Tooltip id={`refresh_${shipment_no}`} />
                                                            <></>
                                                        )}
                                                        <div style={{ fontSize: "8px", fontWeight: "bolder" }}>
                                                            Last Update on
                                                        </div>
                                                        <div style={{ fontSize: "8px", fontWeight: "bolder" }}>
                                                            {updated_date}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    );
                                })}
                            </>
                    }
                    <div className="py-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {Array.from({ length: Math.ceil(props.container_data.length / ITEMS_PER_PAGE) }, (_, i) => (
                            <span className="custom-paginationItem" id={(i + 1 === currentPage) ? "page-active" : ""}
                                onClick={() => setCurrentPage(i + 1)}>
                                {i + 1}
                            </span>
                        ))}
                    </div>

                    {/* <Pagination className="custom-pagination">
                            {Array.from({ length: Math.ceil(props.container_data.length / ITEMS_PER_PAGE) }, (_, i) => (
                                <Pagination.Item className="custom-paginationItem"
                                    key={i + 1}
                                    active={i + 1 === currentPage}
                                    // onClick={() => setCurrentPage(i + 1)}
                                >
                                    {i + 1}
                                </Pagination.Item>
                            ))}
                        </Pagination> */}
                </div>
            }
        </>
    );
}

export default ContainerTileImports


const CustomSVG = ({ text, height, width }) => (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="100%" rx="15" ry="15" fill="#f0f0f0" />
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="1vw" fill="#333">
            {text}
        </text>
    </svg>
);