import React, { useEffect, useState } from 'react'
import "./sustainabilitydashboard.css"

import Select from 'react-select';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';

// Icons
import LeafIcon from '../../assets/icons/leaf.svg'
import TreesSavedImg from '../../assets/icons/trees_saved_img.png'
import Co2HeaderIcon from '../../assets/icons/co2_header_icon.svg'
import SavingsForestImg from '../../assets/icons/savings_forest_img.png'
import PlantImg from '../../assets/icons/plant_img.png'
import TotalTripsIcon from '../../assets/icons/total_trips_icon.svg'
import TotalDistanceIcon from '../../assets/icons/total_distance_icon.svg'
import TotalEmissionIcon from '../../assets/icons/total_emission_icon.svg'
import TotalTreesIcon from '../../assets/icons/total_trees_icon.svg'
import RouteAnalyticsChart from './RouteAnalyticsChart'
import CO2EmissionChart from './CO2EmissionChart'
import EmissionTypesChart from './EmissionTypesChart'
import AgeOfTrucksChart from './AgeOfTrucksChart'

var redirectURL = require('../redirectURL');
var moment = require('moment');

const SustainabilityDashboard = () => {
  const [selectedFilter, setSelectedFilter] = useState({ label: "Vehicle", value: 'vehicle_type_emissions' });
  // const [startDate, setStartDate] = useState(moment().startOf('month').format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [show, setShow] = useState(false);
  const [basicTitle, setBasiTitle] = useState("");
  const [basicType, setBasicType] = useState("default");
  const [loadshow, setLoadShow] = useState("show-n");
  const [sustainabilityDashboardData, setSustainabilityDashboardData] = useState([]);
  const [sustainabilityDashboardDataForCharts, setSustainabilityDashboardDataForCharts] = useState([]);
  const [co2EmissionData, setCO2EmissionData] = useState([]);
  const [emissionTypesData, setEmissionTypesData] = useState([]);
  const [emissionsByTruckAgeData, setEmissionsByTruckAgeData] = useState([]);
  const [treesSavedData, setTreesSavedData] = useState([]);
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");
  const [startDateFromAPI, setStartDateFromAPI] = useState("");
  const [endDateFromAPI, setEndDateFromAPI] = useState("");
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({
    categories: [],
    series: []
  });

  // Runs when component mounts
  useEffect(() => {
    fetchData();
  }, []);

  // Runs when start and end date gets reset and shouldFetchData is true
  useEffect(() => {
    if (shouldFetchData) {
      fetchData();
      setShouldFetchData(false); // Reset to false after data is fetched
    }
  }, [shouldFetchData]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setLoadShow("show-m");

      // Run all API calls concurrently using Promise.all
      await Promise.all([
        getSustainabilityDashboardData(),
        getTotalEmissionsCalculations(),
        getEmissionsBasedOnVehicleType(),
        getEmissionsByTrucksAge(),
        getTreesSavedCount()
      ]);

      const time = moment(new Date(), "hh:mm A").format("hh:mm A");
      // console.log("Time: ", time);
      setLastUpdatedTime(time);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
      setLoadShow("show-n");
    }
  };

  // Code for Emission Type chart data
  useEffect(() => {
    if (selectedFilter && selectedFilter.value) {
      // const sampleData = {
      //   "fuel_type_emissions": {
      //     "categories": [
      //       "CNG",
      //       "DIESEL"
      //     ],
      //     "series": [
      //       {
      //         "data": [
      //           110258872.6,
      //           null
      //         ],
      //         "name": "CNG"
      //       },
      //       {
      //         "data": [
      //           null,
      //           116832343294.8
      //         ],
      //         "name": "DIESEL"
      //       }
      //     ],
      //     "status": "success"
      //   },
      //   "plant_emissions": {
      //     "categories": [
      //       "Banglore",
      //       "Bidadi",
      //       "Gujarat",
      //       "Gurgaon",
      //       "Manesar",
      //       "Nagpur",
      //       "Siliguri"
      //     ],
      //     "series": [
      //       {
      //         "data": [
      //           9503262065.0,
      //           null,
      //           null,
      //           null,
      //           null,
      //           null,
      //           null
      //         ],
      //         "name": "Banglore"
      //       },
      //       {
      //         "data": [
      //           null,
      //           14783378896.7,
      //           null,
      //           null,
      //           null,
      //           null,
      //           null
      //         ],
      //         "name": "Bidadi"
      //       },
      //       {
      //         "data": [
      //           null,
      //           null,
      //           26786709673.4,
      //           null,
      //           null,
      //           null,
      //           null
      //         ],
      //         "name": "Gujarat"
      //       },
      //       {
      //         "data": [
      //           null,
      //           null,
      //           null,
      //           17430929291.2,
      //           null,
      //           null,
      //           null
      //         ],
      //         "name": "Gurgaon"
      //       },
      //       {
      //         "data": [
      //           null,
      //           null,
      //           null,
      //           null,
      //           41141109129.6,
      //           null,
      //           null
      //         ],
      //         "name": "Manesar"
      //       },
      //       {
      //         "data": [
      //           null,
      //           null,
      //           null,
      //           null,
      //           null,
      //           3847824448.0,
      //           null
      //         ],
      //         "name": "Nagpur"
      //       },
      //       {
      //         "data": [
      //           null,
      //           null,
      //           null,
      //           null,
      //           null,
      //           null,
      //           3449388663.5
      //         ],
      //         "name": "Siliguri"
      //       }
      //     ],
      //     "status": "success"
      //   },
      //   "region_emissions": {
      //     "categories": [
      //       "Central",
      //       "East",
      //       "North",
      //       "South",
      //       "South East",
      //       "West"
      //     ],
      //     "series": [
      //       {
      //         "data": [
      //           14307062103.0,
      //           null,
      //           null,
      //           null,
      //           null,
      //           null
      //         ],
      //         "name": "Central"
      //       },
      //       {
      //         "data": [
      //           null,
      //           14779784802.0,
      //           null,
      //           null,
      //           null,
      //           null
      //         ],
      //         "name": "East"
      //       },
      //       {
      //         "data": [
      //           null,
      //           null,
      //           19883216522.7,
      //           null,
      //           null,
      //           null
      //         ],
      //         "name": "North"
      //       },
      //       {
      //         "data": [
      //           null,
      //           null,
      //           null,
      //           15497259817.6,
      //           null,
      //           null
      //         ],
      //         "name": "South"
      //       },
      //       {
      //         "data": [
      //           null,
      //           null,
      //           null,
      //           null,
      //           11877767705.1,
      //           null
      //         ],
      //         "name": "South East"
      //       },
      //       {
      //         "data": [
      //           null,
      //           null,
      //           null,
      //           null,
      //           null,
      //           40597511217.0
      //         ],
      //         "name": "West"
      //       }
      //     ],
      //     "status": "success"
      //   },
      //   "vehicle_type_emissions": {
      //     "categories": [
      //       "TK",
      //       "TR",
      //       "TR8",
      //       "TR9",
      //       "TRE",
      //       "TK4"
      //     ],
      //     "series": [
      //       {
      //         "data": [
      //           1806128141.2,
      //           8869560.7,
      //           11639547341.8,
      //           12839542407.9,
      //           79789762905.9,
      //           null
      //         ],
      //         "name": "TRAILER"
      //       },
      //       {
      //         "data": [
      //           10729498775.6,
      //           null,
      //           null,
      //           null,
      //           null,
      //           129253034.3
      //         ],
      //         "name": "TRUCK"
      //       }
      //     ],
      //     "status": "success"
      //   }
      // };
      // const filteredData = sampleData[selectedFilter.value];
      const filteredData = emissionTypesData[selectedFilter.value];

      // console.log("Filtered Data: ", filteredData);

      if (filteredData && filteredData.categories && filteredData.series) {
        setChartData({
          categories: filteredData.categories,
          series: filteredData.series
        });
      }
    }
  }, [selectedFilter, emissionTypesData]);

  const getSustainabilityDashboardData = async () => {
    const params = {
      start_date: startDate,
      end_date: endDate
    }
    await redirectURL.post("/dashboard/getSustainabilityDashboardData", params).then(response => {
      // console.log("Response: ", response.data);
      if (response && response.status && response.status === 200 && response.data && response.data.data) {
        const originalData = JSON.parse(response.data.data);

        const transformedData = {
          categories: ["Trips Completed", "Distance Travelled (km)", "CO<sub>2</sub> emitted"],
          series: [
            {
              name: "With Optimized Routes",
              data: [
                originalData.Trips_Completed_with_optimized_routes,
                originalData.Distance_Travelled_kms_with_optimized_routes,
                originalData.co2_emitted_with_optimized_routes
              ]
            },
            {
              name: "Without Optimized Routes",
              data: [
                originalData.Trips_Completed_without_optimized_routes,
                originalData.Distance_Travelled_kms_without_optimized_routes,
                originalData.co2_emitted_without_optimized_routes
              ]
            }
          ]
        };
        // console.log("Formatted Data: ", transformedData);
        setSustainabilityDashboardData(originalData);
        setSustainabilityDashboardDataForCharts(transformedData);
        if (originalData.start_date && originalData.end_date) {
          setStartDateFromAPI(originalData.start_date);
          setEndDateFromAPI(originalData.end_date);
        }
      }
    })
  }

  const getTotalEmissionsCalculations = async () => {
    const params = {
      start_date: startDate,
      end_date: endDate
    }
    await redirectURL.post("/dashboard/getTotalEmissionsCalculations", params).then(response => {
      // console.log("Response: ", response.data);
      if (response && response.status && response.status === 200 && response.data && response.data.data) {
        // console.log("Response: ", JSON.parse(response.data.data))
        setCO2EmissionData(JSON.parse(response.data.data))
      }
    })
  }

  const getTreesSavedCount = async () => {
    const params = {
      start_date: startDate,
      end_date: endDate
    }
    await redirectURL.post("/dashboard/getTreesSavedCount", params).then(response => {
      // console.log("Response: ", response.data);
      if (response && response.status && response.status === 200 && response.data && response.data.data) {
        // console.log("Response: ", JSON.parse(response.data.data))
        const treesCount = JSON.parse(response.data.data);
        setTreesSavedData(parseInt(treesCount));
      }
    })
  }

  const getEmissionsBasedOnVehicleType = async () => {
    const params = {
      start_date: startDate,
      end_date: endDate
    }
    await redirectURL.post("/dashboard/getEmissionsBasedOnVehicleType", params).then(response => {
      if (response && response.status && response.status === 200 && response.data && response.data.data) {
        const originalData = JSON.parse(response.data.data);
        console.log("Original Data: ", originalData);
        setEmissionTypesData(originalData);
      }
    })
  }

  const getEmissionsByTrucksAge = async () => {
    const params = {
      start_date: startDate,
      end_date: endDate
    }
    await redirectURL.post("/dashboard/getEmissionsByTrucksAge", params).then(response => {
      if (response && response.status && response.status === 200 && response.data && response.data.data) {
        setEmissionsByTruckAgeData(JSON.parse(response.data.data))
      }
    })
  }

  const handlerStartDateTime = (event, currentDate, selectedDate) => {
    const startDate = moment(event._d).format("YYYY-MM-DD");
    // console.log(startDate, 'startDate');

    if (endDate && endDate !== "") {
      const startDateObj = moment(startDate);
      const endDateObj = moment(endDate);

      if (startDateObj.isAfter(endDateObj)) {
        // Handle invalid date range (startDate is after endDate)
        setShow(true);
        setBasiTitle("To Date should be greater than From Date");
        setBasicType("danger");
        setLoadShow("show-n");
      } else {
        setStartDate(startDate);
      }
    } else {
      setStartDate(startDate);
    }
  }

  const handlerEndDateTime = (event, currentDate, selectedDate) => {
    const endDate = moment(event._d).format("YYYY-MM-DD");
    // console.log(endDate, 'endDate');

    if (startDate && startDate !== "") {
      const startDateObj = moment(startDate);
      const endDateObj = moment(endDate);

      if (startDateObj.isAfter(endDateObj)) {
        // Handle invalid date range (startDate is after endDate)
        setShow(true);
        setBasiTitle("To Date should be greater than From Date");
        setBasicType("danger");
        setLoadShow("show-n");
      } else {
        setEndDate(endDate);
      }
    } else {
      setEndDate(endDate);
    }
  }

  const onClickGetData = () => {
    if (startDate && endDate) {
      // fetchData();
      setShouldFetchData(true);
    }
  }

  const onClickResetFilters = () => {
    // console.log("Reset Clicked");
    // setStartDate(moment().startOf('month').format("YYYY-MM-DD"));
    setStartDate(moment().subtract(30, 'days').format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));

    setShouldFetchData(true);
  }

  const closeAlert = () => {
    setShow(false);
  }

  const getCurrentFinancialYear = () => {
    const currentDate = moment();
    const startYear = currentDate.month() >= 3 ? currentDate.year() : currentDate.year() - 1;
    const endYear = startYear + 1;

    const startOfFinancialYear = moment(`${startYear}-04-01`);
    const endOfFinancialYear = moment(`${endYear}-03-31`);

    return { startOfFinancialYear, endOfFinancialYear };
  };

  const { startOfFinancialYear, endOfFinancialYear } = getCurrentFinancialYear();

  const getFYText = () => {
    const currentYear = moment().year();
    const nextYear = currentYear + 1;
    return `FY ${currentYear.toString().slice(-2)}-${nextYear.toString().slice(-2)}`;
  }

  return (
    <>
      <div className={"dataLoadpage " + (loadshow)}>
      </div>
      <div className={"dataLoadpageimg " + (loadshow)} style={{ top: "20%" }}>
        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
      </div>
      <SweetAlert
        show={show}
        type={basicType}
        title={basicTitle}
        onConfirm={closeAlert}
      >
      </SweetAlert>
      <div className="sustainability_dashboard_header">
        <div className="sustainability_dashboard_header_left">
          <h1>CO<sub>2</sub> Emission Sustainability</h1>
          <img src={Co2HeaderIcon} alt="" />
        </div>
        <div className="sustainability_dashboard_header_right">
          <div className="sustainability_last_updated"><p>Last Updated: {lastUpdatedTime}</p></div>
        </div>
      </div>
      <div className="sustainability_date_picker_container">
        <div className="row col-xl-7 col-lg-7">
          <div className=" card">
            <div className="row card-body" style={{ padding: "10px 30px" }}>
              <div className="col-xl-6 col-lg-6 form-group">
                <label>From Date</label>
                <Datetime value={startDate}
                  disableCloseOnClickOutside={false}
                  closeOnSelect={true}
                  inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete: 'off', readOnly: true, required: true }}
                  dateFormat="YYYY-MM-DD"
                  name="startDate"
                  onChange={handlerStartDateTime}
                  timeFormat={false}
                  isValidDate={current => {
                    return current.isSameOrAfter(startOfFinancialYear, 'day') && current.isSameOrBefore(endOfFinancialYear, 'day');
                  }}
                // isValidDate={current => {
                //   return current.isSameOrBefore(moment(), 'day');
                // }} 
                />
              </div>

              <div className="col-xl-6 col-lg-6 form-group">
                <label>To Date</label>
                <Datetime value={endDate}
                  disableCloseOnClickOutside={false}
                  closeOnSelect={true}
                  inputProps={{ placeholder: 'To Date', name: 'endDate', autoComplete: 'off', readOnly: true, required: true }}
                  dateFormat="YYYY-MM-DD"
                  onChange={handlerEndDateTime}
                  timeFormat={false}
                  isValidDate={current => {
                    return current.isSameOrAfter(startOfFinancialYear, 'day') && current.isSameOrBefore(endOfFinancialYear, 'day');
                  }}
                // isValidDate={current => {
                //   return current.isSameOrBefore(moment(), 'day');
                // }}
                />
              </div>
              <div className="form-group col-xl-12 col-lg-12" style={{ textAlign: "left", display: 'flex' }}>
                <div>
                  <button type="button" style={{ margin: "0 auto" }} className="btn btn-success" disabled={false} onClick={onClickGetData}>Submit</button>
                  &nbsp;<button type="button" style={{ margin: "0 auto" }} className="btn btn-info" disabled={false} onClick={onClickResetFilters}>Reset</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="container-fluid card" style={{ padding: "20px", gap: "20px", borderRadius: "7px" }}>
        <div className="emission_sustainability_row_1">
          <div className="emission_data_container">
            <div className="emission_data_header">
              <div className="emission_data_text">
                <h6>Emission</h6>
                {/* <p>Current Month</p> */}
              </div>
              <div className="emission_data_img">
                <img src={LeafIcon} alt="Leaf-Icon" />
              </div>
              {(startDateFromAPI && endDateFromAPI) && <span className="date-range-specifier">{`( ${moment(startDateFromAPI).format("DD/MM/YY")} - ${moment(endDateFromAPI).format("DD/MM/YY")} )`}</span>}
            </div>
            <div className="emission_data_cards">
              <div className="emission_data_card">
                <div className="emission_data_card_icon">
                  <img src={TotalTripsIcon} alt="Total Trips Icon" />
                </div>
                {/* <h6 className="total_trips_count">{sustainabilityDashboardData.Total_Trips || 0}</h6> */}
                <h6 className="total_trips_count"><CountUp end={(sustainabilityDashboardData.Total_Trips) ? sustainabilityDashboardData.Total_Trips : 0} /></h6>
                <p className="total_trips_title">Total Trips</p>
              </div>
              <div className="emission_data_card">
                <div className="emission_data_card_icon">
                  <img src={TotalDistanceIcon} alt="Total Distance Icon" />
                </div>
                {/* <h6 className="total_distance_count">{sustainabilityDashboardData.Total_Distance || 0} <span>KM</span></h6> */}
                <h6 className="total_distance_count"><CountUp end={(sustainabilityDashboardData.Total_Distance) ? sustainabilityDashboardData.Total_Distance : 0} /> <span>KM</span></h6>
                <p className="total_distance_title">Total Distance</p>
              </div>
              <div className="emission_data_card">
                <div className="emission_data_card_icon">
                  <img src={TotalEmissionIcon} alt="Total Emission Icon" />
                </div>
                {/* <h6 className="total_trips_count">{sustainabilityDashboardData.Total_Emissions || 0}</h6> */}
                <div className="emission_data_row">
                  <h6 className="total_emission_count"><CountUp end={(sustainabilityDashboardData.Total_Emissions) ? sustainabilityDashboardData.Total_Emissions : 0} /></h6>
                  <p className="total_emission_subtitle" style={{ color: "#3F3F40", fontStyle: "italic", fontSize: "0.75vw" }}>MTCO<sub>2</sub>e</p>
                </div>
                <p className="total_emission_title">Total Emission</p>
              </div>
              <div className="emission_data_card">
                <div className="emission_data_card_icon">
                  <img src={TotalTreesIcon} alt="Total Trees Icon" />
                </div>
                {/* <h6 className="total_trees_count">{parseInt(treesSavedData) || 0}</h6> */}
                <h6 className="total_trees_count"><CountUp end={(treesSavedData) ? treesSavedData : 0} /></h6>
                <p className="total_trees_title">Trees Saved {getFYText()}</p>
              </div>
            </div>
          </div>
          {/* <div className="trees_saved_container">
            <div className="trees_saved_img">
              <img src={PlantImg} alt="" />
            </div>
            <div className="trees_saved_text">
              <h6 className="trees_saved_count">{parseInt(treesSavedData) || ""}</h6>
              <p className="trees_saved_title">Trees Saved</p>
              <p className="trees_saved_subtitle">FY 24-25</p>
            </div>
          </div> */}
        </div>
        <div className="emission_sustainability_row_2">
          <div className="emission_sustainability_row_2_left">
            <div className="total_savings_container">
              <div className="total_savings_header">
                {/* <h6 className="total_savings_title">Total Savings <span className="date-range-specifier">{`( ${moment(startDate).format("DD/MM/YY")} - ${moment(endDate).format("DD/MM/YY")} )`}</span></h6> */}
                <h6 className="total_savings_title">Total Savings {(startDateFromAPI && endDateFromAPI) && <span className="date-range-specifier">{`( ${moment(startDateFromAPI).format("DD/MM/YY")} - ${moment(endDateFromAPI).format("DD/MM/YY")} )`}</span>}</h6>
                <p className="total_savings_subtitle">By CNG Trips</p>
              </div>
              <div className="total_savings_row_1">
                <div className="total_savings_trips">
                  <p className="total_savings_trips_count">{sustainabilityDashboardData.Total_trips_CNG || 0}</p>
                  {/* <p className="total_savings_trips_count"><CountUp end={(sustainabilityDashboardData.Total_trips_CNG) ? sustainabilityDashboardData.Total_trips_CNG : 0}/></p> */}
                  <p className="total_savings_trips_text">Total Trips</p>
                </div>
                <div className="total_savings_distance">
                  <p className="total_savings_distance_count">{sustainabilityDashboardData.Total_CNG_Trips_Distance || 0} <span>KM</span></p>
                  {/* <p className="total_savings_distance_count"><CountUp end={(sustainabilityDashboardData.Total_CNG_Trips_Distance) ? sustainabilityDashboardData.Total_CNG_Trips_Distance : 0}/> <span>KM</span></p> */}
                  <p className="total_savings_distance_text">Total Distance</p>
                </div>
              </div>
              <div className="total_savings_row_2">
                <div className="total_savings_diesel">
                  <p className="total_savings_diesel_title">Diesel</p>
                  <p className="total_savings_diesel_count">{sustainabilityDashboardData.Total_co2_emission_by_DIESEL || 0} <span>MTCO<sub>2</sub>e</span></p>
                  {/* <p className="total_savings_diesel_count"><CountUp end={(sustainabilityDashboardData.Total_co2_emission_by_DIESEL) ? sustainabilityDashboardData.Total_co2_emission_by_DIESEL : 0}/> <span>MTCO<sub>2</sub>e</span></p> */}
                  <p className="total_savings_diesel_text">CO<sub>2</sub> Emitted</p>
                </div>
                <div className="total_savings_cng">
                  <p className="total_savings_cng_title">CNG</p>
                  <p className="total_savings_cng_count">{sustainabilityDashboardData.Total_co2_emission_by_CNG || 0} <span>MTCO<sub>2</sub>e</span></p>
                  {/* <p className="total_savings_cng_count"><CountUp end={(sustainabilityDashboardData.Total_co2_emission_by_CNG) ? sustainabilityDashboardData.Total_co2_emission_by_CNG : 0}/> <span>MTCO<sub>2</sub>e</span></p> */}
                  <p className="total_savings_cng_text">CO<sub>2</sub> Emitted</p>
                </div>
              </div>
              <div className="total_savings_saved">
                <div className="total_savings_img">
                  <img src={SavingsForestImg} alt="" />
                </div>
                <div className="total_savings_text">
                  {/* <h6>Saved <span>{parseInt(sustainabilityDashboardData.Total_co2_emission_by_DIESEL - sustainabilityDashboardData.Total_co2_emission_by_CNG) || 0}</span></h6> */}
                  <h6>Saved <span>{sustainabilityDashboardData.Total_co2_emission_savings || 0}</span></h6>
                  <p>MTCO<sub>2</sub>e</p>
                </div>
              </div>
            </div>
            <div className="co2_emission_container">
              <div className="card">
                <div className="card-body" style={{ padding: "20px" }}>
                  <div className="co2_emission_header">
                    <h6>CO<sub>2</sub> Emission <span className="date-range-specifier">{`( Last 6 months )`}</span></h6>
                  </div>
                  <CO2EmissionChart
                    categories={co2EmissionData.categories || []}
                    yAxis={"MTCO<sub>2</sub>e"}
                    series={co2EmissionData.series || []}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="emission_sustainability_row_2_right">
            <div className="route_analysis_container">
              <div className="route_analysis_header">
                <h6>Route Analysis {(startDateFromAPI && endDateFromAPI) && <span className="date-range-specifier">{`( ${moment(startDateFromAPI).format("DD/MM/YY")} - ${moment(endDateFromAPI).format("DD/MM/YY")} )`}</span>}</h6>
              </div>
              <RouteAnalyticsChart
                categories={['Trips Completed', 'Distance Travelled (km)', 'CO<sub>2</sub> emitted (MTCO<sub>2</sub>e)'] || []}
                yAxis={""}
                series={sustainabilityDashboardDataForCharts.series || []}
              // onColumnClick={handleColumnClick}
              />
            </div>
            <div className="emission_types_container">
              <div className="emission_types_header">
                <h6>Emission Types <span className="date-range-specifier">{`( For ${getFYText()} )`}</span></h6>
                <div className="emission_types_dropdown">
                  <Select
                    className="border-radius-0"
                    isMulti={false}
                    value={selectedFilter}
                    style={{ borderRadius: "0px" }}
                    options={[
                      {
                        label: "Vehicle",
                        value: "vehicle_type_emissions",
                        default: true
                      },
                      {
                        label: "Fuel",
                        value: "fuel_type_emissions"
                      },
                      {
                        label: "Region",
                        value: "region_emissions"
                      },
                      {
                        label: "Plant",
                        value: "plant_emissions"
                      }
                    ]}
                    onChange={e => {
                      setSelectedFilter(e);
                    }}
                    required
                  />
                </div>
              </div>
              <EmissionTypesChart
                categories={chartData.categories || []}
                yAxis={"MTCO<sub>2</sub>e"}
                series={chartData.series || []}
              />
            </div>
          </div>
        </div>
        <div className="emission_sustainability_row_3">
          <div className="age_of_trucks_container">
            <div className="age_of_trucks_header">
              <h6>CO<sub>2</sub> Emission by Age of Trucks & Trailers <span className="date-range-specifier">{`( For ${getFYText()} )`}</span></h6>
              {/* <p className="date-range-specifier">{getFYText()}</p> */}
            </div>
            <AgeOfTrucksChart
              categories={emissionsByTruckAgeData.categories || []}
              yAxis={"MTCO<sub>2</sub>e"}
              xAxis={"Age of Trucks"}
              series={emissionsByTruckAgeData.series || []}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default SustainabilityDashboard