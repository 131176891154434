
import React, { useEffect, useState, useRef } from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import "../../../assets/css/exportcontainers.css";
import Exportcontainerdashboard from "./exportcontainersdashboard";
import Exportbookings from "./exportbookings";
import ContainerTracking from "../../layouts/containerTracking";






const ExportContainers = () => {
  var [loadshow, setloadshow] = useState('show-n')
  var [subcounters, setsubcounters] = useState('AllUpdates')
  var [basicTitle, setbasicTitle] = useState('')
  var [basicType, setbasicType] = useState('')
  var [show, setshow] = useState(false)
  var [tabActive, settabActive] = useState('containerbooking')
  const [reloadData, setReloadData] = useState(true);

  var [icon, setIcon] = useState({
    ibicon: 0,
    planticon: 0,
    CHAicon: 0,
    transportericon: 0,
    completedicon: 0,
    alltabicon: 0,})
    const onclickbulkupload = () => {
     console.log("booking creation button clicked")
  }
  var closeAlert = () => {
    setshow(false)
    setloadshow('show-n')
  }
  useEffect(()=>{
    try {
        const queryParams = new URLSearchParams(window.location.search)
        const tab = queryParams.get('tab')
        if (tab == "container-tracking") {
            settabActive("containertracking")
        }
      } catch (err) {
   
      }
  },[])
  useEffect(() => {
    if (reloadData) {
    }
    setReloadData(false);

    }, [reloadData]);

  return (
    <div class="container-fluid">
    <SweetAlert
        show={show}
        type={basicType}
        title={basicTitle}
        onConfirm={closeAlert}
    >
    </SweetAlert>
    <div className="d-flex flex-row justify-content-between ml-3 mt-2p">
                <div className="d-flex">
                            {/* <div
                                className={`mr-3 d-flex ${tabActive == "dashboard" ? "align-items-center tab-active-container" : " align-items-end tab-inactive-container"
                            }`}

                                aria-current="page"
                                onClick={()=> settabActive('dashboard')}
                                style={{cursor:"pointer"}}
                            >
                                <p style={{fontWeight:"bold",  fontSize: tabActive === "dashboard" ? "17px" : "16px", paddingBottom: tabActive === "dashboard" ? "2px" : "4px", margin:"0px"}}>Dashboard</p>
                            </div> */}
                            <div
                                className={`mr-3 d-flex ${tabActive == "containerbooking" ? "align-items-center tab-active-container" : " align-items-end tab-inactive-container"
                                    }`}
                                onClick={() => { settabActive("containerbooking")}}
                                style={{cursor:"pointer"}}
                            >
                              <p style={{fontWeight:"bold",  fontSize: tabActive === "containerbooking" ? "17px" : "16px",paddingBottom: tabActive === "containerbooking" ? "2px" : "4px",  margin:"0px"}}>Container Booking</p> 
                            </div>
                            <div
                                className={`mr-3 d-flex ${tabActive == "containertracking" ? "align-items-center tab-active-container" : " align-items-end tab-inactive-container"
                                    }`}
                                onClick={() => { settabActive("containertracking")}}
                                style={{cursor:"pointer"}}
                            >
                              <p style={{fontWeight:"bold", fontSize: tabActive === "containertracking" ? "17px" : "16px", paddingBottom: tabActive === "containertracking" ? "2px" : "4px", margin:"0px"}}>Container Tracking</p> 
                            </div>
                </div>
                <div className="d-flex justify-content-center" style={{paddingRight:"9px", paddingTop:"5px", paddingBottom:"5px"}}>
                    <button type='button' className="create-booking-button"><a href="/containerbookingsystem/onetime" style={{color:"#171c8f",fontWeight:"bold",fontFamily:"Poppins-Regular"}}>Container Creation</a></button>

            </div>
    </div>
    {tabActive === "dashboard" &&  <Exportcontainerdashboard value= {tabActive}/>}
    {tabActive === "containerbooking" &&  <Exportbookings/>}
    {tabActive === "containertracking" &&  <ContainerTracking/>}

    </div>
  )

}

export default ExportContainers