import React, { useState, useRef, useEffect } from "react";
import TrackingShipGreen from "../../assets/icons/tracking-ship-green.svg";
import TrackingShipRed from "../../assets/icons/tracking-ship-red.svg";
import TrackingShipBlue from "../../assets/icons/cargo-ship-blue.svg";
import LocationBlue from "../../assets/icons/location-blue.svg";
import FlagGrey from "../../assets/icons/flag-grey.svg";
import FlagBlue from "../../assets/icons/flag-blue.svg";
import ExportIcon from "../../assets/icons/export-arrow.svg";
import infoIcon from "../../assets/icons/info.png";
import ImportIcon from "../../assets/icons/import-arrow.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import CompletedIcon from "../../assets/icons/completed-icon.svg";
import ShareIcon from "../../assets/icons/share-icon.svg";
import RefreshIcon from "../../assets/icons/refresh-icon.svg";
import { countries } from "country-flags-svg";
import redirectURL from "../redirectURL";
import $ from "jquery";
// import { Tooltip } from "react-tooltip";
// import "react-tooltip/dist/react-tooltip.css";
import EmissionCloud from '../../assets/icons/co2-1.svg'
import InfoIcon from '../../assets/icons/info.png';
var moment = require('moment');

const TrackingTile2 = (props) => {
  console.log("TrackingTile2", props);
  const inputRef = useRef(null);


  const getFlagIcon = (countryName) => {
    let country_name = "";
    var srcFlag = countries.filter((eachCountry) => {
      if (eachCountry.iso2 === countryName) {
        country_name = eachCountry.name;
        return eachCountry;
      }
    });
    if (srcFlag && srcFlag[0].flag) {
      return {
        img: srcFlag[0].flag,
        name: country_name,
      };
    } else {
      return "";
    }
  };


  function getValue(inputString) {
    var words = inputString.slice(0, 14) + "...";
    return words;
  }
  let {
    booking_no,
    container_no,
    discharge_port_code,
    last_event_data,
    last_port_ata,
    last_port_eta,
    loading_port_atd,
    loading_port_code,
    loading_port_etd,
    mbl_number,
    movement_type,
    shipment_no,
    shipping_line = "NA",
    tracking_updated_at,
    url,
    delay_message,
    delay_state,
    last_port,
    loading_port,
  } = props.container_data;

  let shipIcon;
  let progressLine;
  let textDecoration;

  if (delay_state === "On Time") {
    shipIcon = TrackingShipBlue;
    progressLine = "tracking-progress-blue-line";
    textDecoration = "ontime-text";
  } else if (delay_state === "Early") {
    shipIcon = TrackingShipGreen;
    progressLine = "tracking-progress-green-line";
    textDecoration = "early-text";
  }else if (delay_state === "Delay") {
    shipIcon = TrackingShipRed;
    progressLine = "tracking-progress-red-line";
    textDecoration = "delay-text";
} else {
    shipIcon = TrackingShipBlue;
    progressLine = "tracking-progress-blue-line";
    textDecoration = "ontime-text";
  }

  

  let { event_group, status, location } = last_event_data;

  let imgPos = "0%";
  let barPos = "0%";

  if (status != "COMPLETED") {
    if (event_group === "origin") {
      barPos = "0%";
      imgPos = "0%";
    } else if (
      event_group === "in_transit" ||
      event_group === "transshipment"
    ) {
      barPos = "33%";
      imgPos = "31%";
    } else if (event_group === "destination") {
      barPos = "66%";
      imgPos = "62%";
    } else {
      barPos = "100%";
      imgPos = "95%";
    }
  }
  if (
    status === "COMPLETED" ||
    last_port === location ||
    (props.container_data.hasOwnProperty("force_close") ? props.container_data.force_close : "") === 1
  ) {
    barPos = "100%";
    imgPos = "95%";
  }

  let s_etd = "NA";
  let a_etd = "NA";
  let p_eta = "NA";
  let a_eta = "NA";

  if (loading_port_etd) {
    s_etd = moment(loading_port_etd).format("Do MMMM YYYY");
  }
  if (loading_port_atd) {
    a_etd = moment(loading_port_atd).format("Do MMMM YYYY");
  }
  if (last_port_eta) {
    p_eta = moment(last_port_eta).format("Do MMMM YYYY");
  }
  if (last_port_ata) {
    a_eta = moment(last_port_ata).format("Do MMMM YYYY");
  }

  let srcIcon = {};
  if (loading_port_code) {
    srcIcon = getFlagIcon(loading_port_code.slice(0, 2));
  }

  let dstnIcon = {};
  if (discharge_port_code) {
    dstnIcon = getFlagIcon(discharge_port_code.slice(0, 2));
  }
  
  let imgStatus=true;
  try{
    let MyModule=require(`../../assets/shippinglines/${props.container_data.carrier_scac_name}.png`)
  }catch(err){
    imgStatus=false
  }

  return (
    <>
      <div>
        {
          (props.container_data !== undefined) ?
            <div
              key={shipment_no}
              className="card py-2 pr-3 d-flex flex-row justify-content-between align-items-center mx-3"
              style={{
                borderRadius: "18px",
                //   cursor: "pointer"
              }}
            >
              <div className="d-flex justify-content-start align-items-start">
                {movement_type === "Import" ? (
                  <>
                    <div
                      className="d-flex flex-column justify-content-center align-items-center p-2"
                      style={{ background: "#171c8f" }}
                    >
                      <img
                        style={{
                          width: "10px",
                          marginBottom: "5px",
                        }}
                        src={ImportIcon}
                      />
                      <div
                        style={{
                          color: "white",
                          fontSize: "10px",
                          fontWeight: 700,
                        }}
                      >
                        IM
                      </div>

                    </div>
                  </>
                ) : movement_type === "Export" ? (
                  <>
                    <div
                      className="d-flex flex-column justify-content-center align-items-center p-2"
                      style={{ background: "#171c8f" }}
                    >
                      <img
                        style={{
                          width: "10px",
                          marginBottom: "5px",
                        }}
                        src={ExportIcon}
                      />
                      <div
                        style={{
                          color: "white",
                          fontSize: "10px",
                          fontWeight: 700,
                        }}
                      >
                        EX
                      </div>

                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="" style={{ width: "7%" }}>
                {(imgStatus) ?
                  <img
                    src={require(`../../assets/shippinglines/${props.container_data.carrier_scac_name}.png`)}
                    alt={`${props.container_data.carrier_scac_name}`}
                    style={{ width: "100%" }}
                  /> :
                  <CustomSVG text={shipping_line} height="5vw" width="7vw" />
                }
                <br />
                {props.tabActive === "BL/Booking" ? (
                  <label for="" style={{ whiteSpace: "nowrap" }}>1 Container</label>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column justify-content-start eachCard mr-5">
                <div className="d-flex flex-row justify-content-between">
                  <div style={{ color: "#858796", fontSize: "10px" }}>
                    BL No:
                  </div>
                  {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.billOfLadingNo}</div> */}
                  <div
                    style={{
                      fontSize: "10px",
                      fontWeight: 600,
                      marginLeft: "8px",
                    }}
                  >
                    {mbl_number==""||mbl_number==undefined?"NA":mbl_number}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between">
                  <div style={{ color: "#858796", fontSize: "10px" }}>
                    Booking No:
                  </div>
                  {/* <div style={{ fontSize: "10px", fontWeight: 600, marginLeft: "8px" }}>{props.bookingNo}</div> */}
                  <div
                    style={{
                      fontSize: "10px",
                      fontWeight: 600,
                      marginLeft: "8px",
                    }}
                  >
                    {booking_no==""||booking_no==undefined?"NA":booking_no}
                  </div>
                </div>
                  <div className="d-flex flex-row justify-content-between">
                    <div style={{ color: "#858796", fontSize: "10px" }}>
                      Container No:
                    </div>
                    <div
                      style={{
                        fontSize: "10px",
                        fontWeight: 600,
                        marginLeft: "8px",
                      }}
                    >
                      {container_no == "" ? "NA" : container_no}
                    </div>
                  </div>
                <div className="d-flex flex-row justify-content-between"></div>
              </div>

              <div className="d-flex flex-row justify-content-center align-items-center cursor-class tilebl">
                <div className="align-items-center ml-1 portpos">
                  <div className="row textAlignCenter">
                    <div className="col-12" style={{ whiteSpace: "nowrap" }}>
                      <img className="portind" src={LocationBlue}></img>
                      <br />
                      {srcIcon.hasOwnProperty("img") &&
                        srcIcon.hasOwnProperty("name") ? (
                        <>
                          <img
                            src={srcIcon.img}
                            alt="SRC"
                            className="flag_class"
                            style={{
                              transform: `${srcIcon.name === "China" ? "rotateX(45deg)" : ""
                                }`,
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      <br />
                      {(loading_port !== "" &&
                        loading_port !== undefined &&
                        loading_port !== null
                        ? loading_port.length
                        : 0) +
                        (srcIcon.name != "" &&
                          srcIcon.name !== undefined &&
                          srcIcon.name !== null
                          ? srcIcon.name.length
                          : 0) >
                        27 ? (
                        <>
                          {/* <Tooltip id="info_name" /> */}
                          <div
                            className="col-12 portxt info_name"
                            data-tooltip-id="info_name"
                            data-tooltip-content={
                              loading_port +
                              ", " +
                              (srcIcon.hasOwnProperty("name")
                                ? srcIcon.name
                                : "")
                            }
                            style={{
                              fontSize: "10px",
                              fontWeight: 600,
                              marginTop: "0px",
                            }}
                          >
                            {getValue(loading_port)}
                            <br />
                            {getValue(
                              srcIcon.hasOwnProperty("name") ? srcIcon.name : ""
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="col-12 portxt"
                            style={{
                              fontSize: "10px",
                              fontWeight: 600,
                              marginTop: "0px",
                            }}
                          >
                            {loading_port}
                            <br />
                            {srcIcon.hasOwnProperty("name")
                              ? srcIcon.name
                              : ""}
                          </div>
                        </>
                      )}
                    </div>
                    {/* <div className="row">
                    <div className="col-12">
                    <img
                      src={srcIcon.img}
                      alt="SRC"
                      className="flag_class portsimg"
                      style={{
                        transform: `${
                          srcIcon.name === "China" ? "rotateX(45deg)" : ""
                        }`,
                      }}
                    />
                    </div>
                    <div
                      className="portxt col-12"
                    >
                      <div className="portTxtpos">
                      {loading_port}, {srcIcon.name}
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>

                <div className="current-activity">
                  <span
                    className={`${textDecoration}`}
                    style={{
                      fontSize: "10px",
                      padding: "2px 5px",
                      position: "relative",
                      top: "-11px",
                      borderRadius: "3px",
                      fontWeight: "bolder",
                      left: "-53px",
                    }}
                  >
                    {last_event_data.event_name}
                  </span>
                </div>
                <div className="d-flex flex-row tracking-background-progress">
                  <div
                    // style={{ height: "2px", backgroundColor: "#407bff" }}
                    style={{ "--highlight": barPos }}
                    // className="tracking-progress-green-line"
                    className={progressLine}
                  ></div>
                  <div
                    className="tracking-image-background"
                    style={{ "--highlight2": imgPos }}
                  // style={{ background: "white", position: "absolute", left: "100px", bottom: "-5px", paddingLeft: "5px", paddingRight: "5px" }}
                  >
                    <img
                      className=""
                      // style={{ width: "21px", height: "21px", marginLeft: "5px", marginRight: "5px" }}
                      // src={TrackingShipGreen}
                      src={shipIcon}
                      width="23px"
                    // ["On Time", "Early", "On Time"]
                    ></img>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center ml-1 portdes">
                  <div className="row textAlignCenter location-flag">
                    <div className="col-12">
                      <img src={(props.container_data.status === "COMPLETED") ? FlagBlue : FlagGrey} className="desflg"></img>
                      <br />
                      {dstnIcon.hasOwnProperty("img") &&
                        dstnIcon.hasOwnProperty("name") ? (
                        <>
                          <img
                            src={dstnIcon.img}
                            alt="DSTN"
                            className="flag_class"
                            style={{
                              transform: `${dstnIcon.name === "China"
                                ? "rotateX(45deg)"
                                : ""
                                }`,
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-12" style={{ whiteSpace: "nowrap" }}>
                      {/* <img src={props.destinationFlag}></img> */}
                      {/* <img
                      src={dstnIcon.img}
                      alt="DSTN"
                      className="flag_class portsimg"
                      style={{
                        transform: `${
                          dstnIcon.name === "China" ? "rotateX(45deg)" : ""
                        }`,
                      }}
                    /> */}
                      {/* <div style={{ fontSize: "11px" }}>{props.destination}</div> */}
                      {(last_port != "" && last_port != undefined
                        ? last_port.length
                        : 0) +
                        (dstnIcon.name != "" && dstnIcon.name != undefined
                          ? dstnIcon.name.length
                          : 0) >
                        27 ? (
                        <>
                          {/* <Tooltip id="info_name" /> */}
                          <div
                            className="col-12 portxt"
                            class="info_name"
                            data-tooltip-id="info_name"
                            data-tooltip-content={
                              last_port +
                              ", " +
                              (dstnIcon.hasOwnProperty("name")
                                ? dstnIcon.name
                                : "")
                            }
                            style={{
                              fontSize: "10px",
                              fontWeight: 600,
                              marginTop: "0px",
                            }}
                          >
                            {getValue(last_port)}
                            <br />
                            {getValue(
                              dstnIcon.hasOwnProperty("name")
                                ? dstnIcon.name
                                : ""
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="col-12 portxt"
                            style={{
                              fontSize: "10px",
                              fontWeight: 600,
                              marginTop: "0px",
                            }}
                          >
                            {last_port}
                            <br />
                            {(dstnIcon.hasOwnProperty("name")
                              ? dstnIcon.name
                              : "")
                            }
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="d-flex flex-column justify-content-start"
              //style={{ width: "171.575px" }}
              >
                <div
                  className="d-flex flex-row justify-content-between mb-1"
                  title="Estimated Time of Departure"
                >
                  <div style={{ color: "#858796", fontSize: "10px" }}>ETD:</div>
                  {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.scheduledETD}</div> */}
                  <div
                    style={{
                      fontSize: "10px",
                      marginLeft: "10px",
                      fontWeight: 700,
                    }}
                  >
                    {s_etd}
                  </div>
                </div>
                <div
                  className="d-flex flex-row justify-content-between mb-1"
                  title="Actual Time of Departure"
                >
                  <div style={{ color: "#858796", fontSize: "10px" }}>ATD:</div>
                  {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.actualETD}</div> */}
                  <div
                    style={{
                      fontSize: "10px",
                      marginLeft: "10px",
                      fontWeight: 700,
                    }}
                  >
                    {a_etd}
                  </div>
                </div>
                <div
                  className="d-flex flex-row justify-content-between mb-1"
                  title="Estimated Time of Arrival"
                >
                  <div style={{ color: "#858796", fontSize: "10px" }}>ETA:</div>
                  {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.plannedETA}</div> */}
                  <div
                    style={{
                      fontSize: "10px",
                      marginLeft: "10px",
                      fontWeight: 700,
                    }}
                  >
                    {p_eta}
                  </div>
                </div>
                <div
                  className="d-flex flex-row justify-content-between"
                  title="Actual Time of Arrival"
                >
                  <div style={{ color: "#858796", fontSize: "10px" }}>ATA:</div>
                  {/* <div style={{ fontSize: "10px", marginLeft: "10px", fontWeight: 700 }}>{props.actualETA}</div> */}
                  <div
                    style={{
                      fontSize: "10px",
                      marginLeft: "10px",
                      fontWeight: 700,
                    }}
                  >
                    {a_eta}
                  </div>
                </div>
              </div>
              {/* <Tooltip id="info_card" />
          <div
            class="info_card"
            data-tooltip-id="info_card"
            data-tooltip-content={info_msg}
          >
            <img src={infoIcon} alt="" width="15px" />
          </div> */}
              <div className="d-flex flex-column justify-content-between">
                <div style={{ width: "120px", textAlign: "center" }}>
                  <div
                    className={`${textDecoration} py-1 px-3`}
                    style={{
                      fontSize: "10px",
                      fontWeight: 600,
                      borderRadius: "14px",
                    }}
                  >
                    {props.container_data.delay_text}
                  </div>
                </div>
              </div>
            </div> :

            <div
              key={""}
              className="card py-2 pr-3"
              style={{
                borderRadius: "18px",
                textAlign: "center",
                height: "50px",
                marginTop: "40px",
                marginBottom: "100px"
              }}
            >
              No data found.
            </div>
        }
      </div>
    </>
  );
};

export default TrackingTile2;


const CustomSVG = ({ text, height, width }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
    <rect width="100%" height="100%" rx="15" ry="15" fill="#f0f0f0" />
    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="1vw" fill="#333">
      {text}
    </text>
  </svg>
);