import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { countries } from "country-flags-svg";
var moment = require('moment');


const EximGrid = ({ container_data, paginationSize }) => {
  // console.log("eximgrid", paginationSize, container_data)

  const getFlagIcon = (countryName) => {
    let country_name = "";
    var srcFlag = countries.filter((eachCountry) => {
      if (eachCountry.iso2 === countryName) {
        country_name = eachCountry.name;
        return eachCountry;
      }
    });
    if (srcFlag && srcFlag[0].flag) {
      return {
        img: srcFlag[0].flag,
        name: country_name,
      };
    } else {
      return "";
    }
  };

  const gridRef = useRef();
  const [state, setState] = useState({
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
      pivot: true,
      enableValue: true,
      enableRowGroup: false,
    },
    rowData: [],
    columnDefs: [
      {
        headerName: "Container No",
        field: "container_no",
        filter: true,
        width: 130,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            return (params.data.container_no === "") ? "NA" : params.data.container_no;
          } else {
            return "NA";
          }
        },
      },
      // {
      //   headerName: "BL No",
      //   field: "bl_no",
      //   filter: true,
      //   width: 130,
      // },
      {
        headerName: "Ocean Line",
        field: "carrier_scac_name",
        filter: true,
        width: 130,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            return (params.data.carrier_scac_name === "") ? "NA" : params.data.carrier_scac_name;
          } else {
            return "NA";
          }
        },
      },
      // {
      //   headerName: "Tracking Type",
      //   field: "tracking_type",
      //   filter: true,
      //   width: 180,
      // },
      {
        headerName: "Booking No",
        field: "booking_number",
        filter: true,
        width: 150,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            return (params.data.booking_number === "") ? "NA" : params.data.booking_number;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Shipment No",
        field: "shipment_no",
        filter: true,
        width: 150,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            return (params.data.shipment_no === "") ? "NA" : params.data.shipment_no;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Origin",
        field: "origin",
        filter: true,
        width: 200,
        valueGetter: function (params) {
          if (params) {
            return (params.data.origin === "") ? "NA" : params.data.origin;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Destination",
        field: "destination",
        filter: true,
        width: 200,
        resizable: true,
        valueGetter: function (params) {
          if (params) {
            return (params.data.destination === "") ? "NA" : params.data.destination;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "ETD",
        field: "etd",
        filter: true,
        width: 150,
        resizable: true,
      },
      {
        headerName: "ATD",
        field: "atd",
        filter: true,
        width: 150,
        resizable: true,
      },
      {
        headerName: "ETA",
        field: "eta",
        filter: true,
        width: 150,
        resizable: true,
      },
      {
        headerName: "ATA",
        field: "ata",
        filter: true,
        width: 150,
        resizable: true,
      },
    ],
    container_columnDefs: [
      {
        headerName: "Container No",
        field: "container_no",
        filter: true,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.container_no==undefined || params.data.container_no === "") ? "NA" : params.data.container_no;
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Bill Of Lading",
        field: "mbl_number",
        filter: true,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.mbl_number==undefined || params.data.mbl_number === "") ? "NA" : params.data.mbl_number;
          } else {
            return "NA";
          }
        },
      },

      {
        headerName: "Shipping Line Booking Id",
        field: "booking_no",
        filter: true,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.booking_no==undefined || params.data.booking_no === "") ? "NA" : params.data.booking_no;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Ocean Line",
        field: "carrier_scac_name",
        filter: true,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
          if (params) {
            return (params.data.carrier_scac_name === "") ? "NA" : params.data.carrier_scac_name;
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Origin Country",
        field: "loading_port",
        filter: true,
        width: 180,
        resizable: true,
        cellRenderer: function (params) {
          if (params) {
            if (params.data.loading_port_code != null && params.data.loading_port_code != "") {
              let countryFlag = getFlagIcon(params.data.loading_port_code.slice(0, 2))
              return `<img src=${countryFlag.img} alt="SRC" class="flag_class" />&nbsp;&nbsp;${countryFlag.name}`
            }
            return "NA"
          }
        }
      },
      {
        headerName: "Destination Country",
        field: "discharge_port",
        filter: true,
        width: 180,
        resizable: true,
        cellRenderer: function (params) {
          if (params) {
            if (params.data.discharge_port_code != null && params.data.discharge_port_code != "") {
              let countryFlag = getFlagIcon(params.data.discharge_port_code.slice(0, 2))
              return `<img src=${countryFlag.img} alt="SRC" class="flag_class" />&nbsp;&nbsp;${countryFlag.name}`
            }
            return "NA"
          }
        }
      },
      {
        headerName: "Active Event",
        field: "event_name",
        filter: true,
        resizable: true,
        width: 250,
        valueGetter: function (params) {
          if (params) {
            if (params.data.status === "NEW")
              return "Newly Added"
            else if (params.data.status === "UNTRACKABLE")
              return "Untrackable"
            return (params.data.last_event_data.event_name === "" ? "NA" : params.data.last_event_data.event_name);
            // return "Event name";
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Cha Name",
        field: "cha_name",
        filter: true,
        resizable: true,
        width: 150,
        valueGetter: function (params) {
          if (params) {
            return ((params.data.cha_name === "") | (params.data.cha_name === undefined)  ? "NA" : params.data.cha_name);
            // return ""
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Requirement ID",
        field: "requirement_id",
        filter: true,
        resizable: true,
        width: 150,
        valueGetter: function (params) {
          if (params) {
            return ((params.data.requirement_id === "") | (params.data.requirement_id === undefined) ? "NA" : params.data.requirement_id);
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Shipping On Board",
        field: "shipping_on_board",
        filter: true,
        resizable: true,
        width: 150,
        valueGetter: function (params) {
          if (params) {
            return ((params.data.shipping_on_board === "") | (params.data.shipping_on_board === undefined)  ? "NA" : params.data.shipping_on_board);
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Container Type",
        field: "container_type",
        filter: true,
        resizable: true,
        width: 150,
        valueGetter: function (params) {
          if (params) {
            return (params.data.container_type === "" ? "NA" : params.data.container_type);
            // return ""
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Status",
        field: "event_status",
        filter: true,
        resizable: true,
        width: 150,
        valueGetter: function (params) {
          if (params) {
            return (params.data.last_event_data.status === "" ? "NA" : params.data.last_event_data.status);
            // return ""
          } else {
            return "NA";
          }
        },
      },
      {
        headerName: "Tracking Last Updated",
        field: "updated_date",
        filter: true,
        width: 200,
        resizable: true,
        comparator: (date1, date2) => {
          if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
            let d1 = moment(date1, 'DD/MM/YYYY HH:mm')
            let d2 = moment(date2, 'DD/MM/YYYY HH:mm')
            return d2.isBefore(d1) ? 1 : -1
          }
        },
        valueGetter: function (params) {
          if (params) {
            if (params.data.status === "NEW" || params.data.status === "UNTRACKABLE")
              return "NA"
            return moment
              .parseZone(params.data.updated_date)
              .utcOffset("+05:30")
              .format("DD/MM/YYYY HH:mm");
          } else {
            return "NA";
          }
        },
      },
      // {
      //   headerName: "Event Status",
      //   field: "",
      //   filter: true,
      //   width: 150,
      //   valueGetter: function (params) {
      //     if (params) {
      //       if()
      //       return moment.parseZone(params.data.tracking_updated_at).utcOffset("+05:30").format("DD/MM/YYYY HH:mm")
      //       // return params.data.last_event_data[0].status;
      //       // return ""
      //     } else {
      //       return "";
      //     }
      //   },
      // },
    ],
  });

  // useEffect(()=>{
  //   setState({
  //     ...state,
  //     paginationSize: paginationSize
  //   })
  // },[paginationSize])


  useEffect(() => {

  }, [container_data])


  const onGridReady = (params) => {
    const gridApi = params.api;
    const gridColumnApi = params.columnApi;
    console.log("Grid is ready");
  };

  const renderCellClick = (rowData) => {
    console.log(rowData);
  };
  const gridOptions = {
    getRowStyle: params => ({ verticalAlign: 'middle' }),
    getCellStyle: params => ({ verticalAlign: 'middle' }),
    // other grid options...
  };
  return (
    <div
      className="ag-theme-balham"
      style={{ width: "100%", height: "400px" }}
    >
      <AgGridReact
        // rowHeight={rowHeight}
        modules={AllModules}
        defaultColDef={state.defaultColDef}
        pagination={true}
        gridOptions={gridOptions}
        ref={gridRef}
        rowData={container_data}
        // columnDefs={state.columnDefs}
        columnDefs={
          state.container_columnDefs
        }
        animateRows={true}
        paginationPageSize={paginationSize.value}
        suppressCellFocus={true}
        suppressMovableColumns={true}
        allowScrolling={true}
        showNoRowsOverlay={false}
        suppressRowHoverHighlight={true}
        onGridReady={onGridReady}
        onCellClicked={(e) => {
          renderCellClick(e.data);
        }}
      />
    </div>
  );
};

export default EximGrid;
