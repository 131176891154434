import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const EmissionTypesChart = ({ onColumnClick, categories, yAxis, series }) => {

    // Define handlePointClick first
    // const handlePointClick = (event) => {
    //     const { category, y } = event.point;
    //     onColumnClick({
    //         category,
    //         value: y,
    //         additionalText: 'Additional text here', // Add your additional text or data here
    //     });
    // };

    // console.log("Categories inside chart: ", categories);
    // console.log("Series inside chart: ", series);

    // Then map over the series
    series = series.map(e => ({
        ...e,
        point: {
            events: {
                click: null,
            },
        }
    }));

    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: '',
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            title: {
                text: yAxis,
                useHTML: true,
                formatter: function () {
                    return this.value;
                }
            },
            type: 'logarithmic',
        },
        series: series,
        plotOptions: {
            column: {
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    // format: '{point.y}',
                },
                color: "#7ce77e"
            },
        },
        tooltip: {
            pointFormat: '<b>{point.y}</b>',
            backgroundColor: '#FFFFFF',
            useHTML: true,
        },
        legend: {
            enabled: false, // Adjust as needed
        },
        credits: {
            enabled: false,
        },
    };

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
};

export default EmissionTypesChart;