import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import Modal from 'react-responsive-modal';
import Counters from './railcountersComponent';
import DeliverCounters from './raildelivercountersComponent';
import DateFormater from './dateComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenDDMMMYYYYHHMM, getYYYYMMDDHHMMSS, getDDMMMYYYYHHMMDefault, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import CSVFileValidator from 'csv-file-validator';
import CreatableSelect from 'react-select/creatable';
import Edittruckavailable from "./edittruckavailable";
import Deletetruckavailable from "./deletetruckavailable";
import Remarksaction from "./remarkstruckavailableaction";
import Repairaction from "./repairstruckavailableaction";
import TruckGrid from "./truckavailabilitygrid";
import CountUp from 'react-countup';
import { object } from 'prop-types';
import { ImageFilter9 } from 'material-ui/svg-icons';
import download from 'js-file-download';
//import * as XLSX from 'xlsx';

$.datetimepicker.setLocale('en');
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
//var rowdefs = []
export default class ContainerBookingOneTime extends Component {

	constructor(props){
		super(props);
		this.displayData=[];
		this.state={
			pageTitle: "",
			deptcode:"",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
			eventAction: null,
			allRowData: [],
			activeFilter: null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			sliderBulkForceClose:"",
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			dealer:'',
			maptruckno:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			rownode:'',
			leg_no:'',
			basicType:"default",

			alert1:null,
            show1: false,
			basicTitle1:'',
			basicType1:"default",

			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			valuecolumns:[],
			activeconsignemnt:[],
			activetrucks:[],
			transitdelays:[],
			gpsdatana:[],
			overspeedtrucks:[],
			nightdrive:[],
			open: false,
			openforceclose: false,
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			routeTruck:{}, 
			sidebarSubHeader:'',
			googelRoutes:'',
			plants:[],
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
      	    },
      	    rowData: null,
			  maprowData:null,
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
                edittruckavailable:Edittruckavailable,
                deletetruckavailable:Deletetruckavailable
            },
            emptyframeworkcomponent:{
                Remarksaction:Remarksaction,
                Repairaction:Repairaction
            },

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
            rowModelType: "serverSide",
            headerHeight:60,
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.gate_in_time != ''",
				//"green":"data.status >= 3"

			},
			screenpage:'',
			pagetitle:'',
			open:false,
            truckslist:[],
            truckcategories:[],
            truckcategory:{"value":"","label":""},
            truck:"",
            sliderTitle:"",
            container_requirement:[],
            slidertranslate:'',
            trucktype: 40,
            warehouse:"",
            tentitivetime:"",
			showTrucksList : "show-n",
            filteredData : [],
            startDate:"",
            endDate:"",
            defaultsDate:"",
            defaulteDate:"",
            rowid:"",
            trucktypeother:"show-n",
            othertrucktype:"",
            bulkslide:"",
            file:"",
            bulkslideforcontainer:"",
            csvcontent:'',
            categories:[],
            trucktypes:[],
            transporters:[],
            columns:[],
            rowdefs:[],
            rowloadedtruckdefs:[],
            pinnedRegionBottomRowData:[],
            marktabactive:"btn-success",
            emptytabactive:"",
            emptytabstatusactive:"",
            // markedshow:"show-m",
            emptyshow:"show-n",
            emptyrowData:[],
            emptyplant:"",
            // onetimeshow:"show-n",
            emptyrowStatusData:[],
            usermanualmodal:false,
            overly:"show-m",
            loadshow:"show-m",
            loadtruckactive:"",
            loadtruckshow:"show-n",
            rowDataLoadedTrucks:[],
            startDateLoad:"",
            endDateLoad:"",
            auto_marked_by_system:[],
            loaded_auto_marked_by_system:[],
            waiting_for_unloading:0,
            unloadtruckactive:"",
            unloadtruckshow:"show-n",
            transportercode:[],
            unloadtrucksdata:[],
            lsp_name :"",
            lsp_email:"",
			onetimeid:"",
            bulkslide1:"",
            //
            // marktabactive:"",
            // emptytabactive:"",
            // loadtruckactive:"",
            // unloadtruckactive:"",
            // emptytabstatusactive:"btn-success",
            markedshow:"show-n",
            onetimeshow:"show-m",
		}
		this.changeFileHandler = this.changeFileHandler.bind(this);
	}
	logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
	componentDidMount(){
        var pageUrl = this.props.match.path;
        console.log(pageUrl,"pageUrl")
        pageUrl = pageUrl.split("/");
        console.log(pageUrl,"pageUrlarray")
        var pageType = pageUrl[(pageUrl.length)-1];
        console.log(pageType,"pageType")
        this.onClickShowTabView(pageType);
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })        
        loadDateTimeScript();
        // if(pageType == "weekly")
        // {
        //     this.weeklyBookingData();
        // }
        // else
        // {
        //     this.oneTimeBookingData();
        // }     
        this.oneTimeBookingData()
    };
    oneTimeBookingData = () => {
        redirectURL.post("/consignments/onetimebookingData").then((response)=>{
            console.log(response.data,"324")
            var resp = response.data
            this.setState({
                emptyrowData : resp,
                loadshow:"show-n",
                overly:"show-n"
            })
        })
    }
    // weeklyBookingData = () => {
    //     redirectURL.post("/consignments/weeklytimebookingData").then((response)=>{
    //         console.log(response.data,"324")
    //         var resp = response.data
    //         this.setState({
    //             rowData : resp,
    //             loadshow:"show-n",
    //             overly:"show-n"
    //         })
    //     })
    // }
     onCellRowClicked = e => {
        
        if(e.colDef.field == "container_requirement")
        {
            var container_requirement = JSON.parse(e.data.container_requirement);
            container_requirement.map(function(e){
                var containerDate = e.container_requirement_date;
                if(containerDate !=undefined && containerDate !='')
                {
                    if(containerDate.includes("T") == true)
                    {
                        containerDate = containerDate.split("T");
                        containerDate = containerDate[0];
                        containerDate = containerDate.split("-")[2]+"-"+containerDate.split("-")[1]+"-"+containerDate.split("-")[0];
                    }
                }
                e.container_requirement_date = containerDate;
            })
            var sliderTitle = e.data.requirement_id+" - Container Details";
            this.setState({
                overly :"show-m",
                container_requirement:container_requirement,
                invoiceData: [],
                bulkslideforcontainer : "slider-translate-40p",
                sliderTitle : sliderTitle
            })
        }
    }
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			slidertranslate:"",
			overly:'show-n',
			loadshow:'show-n'
		});
	}
	
	onCloseRouteDiv = () => {
		this.setState({
			slidertranslate:"",
			showDiv:'show-n',
			overly:'show-n'
		});
	}

	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}


	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            slidertranslate:'',
            bulkslide:"",
            bulkslideforcontainer:"",
            showTrucksList : "show-n",
            truck:{"value":"","label":""},
            truckcategory:{"value":"","label":""},
            warehouse:{"value":"","label":""},
            trucktype: 40,
            rowid:"",
			bulkslide1:""
		});
		$("#uploadFile").val("")
	}
	closeAlert = async() => {
        await this.setState({
            show: false
        });
        $("#uploadFile").val("")
        //await window.location.reload();
	}
	changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
onClickShowFilterData(e){
    e.preventDefault()
    this.setState({
        loadshow:"show-m",
        overly:"show-m"
    })
    //var csvData =  this.state.csvcontent
    var csvData =  this.state.file
    var request_type = 2;
    var uploaded_by = localStorage.getItem("email")
    var requirement_id = moment.parseZone().format("YYYYMMDDHHmmss");
    let saveData = new FormData();
    saveData.append("csvData",csvData);
    saveData.append("request_type",request_type);
    saveData.append("uploaded_by",uploaded_by);
    saveData.append("requirement_id",requirement_id);
    redirectURL.post("/consignments/weeklyBulkupload",saveData,{
        headers:{
            'content-type': 'multipart/form-data',
            // 'Access-Control-Allow-Origin':"*",
            // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
            'Access-Control-Allow-Headers': 'Origin, Content-Type'
        }
    }).then((response)=>{
            if(response.data.message == "Success"){
            this.setState({
                show: true,
                basicTitle: response.data.message,
                basicType: "success",
                slidertranslate :"",
                loadshow:"show-n",
                overly:"show-n",
                file:""
            })            
            this.weeklyBookingData();
            $("#uploadFile").val("")
        }
        else{
            this.setState({
                show: true,
                basicTitle: response.data.message,
                basicType: "warning",
                slidertranslate :"",
                loadshow:"show-n",
                overly:"show-n",
                file:""
            })
            $("#uploadFile").val("")
        }
    })
}   

    onShowBulkUpload(){
		
		this.setState({
			bulkslide:"slider-translate",
			overly:'show-m'
		});
	}
    resetUpload = () => {
		this.setState({
			uploadDivWidth:'0%',
			bulkslide:'',
			showDiv:'show-n',
			overly:'show-n',
			file:''
		});
		document.getElementById("upform").reset();
    }
    
   
    changeFileHandler (e){
		this.setState({
			file:e.target.files[0]
		});
    }
    onCellRowClickedonetime = e => {
        if(e.colDef.field == "dgd_file_name"){
            var fileName  = e.data.dgd_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimedgdfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "msds_file_name"){
            var fileName  = e.data.msds_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimemsdsfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "iip_file_name"){
            var fileName  = e.data.iip_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimeiipfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "grw_file_name"){
            var fileName  = e.data.grw_file_name
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadonetimegrwfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "container_details_file"){
            var fileName  = e.data.container_details_file
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadweeklyffupdatesfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "ringi_data"){
                var onetimeid = e.data._id
                // console.log(onetimeid,"onetimeid")
                this.setState({
                    onetimeid : onetimeid,
                    overly :"show-m",
                    loadshow : "show-m",
                    bulkslide1 : "slider-translate-30p",
                })
                var params ={
                    onetimeid : onetimeid,
                    request_type : 1,
                }
                redirectURL.post("/consignments/getRingiData",params).then((response)=>{
                    console.log(response.data.data)
                    if(response.data.message == "Success"){
                        console.log("check")
                       
                            console.log("check-2")
                            if(typeof(response.data.data) == "string")
                            {
                                var resp = JSON.parse(response.data.data)
                            }
                            else
                            {
                                var resp = response.data.data
                            }
                            console.log(resp,"584")
                            this.setState({
                                unloadtrucksdata : resp,
                                loadshow : "show-n",
                            })
                    }else{
                            this.setState({
                                unloadtrucksdata : [],
                                loadshow : "show-n",
                            })
                    }
                })
        }
        if(e.colDef.field == "approve_requirement_onetime"){
            console.log(e.data,"617")
            var params = e.data
            redirectURL.post("/consignments/approverequirementforonetime",params).then((response)=>{
                if(response.data.message == "Success"){
                    this.setState({
                        show:true,
                        basicTitle:"Approved",
                        basicType:"success"
                    })
                    this.oneTimeBookingData()
                }
            })
          }
          if(e.colDef.field == "reject_requirement_onetime"){
             console.log(e.data,"620")
             var params = e.data
            redirectURL.post("/consignments/rejectrequirementforonetime",params).then((response)=>{
                this.setState({
                    show:true,
                    basicTitle:"Rejected",
                    basicType:"success"
                })
                this.oneTimeBookingData()
            })
          }
    }
    onCellRowClickedweekly = e => {
        // console.log(e.data._id,"477")
        if(e.colDef.field == "weekly_update_files")
        {
            var weeklyid = e.data._id
            // console.log(weeklyid,"weeklyid")
            // console.log(e.params)
            this.setState({
                weeklyid : weeklyid,
                overly :"show-m",
                bulkslide2 : "slider-translate-30p",
            })
        }
        if(e.colDef.field == "container_details_file"){
            var fileName  = e.data.container_details_file
            // console.log(fileName,"496")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadweeklyffupdatesfile",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
        if(e.colDef.field == "approve_requirement_weekly"){
          console.log(e.data,"649") 
          var params = e.data
          redirectURL.post("/consignments/approverequirementforweekly",params).then((response)=>{
            this.setState({
                show:true,
                basicTitle:"Approved",
                basicType:"success"
            })
            this.weeklyBookingData()
          })
        }
        if(e.colDef.field == "reject_requirement_weekly"){
           console.log(e.data,"652")
           var params = e.data
          redirectURL.post("/consignments/rejectrequirementforweekly",params).then((response)=>{
            this.setState({
                show:true,
                basicTitle:"Rejected",
                basicType:"success"
            })
            this.weeklyBookingData()
          })
        }
    }
    onCellRowClickedRingiData = e =>{
        if(e.colDef.field == "download_ringi_file")
        {
            var fileName  = e.data.ringi_file
            console.log(e.data,"692")
            if(fileName != ""){
                var reqparams ={
                    uploadOtherFileName : fileName,
                }
                redirectURL.post("/consignments/downloadRingiDataFiles",reqparams,{
                    responseType: 'blob'
                }).then((response)=>{
                    // console.log("download response ",response)
                    download(response.data, fileName)
                   
                }).catch(function(error){
                    console.log(error);
                })
            }
        }
    }
    onClickShowTabView(tab){
        if(tab == "weekly")
        {
            this.setState({
                marktabactive:"btn-success",
                emptytabactive:"",
                emptytabstatusactive:"",
                loadtruckactive:"",
                unloadtruckactive:"",
                markedshow:"show-m",
                emptyshow:"show-n",
                onetimeshow:"show-n",
                loadtruckshow:"show-n",
                unloadtruckshow:"show-n"
            })
            this.weeklyBookingData();
        }
        if(tab == "onetime")
        {
            this.setState({
                marktabactive:"",
                emptytabactive:"",
                loadtruckactive:"",
                unloadtruckactive:"",
                emptytabstatusactive:"btn-success",
                markedshow:"show-n",
                emptyshow:"show-n",
                onetimeshow:"show-m",
                loadtruckshow:"show-n",
                unloadtruckshow:"show-n"
            })
            this.oneTimeBookingData();
        }    
    }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };
    downloadTemplate = () => {
        redirectURL.post('/consignments/getTemplateForExportsWeeklyRequirement',{})
        .then((response) => {
            console.log(response.data);
            let fetchDataModified = `data:application/xlsx;base64,${response.data}`;
            let a = document.createElement("a");
            a.href = fetchDataModified;
            a.download = 'template.xlsx';
            a.click();
        });
    }
    onClickShowAvailabilityForm(){
        this.setState({
            slidertranslate:"slider-translate-30p",
            overly:"show-m"
        })
    }
    render(){
        const modalStyles  = {
            width:'1300px !important',
        }
        
        const {usermanualmodal} = this.state;

		const { open } = this.state;
        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            var showopts = false
            var showdelete = false
        }
        else{
            var showopts = false
            showdelete = false
        }
        var showAcceptandreject = ""
        if(localStorage.getItem("username")=="Rathod AnandSingh" || localStorage.getItem("username")=="Khushal Pahuja" || localStorage.getItem("username")=="Ritesh kumarSingh" || localStorage.getItem("username")=="ASHISH." || localStorage.getItem("username")=="Hiten Chauhan" || localStorage.getItem("username")=="SandeepMathur" || localStorage.getItem("username")=="Aakash Jeengar" || localStorage.getItem("username")=="Nanda Kishore" || localStorage.getItem("username")=="TarunKumar" || localStorage.getItem("username")=="Sudeep Kumar"){
            showAcceptandreject = false
        }else{
            showAcceptandreject =true
        }
        var columnDefs = [
            {
                headerName:"Required Id",
                field:"requirement_id",
                width:140
            },
            {
                headerName:"Container No",
                field:"container_no",
                width:180
            },
            {
                headerName:"Container Requirement Date",
                field:"container_requirement_date",
                width:180,
                valueGetter:function(params){
                    try{
                        if(params.data.container_requirement_date)
                        {
                            return getHyphenDDMMMYYYY(params.data.container_requirement_date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Stuffing Location",
                field:"dispatch_location",
                width:140
            },
            {
                headerName:"Country",
                field:"destination_country",
                width:140,
            },
            {
                headerName:"Container Type",
                field:"container_type",
                width:140
            },
            {
                headerName:"Container Size",
                field:"container_size",
                width:120,
            },
            // {
            //     headerName:"Container Requirement",
            //     field:"container_requirement",
            //     width:120,
            // },
            {
                headerName:"Vehicle No",
                field:"truck_no",
                width:180
            },
            {
                headerName:"Port Of Destination",
                field:"destination_port",
                width:220,
            },
            {
                headerName:"BKG",
                field:"bkg",
                width:140
            },
            {
                headerName:"Vessel",
                field:"vessel",
                width:140
            },
            {
                headerName:"Estimated Time of Dispatch",
                field:"etd",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.etd)
                        {
                            return getHyphenDDMMMYYYY(params.data.etd);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Pickup Location",
                field:"pickup_location",
                width:140
            },
            {
                headerName:"Incoterms",
                field:"incoterms",
                width:220,
            },
           
            {
                headerName:"FF Uploaded File",
                field:"container_details_file",
                width:180,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                    return htmloption;
                },
            },
        ]
        if(localStorage.getItem("username")=="Rathod AnandSingh" || localStorage.getItem("username")=="Khushal Pahuja"  || localStorage.getItem("username")=="Ritesh kumarSingh" || localStorage.getItem("username")=="ASHISH." || localStorage.getItem("username")=="Hiten Chauhan" || localStorage.getItem("username")=="SandeepMathur" || localStorage.getItem("username")=="Aakash Jeengar" || localStorage.getItem("username")=="Nanda Kishore" || localStorage.getItem("username")=="TarunKumar" || localStorage.getItem("username")=="Sudeep Kumar"){
            columnDefs.splice(14,0,  {
                headerName:"Approve Requirement",
                field:"approve_requirement_weekly",
                width:140,
                // hide:showAcceptandreject,
                cellRenderer: function(params){
                    if(params.data.is_approved == 0){
                        var htmloption = '<button class="btn btn-success" style="padding-top:0px">Approve</button>';
                        return htmloption;
                    }else{
                        var htmloption = '<button class="btn btn-success" disabled="on" style="padding-top:0px">Approve</button>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"Reject Requirement",
                field:"reject_requirement_weekly",
                width:140,
                // hide:showAcceptandreject,
                cellRenderer: function(params){
                    if(params.data.is_approved == 0){
                        var htmloption = '<button class="btn btn-danger" style="padding-top:0px">Reject</button>';
                        return htmloption;
                    }else{
                        var htmloption = '<button class="btn btn-danger" disabled="on" style="padding-top:0px">Reject</button>';
                        return htmloption;
                    }
                },
            },)
           }
        var listData = [] 
        this.state.filteredData.map(function(e){
            listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        var user = localStorage.getItem("user_type")
        if(user == "TRANSPORTER")
        {
            var showcol = false
        }
        else{
            var showcol = true
        }
        var showAcceptandreject = ""
        if(localStorage.getItem("username")=="Rathod AnandSingh" || localStorage.getItem("username")=="Khushal Pahuja" || localStorage.getItem("username")=="Ritesh kumarSingh" || localStorage.getItem("username")=="ASHISH." || localStorage.getItem("username")=="Hiten Chauhan" || localStorage.getItem("username")=="SandeepMathur" || localStorage.getItem("username")=="Aakash Jeengar" || localStorage.getItem("username")=="Nanda Kishore" || localStorage.getItem("username")=="TarunKumar" || localStorage.getItem("username")=="Sudeep Kumar"){
            showAcceptandreject = false
        }else{
            showAcceptandreject =true
        }
        var emptyColumnDefs = [
            {
                headerName:"Required Id",
                field:"requirement_id",
                width:140
            },
            {
                headerName:"Container No",
                field:"container_no",
                width:180
            },
            {
                headerName:"Container Requirement Date",
                field:"container_requirement_date",
                width:200,
                valueGetter:function(params){
                    try{
                        if(params.data.container_requirement_date)
                        {
                            return getHyphenDDMMMYYYY(params.data.container_requirement_date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Container Type",
                field:"container_type",
                width:140
            },
            {
                headerName:"Dispatch Location ",
                field:"dispatch_location",
                width:140,
            },
            {
                headerName:"Container Size(in ft)",
                field:"container_size",
                width:160,
            },
            // {
            //     headerName:"Container Requirement",
            //     field:"container_requirement",
            //     width:160,
            // },
            {
                headerName:"Vehicle No",
                field:"truck_no",
                width:180
            },
            {
                headerName:"Date",
                field:"date",
                width:180,
                valueGetter:function(params){
                    try{
                        if(params.data.date)
                        {
                            return getHyphenDDMMMYYYY(params.data.date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Department Name",
                field:"department_name",
                width:180
            },
            {
                headerName:"Intender Name",
                field:"intender_name",
                width:180
            },
            {
                headerName:"Mobile Number",
                field:"mobile_number",
                width:180
            },
            {
                headerName:"Intender Mails",
                field:"intenderMails",
                width:180
            },
            {
                headerName:"Dispatch Port",
                field:"dispatch_port",
                width:140,
            },
            {
                headerName:"Destination Port",
                field:"destination_port",
                width:140
            },
            // {
            //     headerName:"Dispatch Date ",
            //     field:"dispatch_date",
            //     width:140,
            //     valueGetter:function(params){
            //         try{
            //             if(params.data.dispatch_date)
            //             {
            //                 return getHyphenDDMMMYYYY(params.data.dispatch_date);
            //             }
            //         }
            //         catch(e){
            //             return "";
            //         }
            //     }
            // },
            {
                headerName:"MRD at Destination",
                field:"mrd_date",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.mrd_date)
                        {
                            return getHyphenDDMMMYYYY(params.data.mrd_date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"BKG",
                field:"bkg",
                width:140
            },
            {
                headerName:"Vessel",
                field:"vessel",
                width:140
            },
            {
                headerName:"Estimated Time of Dispatch",
                field:"etd",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.etd)
                        {
                            return getHyphenDDMMMYYYY(params.data.etd);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Pickup Location",
                field:"pickup_location",
                width:140
            },
            {
                headerName:"Incoterms",
                field:"incoterms",
                width:140,
            },
            {
                headerName:"Payment Terms",
                field:"payment_terms",
                width:140
            },
            {
                headerName:"Certificate of Origin",
                field:"certificate_of_origin",
                width:140
            },
            {
                headerName:"FTA(Free Trade Agreement) Required",
                field:"fta_required",
                width:170
            },
            {
                headerName:"Export Incentive",
                field:"export_incentive",
                width:140
            },
            {
                headerName:"Remarks",
                field:"remarks",
                width:140,
            },
            {
                headerName:"FF Uploaded File",
                field:"container_details_file",
                width:180,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                    return htmloption;
                },
            },
            {
                headerName:"DGD Uploaded File",
                field:"dgd_file_name",
                width:180,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                    return htmloption;
                },
            },
            {
                headerName:"MSDS Uploaded File",
                field:"msds_file_name",
                width:180,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                    return htmloption;
                },
            },
            {
                headerName:"IIP Uploaded File",
                field:"iip_file_name",
                width:180,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                    return htmloption;
                },
            },
            {
                headerName:"GR Waiver File",
                field:"grw_file_name",
                width:180,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    if(params.data.grw_file_name != undefined &&  params.data.grw_file_name != null && params.data.grw_file_name != ""){
                        var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                        return htmloption;
                    }else{
                        var htmloption = '<button class="btn btn-info" disabled="on" style="padding-top:0px">Download</button>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"Ringi Files",
                field:"ringi_data",
                width:180,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button class="btn btn-primary" style="padding-top:0px">Ringi Data</button>';
                    return htmloption;
                },
            },
        ];
        if(localStorage.getItem("username")=="Rathod AnandSingh" || localStorage.getItem("username")=="Khushal Pahuja"  || localStorage.getItem("username")=="Ritesh kumarSingh" || localStorage.getItem("username")=="ASHISH." || localStorage.getItem("username")=="Hiten Chauhan" || localStorage.getItem("username")=="SandeepMathur" || localStorage.getItem("username")=="Aakash Jeengar" || localStorage.getItem("username")=="Nanda Kishore" || localStorage.getItem("username")=="TarunKumar" || localStorage.getItem("username")=="Sudeep Kumar"){
            emptyColumnDefs.splice(25,0,  {
                headerName:"Approve Requirement",
                field:"approve_requirement_onetime",
                width:140,
                // hide:showAcceptandreject,
                cellRenderer: function(params){
                    if(params.data.is_approved == 0){
                        var htmloption = '<button class="btn btn-success" style="padding-top:0px">Approve</button>';
                        return htmloption;
                    }else{
                        var htmloption = '<button class="btn btn-success" disabled="on" style="padding-top:0px">Approve</button>';
                        return htmloption;
                    }
                    
                },
            },
            {
                headerName:"Reject Requirement",
                field:"reject_requirement_onetime",
                width:140,
                // hide:showAcceptandreject,
                cellRenderer: function(params){
                    if(params.data.is_approved == 0){
                        var htmloption = '<button class="btn btn-danger" style="padding-top:0px">Reject</button>';
                        return htmloption;
                    }else{
                        var htmloption = '<button class="btn btn-danger" disabled="on" style="padding-top:0px">Reject</button>';
                        return htmloption;
                    }
                },
            },)
           }
        var onetimeColumnDefs  = [
            {
                headerName:"",
                field:"_id",
                width:50,
                hide:showopts,
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: 'deletetruckavailable'
                    };
                    return rendComponent
                }
            },
            
            {
                headerName: "",
                field: "revert",
                width: 80,
                cellStyle: {fontWeight: 'bold',cursor: 'pointer'},
                valueGetter: function(params){
                    if(params.data.remarks_status == "delete")
                    {                        
                        return "Revert";
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            {
                headerName: "Remarks",
                field: "remarks_status",
                width: 140
            },
            {
                headerName:"Truck No",
                field:"truck_no",
                width:140
            },
            {
                headerName:"Ware House Location",
                field:"warehouse_text",
                width:140
            },
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:140
            },
            {
                headerName:"Tentative Time",
                field:"tentitivetime",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.tentitivetime)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.tentitivetime);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Created On",
                field:"created_on",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.created_on)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.created_on);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Updated On",
                field:"updated_on",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.updated_on)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.updated_on);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            }
        ]
        
        var columnLoadedTrucksDefs = [
            
            {
                headerName:"Ringi No",
                field:"ringi_desc",
                width:180,
                
            },
            {
                headerName:"Ringi File",
                field:"download_ringi_file",
                width:180,
                cellRenderer: function(params){
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button class="btn btn-info" style="padding-top:0px">Download</button>';
                    return htmloption;
                },
            },
            
        ]
		return(
			<div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">   
                    {/* <div className="col-xl-12 col-lg-12">
                        <button type="button" className={"btn "+(this.state.emptytabstatusactive)} onClick={this.onClickShowTabView.bind(this,"onetime")}>One Time Requirement</button>
                        <button type="button" className={"btn "+(this.state.marktabactive)} onClick={this.onClickShowTabView.bind(this,"weekly")}>Weekly Requirement</button>
                    </div> */}
                    <div className={"col-xl-12 col-lg-12 "+(this.state.markedshow)}>
                        <div className="card">                            
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Weekly Container Booking</span>                                   
                                    <span>
                                        <span className="float-right f12">
                                            <button type="button" onClick={this.onClickShowAvailabilityForm.bind(this)} className="btn btn-warning">Upload Requirement</button>
                                        </span>
                                    </span>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                <div className="row">
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            onCellClicked={this.onCellRowClickedweekly}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                        
                                       
                                    </div> 
                                   
                                       
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+(this.state.onetimeshow)}>
                        <div className="card">                            
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>One Time Requirements</span>                                   
                                    <span>
                                        <span className="float-right f12">
                                            <a href="/containerBookingonetimeform" className="btn btn-warning">Onetime Requirement</a>
                                        </span>
                                    </span>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                <div className="row">
                                    <div id="myGrid1" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={emptyColumnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.emptyrowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            onCellClicked={this.onCellRowClickedonetime}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                        
                                       
                                    </div> 
                                   
                                       
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                <div className={"slide-r "+(this.state.slidertranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                            Upload Weekly Requirement
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <form method="post" onSubmit={this.onClickShowFilterData.bind(this)} className="row col-xl-12 col-lg-12">
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" id="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
                            <div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/weekly_requirement_data.csv')} target="_blank">Sample Template</a> */}
                                <a className="btn btn-primary" onClick={this.downloadTemplate.bind(this)}>Sample Template</a>
                                {/* <div className="col-xl-12 col-lg-12 mt-20p" >Tentitive Time format should be <span style={{color:"red", fontSize:"13px"}}>MM/DD/YYYY HH:mm:ss</span></div> */}
							</div>	
                            <div className="form-group col-xl-3 col-lg-3">
                                <button style={{marginTop:"30px"}} type="submit" className="btn btn-success">Submit</button>
                            </div>
                           
                        </form>
                    </div>
                </div>
            

                {/* Bulk Upload */}
                <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Ringi Data
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                        <div className="row">
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnLoadedTrucksDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.unloadtrucksdata}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            onCellClicked={this.onCellRowClickedRingiData}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                        
                                        {/* {console.log(this.state.unloadtrucksdata,"unloadtruckrowData")} */}
                                    </div> 
                                   
                                       
                                </div>
                        </div>
                    </div>
                </div>

                {/* <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
					<h3 className="subH">Bulk Upload</h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form id="upform" className="theme-form" onSubmit={this.uploadFormHandler}>

							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Submit</button>
								<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
							</div>
							</form>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/Bulk_Trucks_Availability_sample.csv')} target="_blank">Sample Template</a>
                                 <div className="col-xl-12 col-lg-12 mt-20p" >Tentitive Time format should be <span style={{color:"red", fontSize:"13px"}}>MM/DD/YYYY HH:mm:ss</span></div>
							</div>		
							
						</div>
					</div>
				</div> */}
                {/* <div className={"slide-r "+(this.state.bulkslideforcontainer)} style={{overflow:"hidden"}}>
                        <h3 className="subH">
                                        Container Details
                            <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                        </h3>
                <div className="slide-r-body" style={{position:"relative"}}>
                    {(this.state.container_requirement.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>Container Size (in ft)</th>
                                                <th>Containers Required</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.container_requirement.map((e, index) => (
                                            <tr>
                                                <td>{e.container_size}</td>
                                                <td>{e.container_required}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                               
                </div>
            </div> */}
            
                {/* <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
                <Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
                    <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                        <h5 className="model-title">Truck Availability</h5>
                        <div className="col-xl-12 col-lg-12 mt-20p">
                            <div className="col-xl-12 col-lg-12">
                                This screen helps user in identifying vehicles of various transporters that are near the point of origin and can be readily used for loading in MSIL as per requirement. This feature is available only for PRT dept as per the requirement raised.
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                A truck can be marked in following ways by transporters
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>
                                        1. Empty Near plant
                                    </li>
                                    <li>
                                        2. Marked for Arrival
                                    </li>
                                    <li>
                                        3. Marked for Repair
                                    </li>
                                </ul>
                            </div>
                            <h6 style={{fontWeight:"bold"}}>Empty Near plant</h6>
                            <div className="col-xl-12 col-lg-12">
                                Vehicles that are marked as empty near plant are those vehicles which are not carrying any MSIL consignments and are in the vicinity of approx. 100 kms. To check the list of empty vehicles following process is to be followed
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>1. Select the option “Empty Near Plant”</li>
                                    <li>
                                        2. Select the plant for which details are required
                                    </li>
                                    <li>
                                        3. Click on submit button
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                After clicking the submit button the use will get the details of various transporters whose vehicles are empty near selected plant in the format provided below
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>

                            <h6 style={{fontWeight:"bold"}}>Marked for Arrival</h6>
                            <div className="col-xl-12 col-lg-12">
                                This screen provides details for all those vehicles which are available for arrival in MSIL and is ready for taking next load from various MSIL locations. To check vehicles that are marked for arrival are:
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>1. Select the option “Marked for arrival”</li>
                                    <li>
                                        2. Select the Time frame for which  data is required ( by default it is for current date)
                                    </li>
                                    <li>
                                        3. Click on submit button
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                After clicking on Submit button user will be able to check the list of vehicles transporter wise and its availability for MSIL near the various MSIL locations
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>

                            <h6 style={{fontWeight:"bold"}}>Marked for Repair</h6>
                            <div className="col-xl-12 col-lg-12">
                            If a transporter marks any vehicle under “marked for repair”, those vehicles will be listed here and will be exempted from getting any new load until marked fit from transporter’s end. One can check list of vehicles that are marked as marked for repair by clicking option Marked for repair. The user will get the list of vehicles in following manner    
                            </div>
                           
                        </div>
                    </div>
                </Modal>     */}


            </div>
    
              	
		);
	}
}

window.onload = function (){

    var currentTime = new Date().getTime();
    var todayDate = new Date().getDate();
    var logic = function( currentDateTime ){
        // console.log("currentDateTime ", new Date(currentDateTime).getDate())
        // console.log("todayDate ", todayDate)
        var clickdate = new Date(currentDateTime).getDate()
        if( clickdate == todayDate ){
          this.setOptions({
            minTime:currentTime,
            timepickerScrollbar: true,
            scrollInput:true
          });
        }
        else
        {
            this.setOptions({
                minTime:"00:00",
                timepickerScrollbar: true,
                scrollInput:true
            });
        }
          
      };


	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#tentitivetime').datetimepicker({
		mask:'9999-19-39 29:59',
        format:'Y-m-d H:i',
        formatTime:"H:i A",
        minTime:currentTime,
        onChangeDateTime:logic,
        onShow:logic,
        minDate : new Date(new Date().setDate(todayDate)),
        maxDate: new Date(new Date().setDate(todayDate + 2)),
        startDate:new Date(new Date().setDate(todayDate)),
        timepickerScrollbar: true,
        scrollInput:true
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){
    var currentTime = new Date().getTime();
    var todayDate = new Date().getDate();
    var logic = function( currentDateTime ){
        // console.log("currentDateTime ", new Date(currentDateTime).getDate())
        // console.log("todayDate ", todayDate)
        var clickdate = new Date(currentDateTime).getDate()
        if( clickdate == todayDate ){
          this.setOptions({
            minTime:currentTime,
            timepickerScrollbar: true,
            scrollInput:true
          });
        }
        else
        {
            this.setOptions({
                minTime:"00:00",
                timepickerScrollbar: true,
                scrollInput:true
            });
        }
          
      };

    var todayDate = new Date().getDate();
	$('#tentitivetime').datetimepicker({
		mask:'9999-19-39 29:59',
        format:'Y-m-d H:i',
        formatTime:"H:i A",
        minTime:currentTime,
        onChangeDateTime:logic,
        onShow:logic,
        minDate : new Date(new Date().setDate(todayDate)),
        maxDate: new Date(new Date().setDate(todayDate + 2)),
        startDate:new Date(new Date().setDate(todayDate)),
        timepickerScrollbar: true,
        scrollInput:true
	});
	
    // var index  =window.document.getElementsByTagName("script")[1]
    // var script = window.document.createElement("script")
    // script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    // script.async=true
    // script.defer = true
    // index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}

function onSetCritical(params, status){
	//console.log("onSetCritical ", params);
	//console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus',{
		params:params,
		is_critical:status,
		token:window.atob(localStorage.getItem('_t')),
		userId:localStorage.getItem("userid")
	})
		.then((response) => {
		
		//var records = JSON.parse(JSON.stringify(response)).data;
		console.log(response.data);
		});
		
}
$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }