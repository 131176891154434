import React, { useState, useEffect, useRef } from "react";
import redirectURL from "../redirectURL";
import CargoShipBlue from "../../assets/icons/cargo-ship-blue.svg";
import CargoShipWhite from "../../assets/icons/cargo-ship-white.svg";
import LocationBlue from "../../assets/icons/location-blue.svg";
import LocationWhite from "../../assets/icons/location-white.svg";
import ShipmentBlue from "../../assets/icons/shipment-blue.svg";
import ShipmentWhite from "../../assets/icons/shipment-white.svg";
import FlagBlue from "../../assets/icons/flag-blue.svg";
import FlagWhite from "../../assets/icons/flag-white.svg";
import ContainerCompletedBlue from "../../assets/icons/container-completed-blue.svg";
import ContainerCompletedWhite from "../../assets/icons/container-completed-white.svg";
import ContainerDelayedRed from "../../assets/icons/container-delayed-red.svg";
import ContainerDelayedWhite from "../../assets/icons/container-delayed-white.svg";
import ContainerDetainedRed from "../../assets/icons/container-detained-red.png";
import ContainerDetainedWhite from "../../assets/icons/container-detained-white.png";
import SubTrackingCounters from "./subCounters.js";

const MainTrackingCounters = (props) => {
  console.log("maintrackingCounters", props);
  
  const [state, setState] = useState({
    totalCount: 25,
    originCount: 5,
    inTransitCount: 2,
    destinationCount: 3,
    completedCount: 10,
    delayedCount: 1,
    detainedCount: 4,
    activeCounterId: 1,
    activeCounterName: "all",
    isLoading: false,
  });

  let [counters, setCounterData] = useState([
    {
      id: 1,
      name: "all",
      title: "All",
      // count props.counters.all,
      imgSrc: CargoShipBlue,
      activeImgSrc: CargoShipWhite,
      color: "#171c8f",
      width: "35px",
      height: "35px",
    },
    {
      id: 2,
      name: "origin",
      title: "Origin",
      // count props.counters.origin,
      imgSrc: LocationBlue,
      activeImgSrc: LocationWhite,
      color: "#171c8f",
      width: "30px",
      height: "30px",
    },
    {
      id: 3,
      name: "in_transit",
      title: "In Transit",
      // count props.counters.in_transit,
      imgSrc: ShipmentBlue,
      activeImgSrc: ShipmentWhite,
      color: "#171c8f",
      width: "35px",
      height: "35px",
    },
    // {
    //   id: 4,
    //   name: "transhipment",
    //   title: "Transhipment",
    //   // count props.counters.in_transit,
    //   imgSrc: TranshipmentBlue,
    //   activeImgSrc: TranshipmentWhite,
    //   color: "#171c8f",
    //   width: "35px",
    //   height: "35px",
    // },
    {
      id: 4,
      name: "destination",
      title: "Destination",
      // count props.counters.destination,
      imgSrc: FlagBlue,
      activeImgSrc: FlagWhite,
      color: "#171c8f",
      width: "35px",
      height: "35px",
    },
    {
      id: 5,
      name: "completed",
      title: "Completed",
      // count props.counters.completed,
      imgSrc: ContainerCompletedBlue,
      activeImgSrc: ContainerCompletedWhite,
      color: "#171c8f",
      width: "35px",
      height: "35px",
    },
    {
      id: 8,
      name: "untrackable",
      title: "Untrackable",
      // count props.counters.completed,
      imgSrc: ContainerCompletedBlue,
      activeImgSrc: ContainerCompletedWhite,
      color: "#171c8f",
      width: "35px",
      height: "35px",
    },
    {
      id: 6,
      name: "delayed",
      title: "Delayed",
      // count props.counters.delayed,
      imgSrc: ContainerDelayedRed,
      activeImgSrc: ContainerDelayedWhite,
      color: "#ff0000",
      width: "35px",
      height: "35px",
    },
    {
      id: 7,
      name: "detained",
      title: "Detention",
      // count props.counters.detained,
      imgSrc: ContainerDetainedRed,
      activeImgSrc: ContainerDetainedWhite,
      color: "#ff0000",
      width: "35px",
      height: "25px",
    },
  ]);

  const originSub=["in_transit_to_consignee","at_consignee","in_transit_to_first_port","at_port","in_transit_to_pol","at_pol"]
  const inTransitSub=["high_seas","at_transshipment"]
  const destinationSub=["at_pod","in_transit_to_final_port","at_final_port","out_for_final_delivery","at_customer"]
  const [origin,setOrigin]=useState(false);
  const [inTransit,setInTransit]=useState(false);
  const [destination,setDestination]=useState(false);
  
  useEffect(() => { 
    
    // localStorage.setItem("current_counter","all");
    // localStorage.setItem("sub_counter","");
    // console.log("here") 
  // if(props.tabActiveSCnt == "0")
  //   {
      props.filterDataByCounter("main", "all");
      setState({
        ...state,
        activeCounterId: 1,
        activeCounterName: "all",
      });
      localStorage.setItem("current_counter","all")
    //}
}, [props.tabActiveSCnt]);
  // var newFn = () => {
  //   // console.log("S1",props.tabActiveSCnt)
    
  // }
  const handleCounterClick = (id, name) => {
    // console.log("title", id+"=="+name)
    // let classList = document.getElementsByClassName("card-container");
    // let idList = [];
    // for (let item of classList) {
    //     idList.push(item.id)
    // }
    // idList = idList.filter((item) => item != id);
    // console.log("idList", idList)
    // $(`#${id}`).addClass("counter-active");
    // for (let id of idList) {
    //     $(`#${id}`).removeClass("counter-active");
    // }
    localStorage.setItem("current_counter",name);
    localStorage.setItem("sub_counter","");
    props.clearAllFilters()
    props.filterDataByCounter("main", name);
    setState({
      ...state,
      activeCounterId: id,
      activeCounterName: name,
    });
  };
useEffect(()=>{
  if(props.subCountersTab!==null || props.subCountersTab.length !== 0 || props.subCountersTab!==undefined){
    setOrigin(!(originSub.some(element => props.subCountersTab.includes(element))));
    setInTransit(!(inTransitSub.some(element => props.subCountersTab.includes(element))))
    setDestination(!(destinationSub.some(element => props.subCountersTab.includes(element))));
  }
},[props.subCountersTab])
  return (
    <div className="col-12 justify-content-start align-items-center">
      <div className="row ">
      {(!counters || counters.length === 0 || counters===undefined)?<></>:<>
        {counters.map((item, idx) => {
          let countValue = 0;
        
          if (item.id === 1) {
            countValue = props.counters.all;
          } else {
            countValue = props.counters[item.name];
          }
          let customFontSize = ""
          let displayNone="none"
          let current_counter=localStorage.getItem("current_counter")
          if(current_counter=="all" || current_counter=="delayed" || current_counter=="untrackable" || (current_counter==="origin" && origin) ||(current_counter==="in_transit" && inTransit)|| (current_counter==="destination" && destination)){
            displayNone="none"
          }
          else{
            if(state.activeCounterId == item.id)
            displayNone="block"
          }
          // if(item.title === "Untrackable" || item.title==="Completed"){
          //   customFontSize="0.8vw"
          // }
          // else{
          //   customFontSize="1vw"
          // }
          customFontSize="0.8vw"
          return (
            <div
              key={item + idx}
              id={item.id}
              className={`card col d-flex flex-row justify-content-between align-items-center mr-3 py-2 px-3 card-container`}
              style={{
                borderRadius: "17px",
                color: state.activeCounterId == item.id ? "white" : item.color,
                background:
                  state.activeCounterId == item.id ? item.color : "white",
                height: "75px",
                cursor: "pointer",
                border: "none"
              }}
              onClick={() => handleCounterClick(item.id, item.name)}
            >
              
            <span className={"arwUp "} style={{display:displayNone}}></span>

              {item.name === 'delayed'?
              <span className="cntrdiv">&nbsp;&nbsp;</span>
              :
              ''}
              <div className="icon-container">
                <img className="img-fluid"
                  src={
                    state.activeCounterId == item.id
                      ? item.activeImgSrc
                      : item.imgSrc
                  }
                  style={{ width: "38px", height: "24px" }}
                />
              </div>

              <div
                className="d-flex flex-column justify-items-left align-items-left"
                style={{ fontWeight: 700 }}
              >
                <div
                  style={{
                    fontSize: customFontSize,
                    //lineHeight: "21px",
                    fontFamily: "Poppins-Regular",
                    whiteSpace: "nowrap",
                  }}
                >
                  &nbsp; {item.title}
                </div>
                <div style={{ fontSize: "1vw",textAlign: "center"  }}>
                  {countValue ? countValue : 0}
                </div>
              </div>
            </div>
          );
        })}
        </>}
      </div>
      <div className={"row bghighliter"} style={{display:(localStorage.getItem("current_counter") == "all" ||localStorage.getItem("current_counter") == "delayed"|| localStorage.getItem("current_counter") == "untrackable"  || (localStorage.getItem("current_counter")==="origin" && origin) ||(localStorage.getItem("current_counter")==="in_transit" && inTransit)|| (localStorage.getItem("current_counter")==="destination" && destination))?"none":"block"}}>
        <SubTrackingCounters
          parentCounter={state.activeCounterName}
          counters={props.counters}
          filterDataByCounter={props.filterDataByCounter}
          subCountersTab={props.subCountersTab}
        />
      </div>
    </div>
  );
};

export default MainTrackingCounters;
