/* eslint-disable eqeqeq */
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';
import CSVFileValidator from 'csv-file-validator';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';  
// const $ = window.$;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class ContractMonitoringSystem extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
            // currentAddAccidentProneZone: null,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        allData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:140,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        maxNodes: [],
        telRoutes: [],
        deviation: [],
        showCalendar : "show-m",
        showGridData : "show-m",
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        showGridData : "show-m",
        vechicleTypes : [],
        hrefLink : "",
        rowId : "",
        sliderType : "",
        partyData : [{}],
        extend_contract_start_date : "",
        active:"activet",       
        closed:"",
        alldept:"activet",
        deptbdi:"",
        deptexp:"",
        deptimp:"",
        deptrly:"",
        deptspl:"",
        deptsndf:"",
        deptsndg:"",
        deptsndgj:"",
        deptsndpj:"",
        deptsndb:"",
        deptsndm:"",
        pageType:1,
        dept_code:"all",
        historyAllData:[],
        historyData:[],
        historyStatus:"all",
        activeData:[],
        activeRateData:[],
        expiredData:[],
        expiredRateData:[],
        fifteenData:[],
        fifteenRateData:[],
        monthData:[],
        monthRateData:[],
        fortyfiveData:[],
        fortyfiveRateData:[],
        sixtyData:[],
        sixtyRateData:[],
        activeCnt:0,
        expiredCnt:0,
        fifteenCnt:0,
        monthCnt:0,
        fortyfiveCnt:0,
        sixtyCnt:0,
        showDepts:"show-n"
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this)
    this.changeHandler = this.changeHandler.bind(this);
    this.filterHistory = this.filterHistory.bind(this);
  }

logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}  

async componentDidMount(){
    this.logPageView();
    loadDateTimeScript();
    this.onloadPageData();
}

async onloadPageData(){
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    var roles = localStorage.getItem("roles");
    var userEmail = localStorage.getItem("email");
    if(roles.includes("SITE_ADMIN") == true)
    {
        var params = {
        }
        this.setState({
            showDepts:"show-m"
        });
    }
    else if(roles.includes("CMS_BDI") == true)
    {
        var params = {
            dept_code : "LOG-BDI"
        }
    }
    else if(roles.includes("CMS_EXP") == true)
    {
        var params = {
            dept_code : "LOG-EXP"
        }
    }
    else if(roles.includes("CMS_IMP") == true)
    {
        var params = {
            dept_code : "LOG-IMP"
        }
    }
    else if(roles.includes("CMS_RAIL") == true)
    {
        var params = {
            dept_code : "LOG-RLY"
        }
    }
    else if(roles.includes("CMS_SPL") == true)
    {
        var params = {
            dept_code : "LOG-SPL"
        }
    }
    else if(roles.includes("CMS_SFNC") == true)
    {
        var params = {
            dept_code : "SND-FNC"
        }
    }
    else if(roles.includes("CMS_SNDG") == true)
    {
        var params = {
            dept_code : "SND-G"
        }
    }
    else if(roles.includes("CMS_SNDGJ") == true)
    {
        var params = {
            dept_code : "SND-GJ"
        }
    }
    else if(roles.includes("CMS_SNDPZ") == true)
    {
        var params = {
            dept_code : "SND-PZ"
        }
    }
    else if(roles.includes("CMS_SNDB") == true)
    {
        var params = {
            dept_code : "SND-B"
        }
    }
    else if(roles.includes("CMS_SNDM") == true)
    {
        var params = {
            dept_code : "SND-M"
        }
    }
    else if(roles.includes("cms_user") == true)
    {
        var params = {
        }
        this.setState({
            showDepts:"show-m"
        });
    }
    else
    {
        var params = {
            pic : userEmail
        }
    }
    console.log(params,"params")
    redirectURL.post("/consignments/getContractMonitoringData",params).then(async(response)=>{
        // console.log("response.data:=>",response.data);
        var rowData = response.data;  
        // console.log("rowData:=>" , rowData)      
        await this.setState({
            allData : rowData,
            loadshow:'show-n',
            overly:'show-n',
        })
        await this.filterData();
    }).catch((e)=>{
        console.log(e);
    })
}

async filterData()
{
    var pageType = this.state.pageType;
    var dept_code = this.state.dept_code;
    var allData = this.state.allData;
    var rowData = [];
    var pageData = [];
    console.log(pageType,"pageType")
    if(pageType == 2)
    {
        var pageData = allData.filter(e => e.status == 2)
        console.log(pageData,"pageData")
    }
    else
    {
        var pageData = allData.filter(e => e.status != 2)
    }
    if(pageData.length > 0)
    {
        if(dept_code == 'all')
        {
            rowData = pageData;
        }
        else
        {
            rowData = pageData.filter(e => e.dept_code == dept_code)
        }
    }
    var activeData = [];
    var activeRateData = [];
    var expiredData = [];
    var expiredRateData = [];
    var fifteenData = [];
    var fifteenRateData = [];
    var monthData = [];
    var monthRateData = [];
    var fortyfiveData = [];
    var fortyfiveRateData = [];
    var sixtyData = [];
    var sixtyRateData = [];
    await rowData.map(function(e){

        var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
        var d2 = moment.parseZone(new Date(e.contract_end_date)).format("YYYY-MM-DD HH:mm:ss")
        var d3 = moment.parseZone(new Date(e.current_rate_valid_till)).format("YYYY-MM-DD HH:mm:ss")
        var ms = moment(d2,"YYYY-MM-DD HH:mm:ss").diff(moment(d1,"YYYY-MM-DD HH:mm:ss"));
        var d = moment.duration(ms);
        var difference = Math.floor(d.asDays());
        var ms1 = moment(d3,"YYYY-MM-DD HH:mm:ss").diff(moment(d1,"YYYY-MM-DD HH:mm:ss"));
        var d1 = moment.duration(ms1);
        var difference1 = Math.floor(d1.asDays());
        if(difference <= 0)
        {
            expiredData.push(e);
        }
        if(difference > 0)
        {
            activeData.push(e);
        }
        if(difference <= 15 && difference >= 0)
        {
            fifteenData.push(e);
        }
        if(difference <= 30 && difference >= 16)
        {
            monthData.push(e);
        }
        if(difference <= 45 && difference >= 31)
        {
            fortyfiveData.push(e);
        }
        if(difference <= 60 && difference >= 46)
        {
            sixtyData.push(e);
        }
        if(difference1 <= 0)
        {
            expiredRateData.push(e);
        }
        if(difference1 > 0)
        {
            activeRateData.push(e);
        }
        if(difference1 <= 15 && difference1 >= 0)
        {
            fifteenRateData.push(e);
        }
        if(difference1 <= 30 && difference1 >= 16)
        {
            monthRateData.push(e);
        }
        if(difference1 <= 45 && difference1 >= 31)
        {
            fortyfiveRateData.push(e);
        }
        if(difference1 <= 60 && difference1 >= 46)
        {
            sixtyRateData.push(e);
        }
    })
    this.setState({
        rowData : rowData,
        activeData:activeData,
        expiredData:expiredData,
        fifteenData:fifteenData,
        monthData:monthData,
        fortyfiveData:fortyfiveData,
        sixtyData:sixtyData,
        activeCnt:activeData.length,
        expiredCnt:expiredData.length,
        fifteenCnt:fifteenData.length,
        monthCnt:monthData.length,
        fortyfiveCnt:fortyfiveData.length,
        sixtyCnt:sixtyData.length,
        activeRateData:activeRateData,
        expiredRateData:expiredRateData,
        fifteenRateData:fifteenRateData,
        monthRateData:monthRateData,
        fortyfiveRateData:fortyfiveRateData,
        sixtyRateData:sixtyRateData,
        activeRateCnt:activeRateData.length,
        expiredRateCnt:expiredRateData.length,
        fifteenRateCnt:fifteenRateData.length,
        monthRateCnt:monthRateData.length,
        fortyfiveRateCnt:fortyfiveRateData.length,
        sixtyRateCnt:sixtyRateData.length,
    })
    //this.onclickCounter(1);
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}
onGridReady1 = params => {
    this.gridApi1 = params.api;
    
    //console.log("Grid is: ", params);
    this.gridColumnApi1 = params.columnApi;

    
};


onGridState1 = () =>{
     //console.log(this.gridApi);
    
     /*Get  Current Columns State and Store in this.colState */
       this.colState1 = this.gridColumnApi1.getColumnState();

     /*Get Current RowGroup Columns State and Store in this.rowGroupState */
       this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();

     /*Get Current Pivot Columns State and Store in this.pivotColumns, 
      * User should enable the Pivot mode.*/
       this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
                
     /*Get Current Filter State and Store in window.filterState */
       window.filterState1 = this.gridApi1.getFilterModel();
       this.gridApi1.setFilterModel(window.filterState1);
       
       
}



closeAlert = () => {
    this.setState({
        show: false
    });
}

async onCellClicked(e)
{
    if(e.colDef.field == "close")
    {
        if (window.confirm("Are You Sure! Do you want to close the contract?"))
        {
            var params = {
                rowId : e.data._id,
                dept_code : e.data.dept_code,
                sub_department : e.data.sub_department,
                divison : e.data.divison,
                contract_name : e.data.contract_name,
                partyData : e.data.partyData,
                partyLength : e.data.partyLength,
                contract_start_date : e.data.contract_start_date,
                contract_end_date : e.data.contract_end_date,
                //contract_validity : e.data.contract_validity,
                rate_validity : e.data.rate_validity,
                ringi_no : e.data.ringi_no,
                ringi_date : e.data.ringi_date,
                process_renewal_contract : e.data.process_renewal_contract,
                actual_renewal_process_start : e.data.actual_renewal_process_start,
                reasons_for_delay : e.data.reasons_for_delay,
                loc : e.data.loc,
                asc_ecm : e.data.asc_ecm,
                pic : e.data.pic,
                updated_by : localStorage.getItem("email"),
                updated_by_user : localStorage.getItem("username"),
            }
            this.setState({
                loadshow : "show-m",
                overly :"show-m",
            })
            redirectURL.post("/consignments/closeContract",params).then((response)=>{
                this.setState({
                    loadshow:'show-n',
                    overly:'show-n',
                    basicTitle:"Success",
                    basicType : "success",
                    show : true
                })
                this.onloadPageData();
            }).catch((e)=>{
                this.setState({
                    loadshow:'show-n',
                    overly:'show-n',
                })
                console.log(e);
            })
        }
    }
    if(e.colDef.field == "extend")
    {
        $(".subH").html("Extend");
        this.setState({
            sliderType : 1,
            rowId : e.data._id,
            overly :"show-m",
            bulkslide : "slider-translate-60p",
        })
        
        loadDateTimeScript();
        var contract_start_date = "";
        if(e.data.contract_start_date !=undefined && e.data.contract_start_date !="" && e.data.contract_start_date !=null)
        {
            contract_start_date = getHyphenDDMMMYYYY(e.data.contract_start_date);
        }
        var contract_end_date = "";
        if(e.data.contract_end_date !=undefined && e.data.contract_end_date !="" && e.data.contract_end_date !=null)
        {
            contract_end_date = getHyphenDDMMMYYYY(e.data.contract_end_date);
        }
        var current_rate_valid_till = "";
        if(e.data.current_rate_valid_till !=undefined && e.data.current_rate_valid_till !="" && e.data.current_rate_valid_till !=null)
        {
            current_rate_valid_till = getHyphenDDMMMYYYY(e.data.current_rate_valid_till);
        }
        var ringi_date = "";
        if(e.data.ringi_date !=undefined && e.data.ringi_date !="" && e.data.ringi_date !=null)
        {
            ringi_date = getHyphenDDMMMYYYY(e.data.ringi_date);
        }
        $("#contract_start_date").val(contract_start_date);
        $("#contract_end_date").val(contract_end_date);
        //$("#contract_validity").val(e.data.contract_validity);
        $("#rate_validity").val(e.data.rate_validity);
        $("#current_rate_valid_till").val(current_rate_valid_till);
        $("#contract_stage").val(e.data.contract_stage);
        $("#ringi_no").val(e.data.ringi_no);
        $("#ringi_date").val(ringi_date);
    }
    if(e.colDef.field == "renewal")
    {
        $(".subH").html("Renewal");
        
        var partyData = e.data.partyData;
        var partyNames = [];
        if(partyData !=undefined && partyData !='')
        {
            //var partyNames = JSON.parse(partyData);
            var partyNames = partyData;
        }    
        await this.setState({
            sliderType : 2,
            rowId : e.data._id,
            overly :"show-m",
            bulkslide : "slider-translate-60p",
            partyData : partyNames,
        })  
        
        loadDateTimeScript();
        var contract_start_date = "";
        if(e.data.contract_start_date !=undefined && e.data.contract_start_date !="" && e.data.contract_start_date !=null)
        {
            contract_start_date = getHyphenDDMMMYYYY(e.data.contract_start_date);
        }
        var contract_end_date = "";
        if(e.data.contract_end_date !=undefined && e.data.contract_end_date !="" && e.data.contract_end_date !=null)
        {
            contract_end_date = getHyphenDDMMMYYYY(e.data.contract_end_date);
        }
        var current_rate_valid_till = "";
        if(e.data.current_rate_valid_till !=undefined && e.data.current_rate_valid_till !="" && e.data.current_rate_valid_till !=null)
        {
            current_rate_valid_till = getHyphenDDMMMYYYY(e.data.current_rate_valid_till);
        }
        var ringi_date = "";
        if(e.data.ringi_date !=undefined && e.data.ringi_date !="" && e.data.ringi_date !=null)
        {
            ringi_date = getHyphenDDMMMYYYY(e.data.ringi_date);
        }
        var actual_renewal_process_start = "";
        if(e.data.actual_renewal_process_start !=undefined && e.data.actual_renewal_process_start !="" && e.data.actual_renewal_process_start !=null)
        {
            actual_renewal_process_start = getHyphenDDMMMYYYY(e.data.actual_renewal_process_start);
        }
        var process_renewal_contract = "";
        if(e.data.process_renewal_contract !=undefined && e.data.process_renewal_contract !="" && e.data.process_renewal_contract !=null)
        {
            process_renewal_contract = getHyphenDDMMMYYYY(e.data.process_renewal_contract);
        }
        $("#extend_contract_start_date").val(contract_start_date);
        $("#extend_contract_end_date").val(contract_end_date);
        //$("#extend_contract_validity").val(e.data.contract_validity);
        $("#extend_rate_validity").val(e.data.rate_validity);
        $("#extend_current_rate_valid_till").val(current_rate_valid_till);
        $("#extended_contract_stage").val(e.data.contract_stage);
        $("#extend_ringi_no").val(e.data.ringi_no);
        $("#extend_ringi_date").val(ringi_date);
        //$("#process_renewal_contract").val(process_renewal_contract);
        $("#actual_renewal_process_start").val(actual_renewal_process_start);  
        console.log(partyNames,"partyData");
        await partyNames.map(async function(p,index){
            var ptIndex="party_name_"+index;
            console.log(ptIndex,p)
            //await this.setState({[ptIndex]:p});
            $("#"+ptIndex).val(p);
        })
    }
    if(e.colDef.field == "history")
    {
        $(".subH").html(e.data.contract_name+" - History");
        var params = {
            rowId : e.data._id
        }
        this.setState({
            loadshow : "show-m",
            overly :"show-m",
        })
        redirectURL.post("/consignments/getContractHistory",params).then(async(response)=>{
            await this.setState({
                loadshow:'show-n',
                overly:'show-n',
                historyAllData: response.data,
                sliderType: 3,
                overly :"show-m",
                bulkslide : "slider-translate-60p"
            })
            await this.filterHistoryData();
        }).catch((e)=>{
            this.setState({
                loadshow:'show-n',
                overly:'show-n',
            })
            console.log(e);
        })
    }
}

async filterHistoryData()
{
    var historyStatus = this.state.historyStatus;
    var historyAllData = this.state.historyAllData;
    if(historyStatus == "all")
    {
        await this.setState({
            historyData: historyAllData,
        });
    }
    else
    {
        var historyData = historyAllData.filter(e => e.status == historyStatus);
        await this.setState({
            historyData: historyData,
        });
    }
}

async filterHistory(){
    var historyStatus = $("#historyStatus").val();
    if(historyStatus !='')
    {
        await this.setState({
            historyStatus: historyStatus,
        });
    }
    else
    {
        await this.setState({
            historyStatus: "all",
        });

    }
    await this.filterHistoryData();
}

onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",
        rowId:"",
        sliderType:""
    });
    
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate-60p"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
   
    
}

uploadBulkFormHandler(event){
   
};

extendFormHandler(event){
    event.preventDefault();
    
    var contract_start_date = $("#contract_start_date").val();
    var contract_end_date = $("#contract_end_date").val();
    //var contract_validity = $("#contract_validity").val();
    var rate_validity = $("#rate_validity").val();
    var current_rate_valid_till = $("#current_rate_valid_till").val();
    var contract_stage = $("#contract_stage").val();
    var ringi_no = $("#ringi_no").val();
    var ringi_date = $("#ringi_date").val();
    
    var formfields = {
        contract_start_date : contract_start_date,
        contract_end_date : contract_end_date,
        //contract_validity : contract_validity,
        rate_validity : rate_validity,
        current_rate_valid_till : current_rate_valid_till,
        contract_stage : contract_stage,
        ringi_no : ringi_no,
        ringi_date : ringi_date,
    }
    
    var flag= 1;
    for(let key in formfields)
    {  
        if(key !="contract_stage")
        {
            if(formfields[key] != undefined && formfields[key] !="") 
            {
                flag = 0;
            }
            else
            {
                flag = 1;
                console.log(formfields[key],key,"formfields[key]")
                break;
            }
        }
    }
    if(flag == 0)
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        })
        // console.log(ringiNo)
        let saveData = new FormData();
        for(let key in formfields)
        {   
            if(key !="contract_stage")
            { 
                if(formfields[key] != undefined && formfields[key] !="") 
                {
                    flag = 0;
                }
                else
                {
                    flag = 1;
                    break;
                }
            }
            saveData.append(key,formfields[key]);
        }
        saveData.append("updated_by",localStorage.getItem("email"));
        saveData.append("updated_by_user",localStorage.getItem("username"));
        
        saveData.append("rowId",this.state.rowId);
        redirectURL.post("/consignments/updatecontractdetails",saveData,{
            headers:{
                'content-type': 'multipart/form-data',
                // 'Access-Control-Allow-Origin':"*",
                // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                'Access-Control-Allow-Headers': 'Origin, Content-Type'
            }
        }).then((response)=>{   
            this.setState({
                basicTitle:"Success",
                basicType : "success",
                show : true,
                loadshow:'show-n',
                overly:'show-n',
                bulkslide:"",
                rowId:"",
                sliderType:""
            });
            this.onloadPageData();
        }).catch((e)=>{
            console.log(e);
        })
              
    }
    else
    {
        this.setState({
            basicTitle:"Fields Should not be Empty",
            basicType : "warning",
            show : true
        })
    }
}

renealFormHandler(event){
    event.preventDefault();
    var rngLength=$("#rngLength").val();
    var partyData=[];
    for(var r=0;r<rngLength;r++)
    {
        var party_name=$("#party_name_"+r).val();
        if(party_name !='')
        {
            partyData.push(party_name)
        }
    }
    var contract_start_date = $("#extend_contract_start_date").val();
    var contract_end_date = $("#extend_contract_end_date").val();
    //var contract_validity = $("#extend_contract_validity").val();
    var rate_validity = $("#extend_rate_validity").val();
    var current_rate_valid_till = $("#extend_current_rate_valid_till").val();
    var contract_stage = $("#extended_contract_stage").val();
    var ringi_no = $("#extend_ringi_no").val();
    var ringi_date = $("#extend_ringi_date").val();
    //var process_renewal_contract = $("#process_renewal_contract").val();
    var actual_renewal_process_start = $("#actual_renewal_process_start").val();
    
    var formfields = {
        //partyData : JSON.stringify(partyData),
        partyData : (partyData),
        partyLength : rngLength,
        contract_start_date : contract_start_date,
        contract_end_date : contract_end_date,
        //contract_validity : contract_validity,
        rate_validity : rate_validity,
        current_rate_valid_till : current_rate_valid_till,
        contract_stage : contract_stage,
        ringi_no : ringi_no,
        ringi_date : ringi_date,
        //process_renewal_contract : process_renewal_contract,
        actual_renewal_process_start : actual_renewal_process_start,
    }
    
    var flag= 1;
    for(let key in formfields)
    { 
        if(key !="contract_stage")
        { 
            if(formfields[key] != undefined && formfields[key] !="") 
            {
                flag = 0;
            }
            else
            {
                flag = 1;
                console.log(formfields[key],key,"formfields[key]")
                break;
            }
        }
    }
    if(flag == 0)
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        })
        // console.log(ringiNo)
        let saveData = new FormData();
        for(let key in formfields)
        {  
            if(key !="contract_stage")
            {
                if(formfields[key] != undefined && formfields[key] !="") 
                {
                    flag = 0;
                }
                else
                {
                    flag = 1;
                    break;
                }
            }
            saveData.append(key,formfields[key]);
        }
        saveData.append("updated_by",localStorage.getItem("email"));
        saveData.append("updated_by_user",localStorage.getItem("username"));
        
        saveData.append("rowId",this.state.rowId);
        redirectURL.post("/consignments/updatecontractrenewaldetails",saveData,{
            headers:{
                'content-type': 'multipart/form-data',
                // 'Access-Control-Allow-Origin':"*",
                // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                'Access-Control-Allow-Headers': 'Origin, Content-Type'
            }
        }).then((response)=>{   
            this.setState({
                basicTitle:"Success",
                basicType : "success",
                show : true,
                loadshow:'show-n',
                overly:'show-n',
                bulkslide:"",
                rowId:"",
                sliderType:""
            });
            this.onloadPageData();
        }).catch((e)=>{
            console.log(e);
        })
              
    }
    else
    {
        this.setState({
            basicTitle:"Fields Should not be Empty",
            basicType : "warning",
            show : true
        })
    }
}

changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    console.log(name);
    let value = event.target.value;
    this.setState({[name]:value});
}
handleAddRingiRow = async() => {
    const item = "";
    await this.setState({
        partyData: [...this.state.partyData, item]
    });
    var partyData = this.state.partyData;
    console.log(partyData,"partyData");
    await partyData.map(async function(p,index){
        var ptIndex="party_name_"+index;
        //await this.setState({[ptIndex]:p});
        $("#"+ptIndex).val(p);
    })
};

handleRemoveRingiRow = (idx) => {
    this.state.partyData.splice(idx, 1);
    this.setState({ partyData: this.state.partyData });
};

async onClickTab(pageType)
{
    if(pageType == "active")
    {
        await this.setState({
            pageType : 1,
            active:"activet",       
            closed:"",
        });
    }
    else
    {
        await this.setState({
            pageType : 2,
            active:"",       
            closed:"activet",
        });
    }
    await this.filterData();
}

async onclickCounter(counterType)
{
    var rowData = [];
    if(counterType == "1")
    {
        rowData = this.state.activeData;
    }
    if(counterType == "6")
    {
        rowData = this.state.expiredData;
    }
    else if(counterType == "2")
    {
        rowData = this.state.fifteenData;
    }
    else if(counterType == "3")
    {
        rowData = this.state.monthData;
    }
    else if(counterType == "4")
    {
        rowData = this.state.fortyfiveData;
    }
    else if(counterType == "5")
    {
        rowData = this.state.sixtyData;
    }
    if(counterType == "7")
    {
        rowData = this.state.activeRateData;
    }
    if(counterType == "8")
    {
        rowData = this.state.expiredRateData;
    }
    else if(counterType == "9")
    {
        rowData = this.state.fifteenRateData;
    }
    else if(counterType == "10")
    {
        rowData = this.state.monthRateData;
    }
    else if(counterType == "11")
    {
        rowData = this.state.fortyfiveRateData;
    }
    else if(counterType == "12")
    {
        rowData = this.state.sixtyRateData;
    }
    this.gridApi.setRowData(rowData);
}

async onClickDept(dept_code)
{
    var alldept = "";
    var deptbdi ="";
    var deptexp = "";
    var deptimp = "";
    var deptrly = "";
    var deptspl = "";
    var deptsndf = "";
    var deptsndg = "";
    var deptsndgj = "";
    var deptsndpj = "";
    var deptsndb = "";
    var deptsndm = "";
    if(dept_code == "all")
    {
        alldept = "activet";
    }
    else if(dept_code == "LOG-BDI")
    {
        deptbdi = "activet";
    }
    else if(dept_code == "LOG-EXP")
    {
        deptexp = "activet";
    }
    else if(dept_code == "LOG-IMP")
    {
        deptimp = "activet";
    }
    else if(dept_code == "LOG-RLY")
    {
        deptrly = "activet";
    }
    else if(dept_code == "LOG-SPL")
    {
        deptspl = "activet";
    }
    else if(dept_code == "SND-FNC")
    {
        deptsndf = "activet";
    }
    else if(dept_code == "SND-G")
    {
        deptsndg = "activet";
    }
    else if(dept_code == "SND-GJ")
    {
        deptsndgj = "activet";
    }
    else if(dept_code == "SND-PZ")
    {
        deptsndpj = "activet";
    }
    else if(dept_code == "SND-B")
    {
        deptsndb = "activet";
    }
    else if(dept_code == "SND-M")
    {
        deptsndm = "activet";
    }
    await this.setState({
        dept_code : dept_code,
        alldept:alldept,
        deptbdi:deptbdi,
        deptexp:deptexp,
        deptimp:deptimp,
        deptrly:deptrly,
        deptspl:deptspl,
        deptsndf:deptsndf,
        deptsndg:deptsndg,
        deptsndgj:deptsndgj,
        deptsndpj:deptsndpj,
        deptsndb:deptsndb,
        deptsndm:deptsndm
    });
    await this.filterData();
}

onCellUpdateData = (params) => {
    if(params.colDef.field == "additional_remarks")
    {
        var additional_remarks = '';
        if(params.value != '')
        {
            additional_remarks = params.value;        
            var upddata = {
                rowId:params.data._id,
                additional_remarks:additional_remarks
            }
            redirectURL.post("/consignments/updateadditionalremarksforcms", upddata)
            .then((response) => {
                //console.log("Resp ",response.data)
            });
        }
    }
}

  render(props) {
    var closedHidden = false;
    if(this.state.pageType == 2 || this.state.alldept == "activet")
    {
        //closedHidden = true;
    }
    var columnwithDefs = [   
        {
            field:'',
            headerName:'',
            colId:"_id",
            //pinned:"left",
            width: 72,
            cellRenderer:function(params){
                let basestring = params.data._id;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<a href="/editcontract/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
                return htmloption;
            }            
        },
        {
            headerName: "Department",
            field: "dept_code",
            width:120,
            filter: true,
            resizable: true,
            editable:false,     
        },
        // {
        //     headerName: "Sub Department",
        //     field: "sub_department",
        //     width:120,
        //     filter: true,
        //     resizable: true,
        //     editable:false,     
        // },
        {
            headerName: "Divison",
            field: "divison",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Contract Name",
            field: "contract_name",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Party",
            field: "party",
            width:250,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.partyData !=undefined && params.data.partyData !="")
                {
                    //var partyData = JSON.parse(params.data.partyData);
                    var partyData = params.data.partyData;
                    // if(partyData.length > 1)
                    // {
                    //     return partyData.join(",");
                    // }
                    // else
                    // {
                    //     return partyData;
                    // }
                    return partyData;
                }
            }
        },
        {
            //pinned:"right",
            headerName: "Contract Status",
            field:"status",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.status == 0)
                {
                    return "Expired";
                }
                else if(params.data.status == 1)
                {
                    return "Active";
                }
                else if(params.data.status == 2)
                {
                    return "Closed";
                }
                else if(params.data.status == 3)
                {
                    return "Extended";
                }
                else if(params.data.status == 4)
                {
                    return "Rate Renewal";
                }
                else
                {
                    return "N/A";
                }
            }
        },
        {
            headerName: "Current Contract/Rate Stage",
            field: "contract_stage",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Contract Start Date",
            field: "contract_start_date",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.contract_start_date !=undefined && params.data.contract_start_date !="" && params.data.contract_start_date !=null)
                {
                    return getHyphenDDMMMYYYY(params.data.contract_start_date);
                }
            }
        },
        {
            headerName: "Contract End Date",
            field: "contract_end_date",
            width:140,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.contract_end_date !=undefined && params.data.contract_end_date !="" && params.data.contract_end_date !=null)
                {
                    return getHyphenDDMMMYYYY(params.data.contract_end_date);
                }
            }
        },
        // {
        //     headerName: "Contract Validity",
        //     field: "contract_validity",
        //     width:100,
        //     filter: true,
        //     resizable: true,
        //     editable:false
        // },
        {
            headerName: "Rate Validity",
            field: "rate_validity",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Current Rate Valid till",
            field: "current_rate_valid_till",
            width:140,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.current_rate_valid_till !=undefined && params.data.current_rate_valid_till !="" && params.data.current_rate_valid_till !=null)
                {
                    return getHyphenDDMMMYYYY(params.data.current_rate_valid_till);
                }
            }
        },
        {
            headerName: "Ringi No",
            field: "ringi_no",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Ringi Date",
            field: "ringi_date",
            width:140,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.ringi_date !=undefined && params.data.ringi_date !="" && params.data.ringi_date !=null)
                {
                    return getHyphenDDMMMYYYY(params.data.ringi_date);
                }
            }
        },
        {
            headerName: "Process for renewal of Contract / Rate to be initiated",
            field: "process_renewal_contract",
            width:220,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.process_renewal_contract !=undefined && params.data.process_renewal_contract !="" && params.data.process_renewal_contract !=null)
                {
                    return getHyphenDDMMMYYYY(params.data.process_renewal_contract);
                }
            }
        },
        {
            headerName: "Actual Renewal process start",
            field: "actual_renewal_process_start",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.actual_renewal_process_start !=undefined && params.data.actual_renewal_process_start !="" && params.data.actual_renewal_process_start !=null)
                {
                    return getHyphenDDMMMYYYY(params.data.actual_renewal_process_start);
                }
            }
        },
        {
            headerName: "Reasons for delay",
            field: "reasons_for_delay",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Additional Remarks/Comments",
            field: "additional_remarks",
            width:250,
            filter: true,
            resizable: true,
            editable:true
        },
        {
            headerName: "Location of Original Contract",
            field: "loc",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Availability of Scanned Contract in ECM",
            field: "asc_ecm",
            width:180,
            filter: true,
            resizable: true,
            editable:false
        },
        // {
        //     headerName: "PIC",
        //     field: "pic",
        //     width:160,
        //     filter: true,
        //     resizable: true,
        //     editable:false
        // },
        {
            //pinned:"left",
            headerName: "",
            field:"close",
            width:50,
            filter: true,
            resizable: true,
            hide: closedHidden,
            cellRenderer:function(params){
                var htmloption = '<i title="Close" class="icofont icofont-close-line-circled f20 danger-ico"></i>';
                if(params.data.status != 2)
                {
                    return htmloption;
                }
            }       
        },
        {
            //pinned:"left",
            headerName: "",
            field:"extend",
            width:50,
            filter: true,
            resizable: true,
            hide: closedHidden,
            cellRenderer:function(params){
                var htmloption = '<i title="Extend" class="icofont icofont-expand f20 info-ico"></i>';
                try{
                    if(params.data.status == 2)
                    {
                        return "";
                    }
                    else
                    {
                        return htmloption;
                    }
                }
                catch(e)
                {
                    return "";
                }
            }       
        },
        {
            //pinned:"left",
            headerName: "",
            field:"renewal",
            width:50,
            filter: true,
            resizable: true,
            hide: closedHidden,
            cellRenderer:function(params){
                var htmloption = '<i title="Renewal" class="icofont icofont-refresh f20 warning-ico"></i>';
                try{
                    if(params.data.status == 2)
                    {
                        return "";
                    }
                    else
                    {
                        return htmloption;
                    }
                }
                catch(e)
                {
                    return "";
                }
            }       
        },
        {
            //pinned:"left",
            headerName: "",
            field:"history",
            width:50,
            filter: true,
            resizable: true,
            cellRenderer:function(params){
                var htmloption = '<i title="History" class="icofont icofont-history f20 primary-ico"></i>';
                return htmloption;
            }       
        },
    ]
    
    var columnwithDefsForHistory = [      
        {
            headerName: "Updated By",
            field: "created_by_user",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Updated On",
            field: "created_date",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.created_date !=undefined && params.data.created_date !="" && params.data.created_date !=null)
                {
                    return getHyphenDDMMMYYYY(params.data.created_date);
                }
            }
        },
        {
            headerName: "Party",
            field: "party",
            width:250,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.partyData !=undefined && params.data.partyData !="")
                {
                    //var partyData = JSON.parse(params.data.partyData);
                    var partyData = params.data.partyData;
                    // if(partyData.length > 1)
                    // {
                    //     return partyData.join(",");
                    // }
                    // else
                    // {
                    //     return partyData;
                    // }
                    return partyData;
                }
            }
        },
        {
            headerName: "Contract Start Date",
            field: "contract_start_date",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.contract_start_date !=undefined && params.data.contract_start_date !="" && params.data.contract_start_date !=null)
                {
                    return getHyphenDDMMMYYYY(params.data.contract_start_date);
                }
            }
        },
        {
            headerName: "Contract End Date",
            field: "contract_end_date",
            width:140,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.contract_end_date !=undefined && params.data.contract_end_date !="" && params.data.contract_end_date !=null)
                {
                    return getHyphenDDMMMYYYY(params.data.contract_end_date);
                }
            }
        },
       
        {
            headerName: "Rate Validity",
            field: "rate_validity",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Current Rate Valid till",
            field: "current_rate_valid_till",
            width:140,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.current_rate_valid_till !=undefined && params.data.current_rate_valid_till !="" && params.data.current_rate_valid_till !=null)
                {
                    return getHyphenDDMMMYYYY(params.data.current_rate_valid_till);
                }
            }
        },
        {
            headerName: "Ringi No",
            field: "ringi_no",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Ringi Date",
            field: "ringi_date",
            width:140,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.ringi_date !=undefined && params.data.ringi_date !="" && params.data.ringi_date !=null)
                {
                    return getHyphenDDMMMYYYY(params.data.ringi_date);
                }
            }
        },
        {
            headerName: "Process for renewal of Contract / Rate to be initiated",
            field: "process_renewal_contract",
            width:220,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.process_renewal_contract !=undefined && params.data.process_renewal_contract !="" && params.data.process_renewal_contract !=null)
                {
                    return getHyphenDDMMMYYYY(params.data.process_renewal_contract);
                }
            }
        },
        {
            headerName: "Actual Renewal process start",
            field: "actual_renewal_process_start",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.actual_renewal_process_start !=undefined && params.data.actual_renewal_process_start !="" && params.data.actual_renewal_process_start !=null)
                {
                    return getHyphenDDMMMYYYY(params.data.actual_renewal_process_start);
                }
            }
        },
        {
            headerName: "Reasons for delay",
            field: "reasons_for_delay",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Location of Original Contract",
            field: "loc",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Availability of Scanned Contract in ECM",
            field: "asc_ecm",
            width:180,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "PIC",
            field: "pic",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            pinned:"right",
            headerName: "Status",
            field:"status",
            width:80,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.status == 1)
                {
                    return "Active";
                }
                else if(params.data.status == 2)
                {
                    return "Closed";
                }
                else if(params.data.status == 3)
                {
                    return "Extended";
                }
                else if(params.data.status == 4)
                {
                    return "Rate Renewal";
                }
                else
                {
                    return "N/A";
                }
            }
        },
    ]

    return (
      <div class="container-fluid" style={{marginTop:"2%"}}>
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
        
        <div className="f20" style={{marginBottom:"10px"}}>
            <ul className="d-tabs">
                <li onClick={this.onClickTab.bind(this,"active")} className={"lttabs "+(this.state.active)}>
                    <button type="button" className="btn">Active</button>
                </li>

                <li onClick={this.onClickTab.bind(this,"closed")} className={"lttabs "+(this.state.closed)}>
                    <button type="button" className="btn">Closed</button>
                </li>
            </ul>
        </div>
        <div className="f24" style={{marginBottom:"10px",marginTop:"2%"}}>
            <ul className="d-tabs cms-tabs">
                <li onClick={this.onClickDept.bind(this,"all")} className={"lttab "+(this.state.alldept)}>
                    <button type="button" className="btn">All</button>
                </li>

                <li onClick={this.onClickDept.bind(this,"LOG-BDI")} className={"lttab "+(this.state.deptbdi)}>
                    <button type="button" className="btn">LOG-BDI</button>
                </li>

                <li onClick={this.onClickDept.bind(this,"LOG-EXP")} className={"lttab "+(this.state.deptexp)}>
                    <button type="button" className="btn">LOG-EXP</button>
                </li>

                <li onClick={this.onClickDept.bind(this,"LOG-IMP")} className={"lttab "+(this.state.deptimp)}>
                    <button type="button" className="btn">LOG-IMP</button>
                </li>

                <li onClick={this.onClickDept.bind(this,"LOG-RLY")} className={"lttab "+(this.state.deptrly)}>
                    <button type="button" className="btn">LOG-RLY</button>
                </li>

                <li onClick={this.onClickDept.bind(this,"LOG-SPL")} className={"lttab "+(this.state.deptspl)}>
                    <button type="button" className="btn">LOG-SPL</button>
                </li>

                <li onClick={this.onClickDept.bind(this,"SND-FNC")} className={"lttab "+(this.state.deptsndf)}>
                    <button type="button" className="btn">SND-FNC</button>
                </li>

                <li onClick={this.onClickDept.bind(this,"SND-G")} className={"lttab "+(this.state.deptsndg)}>
                    <button type="button" className="btn">SND-G</button>
                </li>

                <li onClick={this.onClickDept.bind(this,"SND-GJ")} className={"lttab "+(this.state.deptsndgj)}>
                    <button type="button" className="btn">SND-GJ</button>
                </li>

                <li onClick={this.onClickDept.bind(this,"SND-PZ")} className={"lttab "+(this.state.deptsndpj)}>
                    <button type="button" className="btn">SND-PZ</button>
                </li>

                <li onClick={this.onClickDept.bind(this,"SND-B")} className={"lttab "+(this.state.deptsndb)}>
                    <button type="button" className="btn">SND-B</button>
                </li>
                <li onClick={this.onClickDept.bind(this,"SND-M")} className={"lttab "+(this.state.deptsndm)}>
                    <button type="button" className="btn">SND-M</button>
                </li>
            </ul>
        </div>
        <div className="col-xl-12 col-lg-12 col-sm-12 beffect" style={{padding:"0"}}>
            <div className="card">
                <div className="card-body n-p-0">
                    <div className="crm-numbers pb-0">
                        <div className="row col-xl-12 col-lg-12 col-sm-12">
                            <div className="col cirlce-d">
                                <span className="f15" style={{color:"#db3031"}}>Active</span>
                                <div className="row col-xl-12 col-lg-12 col-sm-12" style={{padding:"0"}}>
                                    <div className="col-xl-6 col-lg-6 col-sm-6 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"1")}>
                                        <p className="f11" style={{height:"40px"}}>Contract Validity</p>
                                        <h5 className={"f25 mt-20p txt-info"}>
                                            <span className="counter">
                                                <CountUp end={(this.state.activeCnt)?this.state.activeCnt:0}/>
                                            </span>
                                        </h5> 
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-6 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"7")}>
                                        <p className="f11" style={{height:"40px"}}>Rate Validity</p>
                                        <h5 className={"f25 mt-20p txt-info"}>
                                            <span className="counter">
                                                <CountUp end={(this.state.activeRateCnt)?this.state.activeRateCnt:0}/>
                                            </span>
                                        </h5> 
                                    </div>
                                </div>                              
                            </div>
                            <div className="col cirlce-d">
                                <span className="f15" style={{color:"#db3031"}}>Expired</span>
                                <div className="row col-xl-12 col-lg-12 col-sm-12">
                                    <div className="col-xl-6 col-lg-6 col-sm-6 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"6")}>
                                        <p className="f11" style={{height:"40px"}}>Contract Validity</p>
                                        <h5 className={"f25 mt-20p txt-danger-dark"}>
                                            <span className="counter">
                                                <CountUp end={(this.state.expiredCnt)?this.state.expiredCnt:0}/>
                                            </span>
                                        </h5> 
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-6 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"8")}>
                                        <p className="f11" style={{height:"40px"}}>Rate Validity</p>
                                        <h5 className={"f25 mt-20p txt-danger-dark"}>
                                            <span className="counter">
                                                <CountUp end={(this.state.expiredRateCnt)?this.state.expiredRateCnt:0}/>
                                            </span>
                                        </h5> 
                                    </div>
                                </div>                                
                            </div>
                            <div className="col cirlce-d">
                                <span className="f15" style={{color:"#db3031"}}>Expires In 15 Days</span>
                                <div className="row col-xl-12 col-lg-12 col-sm-12">
                                    <div className="col-xl-6 col-lg-6 col-sm-6 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"2")}>
                                        <p className="f11" style={{height:"40px"}}>Contract Validity</p>
                                        <h5 className={"f25 mt-20p txt-warning-medium"}>
                                            <span className="counter">
                                                <CountUp end={(this.state.fifteenCnt)?this.state.fifteenCnt:0}/>
                                            </span>
                                        </h5> 
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-6 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"9")}>
                                        <p className="f11" style={{height:"40px"}}>Rate Validity</p>
                                        <h5 className={"f25 mt-20p txt-warning-medium"}>
                                            <span className="counter">
                                                <CountUp end={(this.state.fifteenRateCnt)?this.state.fifteenRateCnt:0}/>
                                            </span>
                                        </h5> 
                                    </div>
                                </div>                                   
                            </div>
                            <div className="col cirlce-d">
                                <span className="f15" style={{color:"#db3031"}}>Expires In 30 Days</span>
                                <div className="row col-xl-12 col-lg-12 col-sm-12">
                                    <div className="col-xl-6 col-lg-6 col-sm-6 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"3")}>
                                        <p className="f11" style={{height:"40px"}}>Contract Validity</p>
                                        <h5 className={"f25 mt-20p txt-warning-dark"}>
                                            <span className="counter">
                                                <CountUp end={(this.state.monthCnt)?this.state.monthCnt:0}/>
                                            </span>
                                        </h5> 
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-6 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"10")}>
                                        <p className="f11" style={{height:"40px"}}>Rate Validity</p>
                                        <h5 className={"f25 mt-20p txt-warning-dark"}>
                                            <span className="counter">
                                                <CountUp end={(this.state.monthRateCnt)?this.state.monthRateCnt:0}/>
                                            </span>
                                        </h5> 
                                    </div>
                                </div>
                            </div>
                            <div className="col cirlce-d">
                                <span className="f15" style={{color:"#db3031"}}>Expires In 45 Days</span>
                                <div className="row col-xl-12 col-lg-12 col-sm-12">
                                    <div className="col-xl-6 col-lg-6 col-sm-6 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"4")}>
                                        <p className="f11" style={{height:"40px"}}>Contract Validity</p>
                                        <h5 className={"f25 mt-20p txt-danger-light"}>
                                            <span className="counter">
                                                <CountUp end={(this.state.fortyfiveCnt)?this.state.fortyfiveCnt:0}/>
                                            </span>
                                        </h5> 
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-6 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"11")}>
                                        <p className="f11" style={{height:"40px"}}>Rate Validity</p>
                                        <h5 className={"f25 mt-20p txt-danger-light"}>
                                            <span className="counter">
                                                <CountUp end={(this.state.fortyfiveRateCnt)?this.state.fortyfiveRateCnt:0}/>
                                            </span>
                                        </h5> 
                                    </div>
                                </div>                                   
                            </div>
                            <div className="col cirlce-d">
                                <span className="f15" style={{color:"#db3031"}}>Expires In 60 Days</span>
                                <div className="row col-xl-12 col-lg-12 col-sm-12">
                                    <div className="col-xl-6 col-lg-6 col-sm-6 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"5")}>
                                        <p className="f11" style={{height:"40px"}}>Contract Validity</p>
                                        <h5 className={"f25 mt-20p txt-danger-medium"}>
                                            <span className="counter">
                                                <CountUp end={(this.state.sixtyCnt)?this.state.sixtyCnt:0}/>
                                            </span>
                                        </h5> 
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-sm-6 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onclickCounter.bind(this,"12")}>
                                        <p className="f11" style={{height:"40px"}}>Rate Validity</p>
                                        <h5 className={"f25 mt-20p txt-danger-medium"}>
                                            <span className="counter">
                                                <CountUp end={(this.state.sixtyRateCnt)?this.state.sixtyRateCnt:0}/>
                                            </span>
                                        </h5> 
                                    </div>
                                </div>                                   
                            </div>
                        </div>                                
                    </div>
                </div>
            </div>
        </div>
        <div className="card col-xl-12">
            <div className="card-header d-flex justify-content-between">
                <h5>
                    <i className="icofont icofont-book cus-i"></i> <span>Contract Monitoring System</span>
                </h5>
                <h5>    
                    <a href="/addcontract" className="btn btn-success" style={{float:"right",marginLeft:"10px"}}>Add Contract</a>
                    {(localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0 || localStorage.getItem("roles").indexOf("SR MGMT") >= 0) ?
                    <a href="/cmsautomailconfig" className="btn btn-warning" style={{float:"right"}}>Auto Mailer Configuration</a>
                    : ""}
                </h5>
            </div>
            <div className="card-body col-xl-12 col-lg-12">
                <div className="f16" style={{marginBottom:"10px"}}>
                    <p style={{textAlign:"right"}}>
                        Close : <i title="Close" className="icofont icofont-close-line-circled f20 danger-ico"></i> &nbsp;&nbsp;
                        Extend : <i title="Extend" className="icofont icofont-expand f20 info-ico"></i>&nbsp;&nbsp;
                        Renewal : <i title="Renewal" className="icofont icofont-refresh f20 warning-ico"></i>&nbsp;&nbsp;
                        History : <i title="History" className="icofont icofont-history f20 primary-ico"></i>
                    </p>
                </div>
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH"></h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                <div className="container-fluid">
                    {(this.state.sliderType == 1) ?
                    <form id="extend-form" onSubmit={this.extendFormHandler.bind(this)} encType="multipart/form-data">
                        <div className="row col-xl-12">
                            <div className={"col-xl-4 "+this.state.requestFields}>
                                <label>Contract Start Date* : </label>
                                <input type="text" name="contract_start_date" id="contract_start_date" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} required/>
                            </div>
                            <div className={"col-xl-4 "+this.state.requestFields}>
                                <label>Contract End Date* : </label>
                                <input type="text" name="contract_end_date" id="contract_end_date" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} required/>
                            </div>
                            {/* <div className="col-xl-4">
                                <label>Contract Validity* : </label>
                                <input type="text" name="contract_validity" id="contract_validity" className="form-control" onChange={this.changeHandler} required/>
                            </div> */}
                        </div>
                        <div class="hr-dashed"></div>
                        <div className="row col-xl-12">
                            <div className="col-xl-3">
                                <label>Rate Validity* : </label>
                                <select class="form-control col-xl-12 col-lg-12" name="rate_validity" id="rate_validity" required onChange={this.changeHandler}>
                                    <option value="">-- Select Option --</option>
                                    <option value="N/A">N/A</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Half yearly">Half yearly</option>
                                    <option value="Half yearly except 1st month">Half yearly except 1st month</option>
                                    <option value="6 months">6 months</option>
                                    <option value="8 months">8 months</option>
                                    <option value="9 months">9 months</option>
                                    <option value="Annually">Annually</option>
                                    <option value="Bi-Annual">Bi-Annual</option>
                                    <option value="2 years">2 years</option>
                                    <option value="After 2 years">After 2 years</option>
                                    <option value="After 3 years">After 3 years</option>
                                    <option value="After 5 years">After 5 years</option>
                                </select>
                            </div>
                            <div className="col-xl-3">
                                <label>Current Rate Validity Till* : </label>
                                <input type="text" name="current_rate_valid_till" id="current_rate_valid_till" className="datetimepicker_date form-control" onChange={this.changeHandler} required/>
                            </div>
                            <div className="col-xl-3">
                                <label>Current Contract/Rate Stage : </label>
                                <select name="contract_stage" id="contract_stage" className="form-control" onChange={this.changeHandler}>
                                    <option value="">Select Contract/Rate Stage</option>
                                    <option value="Discussion with stakeholders">Discussion with stakeholders</option>
                                    <option value=" RFQ Ringi under process"> RFQ Ringi under process</option>
                                    <option value="RFQ Ringi approved">RFQ Ringi approved</option>
                                    <option value="Floating RFQ">Floating RFQ</option>
                                    <option value="Negotiations/Discussions in progress">Negotiations/Discussions in progress</option>
                                    <option value="Final Ringi under process">Final Ringi under process</option>
                                    <option value="Final Ringi approved">Final Ringi approved</option>
                                    <option value="Contract/Rate/Work order prepared and submitted">Contract/Rate/Work order prepared and submitted</option>
                                    <option value="Contract/Rate/Work order signed">Contract/Rate/Work order signed</option>
                                </select>
                            </div>
                            <div className="col-xl-3">
                                <label>Ringi No* : </label>
                                <input type="text" name="ringi_no" id="ringi_no" className="form-control"  onChange={this.changeHandler} required/>
                            </div>
                        </div>
                        <div class="hr-dashed"></div>
                        <div className="row col-xl-12">
                            <div className={"col-xl-4 "+this.state.requestFields}>
                                <label>Ringi Date* : </label>
                                <input type="text" name="ringi_date" id="ringi_date" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} required/>
                            </div>
                        </div>
                        <div className="row col-xl-12 col-lg-12 form-group">
                            <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                        </div>
                    </form>
                    : ""}
                    {(this.state.sliderType == 2) ?
                    <form id="extend-form" onSubmit={this.renealFormHandler.bind(this)} encType="multipart/form-data">
                        <div className={"row col-xl-12 "}>
                            {this.state.partyData.map((item, idx) => (
                            <div className="col-xl-4" key={idx}>
                                <label>Party : </label>
                                <input type="text" name={"party_name_"+idx} id={"party_name_"+idx} className="form-control" onChange={this.changeHandler} />
                                <h6 style={{marginTop:"1%",cursor:"pointer",color:"red"}} onClick={() => this.handleRemoveRingiRow(idx)}>Clear</h6>
                            </div>
                            ))}
                        </div>
                        <input type="hidden" id="rngLength" value={this.state.partyData.length}/>
                        <div class={"row col-sm-12 "} style={{marginBottom:"2%"}}>
                            <div className="row col-xl-12 m-top-1">
                                <div class="col-xl-12">
                                    <button class="btn btn-primary" type="button" onClick={this.handleAddRingiRow}>Add More Fields +</button>
                                </div>
                            </div>
                        </div>
                        <div className="row col-xl-12">
                            <div className={"col-xl-4 "+this.state.requestFields}>
                                <label>Contract Start Date* : </label>
                                <input type="text" name="extend_contract_start_date" id="extend_contract_start_date" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} required/>
                            </div>
                            <div className={"col-xl-4 "+this.state.requestFields}>
                                <label>Contract End Date* : </label>
                                <input type="text" name="extend_contract_end_date" id="extend_contract_end_date" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} required/>
                            </div>
                            {/* <div className="col-xl-4">
                                <label>Contract Validity* : </label>
                                <input type="text" name="extend_contract_validity" id="extend_contract_validity" className="form-control" onChange={this.changeHandler} required/>
                            </div> */}
                        </div>
                        <div class="hr-dashed"></div>
                        <div className="row col-xl-12">
                            <div className="col-xl-3">
                                <label>Rate Validity* : </label>
                                <select class="form-control col-xl-12 col-lg-12" name="extend_rate_validity" id="extend_rate_validity" required onChange={this.changeHandler}>
                                    <option value="">-- Select Option --</option>
                                    <option value="N/A">N/A</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Half yearly">Half yearly</option>
                                    <option value="Half yearly except 1st month">Half yearly except 1st month</option>
                                    <option value="6 months">6 months</option>
                                    <option value="8 months">8 months</option>
                                    <option value="9 months">9 months</option>
                                    <option value="Annually">Annually</option>
                                    <option value="Bi-Annual">Bi-Annual</option>
                                    <option value="2 years">2 years</option>
                                    <option value="After 2 years">After 2 years</option>
                                    <option value="After 3 years">After 3 years</option>
                                    <option value="After 5 years">After 5 years</option>
                                </select>
                            </div>
                            <div className="col-xl-3">
                                <label>Current Rate Validity Till* : </label>
                                <input type="text" name="extend_current_rate_valid_till" id="extend_current_rate_valid_till" className="datetimepicker_date form-control" onChange={this.changeHandler} required/>
                            </div>
                            <div className="col-xl-3">
                                <label>Current Contract/Rate Stage : </label>
                                <select name="extended_contract_stage" id="extended_contract_stage" className="form-control" onChange={this.changeHandler}>
                                    <option value="">Select Contract/Rate Stage</option>
                                    <option value="Discussion with stakeholders">Discussion with stakeholders</option>
                                    <option value=" RFQ Ringi under process"> RFQ Ringi under process</option>
                                    <option value="RFQ Ringi approved">RFQ Ringi approved</option>
                                    <option value="Floating RFQ">Floating RFQ</option>
                                    <option value="Negotiations/Discussions in progress">Negotiations/Discussions in progress</option>
                                    <option value="Final Ringi under process">Final Ringi under process</option>
                                    <option value="Final Ringi approved">Final Ringi approved</option>
                                    <option value="Contract/Rate/Work order prepared and submitted">Contract/Rate/Work order prepared and submitted</option>
                                    <option value="Contract/Rate/Work order signed">Contract/Rate/Work order signed</option>
                                </select>
                            </div>
                            <div className="col-xl-3">
                                <label>Ringi No* : </label>
                                <input type="text" name="extend_ringi_no" id="extend_ringi_no" className="form-control"  onChange={this.changeHandler} required/>
                            </div>
                        </div>
                        <div class="hr-dashed"></div>
                        <div className="row col-xl-12">
                            <div className={"col-xl-4 "+this.state.requestFields}>
                                <label>Ringi Date* : </label>
                                <input type="text" name="extend_ringi_date" id="extend_ringi_date" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} required/>
                            </div>
                            {/* <div className={"col-xl-4 "+this.state.requestFields}>
                                <label>Process for renewal of Contract / Rate to be initiated (Target)* : </label>
                                <input type="text" name="process_renewal_contract" placeholder="" id="process_renewal_contract" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} required/>
                            </div> */}
                            <div className={"col-xl-4 "+this.state.requestFields}>
                                <label>Actual Renewal process start (Actual)* : </label>
                                <input type="text" name="actual_renewal_process_start" id="actual_renewal_process_start" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} required/>
                            </div>
                        </div>
                        <div className="row col-xl-12 col-lg-12 form-group">
                            <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                        </div>
                    </form>
                    : ""}
                    {(this.state.sliderType == 3) ?
                    <div className="row col-sm-12" style={{marginBottom:"2%"}}>
                        <div className="col-sm-4">
                            <select className="form-control" id="historyStatus" onChange={this.filterHistory} >
                                <option value="">Filter By Status</option>
                                <option value="all">All</option>
                                <option value="1">Active</option>
                                <option value="2">Closed</option>
                                <option value="3">Extended</option>
                                <option value="4">Rate Renewal</option>
                            </select>
                        </div>
                    </div>
                    : ""}
                    {(this.state.sliderType == 3) ?
                        <div id="myGrid1" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnwithDefsForHistory}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.historyData}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady1}
                            onGridState={this.onGridState1}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            gridOptions={{
                                context: { componentParent: this }
                            }}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            />
                    </div>
                    : ""}
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function loadDateTimeScript(){
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};