
import React, { Component } from 'react';
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from "react-select";
import CriticalDalayScreen from "./criticaldelay"
import TMSFORLSPCEODASHBOARD from './ticketmanagementsystemforlspceo';
var redirectURL = require("../redirectURL");
export default class LiveAbnormality extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ontime_performance: 0,
      filterType: 'All',
      totalSumedUpValues: [],
      loadshow: 'show-m',
      overly: 'show-m',
      returnPathName:"",
    }
  }
  componentDidMount() {
    const searchParams = this.props.location.search;
    if (searchParams.includes('lspceodashboardDemo')) {
       var returnPathName = "lspceodashboardDemo";
    }
    const { transporterCode } = this.props.match.params;
    console.log(transporterCode, 'transporterCode');
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      returnPathName:returnPathName,
      transporter_code: transporterCode,
    })
  }

  onClickHideAll() {
    this.setState({
      loadshow: 'show-n',
      overly: 'show-n'
    });

  }
  render() {
    return (
      <div className='row'>
        <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{ position: "fixed", top: "43%" }}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        <div style={{ textAlign: "center", background: "#171C8F", width: '100%', margin: "5px", borderRadius: "5px", height: '70px', paddingBottom: '25px' }}>
          <h6 class="txt-dark f40" style={{ marginTop: "0px" }}>
            <span style={{ fontSize: "20px", color: "#FFFFFF" }}>
              <span>Immediate Action Required</span>

            </span>
          </h6>
        </div>

        <div className="row card-body">
        
          {
       this.state.returnPathName == "lspceodashboardDemo"?
       <div className="col-xl-12 col-lg-12">  
       <h5>
        <span className="float-right f12 ">
           <a className="btn btn-danger" style={{ float: "left", marginLeft: "10%", marginBottom: "20px" }}
             href="/lspceodashboardDemo"
           >Back</a>
         </span>
          </h5>
     </div>
     :
     <div className="col-xl-12 col-lg-12">
          <h5>
           <span className="float-right f12 ">
              <a className="btn btn-danger" style={{ float: "left", marginLeft: "10%", marginBottom: "20px" }}
                href="/lspceodashboard"
              >Back</a>
            </span>
             </h5>
        </div>
        } 

        </div>

        <div className="row col-xl-12 col-lg-12">
          <div className="col-xl-6 col-lg-6">
            {this.state.transporter_code && (
              <CriticalDalayScreen transporterCode={this.state.transporter_code} />
            )}
          </div>
          <div className="col-xl-6 col-lg-6">
            {this.state.transporter_code && (
              <TMSFORLSPCEODASHBOARD transporterCode={this.state.transporter_code} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
