import React, { Component } from 'react';
import _ from "lodash";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import EmissionModelMaster from './emissionmodelmaster';
import EmissionTruckMaster from './emissiontruckmaster';
import EmissionStandardDistance from "./emissiondstandarddistance"
import EmissionFactors from './emissionfactors'
$.datetimepicker.setLocale('en');


export default class EmissionTabs extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            modelMaster: 'activet',
            truckMaster: "",
            standardDistance: '',
            emissionFactor:''
        };

    }
    componentDidMount() {
    }

 onClickTab(pageType) {
        if (pageType == "modelMaster") {
            var modelMaster = 'activet';
            var truckMaster = "";
            var standardDistance = "";
            var emissionFactor = "";
        } else if (pageType == "truckMaster") {
            var modelMaster = '';
            var truckMaster = "activet";
            var standardDistance = "";
            var emissionFactor = "";
        } else if (pageType == "standardDistance") {
            var modelMaster = '';
            var truckMaster = "";
            var standardDistance = "activet";
            var emissionFactor = "";
        }else if (pageType == "emissionFactor") {
            var modelMaster = '';
            var truckMaster = "";
            var standardDistance = "";
            var emissionFactor = "activet";
        }
        this.setState({
            modelMaster: modelMaster,
            truckMaster: truckMaster,
            standardDistance: standardDistance,
            emissionFactor:emissionFactor
        })
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                   <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                        <div style={{ margin: '20px' }}>

                            <ul className="d-tabs">
                                <li onClick={this.onClickTab.bind(this, "modelMaster")} className={"ttabs " + (this.state.modelMaster)}>
                                    <button type="button" className="btn" >Model Master</button>
                                </li>
                                <li onClick={this.onClickTab.bind(this, "truckMaster")} className={"ttabs " + (this.state.truckMaster)}>
                                    <button type="button" className="btn" >Truck Master</button>
                                </li>
                                <li onClick={this.onClickTab.bind(this, "standardDistance")} className={"ttabs " + (this.state.standardDistance)}>
                                    <button type="button" className="btn" >Standard Distance</button>
                                </li>
                                <li onClick={this.onClickTab.bind(this, "emissionFactor")} className={"ttabs " + (this.state.emissionFactor)}>
                                    <button type="button" className="btn" >Emission Factor</button>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                {this.state.modelMaster === 'activet' ? (
                    <EmissionModelMaster />
                ) : this.state.truckMaster === 'activet' ? (
                    <EmissionTruckMaster />
                ) :  this.state.standardDistance === 'activet' ? (
                    <EmissionStandardDistance />
                ):
                <EmissionFactors/>
                }
            </div>

        );
    }
}
