/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _, { join } from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import Grid from '../layouts/gridComponent';
import CSVFileValidator from 'csv-file-validator'
import ConsignmentActions from './ConsignmentActionsComponent';
import CommentActions from '../layouts/commentsComponent';
import SegmentAction from "../layouts/segmentAction";
import KpiActions from './kpiActionsComponent';
import KpiDeviationActions from './kpiDeviationActionsComponent';
import SegmentSideBar from "../layouts/segmentComponent";
import KpiTransitDelayActions from './kpiTransitDelayActions';
import Nooverspeed from './nooverspeedComponent.js';
import Notransit from './notransitComponent.js';
import RoutemapAction from './routemapActionComponent';
import RoutemapTransitAction from './routemaptransit';
import RoutemapNoGPSAction from './routemapnogps';
import RouteNightDrivemap from './routenightdrivemap';
import RoutemapenrouteAction from './routemapenroute';
import KpiNightDriveActions from './kpiNightDriveActionsComponent';
import Nonightdrive from './nonightdriveComponent.js';
import KpiEnrouteStoppagesActions from './kpiEnrouteStoppagesActionsComponent';
import KpiLoadingDelayActions from './kpiLoadingDelayActions';
import KpiUnLoadingDelayActions from './kpiUnLoadingDelayActions';
import Noenroutes from './noenroutesComponent.js';
import Gps from './gpsActionComponent.js';
import Gpsdata from './gpsdataaction.js';
import NoGps from './nogpsActionComponent.js';
import NoGpsLifetime from './nogpslifetimeActionComponent.js';
import Consignmentforceclose from './consignmentforceclose.js';
import Select from 'react-select';
import ForceCloseSideBar from '../layouts/forceclosuresidebarcomponent.js';
import Modal from 'react-responsive-modal';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import AppendComponentIndex from '../common/appendComponents';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
//import Counters from '../layouts/consignmentcountersComponent';
import Counters from '../layouts/consignmentkpicountersComponent';
import DateFormater from '../layouts/dateComponent';
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import ConsignmentDrawMap from '../common/consignmentdrawmap';
import ComponentIndex from '../common/appendComponents';
import CustomDateComponent from '../common/dateFilterComponent';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ProbableAction from "../layouts/probableDataComponent";
import Consignmenttickets from "./consignmenttickets";
import ExportPDF from '../layouts/exportpdf.js';

$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
 
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;
var containerslist=[];
export default class ManageConsignments extends Component {

	constructor(props){
		super(props);
		this.displayData=[];
		this.state={
			deptcode:"",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
			eventAction: null,
			forceCloseRowNode : "",
			allRowData: [],
			activeFilter: null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			sliderCommentTranslate : "",
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			sliderSegmentTranslate : "",
			mapinfo:'',
			commentsRowData:[],
			dealer:'',
			maptruckno:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			rownode:'',
			leg_no:'',
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			valuecolumns:[],
			activeconsignemnt:[],
			activetrucks:[],
			transitdelays:[],
			gpsdatana:[],
			overspeedtrucks:[],
			nightdrive:[],
			open: false,
			openforceclose: false,
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			routeTruck:{}, 
			sidebarSubHeader:'',
			googelRoutes:'',
			plants:[],
			statusList:["MSIL-G", "MSIL-M", "MSILMC","MSILME", "SMG", "MUL", "TKM", "TKAP-DHR"],
			bulkPrioritySlide : "",
			hideTransporterBtns : "show-m",
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
				pivot : true,
				enableValue: true,
    			enableRowGroup: true,
      	    },
      	    rowData: [],
			textContent : "Consignments",
			maprowData:null,
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			consignmentActions:ConsignmentActions,
			commentActions:CommentActions,
			SegmentAction:SegmentAction,
			kpiActions:KpiActions,
			kpiDeviationActions:KpiDeviationActions,
			kpiTransitDelayActions:KpiTransitDelayActions,
			nooverspeed:Nooverspeed,
			notransit:Notransit,
			kpiNightDriveActions:KpiNightDriveActions,
			nonightdrive:Nonightdrive,
			kpiEnrouteStoppagesActions:KpiEnrouteStoppagesActions,
			noenroutes:Noenroutes,
			kpiLoadingDelayActions:KpiLoadingDelayActions,
			kpiUnLoadingDelayActions:KpiUnLoadingDelayActions,
			dateFormater:DateFormater,
			gps:Gpsdata,
			nogps:NoGps,
			noGpsLifetime:NoGpsLifetime,
			RoutemapenrouteAction:RoutemapenrouteAction,
			customLoadingCellRenderer: CustomLoadingCellRenderer,
			consignmentforceclose:Consignmentforceclose,
			customDateComponent:CustomDateComponent,
			ProbableAction:ProbableAction,
			Consignmenttickets:Consignmenttickets
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
			showFiltersForLongHaulScreenTab : 'show-m',
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype:{"value":'all', "label":'All'},
			tripvalue:'',	
			consigner:[{"value":'all', "label":'All'}],
			consignee:[{"value":'all', "label":'All'}],			
			originalData:[],
			isCounterDisplay:1,
			screenpage:'',
			pagetitle:'Active Consignments',
			countersjson:'',
			originalcountersjson:'',
			consigneecoords:'',
			consigner_coordinates:'',
			containerslist:[],
			forceclosedata:'',
			reached_dealer_time:moment.parseZone().format('YYYY-MM-DD'),
			reachedhh:'',
			reachedmm:'',
			reachedss:'00',
			left_dealer_time:moment.parseZone().format('YYYY-MM-DD'),
			lefthh:'',
			leftmm:'',
			leftss:'00',
			invoice_time:'',
			invoicehh:'',
			invoicemm:'',
			segementConsignmentCode : "",
			counter_screen : "",
			priority_counter_display : 0,
			invoicess:'00',
			reasonforceclose:'',
			sliderTranslatesidebar:'',
			consignment_details:'',
			startDatetime:'',
			endDatetime:'',
			tolls : [],
			geofence_coordinates : [],
			excelStyles : [
				{
					id : "dateFormat",
					dataType: 'dateTime',
					numberFormat: { format: "yyyy-mm-dd" }
				}
			],
			getRowHeight: function(params) {
				if(typeof params.data.dct_comment != 'undefined'){
					//console.log("pars ", params.data.dct_comment.length)
					return (
					//params.api.getSizesForCurrentTheme().rowHeight *
					(100 * Math.floor(params.data.dct_comment.length / 50))
					);
				}
				if(typeof params.data.vin_list != 'undefined'){
					//console.log("pars ", params.data.vin_list.toString().length)
					return (
					//params.api.getSizesForCurrentTheme().rowHeight *
					(100 * Math.floor(params.data.vin_list.toString().length/ 50))
					);
				}
				else if(typeof params.data.force_closure_reason != 'undefined'){
					//console.log("pars ", params.data.dct_comment.length)
					return (
					//params.api.getSizesForCurrentTheme().rowHeight *
					(100 * Math.floor(params.data.force_closure_reason.length / 50))
					);
				}
				else{
					return 30;
				}
			},
			defaultsdate:'',
			defaultedate:'',
			EndDateForMap:'',
			StartDateForMap:'',
			movementtype:{"value":"all","label":"All"},
			usergridstate:[],
			screenurl:"",
			screentitle:"",
			showumncr:0,
			showummove:0,
			usermanualncrmodal:false,
			usermanualmovemodal:false,
			slideuploadeway:"",
			cancelled_data: [],
			pod_received_data: [],
			trip_closed_by_pod: [],
			returnConsignments: [],
			tnpButtonTitle: "",
			tnpReturnConsignmentsVisibilty: false,
			consBtn: "btn-danger",
			returnConsBtn: "btn-default",
			longHaulBtn :  "btn-default",
			truckReportedDate : [],
			filterCities:[],
			filterStates:[],
			filterClusters:[],
			filterTransporters:[],
			beforeNewFilterRowData:[],
			beforeNewFiltercountersjson:[],
			flCity : "",
			flState : "",
			flCluster : "",
			flTransporter : "",
			vin:"",
			distinctTnpUserDeptCodes : [],
			baseRouteCoords: [],
			logsSLider : '',
			showFiltersForPartLevelScreenTab : 'show-n',
			parking_coordinates : [],
			activeBtn: "",
			deliveredBtn: "",
			allBtn: "",
			dispatch_type:{label:"ALL",vaue:"ALL"}
		}
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.onShowCommentsDiv = this.onShowCommentsDiv.bind(this);
		this.changeSegmentHandler = this.changeSegmentHandler.bind(this);
		this.onShowTimelineDiv = this.onShowTimelineDiv.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.onShowTransitRouteMap = this.onShowTransitRouteMap.bind(this);
		this.onShowNoGPSRouteMap = this.onShowNoGPSRouteMap.bind(this);
		this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
		this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
		this.onShowEnrouteRouteMap = this.onShowEnrouteRouteMap.bind(this);
		this.onClickShowMapView = this.onClickShowMapView.bind(this);
		this.onClickForceClose = this.onClickForceClose.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.closeSegmentSideBar = this.closeSegmentSideBar.bind(this);
		this.onLoadGetConsignmentsList = this.onLoadGetConsignmentsList.bind(this);
		this.onShowConsignmentTickets = this.onShowConsignmentTickets.bind(this);
		this.onShowUploadEWay = this.onShowUploadEWay.bind(this);
		this.onBulkPriorityBtn = this.onBulkPriorityBtn.bind(this);
		this.uploadEway = this.uploadEway.bind(this);
		this.changeFileHandler = this.changeFileHandler.bind(this);
		this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
		this.getUserGridState = this.getUserGridState.bind(this);

	}

	componentDidCatch(error, info)
	{
		console.log("Error here ", error)
		console.log("Error Info here ", info)
	}
	logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
	componentDidMount(){
		loadDateTimeScript();
		var edate = moment.parseZone().format('YYYY-MM-DD');
		if(this.props.match.path.includes("deliveredconsignments") || this.props.match.path.includes("allconsignments"))
		{
			var sdate = moment.parseZone().subtract(3, 'days').format('YYYY-MM-DD');
		}
		else
		{
			var sdate = moment.parseZone().subtract(10, 'days').format('YYYY-MM-DD');
		}
		
		
		// var sdate = '2020-03-01';

		var edatetime = moment.parseZone().format('DD-MM-YYYY HH:mm');
		var sdatetime = moment.parseZone().format('DD-MM-YYYY HH:mm');
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});
		//console.log("Props ", this.props.match)
		this.setState({
			loadshow:'show-m',
			startDate:sdate,
			endDate:edate,
			defaultsdate:sdate,
			defaultedate:edate,
			startDatetime:sdatetime,
			endDatetime:edatetime
		});
		var urlpath;
		var dpt;
		var isCounterDisplay=1;
		var screenpage='';
		var title='';
		var transporter_code = localStorage.getItem('transportercode');
            if(transporter_code !=undefined && transporter_code !="undefined" && transporter_code !="")
            {
                var tptCode = JSON.parse(transporter_code);
               transporter_code = tptCode[0];
            }
		var consignData = {}
		redirectURL.post("/consignments/getdistinctTnpUserDeptCodes",).then((response) =>{
			let responseData = response.data
			// console.log("responseData",responseData)
			this.setState({
				distinctTnpUserDeptCodes : responseData
			})
		})
			
		if(this.props.match.path == "/sndconsignments")
		{
			
			urlpath = '/consignments/sndconsignments';
			dpt='SNDG';
			title = 'Active Consignments (Sales and Dispatch)';
			var consignData = {
				dept_code:dpt,
				startDate:sdate,
				endDate:edate,
				consigner:this.state.consigner
			} 
			this.setState({
				startDate : "",
				endDate : "",
				counter_screen : "active",
				activeBtn: "btn-info"
			});
		}
		else if(this.props.match.path == "/prtconsignments")
		{
			urlpath = '/consignments/prtconsignments';
			dpt='LOG-PRT';
			title = 'Active Consignments (Spare Parts)';
			var consignData = {
				dept_code:dpt,
				startDate:sdate,
				endDate:edate,
				consigner:this.state.consigner,
				movement:[this.state.movementtype]
			} 
			this.setState({
				startDate : "",
				endDate : "",
				counter_screen : "active",
				priority_counter_display : 1,
				activeBtn: "btn-info",
			});
		}
		else if(this.props.match.path == "/tnpconsignments")
		{
			urlpath = '/consignments/tnpconsignments';
			dpt='LOG-TNP';
			title = 'Active Consignments (Train and Production)';
			var consignData = {
				dept_code:dpt,
				startDate:sdate,
				endDate:edate,
				consigner:this.state.consigner,
				consignee:this.state.consignee,
				triptype:this.state.triptype
			}
			var transportercode = localStorage.getItem('transportercode');
			if(transportercode !=undefined && transportercode !="undefined" && transportercode !="")
			{
				var tptCode = JSON.parse(transportercode);
				consignData.transporter_code = JSON.stringify(tptCode);
			} 
			this.setState({
				startDate : sdate,
				endDate : edate,
				counter_screen : "active",
				tnpButtonTitle: "Active",
				activeBtn: "btn-info",
			});
		}
		else if(this.props.match.path == "/sndallconsignments")
		{
			
			urlpath = '/consignments/allsndconsignments';
			dpt='SNDG';
			isCounterDisplay=1;
			screenpage='all';
			title = 'All Consignments (Sales and Dispatch)';
			var consignData = {
				dept_code:dpt,
				startDate:sdate,
				endDate:edate,
				consigner:this.state.consigner,
				screen : "all"
			} 
			this.setState({
				startDate :sdate,
				endDate : edate,
				allBtn: "btn-info",
			});
			
		}
		else if(this.props.match.path == "/prtallconsignments")
		{
			urlpath = '/consignments/allprtconsignments';
			dpt='LOG-PRT';
			isCounterDisplay=1;
			screenpage='all';
			title = 'All Consignments (Spare Parts)';
			var consignData = {
				dept_code:dpt,
				startDate:sdate,
				endDate:edate,
				consigner:this.state.consigner,
				movement:[this.state.movementtype],
				screen : "all"
			} 
			this.setState({
				startDate :sdate,
				endDate : edate,
				allBtn: "btn-info",
			});
		}
		else if(this.props.match.path == "/tnpallconsignments")
		{
			urlpath = '/consignments/alltnpconsignments';
			dpt='LOG-TNP';
			isCounterDisplay=1;
			screenpage='all';
			title = 'All Consignments (Train and Production)';
			var consignData = {
				dept_code:dpt,
				startDate:sdate,
				endDate:edate,
				consigner:this.state.consigner,
				consignee:this.state.consignee,
				triptype:this.state.triptype,
				screen : "all"
			} 
			var transportercode = localStorage.getItem('transportercode');
			if(transportercode !=undefined && transportercode !="undefined" && transportercode !="")
			{
				var tptCode = JSON.parse(transportercode);
				consignData.transporter_code = JSON.stringify(tptCode);
			} 
			this.setState({
				tnpButtonTitle: "All",
				allBtn: "btn-info"
			})
		}
		else if(this.props.match.path == "/snddeliveredconsignments")
		{
			urlpath = '/consignments/deliveredsndconsignments';
			dpt='SNDG';
			isCounterDisplay=1;
			screenpage='deliver';
			title = 'Delivered Consignments (Sales and Dispatch)';
			var consignData = {
				dept_code:dpt,
				startDate:sdate,
				endDate:edate,
				consigner:this.state.consigner
			}
			this.setState({
				startDate :sdate,
				endDate : edate,
				deliveredBtn: "btn-info",
			});
		}
		else if(this.props.match.path == "/prtdeliveredconsignments")
		{
			urlpath = '/consignments/deliveredprtconsignments';
			dpt='LOG-PRT';
			isCounterDisplay=1;
			screenpage='deliver';
			title = 'Delivered Consignments (Spare Parts)';
			var consignData = {
				dept_code:dpt,
				startDate:sdate,
				endDate:edate,
				consigner:this.state.consigner,
				movement:[this.state.movementtype]
			} 
			this.setState({
				startDate :sdate,
				endDate : edate,
				deliveredBtn: "btn-info",
			});
		}
		else if(this.props.match.path == "/tnpdeliveredconsignments")
		{
			urlpath = '/consignments/deliveredtnpconsignments';
			dpt='LOG-TNP';
			isCounterDisplay=1;
			screenpage='deliver';
			title = 'Delivered Consignments (Train and Production)';
			var consignData = {
				dept_code:dpt,
				startDate:sdate,
				endDate:edate,
				consigner:this.state.consigner,
				consignee:this.state.consignee,
				triptype:this.state.triptype
			} 
			this.setState({
				tnpButtonTitle: "Delivered",
				deliveredBtn: "btn-info"
			})
		}
		else if(this.props.match.path == "/railconsignments")
		{
			urlpath = '/consignments/railsndconsignments';
			dpt='SNDG';
			isCounterDisplay=1;
			screenpage='rail';
			title = 'Rail Consignments';
			var consignData = {
				dept_code:dpt,
				startDate:sdate,
				endDate:edate,
				consigner:this.state.consigner
			} 
		}
		else if(this.props.match.path == "/ncrconsignments")
		{
			urlpath = '/consignments/ncrsndconsignments';
			dpt='SNDG';
			isCounterDisplay=1;
			screenpage='ncr';
			title = 'NCR Consignments (Sales and Dispatch)';
			var consignData = {
				dept_code:dpt,
				startDate:sdate,
				endDate:edate,
				consigner:this.state.consigner
			} 

			this.setState({
				startDate :sdate,
				endDate : edate,
				showumncr:1
			});
		}
		else if(this.props.match.path == "/moveconsignments")
		{
			urlpath = '/consignments/movesndconsignments';
			dpt='SNDG';
			isCounterDisplay=1;
			screenpage='move';
			title = 'Move Consignments (Sales and Dispatch)';
			var consignData = {
				dept_code:dpt,
				startDate:sdate,
				endDate:edate,
				consigner:this.state.consigner
			} 
			this.setState({
				showummove:1
			})
		}
		else if(this.props.match.path == "/insidemsilvicinty")
		{
			urlpath = '/consignments/allsndconsignments';
			dpt='SNDG';
			isCounterDisplay=1;
			screenpage='all';
			title = 'Inside MSIL Vicinity';
			var consignData = {
				dept_code:dpt,
				startDate:sdate,
				endDate:edate,
				consigner:this.state.consigner,
				screen : "insidemsil"
			} 
			this.setState({
				startDate :sdate,
				endDate : edate
			});
			
		}
		
		
		this.setState({
			deptcode:dpt,
			isCounterDisplay:isCounterDisplay,
			screenpage:screenpage,
			pagetitle:title
		});


		redirectURL.post("/consignments/getConsignmentsNewFilterKeys",{dept_code:dpt})
		.then((resp) => {
			var cities = resp.data.cities;
			var filterCities=  [];
		   if(cities != undefined )
            {
			 if(cities.length > 0)
			 {
				cities.map(function(c){
					filterCities.push({
						label : c,
						value : c,
					});
				});
			  }
		    }
			var states = resp.data.states;
			var filterStates=  [];
	           if(states != undefined )
	            {
			      if(states.length > 0)
			      {
				       states.map(function(s){
					       filterStates.push({
						label : s,
						value : s,
					   });
				     });
			      }
		        }
			var clusters = resp.data.clusters;
			var filterClusters=  [];
			if(clusters != undefined )
			{
			if(clusters.length > 0)
			{
				clusters.map(function(cl){
					filterClusters.push({
						label : cl,
						value : cl,
					});
				});
			}
		    }
			var transporters = resp.data.transporters;
			var filterTransporters=  [];
			if(transporters != undefined )
			{
			if(transporters.length > 0)
			{
				transporters.map(function(t){
					filterTransporters.push({
						label : t,
						value : t,
					});
				});
			}
		    }							
			this.setState({
				filterCities:filterCities,
				filterStates:filterStates,
				filterClusters:filterClusters,
				filterTransporters:filterTransporters,
			});
		})
		
		//Use following code after ACL enable
		

		//console.log("dept_code ", this.state.dept_code)
		/*urlpath = '/consignments/consignmentByDepartment';
			dpt=localStorage.getItem('dept_code');
		redirectURL.post(urlpath,consignData)    */

		

		var activeconsignemntarr=[];
		var activetrucksarr=[];
		var transitdelaysarr=[];
		var gpsdatanaarr=[];
		var overspeedtrucksarr=[];
		var nightdrivearr=[];
		var enroutearr=[];
		var loadingarr=[];
		var unloadingarr=[];
		var alltrucksarr =[]; 

		redirectURL.post("/consignments/plants",{dept_code:dpt})
		.then((resp) => {			
				this.setState({
					plants:resp.data
				});
		})

		this.onLoadGetConsignmentsList(urlpath, consignData);
		this.getUserGridState(title)
	};

	getUserGridState = (pagetitle) => {
		let params = {userId:localStorage.getItem("userid"),screenurl:window.location.pathname}
		// if(pagetitle !== ""  && pagetitle !== undefined) params.pagetitle = pagetitle
		redirectURL.post("/consignments/usergridstates",params).then( async (resp) => {
			// console.log("resp ", resp)
			this.setState({
				usergridstate:resp.data
			});
			this.restoreGridStates();
		})
	}
	

	onLoadGetConsignmentsList(urlpath, parameters)
	{
		this.setState({
			loadshow:'show-m',
			overly:'show-m'
		})
		if(localStorage.getItem("region") != undefined && localStorage.getItem("region") != "")
		{
			parameters.region = localStorage.getItem("region");
			parameters.sales_type = localStorage.getItem("sales_type")
		}
		
		console.log("parameters",parameters,urlpath);
		redirectURL.post(urlpath, parameters)
		  .then((response) => {
			console.log(response,"response")
            var records = response.data.consignments;
			var pagecounters = response.data.counters;
			pagecounters['deviation_flag'] = records.filter(e => e.deviation_flag > 0).length;

			if(this.props.match.path == "/snddeliveredconsignments"){
				var totalCO2Emission = records.reduce((acc, curr) => {
					const emission = curr.co2_emission >0 ? curr.co2_emission / 1000 : 0;
					return acc + emission;
				  }, 0);
				  pagecounters['co2_emission'] = totalCO2Emission;

				var totalCO2EmissionStandard = records.reduce((acc, curr) => {
					const emission = curr.co2_emission_standard_dist >0 ? curr.co2_emission_standard_dist / 1000 : 0;
					return acc + emission;
				  }, 0);
				  pagecounters['co2_emission_standard_dist'] = totalCO2EmissionStandard;
			}
			if(this.props.match.path == "/sndconsignments"){
				var totalCO2EmissionInTransit = records.reduce((acc, curr) => {
				  const emission = curr.co2_emission_intransit >0 ? curr.co2_emission_intransit / 1000 : 0;
				  return acc + emission;
				}, 0);
				pagecounters['co2_emission_intransit'] = totalCO2EmissionInTransit;
			}
			  var totalCO2EmissionSavings = records.reduce((acc, curr) => {
				// console.log('currcurrcurrcurr',curr.co2_emission_savings)
				const emission = curr.co2_emission_savings > 0 ? curr.co2_emission_savings /1000 : 0;
				return acc + emission;
			  }, 0);
			  pagecounters["co2_emission_savings"] = totalCO2EmissionSavings
			var returnConsignments = (response.data.returnConsignments != undefined)?response.data.returnConsignments:[] 
			pagecounters.cancelled_count = "Not Applicable"
			// console.log("records", records);
			var cancelled_data = []
			var pod_received_data = [];
			var trip_closed_by_pod = [];
			//console.log("counters", pagecounters);
			records = records.sort(GetSortDescOrder("_id"));
			if(records.length > 0)
			{
				
				if(this.props.match.path == "/sndallconsignments" 
				|| this.props.match.path == "/prtallconsignments" 
				|| this.props.match.path == "/tnpallconsignments")
				{

					if(this.props.match.path == "/prtallconsignments")
					{
						cancelled_data = records.filter(function(e){
							return e.cancelled == 1
						})
						pagecounters.cancelled_count = cancelled_data.length	
						pod_received_data = records.filter(function(e){
							return e.pod_received == 1
						})
						pagecounters.pod_received_data = pod_received_data.length
						trip_closed_by_pod = records.filter(function(e){
							return e.trip_closed_by_pod == 1
						})
						// console.log(trip_closed_by_pod, "trip_closed_by_pod")
						pagecounters.trip_closed_by_pod = trip_closed_by_pod.length
					}

				}
				else if(this.props.match.path == "/ncrconsignments"
				|| this.props.match.path == "/moveconsignments")
				{

					records = records.filter(rec => rec.status <= 3)
				}
				else if(this.props.match.path == "/snddeliveredconsignments" 
				|| this.props.match.path == "/prtdeliveredconsignments" 
				|| this.props.match.path == "/tnpdeliveredconsignments")
				{
					if(this.props.match.path == "/prtdeliveredconsignments")
					{
						pod_received_data = records.filter(function(e){
							return e.pod_received == 1
						})
						pagecounters.pod_received_data = pod_received_data.length
						trip_closed_by_pod = records.filter(function(e){
							return e.trip_closed_by_pod == 1
						})
						pagecounters.trip_closed_by_pod = trip_closed_by_pod.length
						cancelled_data = records.filter(function(e){
							return e.cancelled == 1
						})
						pagecounters.cancelled_count = cancelled_data.length	

					}
					records = records.filter(rec => rec.status > 2)
				}
				else{

					records = records.filter(rec => rec.status >= 1)
					if(this.props.match.path == "/prtconsignments")
					{
						pod_received_data = records.filter(function(e){
							return e.pod_received == 1
						})
						pagecounters.pod_received_data = pod_received_data.length
						trip_closed_by_pod = records.filter(function(e){
							return e.trip_closed_by_pod == 1
						})
						pagecounters.trip_closed_by_pod = trip_closed_by_pod.length
					}
				}
			}
			var trucksReported = [];
			if(this.props.match.path == "/prtconsignments" ||  this.props.match.path == "/prtdeliveredconsignments" ||  this.props.match.path == "/prtallconsignments") {
				trucksReported = records.filter(function(e){
					return (e.truck_reporting_date !== undefined && e.truck_reporting_date !== "")
				})
			}
			//console.log("records ", records.filter(e=> e.tpt_confirm_delivery == 1))
			if(localStorage.getItem("is_sales_type") == 1)
			{
				var recordsarr= []
				if(records.length > 0)
				{
					records.map((item) => {
						
						if(localStorage.getItem("sales_type") == "Arena")
						{
							item.vin_list =item.arena_list;
						}
						if(localStorage.getItem("sales_type") == "nexa")
						{
							item.vin_list =item.nexa_list;
						}
						recordsarr.push(item)
					})
				
					records = recordsarr;
				}
			}
			var gridData = records;
			
			var selectedCity = $("#selectedCity").text();
			var selectedState = $("#selectedState").text();
			var selectedCluster = $("#selectedCluster").text();
			var selectedTransporter = $("#selectedTransporter").text();
			// console.log(selectedCity,selectedState,selectedCluster,selectedTransporter,"filters")
			//console.log(filterRowData,"0")
			if(selectedCity !=undefined && selectedCity !='' && selectedCity !="Select...")
			{
				gridData = gridData.filter(e => e.consignee_city == selectedCity);
				// console.log(gridData,"1")
			}
			if(selectedState !=undefined && selectedState !='' && selectedState !="Select...")
			{
				gridData = gridData.filter(e => e.consignee_state == selectedState);
			}
			if(selectedCluster !=undefined && selectedCluster !='' && selectedCluster !="Select...")
			{
				gridData = gridData.filter(e => e.cluster == selectedCluster);
			}
			if(selectedTransporter !=undefined && selectedTransporter !='' && selectedTransporter !="Select...")
			{
				gridData = gridData.filter(e => e.transporter_name == selectedTransporter);
			}
			this.setState({
				rowData: gridData,
				allRowData: gridData,
				originalData: gridData,
				beforeNewFilterRowData: gridData,
				loadshow:'show-n',
				overly:'show-n',
				countersjson:pagecounters,
				originalcountersjson:pagecounters,
				beforeNewFiltercountersjson:pagecounters,
				cancelled_data: cancelled_data,
				trip_closed_by_pod: trip_closed_by_pod,
				pod_received_data: pod_received_data,
				returnConsignments: returnConsignments,
				// consBtn: "btn-danger",
				// returnConsBtn: "btn-default",
				// longHaulBtn : "btn-default",
				truckReportedDate :trucksReported
			});
			// if(this.state.urlpath == '/consignments/filterTNPActiveConsignments')
			// {

			// }
			// else
			// {
			// 	this.setState({

			// 	})
			// }
			
			
		  })
		.catch(function (error) {
		    console.log(error);
		});
	}

	onShowTimelineDiv = (e) => {
		//console.log("Props data ", e)
		this.setState({
			loadshow:'show-m'
		});
		var reqData = {
            consignment_code:e.consignment_code
        }
        //console.log("reqData: ", reqData);
		redirectURL.post('/consignments/timeline',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			//console.log("records", records);
		
			this.setState({
				uploadDivWidth:'30%',
				sliderTranslate:"slider-translate-50p",
				showDiv:'show-m',
				timelinedata:records,
				loadshow:'show-n',
				overly:'show-m'
			});
		})
		.catch(function (error) {
		console.log(error);
		});
		
	}
	
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			tabsliderTranslate:'',
			showDiv:'show-n',
			sliderTranslatesidebar:"",
			sliderCommentTranslate:"",
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			loadshow:'show-n',
			slideuploadeway:""
		});
	}
	onShowGoogleRoute(consignmentcode, consigneecode, legno,truck_no){
	//	console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var reqData = {
			consignment_code:consignmentcode,
			consignee_code:consigneecode,
			leg_no:legno,
			truck_no:truck_no
		}
		redirectURL.post('/consignments/googlemaproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			// console.log("Google ", response.data)
			//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]' 
			this.setState({
				googelRoutes:response.data.googleroute
			})
		})
		.catch(function(e){
			console.log(e)
		})
	}
	 onShowCommentsDiv = async (params) =>{
		//  console.log(params);
		var reqparams = {
			consignment_code : params.data.consignment_code,
			truck_no : params.data.truck_no,
			dept_code : this.state.deptcode,
			screen : "consignments",
			type : "Force Close"
		}
		// console.log(reqparams);
		
		let eventLabel = googleAnalytics.page.action.comments;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		
		var commentHeaders = [
			{
				headerName : "Truck No",
				field : "truck_no",
				resizable : true,
				width:100,
				filter : true
			  },
			  {
				headerName : "Consignment Code",
				field : "consignment_code",
				resizable : true,
				width:120,
				filter : true
			  },
			  {
				headerName : "User Type",
				field : "user_type",
				width:100,
				resizable : true,
				filter : true,
				valueGetter : function(params){
				  if(params.data.user_type == "ORGUSER"){
					return "MSIL"
				  }
				  if(params.data.user_type == "DCTUSER"){
					return "DCT"
				  } 
				  if(params.data.user_type == "TRANSPORTER"){
					return "TRANSPORTER"
				  } 
				}
			  },
			  {
				headerName : "Comment",
				field : "comment",
				width:250,
				cellClass: ['wraptext'],
				resizable : true,
				filter : true
			  },
			  {
				headerName : "Commented By",
				field : "username",
				width:150,
				resizable : true,
				filter:true
			  },
			
			  {
				headerName : "Commented Date",
				field : "comment_date",
				resizable : true,
				filter : true,
				width:150,
				valueGetter : function(params){
				  if(params.data.comment_date != ""){
					return getHyphenDDMMMYYYYHHMM(params.data.comment_date);
				  }
				}
			  }
		]
		if(params != 0)
		{
			var overspeedData=[];
			
			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			var deptcode = this.state.deptcode;
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: commentHeaders,
						overlayNoRowsTemplate: 'No rows to show',
														
					},
					getDetailRowData:async function(param) {
						param.successCallback([]);
						// console.log("Step 4 ",param)
						// console.log("reqparams",reqparams);
						await redirectURL.post("/consignments/getCommentLogs",{
							consignment_code : param.data.consignment_code,
							truck_no : param.data.truck_no,
							dept_code : deptcode,
							screen : "consignments",
							type : "Force Close"
						}).then(async (response) =>{
							//console.log("Step 1 ",response.data)
							// console.log(response.data.data);
							var comments=response.data.data;
							param.successCallback(comments);
							
							
							//console.log("Step 2 ",response.data)
							
						}).catch(function(error){
							console.log(error);
						})
						 
					},
					masterDetail: true
				}
			});
			// console.log(propsdata);
			if(params.column.colDef.field == 'comment_exists')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: commentHeaders
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
	}
	onShowRouteDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		// console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow:'show-m',
			maptruckno:e.truck_no
		});

		// If gateouttime is current time - It shows "No Route data available"
		if(e.invoice_time && ["F", "M", "G", "T", "C","BP","B","N","I"].includes(e.consigner_code))
		{
				if(e.gate_out_time)
				{
					
					var gateouttime = moment.parseZone(e.gate_out_time).format('YYYY-MM-DD HH:mm:ss');
					
				}
				else{
					if(e.gate_in_time){
						var gateouttime = moment.parseZone(e.gate_in_time).format('YYYY-MM-DD HH:mm:ss');
					}
					else
					{
						// console.log("Step6")
						var gateouttime = moment.parseZone(e.invoice_time).format('YYYY-MM-DD HH:mm:ss');
					}
				}
			}else{
				if(e.dept_code == "LOG-TNP"){
					if(![undefined,"undefined",null,""].includes(e.gate_out_time)){
						var gateouttime = moment.parseZone(e.gate_out_time).format('YYYY-MM-DD HH:mm:ss');
					}else{
						var gateouttime = moment.parseZone(e.invoice_time).format('YYYY-MM-DD HH:mm:ss');
					}
				}else{
					var gateouttime = moment.parseZone(e.gate_out_time).format('YYYY-MM-DD HH:mm:ss');
				}
			}
		
		
		var edate ='';
		var end_date;
		if(e.status <= 2)
		{
			//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1) * 86400000);
			//var edatesecond = new Date(ms);
			// console.log("status less than 2");
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
			edate = getHyphenDDMMMYYYYHHMM(end_date);
		}
		else if((e.status == 3 || e.status == 4) && (this.state.deptcode !== 'LOG-TNP'))
		{	
			if(e.tvp_gate_in){
				end_date = e.tvp_gate_in.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenDDMMMYYYYHHMM(e.tvp_gate_in);
			}
			else if (e.recent_dealer_reported) {
				end_date = e.recent_dealer_reported.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenDDMMMYYYYHHMM(e.recent_dealer_reported);
			}
			// console.log("status 3 and 4");
		}
		else if(e.status == 5)
		{
			if(e.tvp_gate_in){
				end_date = e.tvp_gate_in.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenDDMMMYYYYHHMM(e.tvp_gate_in);
			}
			else if (e.left_dealer_marked) {
				end_date = e.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenDDMMMYYYYHHMM(e.left_dealer_marked);
			}
			// console.log("status 5");
		}
		else if(e.status == 6)
		{
			if(e.tvp_gate_in){
				end_date = e.tvp_gate_in.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenDDMMMYYYYHHMM(e.tvp_gate_in);
			}else if (e.force_closure_time) {
				//end_date = e.force_closure_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				end_date = getHyphenDDMMMYYYYHHMM(e.force_closure_time);
			}
			// console.log("status 6");
		}
		else{
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
			
			edate = getHyphenDDMMMYYYYHHMM(end_date);
			// console.log("in else condition");
		}
		
		//console.log("end date",end_date);
		//console.log("checking e",e)

		if ((e.transit_time) > 0)
		{
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + ((e.transit_time+1) *24*60*60*1000));
		}
		else{
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + 2*24*60*60*1000);
		}	
		
		var sdate = seconddate.getFullYear()+"-"+(seconddate.getMonth()+1)+"-"+seconddate.getDate()+" "+seconddate.getHours()+":"+seconddate.getMinutes()+":"+seconddate.getSeconds();
		
		if (!end_date) {
			// When end date is unavailable then current date is considered as end date.
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			//console.log("Defining end_date ", end_date);
		} 
		this.setState({
			StartDateForMap : gateouttime,
			EndDateForMap : end_date
		})
		// console.log(gateouttime,end_date,"1406")
		var reqData = {
            truck_no:e.truck_no,
			// consignment_code:e.consignment_code,
			// consignee_code:e.consignee_code,
			gate_out_time:gateouttime,
			seconddate:end_date,
			screen:"consignment"
        }
		if(e.consignee_code !== undefined) reqData.consignee_code = e.consignee_code
		if(e.consignment_code !== undefined) reqData.consignment_code = e.consignment_code
        console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			console.log("Props data ", records)

			this.setState({
				loadshow:'show-m'
			});
			try{
				if(records.coords.length == 0 || records.status == "failure")
				{
					//console.log("records", records.coords);
					this.setState({
						show: true,
						basicTitle:'No Route data available',
						basicType:"danger",
						loadshow:'show-n'
					});
				}
				else{
					if(records != '')
					{
						var sdate = e.gate_out_time;
						if(e.invoice_time)
						{
							// console.log("came into invoice time", e.invoice_time)
							//console.log("Step1", e.invoice_time)
							var sdate = e.invoice_time;
						}
						else{
							//console.log("Step2")
							if(e.gate_in_time)
							{
								// console.log("came into gate in time", e.gate_in_time)
								//console.log("Step3")
								var sdate = e.gate_in_time;
							}
							else{
								//console.log("Step4")
								if(e.gate_out_time){
									//console.log("Step5")
									// console.log("came into gate out time", e.gate_out_time)
									var sdate = e.gate_out_time;
								}
							}
						}


						var edate ='';
						if(e.status <= 4)
						{
							//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
							//var edatesecond = new Date(ms);
							end_date = moment.parseZone().format('DD/MM/YYYY HH:mm:ss')
							edate = getHyphenDDMMMYYYYHHMM(end_date);
						}
						else if(e.status == 5)
						{
							if (e.left_dealer_marked)
							{
								edate = getHyphenDDMMMYYYYHHMM(e.left_dealer_marked);
							}
							else{
								end_date = moment.parseZone().format('DD/MM/YYYY HH:mm:ss')
								edate = getHyphenDDMMMYYYYHHMM(end_date);
							}
						}
						//console.log("End Date ", edate)
						if(e.deviation_flag == 1 || this.state.deptcode == "SNDG")
						{
							let consignee_city;
							if(["TVPB", "TVPN", "TVPS"].includes(e.cluster)){
								if(e.cluster == "TVPB"){
									consignee_city = "TVP BANGALORE"
								}
								else if(e.cluster == "TVPN"){
									consignee_city = "TVP NAGPUR"
								}else{
									consignee_city = "TVP SILIGURI"
								}
							}else{
								consignee_city = e.consignee_city
							}
							var params = {
								"base_route_id": e.base_route_id,
								"consigner_code": e.consigner_code,
								"consignee_city": consignee_city
							}
							redirectURL.post("/consignments/getDeviationRouteBaseRoute", 
							params).then((resp)=>{
								var baseRouteCoords = eval(resp.data.route_coords);
								// console.log(baseRouteCoords, "baseRouteCoords")
								this.setState({
									sliderRouteTranslate:"slider-translate-60p",
									showDiv:'show-m',
									mapinfo:records,
									dealer:e.consignee_code,
									consignment_code:"Consignment : "+e.consignment_code,
									maptruckno:e.truck_no,
									routeTruck:	{"truck_no":e.truck_no,"startTime":sdate,"endTime": end_date},
									loadshow:'show-n',
									sidebarSubHeader:"Consignment Information",
									overly:'show-m',
									rownode:e,
									leg_no:0,
									tolls : records.tolls,
									baseRouteCoords: baseRouteCoords
								});
							})
						}
						else
						{
							var params =
							{
								consignee_code:e.consignee_code,
								dept_code:e.dept_code
							}
							if(e.consignee_code === 'TKM'|| e.consignee_code==="MUL" || e.consignee_code==="MSIL-G"){
								params.transporter_code = e.transporter_code
								params.checkTkm = true
							}
							redirectURL.post('/consignments/geofencedata',params).then((response) => {
								var records1 = response.data.records;
								let parkingGeodata = []
								if (response.data.parkingGeofence[0] !== undefined) parkingGeodata = response.data.parkingGeofence[0]
								// console.log("parkingGeodata",parkingGeodata)
								var geofence_coords = []
								if(records1.length)
								{
									geofence_coords = records1[0].geofence_coordinates;
								}
								this.setState({
									sliderRouteTranslate:"slider-translate-60p",
									showDiv:'show-m',
									mapinfo:records,
									dealer:e.consignee_code,
									consignment_code:"Consignment : "+e.consignment_code,
									maptruckno:e.truck_no,
									geofence_coordinates: geofence_coords,
									parking_coordinates : parkingGeodata,
									routeTruck:	{"truck_no":e.truck_no,"startTime":sdate,"endTime": end_date},
									loadshow:'show-n',
									sidebarSubHeader:"Consignment Information",
									overly:'show-m',
									rownode:e,
									leg_no:0,
									tolls : records.tolls
								});
							})
						}
						
						
						// this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
						this.renderMap();

					}
				}
			}
			catch(e){
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			
		})
		.catch(function (error) {
			console.log(error);
		});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			logsSLider : ''
		});
	}

	
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	    if(this.state.deptcode == 'LOG-PRT')
	    {
			// console.log("Step 1 ")
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception","loading_delay_exception","unloading_delay_exception","trip_type"], false);
			
			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if(this.state.deptcode == 'SNDG')
		{
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["total_travelled_trip_dist_km"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if(this.state.deptcode == 'LOG-TNP')
		{
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			//this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], true);
			this.gridColumnApi.setColumnsVisible(["parking_radius_entered_on"], true);
		}
		else if((this.props.match.path == "/sndconsignments") 
		|| (this.props.match.path == "/prtconsignments") 
		|| (this.props.match.path == "/tnpconsignments"))
		{
			this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], false);
			
		}
		else{}

		
		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}

	renderMap = () => {    	
		//loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
		//window.initMap = this.initMap
	}
	
	initMap = () => {
    	//console.log("mapinfo ",this.state.mapinfo);
    	var currentwindow;
    	
		var jsondata = this.state.mapinfo;
		var locations = jsondata.breaks;
		var arr = jsondata.coords;
		

		//console.log("Here",arr);
		if(arr.length > 0)
		{
			var lt=arr[0].lat;
			var ln=arr[0].lng;
		}
		else{
			if(this.state.defTransitCoords == '')
			{
				var lt=28.4519751;
				var ln=77.0310713;
			}
			else{
				var lt=JSON.parse(this.state.defTransitCoords).lat;
				var ln=JSON.parse(this.state.defTransitCoords).lng;
			}
			
		}
        var routeinfo = jsondata.route_details;
    	 var mapOptions = {
    			zoom: 7,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
    	          labels:true,
    	          mapTypeControlOptions: {
    	            mapTypeIds: ['hybrid', 'roadmap'],
    	          },
    	        //   center: new window.google.maps.LatLng(lt,ln),
    	          mapTypeId: window.google.maps.MapTypeId.ROADMAP
    	        };
    	        
    	        var map = new window.google.maps.Map(document.getElementById('map'),
    	            mapOptions);

				var bounds = new window.google.maps.LatLngBounds();
				bounds.extend(new window.google.maps.LatLng(lt,ln));
    			 // Create our info window content
    			var currentinfowindow = null;
    	       var line=new window.google.maps.Polyline(
    	              {
    	                map:map,
    									strokeColor: '#157254',
    									strokeOpacity: 1.0,
    									strokeWeight: 2.5,
    	                		icons: [{
    	                          icon: {
    	                                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                  strokeColor:'#ff8c52',
    	                                  fillColor:'#ff8c52',
    	                                  fillOpacity:1,
    									  strokeWeight: 2
    	                                },
    	                          repeat:'100px',
    	                          path:[]
    	                       }]
    	                 });

			if(arr.length > 0)
			{
    		  for (let i = 0; i < arr.length; i++) {
    				var path=line.getPath().getArray();
    					
    						let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    					

    				  
    		  
    	          //Change line color based on map type
    	        window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
    	            if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#FFFFFF',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });                
    	            }
    	            else
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#157254',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });

    	            }

    	              for (i = 0; i < arr.length; i++) {
    	                    var path=line.getPath().getArray();
    	                    let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    	                            //map.setCenter(latLng);
    	                  }
    	                            
    	             var x = map.getZoom();
    	             var c = map.getCenter();
    	             window.google.maps.event.trigger(map, 'resize');
    	             map.setZoom(x);
    	             map.setCenter(c);
				} );
				
				bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
    		  }

		  var infowindow = new window.google.maps.InfoWindow();
			var marker, l;

			for (l = 0; l < locations.length; l++) {
		      marker = new window.google.maps.Marker({
	    	  position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
		  	  icon:require('../../assets/icons/cf.png'),
		      map: map,
			});

		      // eslint-disable-next-line no-loop-func
		      window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
		        return function() {
		          //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
				 var contentarr = []
				 var header = "Break - "+(l+1)
				 contentarr.push({"key":"Start time", "value":getHyphenDDMMMYYYYHHMM(locations[l].break_start)})
				 contentarr.push({"key":"End time", "value":getHyphenDDMMMYYYYHHMM(locations[l].break_end)+" ("+ secondsToString(locations[l].break_time_seconds)+")"})
				  var contentString = infoBox(marker.icon, header, contentarr)
				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				  //console.log(marker.position.toJSON());
				  //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker, l));
			}
			marker = new window.google.maps.Marker({
		        position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
		  		icon:require('../../assets/icons/track_start.png'),
		        map: map,	
		      });
	   	   window.google.maps.event.addListener(marker, 'click', (function(marker) {
	   	        return function() {
	   	        	//	console.log(clusters[0])
					   var contentarr = []
	       		//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
	   	        var header = "Starting Point"
				contentarr.push({"key":"Started at ", "value":getHyphenDDMMMYYYYHHMM(routeinfo.start_time)})
				var contentString = infoBox(marker.icon, header, contentarr,'')
				  
				  
	   			  infowindow.setContent(contentString);
	   			  currentwindow = infowindow;
	   			  infowindow.open(map, marker);
	   			  //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
	   			}
	   		})(marker));
	   	  marker = new window.google.maps.Marker({
	        position: new window.google.maps.LatLng(arr[(arr.length-1)].lat, arr[(arr.length-1)].lng),
	  		icon:require('../../assets/icons/truck-end.png'),
	        map: map,	
	      });
		   window.google.maps.event.addListener(marker, 'click', (function(marker) {
		        return function() {
		        	//	console.log(clusters[0])
					var contentarr = []
	   		    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
				   var header = "End Point"
				   contentarr.push({"key":"End at ", "value":getHyphenDDMMMYYYYHHMM(routeinfo.end_time)})
				   var contentString = infoBox(marker.icon, header, contentarr)
						
				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				 // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker));
			if(this.state.tolls.length > 0)
			{
				var infowindow = new window.google.maps.InfoWindow();
				var l;
				this.state.tolls.map(function(e,index){
					var tollMarker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(e.lat, e.lon),
						icon:require('../../assets/icons/barrier.png'),
						map: map,
					});
					window.google.maps.event.addListener(tollMarker, 'mouseover', (function(tollMarker, index) {
						return function() 
						{
							var contentarr = []
							var header = "Toll - "+e.name
							contentarr.push({"key":"Address", "value":e.location})
							var contentString = infoBox(tollMarker.icon, header, contentarr)
							infowindow.setContent(contentString);
							var currentwindow = infowindow;
							infowindow.open(map, tollMarker);
						}
					})(tollMarker, index));
					window.google.maps.event.addListener(tollMarker, 'mouseout', function() {
						infowindow.close();
						});
				})  
				
			}
		}
		map.fitBounds(bounds)
    }



	onShowOverspeedKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.overspeed;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowOverspeedKpi, params = ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var overspeedData=[];
			
			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName: "",field: "_id", 
								width:50,
								cellRendererFramework:RoutemapAction,
								resizable: true
							},
							{ headerName: "Truck No",field: "truck_no", filter:true,resizable: true },
							{ headerName: "Speed (km/h)",field: "speed", filter:true,resizable: true },
							{ headerName: "Reported At",field: "first_instance", filter:true,resizable: true,
								valueGetter:function(params){
									return getHyphenDDMMMYYYYHHMM(params.data.first_instance);
								}
							},
						],
						overlayNoRowsTemplate: 'No rows to show',
														
					},
					getDetailRowData: function(param) {
						// console.log("Step 4 ",param)
						redirectURL.post('/consignments/overspeed', {
							consignment_code:param.data.consignment_code, 
							truck_no:param.data.truck_no
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							param.successCallback(overspeedData);
							//console.log("Step 2 ",response.data)
							
						});
						 
					},
					masterDetail: true
				}
			});
			
			if(propsdata.column.colDef.field == 'overspeeding_exception')
			{

				propsdata.node.setExpanded(!propsdata.node.expanded);
			}
			else{

				propsdata.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}

	onShowTransitKpi = async (params) => {
		if(params != 0)
		{
			let eventLabel = googleAnalytics.page.action.transitDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			//console.log("IN onShowTransitKpi, params = ",params);
			//console.log(params);
			//console.log("Consign onShowTransitKpi params = ",params);
			//console.log(params.column.colId);
			if(params != 0)
			{
				var transitedelaydata=[];
				
				await this.setState({
						
					detailCellRendererParams:{
						suppressRefresh: true,
						detailGridOptions: {
							headerHeight:50,
							columnDefs: [
								{ headerName: "",field: "_id", 
								
									cellRendererFramework:RoutemapTransitAction,
									width:50,
									suppressSizeToFit: true,
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Truck No",field: "truck_no", width:100,resizable: true,
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Leg Start Time",field: "leg_start", width:120,resizable: true,
									valueGetter:function(params){
										return getHyphenDDMMMYYYYHHMM(params.data.leg_start);
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Leg End Time",field: "leg_end_eta", 
								width:120,resizable: true,
									valueGetter:function(params){
										return getHyphenDDMMMYYYYHHMM(params.data.leg_end_eta);
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Exp. Leg Distance (KM)",
								field: "leg_expected_distance", width:140,resizable: true,
									valueGetter:function(params){
										return Math.round(params.data.leg_expected_distance);
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},
								{ headerName: "Actual Leg Distance Covered (KM)",
								field: "actual_leg_distance", width:140,resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined)
										{
											return Math.round(params.data.actual_leg_distance);
										}
										else{
											return 0;
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								},							
								{ headerName: "Exp. Distance from Trip Start(KM)",
								field: "expected_distance_start_to_leg", width:140,resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined)
										{
											return Math.round(params.data.expected_distance_start_to_leg);
										}
										else{
											return 0;
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
								},
								{ headerName: "Actual Distance from Trip Start (KM)",
								field: "actual_distance_traveled_start_to_leg", width:140,resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined)
										{
											return Math.round(params.data.actual_distance_traveled_start_to_leg);
										}
										else{
											return 0;
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								},
								
								{ 
									headerName: "Google Distance from Start (KM)",
									field: "actual_start_to_leg_google_distance", 
									width:140,
									resizable: true,
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								},
								{ 
									headerName: "GPS Data Available",
									field: "no_gps_data", 
									width:140,
									resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.no_gps_data == 1)
										{
											return "Not Available";
										}
										if(params.data.no_gps_data == 0)
										{
											return "Available";
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								},
								
								
								{ 
									headerName: "Trip Completed",
									field: "trip_completed", 
									width:140,
									resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.trip_completed == 1)
										{
											return "Yes";
										}
										if(params.data.trip_completed == 2)
										{
											return "";
										}
										if(params.data.trip_completed == 0)
										{
											return "";
										}
										
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}
										
									}
								
							
								}							
							],
							overlayNoRowsTemplate: 'No rows to show',						
						},
						getDetailRowData: function(params) {
							//console.log("Step 4 ",transitedelaydata)
							//console.log("Step 4 ",params)
							redirectURL.post('/consignments/transitdelay', {
								consignment_code:params.data.consignment_code,
								consignee_code:params.data.consignee_code, 
								truck_no:params.data.truck_no
							})
							.then(async (response) =>{
								//console.log("Step 1 ",response.data)
								transitedelaydata=response.data;
								var legsarr=[]
								transitedelaydata.map((item) => {
									//if(item.trip_completed < 2)
									//{
									legsarr.push(item)
									//}
								})
								// console.log('transitedelaydata ', transitedelaydata);

								params.successCallback(legsarr);
								//console.log("Step 2 ",response.data)
								
							});
							
						}
					}
				});
				
				//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
				if(params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'no_gps_data')
				{
					params.node.setExpanded(!params.node.expanded);
					//console.log("expanded ",params.node.expanded)
					if(params.node.expanded == false)
					{
						transitedelaydata=[]
					}
				}
				else{

					params.node.setExpanded(false);
				}
				//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
				
			}
			else{
				this.setState({
				
					detailCellRendererParams:{
						detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No",field: "truck_no" },
							{ headerName: "Speed (km/h)",field: "speed" },
							{ headerName: "Reported At",field: "first_instance" }
						]
						
						},
						getDetailRowData: function(params) {
							//console.log("Child ", params);
						params.successCallback([]);
						}
					}
				});
			}
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"Transit for this consignment has not yet started",
				basicType:"info"
			});
		}
		
	}

	/*NO GPS DATA*/


	onShowNoGPSKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.gpsAvailable;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowNoGPSKpi, params = ",params);
		//console.log(params);
		//console.log("Consign onShowNoGPSKpi params = ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var transitedelaydata=[];
			
			await this.setState({
					
				detailCellRendererParams:{
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{ 
								headerName: "",
								field: "_id",
								cellRendererFramework:RoutemapNoGPSAction,
								width:50,
								suppressSizeToFit: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							
							{ headerName: "Truck No",field: "truck_no", width:100,resizable: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Last Data Receieved",
								field: "last_packet_datetime", 
								width:180,
								resizable: true,
								valueGetter:function(params){
									return getHyphenDDMMMYYYYHHMM(params.data.last_packet_datetime);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Data Received on",
								field: "data_received_on", 
								width:180,
								resizable: true,
								valueGetter:function(params){
									return getHyphenDDMMMYYYYHHMM(params.data.packet_received_on);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
						 	}						
						],
						overlayNoRowsTemplate: 'No rows to show',					
					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",params)
						redirectURL.post('/consignments/nogpsdata', {
							consignment_code:params.data.consignment_code, 
							truck_no:params.data.truck_no
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							transitedelaydata=response.data;
							var legsarr=[]
							transitedelaydata.map((item) => {
								//if(item.trip_completed < 2)
								//{
								legsarr.push(item)
								//}
							})
							// console.log('transitedelaydata ', transitedelaydata);

							params.successCallback(legsarr);
							//console.log("Step 2 ",response.data)
							
						});
						
					}
				}
			});
			
			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if(params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'no_gps_data')
			{
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if(params.node.expanded == false)
				{
					transitedelaydata=[]
				}
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}
	onClickShowProbableData = async (params) =>{
		let eventLabel = googleAnalytics.page.action.nightDrive;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		// console.log("IN probable data, params = ",params);
		var probableData=[];
		await this.setState({
					
			detailCellRendererParams:{
				detailGridOptions: {
					columnDefs: [
						{ headerName: "Truck No",field: "truck_no", width:150,resizable: true },
						{ headerName: "GPS Provider",field: "actual_lspuser", width:180,resizable: true,},
						{ headerName: "Event Time",field: "timestamp",resizable: true ,
							valueGetter:function(params){
								return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
							}
						},
						{ 
							headerName: "Event",field: "", width:180,resizable: true,
							width:220,
							valueGetter : function(params){
								return "Harsh Braking / Probable Accident"
							}
						},			
					],
					overlayNoRowsTemplate: 'No rows to show',		
				},
				getDetailRowData: function(params) {
					//console.log("Step 4 ",overspeedData)
					redirectURL.post('/consignments/probableAccidentsData', {
						consignment_code:params.data.consignment_code,
					})
					.then(async (response) =>{
						// console.log("Step 1 ",response.data)
						probableData = response.data;
						params.successCallback(probableData);
						//console.log("Step 2 ",response.data)
						
					});
					
				}
				
			}
		});
		//console.log("Parmas ", params)
		if(params.column.colDef.field == 'probable_accident')
		{

			params.node.setExpanded(!params.node.expanded);
		}
		else{

			params.node.setExpanded(false);
		}
		
	}

	onShowNightDriveKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.nightDrive;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowNightDriveKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);
	
		if(params != 0)
		{
			var overspeedData=[];
			

			//console.log("Step 3 ",overspeedData)
			
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName: "",field: "_id", 
							
								cellRendererFramework:RouteNightDrivemap,
								width:50,
								suppressSizeToFit: true
							},
							{ headerName: "Truck No",field: "truck_no", width:150,resizable: true },
							{ headerName: "Traveled From",field: "first_instance", width:180,resizable: true,
								valueGetter:function(params){
									return getHyphenDDMMMYYYYHHMM(params.data.first_instance);
								}
							},
							{ headerName: "Traveled Until",field: "recent_instance",resizable: true ,
								valueGetter:function(params){
									return getHyphenDDMMMYYYYHHMM(params.data.recent_instance);
								}
							},
							{ headerName: "Distance Traveled (km)",field: "distance_from_first_instance", width:180,
								resizable: true,
								valueGetter:function(params){
									return Math.round(params.data.distance_from_first_instance)
								}
							}							
						],
						overlayNoRowsTemplate: 'No rows to show',		
					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",overspeedData)
						redirectURL.post('/consignments/nightdriving', {
							consignment_code:params.data.consignment_code,
							gate_out_time:params.data.gate_out_time, 
							truck_no:params.data.truck_no
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							params.successCallback(overspeedData);
							//console.log("Step 2 ",response.data)
							
						});
						
					}
					
				}
			});
			
			//if(params.column.colId == 'nightdriving_exception')
			if(params.column.colDef.field == 'nightdriving_exception')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}


	onShowEnrouteStoppagesKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.enrouteStoppages;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowEnrouteStoppagesKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);
	
		if(params != 0)
		{
			var overspeedData=[];
			

			//console.log("Step 3 ",overspeedData)
			
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							
							{ headerName: "",field: "_id", 
							
								cellRendererFramework:RoutemapenrouteAction,
								width:50,
								suppressSizeToFit: true,
								
							},
							{ 
								headerName: "Break Start",
								field: "stoppage_start_time", 
								width:150,
								resizable: true,
								valueGetter:function(params){
									if(params.data.stoppage_start_time != undefined)
									{
										return getHyphenDDMMMYYYYHHMM(params.data.stoppage_start_time)
									}
									else{
										return '';
									}
									
								}
						    },
							{ 
								headerName: "Break End",
								field: "reset_datetime", 
								width:150,
								resizable: true,
								valueGetter:function(params){
									if(params.data.reset_datetime != undefined)
									{
										return getHyphenDDMMMYYYYHHMM(params.data.reset_datetime)
									}
									else{
										return '';
									}
									
								}
						    },
							// { 
							// 	headerName: "Break End",
							// 	field: "reset_start_time", 
							// 	width:180,
							// 	resizable: true,
							// 	valueGetter:function(params){
							// 		if(params.data.reset_start_time != undefined)
							// 		{
							// 			return getHyphenDDMMMYYYYHHMM(params.data.reset_start_time)
							// 		}
							// 		else{
							// 			return '';
							// 		}
									
							// 	}
							// },

							{ 
								headerName: "Exception Closed On",
								field: "modified_date", 
								width:220,
								resizable: true,
								valueGetter:function(params){
									if(params.data.exception_closed ==1)
									{
											
										if(params.data.modified_date != undefined)
										{
											return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
										}
										else{
											return '';
										}
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Break Time",
								field: "elaped_time_from_last_moved_seconds",
								width:180,
								resizable: true,
								valueGetter:function(params){
									if(params.data.exception_closed == 1)
									{
										// if(params.data.elaped_time_from_last_moved_seconds != undefined)
										// {
										// 	var seconds = params.data.elaped_time_from_last_moved_seconds;
										// 	var days = parseInt(seconds / (24 * 3600)); 
										// 	var hours = parseInt(seconds/3600);
										// 	var minutes = Math.round((seconds-(hours*3600))/60)
										// 	//return hours+" Hr(s) "+minutes+" Min(s)";
										// 	return secondsToString(seconds)
										// }
										// else{
										// 	return '';
										// }
										var sdate = moment.parseZone(params.data.stoppage_start_time).format("x");
										if(typeof params.data.reset_datetime == 'undefined')
										{
											var edate = moment.parseZone().format("x");
										}
										else
										{
											var edate = moment.parseZone(params.data.reset_datetime).format("x");
										}
										var diff = (edate-sdate)/1000;
										return secondsToString(diff);
									}
									else{
										return '';
									}
								}
							}
						],
						overlayNoRowsTemplate: 'No rows to show',					
					},
					getDetailRowData: async function(params) {
						//console.log("Step 4 ",overspeedData)
						if(params.data.is_transshipment == 1)
						{
							var oldtruck = params.data.old_truck_no
						}
						else
						{
							var oldtruck = ''
						}
						redirectURL.post('/consignments/enroutes', {
							consignment_code:params.data.consignment_code, 
							truck_no:params.data.truck_no, 
							old_truck_no:oldtruck
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							params.successCallback(overspeedData);
							//console.log("Step 2 ",response.data)
							
						});
						
					}
				}
			});
			
			if(params.column.colDef.field == 'enroute_exception')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}

	onShowEnrouteRouteMap(rownode){
		//console.log("IN onShowEnrouteRouteMap, params = ",params);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		// console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
		})
		var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		if(rownode.reset_datetime)
		{
			var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		
		}
		else{
			var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		    
		}
		//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
			//consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
            first_instance:fintance,
			recent_instance:eintance,
			screen:"consignment"
		}
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {	
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
		   let records = response.data
			// console.log("Inner Grid ",records);
			if(response.data.coords.length == 0)
			{
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				var sdate='';
				var edate='';

				if(rownode.leg_start != '' && rownode.leg_start != undefined)
				{
					sdate=rownode.leg_start;
				}
				if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
				{
					 edate=getHyphenDDMMMYYYYHHMM(rownode.leg_end_eta)
					//edate=rownode.leg_end_eta;
				}
			     
				if(response.data.route_details.start_time != "" && response.data.route_details.start_time != undefined)
				{
					sdate=records.route_details.start_time;
					
				}
				if(response.data.route_details.end_time != "" && response.data.route_details.end_time != undefined)
				{
					edate=records.route_details.end_time;
				}
			this.setState({
					mapinfo:response.data,
					dealer:rownode.consignee_code,
					sliderRouteTranslate:"slider-translate-60p",
					defTransitCoords: rownode.consignee_coordinates,
					consignment_code:"Consignment : "+rownode.truck_no,
					maptruckno:rownode.truck_no,
					routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
					loadshow:'show-n',
					sidebarSubHeader:"Consignment Leg Information",
					overly:'show-m',
					rownode:rownode,
					leg_no:rownode.leg_no,
					tolls : response.data.tolls

			});

			// this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
				this.renderMap();
			
			} 
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
	}

	onShowLoadingDelayKpi = async (params) => {
		
		let eventLabel = googleAnalytics.page.action.loadingDelay;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowLoadingDelayKpi, params = ",params);
		//console.log(e);
		//console.log("Consign onShowLoadingDelayKpi ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var overspeedData=[];

			var hideonTNP = (this.state.deptcode == 'LOG-TNP')?true:false
			var showonTNP = (this.state.deptcode == 'LOG-TNP')?false:true
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							
							{ 
								headerName: "Gate In Time",
								field: "gate_in_time", 
								width:180,
								resizable: true,
								hide:hideonTNP,
								valueGetter:function(params){
									
									if(params.data.gate_in_time)
									{
										//return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
										return params.data.gate_in_time;
									}
									else
									{
										return "NA"
									}
									
								}
							},
							
							{ 
								headerName: "Gate Out Time",
								field: "gate_out_time", 
								width:180,
								resizable: true,
								hide:hideonTNP,
								valueGetter:function(params){
								//	console.log("IN gate_out_time valueGetter, params = ", params);
										
									if(params.data.gate_out_time)
									{
										//console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
										//return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
										return params.data.gate_out_time;
									}
									else
									{
										//console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
										return "NA"
									}
								}
							},

							
							{ 
								headerName: "Gate In Time",
								field: "gate_in_time", 
								width:180,
								resizable: true,
								hide:showonTNP,
								valueGetter:function(params){
									// console.log("IN gate_out_time valueGetter, params = ", params);
										
									if(params.data.gate_in_time)
									{
										// console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_in_time);
										//return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
										return params.data.gate_in_time;
									}
									else
									{
										// console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_in_time);
										return "NA"
									}
								}
							},

							
							{ 
								headerName: "Gate Out Time",
								field: "gate_out_time", 
								width:180,
								resizable: true,
								hide:showonTNP,
								valueGetter:function(params){
									// console.log("IN gate_out_time valueGetter, params = ", params);
										
									if(params.data.gate_out_time)
									{
										// console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
										//return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
										return params.data.gate_out_time;
									}
									else
									{
										// console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
										return "NA"
									}
								}
							},
							{
								headerName:"Loading Time",
								field:"loading_time",
								width:180,
								
							}
													
						],
						overlayNoRowsTemplate: 'No rows to show',
						
									
					},
					getDetailRowData: function(params) {
							// console.log("Step 4 ",params)
							if(hideonTNP != true)
							{
								// console.log("Inner here")
								if(params.data.gate_in_time != undefined)
								{
									var g1 = getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
									//var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
									var gdate1 = new Date(params.data.gate_in_time);
									var fromDate = g1;
									var secondsgate1 = moment.parseZone(new Date(params.data.gate_in_time)).format("x")
								}
								else{
									
									var g1 = getHyphenDDMMMYYYYHHMM(params.data.inside_fence);
									var gdate1 = new Date(params.data.inside_fence);
									var fromDate = g1;
									var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")
								
								}
								//console.log("g1 ", g1)//console.log("Step 4 ",params)
								if(params.data.gate_out_time != undefined)
								{
									var g2 = getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
									var gdate2 = new Date(params.data.gate_out_time);
									var toField = getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
									//console.log("gatein ", gatein)
									//console.log("gateout ", gateout)
									//var total =0 ;
									var secondsgate2 = moment.parseZone(new Date(params.data.gate_out_time)).format("x")
								
								}
								else{
									var c = new Date();
									var todaydate = moment(new Date(c)).format("x");
									var g2 = getHyphenDDMMMYYYYHHMM(todaydate);
									var gdate2 = new Date(todaydate);
									var toField = getHyphenDDMMMYYYYHHMM(new Date());
									var secondsgate2 = todaydate
								
								}
							}
							else
							{
								var fromDate = getHyphenDDMMMYYYYHHMM(params.data.inside_fence);
								var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")
								var secondsgate2 = moment.parseZone(new Date(params.data.outside_fence)).format("x")
								var toField = getHyphenDDMMMYYYYHHMM(params.data.outside_fence);
							}
						
						//console.log("secondsg1 ", secondsg1)
						//var g2 = getDDMMYYYY(params.data.gate_out_time);

						//console.log("g1 ", g2)
						// var s1 = g1.split("-");
						// var s2 = g2.split("-");
						
						
						// var gatein = gdate1.getTime();
						// var gateout = gdate2.getTime();
						var total = secondsgate2-secondsgate1;
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.ceil(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000); 
						// console.log(diffDays+" "+diffHrs+" "+diffMins);
						let loadingTimeDifference = null;

						var dataarr = []
							
							dataarr.push({
								"gate_in_time": fromDate,
								"gate_out_time": toField,
								//"loading_time": diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
								"loading_time": secondsToString(diffMs/1000)
							})
							// console.log("dataarr ", dataarr)
							params.successCallback(dataarr);
					}
					
				}
			});
			
			//if(params.column.colId == 'loading_delay_exception')
			
			if(params.column.colDef.field == 'loading_delay_exception')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			
		}
		
	}

	onShowUnLoadingDelayKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.unloadingDelay;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowUnLoadingDelayKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var overspeedData=[];
			

			//console.log("Step 3 ",overspeedData)
			
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							
							{ 
								headerName: "From Date",
								field: "from_date", 
								width:180,
								resizable: true,
								valueGetter:function(params){
									// console.log("child params ", params)
									if(params.data.from_date != undefined)
									{
										return getHyphenDDMMMYYYYHHMM(params.data.from_date);
									}
									else
									{
										return "NA"
									}
									
								}
							},
							{ 
								headerName: "To Date",
								field: "to_date", 
								width:180,
								resizable: true,
								valueGetter:function(params){
									if(params.data.to_date != undefined)
									{
										return getHyphenDDMMMYYYYHHMM(params.data.to_date);
									}
									else
									{
										return "NA"
									}
								}
							},
							{
								headerName:"Unloading Time",
								field:"unloading_time",
								width:180,
								
							}
													
						],
						overlayNoRowsTemplate: 'No rows to show',
									
					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",params)
						if(params.data.locked_dealer_reported == undefined)
						{
							var g1 = getHyphenDDMMMYYYYHHMM(params.data.first_dealer_reported);
							var fromField = g1;
						}
						else
						{
							
							var g1 = getHyphenDDMMMYYYYHHMM(params.data.locked_dealer_reported);
							var fromField = g1;
						}
						//console.log("params.data.left_dealer_marked ", params.data.left_dealer_marked)
						if(params.data.left_dealer_marked == undefined)
						{
							var c = new Date();
							var todaydate = c;
							var g2 = getHyphenDDMMMYYYYHHMM(todaydate);
							var toField = g2;
						}
						else
						{
							var g2 = getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
							var toField = g2;
							//console.log("toField stee " ,toField);
						}
							var fstr = fromField.split("-");
							//console.log(fstr);
							var gdate1 = new Date(fromField);
							var gdate2 = new Date(toField);
							var frmd = gdate1.getTime();
							var tod = gdate2.getTime();
							var total = tod-frmd;
							//console.log("From Date ", fromField)
							//console.log("To Date ", toField)
							//var total =0 ;
							//console.log("gdate1 ", frmd)
							//console.log("gdate2 ", tod)
							//console.log("Total ", total)
							var diffMs = total;
							var diffDays = Math.floor(diffMs / 86400000); // days
							var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
							var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000); 
							
							// var sr = params.data.unloading_time_in_hrs;
							// var hrs=sr.toString().split(".");
							// var hours = parseInt(hrs[0]);
							// var mins = Math.round((hrs[1]/100)*60)
							var dataarr = []
							// dataarr.push({
							// 	"from_date":fromField,
							// 	"to_date": toField,
							// 	"unloading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
							// 	//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
							// })
							let loadingTimeDifference = null;
							// if (diffDays) {
							// 	loadingTimeDifference = diffDays+" Day(s) " + diffHrs + " Hr(s) "+" "+diffMins+" Min(s)";
							// } else {
							// 	loadingTimeDifference = diffHrs+" Hr(s) "+" "+diffMins+" Min(s)";
							// }
							loadingTimeDifference = secondsToString(diffMs/1000)
							dataarr.push({
									"from_date":fromField,
									"to_date": toField,
									"unloading_time":loadingTimeDifference
									//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
							})
							params.successCallback(dataarr);
						
					}
					
				}
			});
			
			//if(params.column.colId == 'unloading_delay_exception')
			if(params.column.colDef.field == 'unloading_delay_exception')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			
		}
		
	}


	onShowDeviate = async (params) => {
		let eventLabel = googleAnalytics.page.action.deviation;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		// console.log("IN onShowDeviate, params = ",params);
		
		//console.log(e);
		//console.log("Consign Overspeed ",params);
	
		if(params != 0)
		{
			var overspeedData=[];
		
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No",field: "truck_no" },
							{ headerName: "Speed (km/h)",field: "speed" },
							{ headerName: "Reported At",field: "first_instance" }
						]							
					},
					getDetailRowData: async function(params) {
						// console.log("Step 4 ",overspeedData)
						await params.successCallback(overspeedData);
					}
				}
			});
			
			if(params.column.colId == '_id_1' || params.column.colId == '_id_2')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}
	onRowClicked(params) {
		// console.log("IN onRowClicked, params = ",params);
		// console.log("row click ", params)
		if(params.column.colId == 'consignment_code')
		{
			this.setState({
				sliderSegmentTranslate : "slider-translate-60p",
				segementConsignmentCode : params.data.consignment_code,
				overly : "show-m"
			})
		}
	  }
	closeSegmentSideBar()
	{
		this.setState({
			sliderSegmentTranslate : "",
			segementConsignmentCode : "",
			overly : "show-n",
			basicTitle:"No Segment Record Found",
			basicType:"danger",
			show:true
		});
	}
	onShowRouteMap(rownode){
		// console.log("IN onShowRouteMap, params = ",rownode);
		// console.log("rownode", rownode);
		// console.log("rownode.consignment_code ", rownode.consignment_code)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow:'show-m'
		})
		var d = new Date(rownode.first_instance);
    	var r = new Date(rownode.recent_instance);
		var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
			consignment_code:rownode.consignment_code,
			consignee_code:rownode.consignee_code,
            first_instance:finstance,
			recent_instance:rinstance,
			screen:"consignment"
		}
		
		 redirectURL.post('/reports/overspeedgpsroutes',params)
		  .then((response) => {
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
		    // console.log(response.data);
		   this.setState({
				mapinfo:response.data,
				dealer:rownode.consignee_code,
				sliderRouteTranslate:"slider-translate-50p",
				consignment_code:rownode.truck_no,
				maptruckno:rownode.truck_no,
				routeTruck:	{"truck_no":rownode.truck_no,"startTime":response.data['route_details']['start_time'],
					"endTime": response.data['route_details']['end_time']},
				overly:'show-m',
				loadshow:'show-n',
				rownode:rownode,
				tolls : response.data.tolls
		   });
		   	this.renderMap();
		
			   
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
	}
	
	onShowTransitRouteMap(rownode){
		//console.log("IN onShowTransitRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
		})
		var d = new Date(rownode.leg_start);
    	var r = new Date(rownode.leg_end_eta);
    	var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
			consignment_code:rownode.consignment_code,
			consignee_code:rownode.consignee_code,
            first_instance:fintance,
			recent_instance:eintance,
			screen:"consignment"
		}
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {	
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
			//console.log("Inner Grid ",response.data);
			if(response.data.coords.length == 0)
			{
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				var sdate='';
				var edate='';

				if(rownode.leg_start != '' && rownode.leg_start != undefined)
				{
					sdate=rownode.leg_start;
				}
				if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
				{
					edate=getHyphenDDMMMYYYYHHMM(rownode.leg_end_eta)
				}
			this.setState({
					mapinfo:response.data,
					dealer:rownode.consignee_code,
					sliderRouteTranslate:"slider-translate-60p",
					defTransitCoords: rownode.consignee_coordinates,
					consignment_code:"Consignment : "+rownode.truck_no,
					maptruckno:rownode.truck_no,
					routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
					loadshow:'show-n',
					sidebarSubHeader:"Consignment Leg Information",
					overly:'show-m',
					rownode:rownode,
					leg_no:rownode.leg_no,
					tolls : response.data.tolls

			});

			// this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
				this.renderMap();
			
			} 
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
	}

	onShowNoGPSRouteMap(rownode){
		//console.log("IN onShowNoGPSRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
		})
		var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
			consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
            first_instance:fintance,
			recent_instance:eintance,
			screen:"consignment"
		}
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {	
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
			//console.log("Inner Grid ",response.data);
			if(response.data.coords.length == 0)
			{
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				var sdate='';
				var edate='';

				if(rownode.leg_start != '' && rownode.leg_start != undefined)
				{
					sdate=rownode.leg_start;
				}
				if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
				{
					edate=getHyphenDDMMMYYYYHHMM(rownode.leg_end_eta)
				}
			this.setState({
					mapinfo:response.data,
					dealer:rownode.consignee_code,
					sliderRouteTranslate:"slider-translate-60p",
					defTransitCoords: rownode.consignee_coordinates,
					consignment_code:"Consignment : "+rownode.truck_no,
					maptruckno:rownode.truck_no,
					routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
					loadshow:'show-n',
					sidebarSubHeader:"Consignment Leg Information",
					overly:'show-m',
					rownode:rownode,
					leg_no:rownode.leg_no,
					tolls : response.data.tolls

			});

			// this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
				this.renderMap();
			
			} 
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
	}

	onShowNightDriveRouteMap(rownode){
		// console.log("IN onShowNightDriveRouteMap, params = ",rownode);
		// console.log("nightdrivenode", rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow:'show-m'
		});
		var d = new Date(rownode.first_instance);
    	var r = new Date(rownode.recent_instance);
    	var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
            first_instance:fintance,
            recent_instance:eintance,
		}
		
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
			// console.log("nightroutedata",response.data);
			
		   this.setState({
				mapinfo:response.data,
				sliderRouteTranslate:"slider-translate-50p",
				consignment_code:rownode.truck_no,
				defTransitCoords: {"lat":rownode.first_lat,"lng":rownode.first_lng},
				maptruckno:rownode.truck_no,
				routeTruck:	{"truck_no":rownode.truck_no,"startTime":response.data['route_details']['start_time'],
					"endTime": response.data['route_details']['end_time']},
				loadshow:'show-n',
				overly:'show-m',
				tolls : response.data.tolls
		   });
		   	this.renderMap();
		
			   
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
		  
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			slideuploadeway:'',
			sliderRouteTranslate:'',
			sliderSegmentTranslate : "",
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
			sliderCommentTranslate:"",
			bulkPrioritySlide:"",
		});
		
	}

	showGoogleRouteAlert = () => {
        this.setState({
			show: true,
			basicTitle:"No google routes found",
			basicType:"danger"
        });
	}

	closeAlert = () => {
        this.setState({
            show: false
        });
	}
	onClickCounterShowDataTEST(params){
	}

	onClickCounterShowData(params,textContent){
		
		this.setState({
			textContent : textContent
		})
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				eventLabel = googleAnalytics.page.kpi[params];
				//console.log("eventLabel = ", eventLabel);
			}
			
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		
		if(params == "transit_delay")
		{
			// var filterComponent = this.gridApi.getFilterInstance("transit_delay");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			// {
			// 	filterComponent.selectValue(this.state.tripvalue);
			// }
			// filterComponent.applyModel();
			var gridData = this.state.rowData
			if(this.state.tnpReturnConsignmentsVisibilty){gridData = this.state.returnConsignments}
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["transit_delay"]) {
					return rec["transit_delay"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if(params == "truck_reporting_date")
		{	
			// console.log(this.state.truckReportedDate,"trkfilter");		
			this.gridApi.setRowData(this.state.truckReportedDate);
			this.gridApi.onFilterChanged();
		}
		else if(params == "probable_accident")
		{
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["probable_accident"]) {
					return rec["probable_accident"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if(params == "probable_accident_confirm")
		{
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["probable_accident_confirm"]) {
					return rec["probable_accident_confirm"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if(params == "is_critical")
		{
			//var filterComponent = this.gridApi.getFilterInstance("is_critical");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			{
				//filterComponent.selectValue(this.state.tripvalue);
			}
			// filterComponent.applyModel();
			var gridData = this.state.rowData
			if(this.state.tnpReturnConsignmentsVisibilty){gridData = this.state.returnConsignments}
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["is_critical"]) {
					return rec["is_critical"] == true;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if(params == "is_priority")
		{
			//var filterComponent = this.gridApi.getFilterInstance("is_critical");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			{
				//filterComponent.selectValue(this.state.tripvalue);
			}
			// filterComponent.applyModel();
			var gridData = this.state.rowData
			if(this.state.tnpReturnConsignmentsVisibilty){gridData = this.state.returnConsignments}
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["is_priority"]) {
					return rec["is_priority"] == true;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		
		else if(params == "no_gps_data")
		{
			// var filterComponent = this.gridApi.getFilterInstance("no_gps_data");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			// {
			// 	filterComponent.selectValue(this.state.tripvalue);
			// }
			// filterComponent.applyModel();

			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["no_gps_data"]) {
					return rec["no_gps_data"] == 1;
				}
			});

			// console.log("isFilterDataAvailable ",isFilterDataAvailable)
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		
		
		else if(params == "no_gps_data_lifetime")
		{
			// var filterComponent = this.gridApi.getFilterInstance("no_gps_data_lifetime");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			{
				this.gridApi.getFilterInstance("no_gps_data_lifetime").selectValue(this.state.tripvalue);
			}
			//this.gridApi.getFilterInstance("no_gps_data_lifetime").applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["no_gps_data_lifetime"]) {
					return (rec["no_gps_data_lifetime"] == 1 );
				}
			});
			// console.log("isFilterDataAvailable ",isFilterDataAvailable)
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}

		else if(params == "overspeeding_exception")
		{
			// console.log(this.gridApi,"this.gridApi")
			// console.log("in oveerspeeding");
			// console.log(this.state.rowData);
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["overspeeding_exception"]) {
					return rec["overspeeding_exception"] == 1;
				}
			});
			// console.log(isFilterDataAvailable);
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if(params == "nightdriving_exception")
		{
			// var filterComponent = this.gridApi.getFilterInstance("nightdriving_exception");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			// {
			// 	filterComponent.selectValue(this.state.tripvalue);
			// }
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["nightdriving_exception"]) {
					return rec["nightdriving_exception"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if(params == "unloading_delay_exception")
		{
			// var filterComponent = this.gridApi.getFilterInstance("unloading_delay_exception");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			// {
			// 	filterComponent.selectValue(this.state.tripvalue);
			// }
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["unloading_delay_exception"]) {
					return rec["unloading_delay_exception"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if(params == "loading_delay_exception")
		{
			// var filterComponent = this.gridApi.getFilterInstance("loading_delay_exception");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			// {
			// 	filterComponent.selectValue(this.state.tripvalue);
			// }
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {				
				if (rec["loading_delay_exception"]) {
					return rec["loading_delay_exception"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if(params == "enroute_exception")
		{
			var gridData = this.state.rowData
			if(this.state.tnpReturnConsignmentsVisibilty){gridData = this.state.returnConsignments}
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["enroute_exception"] != undefined || rec["enroute_exception"] != '') {
					return rec["enroute_exception"] == 1;
				}
			});
			//console.log("isFilterDataAvailable Enrotue ", isFilterDataAvailable)
			if(isFilterDataAvailable.length > 0)
			{
				// var filterComponent = this.gridApi.getFilterInstance("enroute_exception");
				// try{
				// 	if (filterComponent) {
				// 		filterComponent.selectNothing();
				// 		filterComponent.selectValue(1);
				// 		if(this.state.tripvalue != undefined && this.state.tripvalue != '')
				// 		{
				// 			filterComponent.selectValue(this.state.tripvalue);
				// 		}
				// 		filterComponent.applyModel();
				// 	}
					
				// }
				// catch(e){
				// 	console.log(e)
				// }
				
				this.gridApi.setRowData(isFilterDataAvailable);
				this.gridApi.onFilterChanged();
			}
			else{
				this.gridApi.setRowData([]);
				this.gridApi.onFilterChanged();
			}
			
		}
		else if(params == "cancelled")
		{
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["cancelled"]) {
					return rec["cancelled"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable)
		}
		else if(params == "pod")
		{
			let isFilterDataAvailableForPod = this.state.rowData.filter(rec => {
				if (rec["pod_received"]) {
					return rec["pod_received"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailableForPod)
		}
		else if(params == "trip_closed")
		{
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["trip_closed_by_pod"]) {
					return rec["trip_closed_by_pod"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable)	
		}
		else if(params == "deviation")
		{
			// this.gridApi.setRowData(this.state.originalcountersjson.deviation_flag)
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["deviation_flag"]) {
					return rec["deviation_flag"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}else if(params == "co2_emission") {
				let isFilterDataAvailableForEmission = this.state.rowData.filter(rec => {
					return rec["co2_emission"] !== undefined && rec["co2_emission"] !== null && rec["co2_emission"] !== ''  && rec["co2_emission"] > 0;
				});
				this.gridApi.setRowData(isFilterDataAvailableForEmission);
				this.gridApi.onFilterChanged();
			}else
			 if(params == "co2_emission_intransit") {
				let isFilterDataAvailableForEmissionInTransit = this.state.rowData.filter(rec => {
					return rec["co2_emission_intransit"] !== undefined && rec["co2_emission_intransit"] !== null && rec["co2_emission_intransit"] !== ''  && rec["co2_emission_intransit"] > 0;
				});
				this.gridApi.setRowData(isFilterDataAvailableForEmissionInTransit);
				this.gridApi.onFilterChanged();
			}
			else if(params == "co2_emission_savings") {
				let isFilterDataAvailableForEmissionSavings = this.state.rowData.filter(rec => {
					return rec["co2_emission_savings"] !== undefined && rec["co2_emission_savings"] !== null && rec["co2_emission_savings"] !== ''  && rec["co2_emission_savings"] > 0;
				});
				this.gridApi.setRowData(isFilterDataAvailableForEmissionSavings);
				this.gridApi.onFilterChanged();
			}			
		else{
			if(this.state.tnpReturnConsignmentsVisibilty == true)
			{
				this.gridApi.setRowData(this.state.rowData)
			}
			else if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			{
				
			this.gridApi.setRowData(this.state.rowData);
			}
			else{

				this.gridApi.setRowData(this.state.allRowData);
			}
			this.gridApi.onFilterChanged();
		}

	}
	// onSelectTrip(value){
	// 	//console.log(value.target.value)
	// 	this.setState({
	// 		triptype:value.target.value
	// 	});
	// }


	onSelectTrip= triptype =>{
		this.setState(
			{ triptype },
			// () => console.log(`Trip Option selected:`, this.state.triptype)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

	changeConsignerArrayHandler = consigner =>{
        this.setState(
	      { consigner },
	    //   () => console.log(`Consigner Option selected:`, this.state.consigner)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}
	changeConsigneeArrayHandler = consignee =>{
        this.setState(
	      { consignee },
	    //   () => console.log(`Consignee Option selected:`, this.state.consignee)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}
	changeMovementArrayHandler = movementtype => {
		this.setState(
			{ movementtype },
			// () => console.log(`Movement Option selected:`, this.state.movementtype)
		);
		
	}
	selectConsignerOptionsItems(){
        let items = [];     
		//console.log(this.state.trucks);
		var consigners=[];
		if(this.state.deptcode == 'SNDG')
		{
			var keys = ["B", "F", "G", "I","M", "N", "BP","D"]; 
      
			// An array of values 
			var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "TVP Siliguri","Manesar", "TVP Nagpur", "D22 Bengaluru (Bidadi)","Dobaspet"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.deptcode == 'LOG-PRT')
		{
			var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"]; 
      
			// An array of values 
			var values = ["MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.deptcode == 'LOG-TNP')
		{
			var keys = ["MSIL-G", "MSIL-M", "MSILMC","MSILME", "SMG", "MUL"]; 
      
			// An array of values 
			var values = ["Gurgaon", "Manesar", "Casting","Power Train", "SMG Gujarat", "SMIPL"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}

		//console.log("consigners ", consigners)
		items.push({value:'all',label:'All'}); 
		consigners.forEach(item =>{
			items.push({value:item.code,label:item.value}); 
		});
       
        return items;
	}
	selectConsigneeOptionsItems(){
        let items = [];     
		//console.log(this.state.trucks);
		var consigners=[];
		if(this.state.deptcode == 'SNDG')
		{
			var keys = ["B", "F", "G", "I","M", "N","D"]; 
      
			// An array of values 
			var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "TVP Siliguri","Manesar", "TVP Nagpur","Dobaspet"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.deptcode == 'LOG-PRT')
		{
			var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"]; 
      
			// An array of values 
			var values = ["MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.deptcode == 'LOG-TNP')
		{
			var keys = ["MSIL-G", "MSIL-M", "MSILMC","MSILME", "SMG", "MUL", "TKM", "TKAP-DHR"]; 
      
			// An array of values 
			var values = ["Gurgaon", "Manesar", "Casting","Power Train", "SMG Gujarat", "SMIPL","TKM","TKAP-DHR"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}

		//console.log("consigners ", consigners)
		items.push({value:'all',label:'All'}); 
		consigners.forEach(item =>{
			items.push({value:item.code,label:item.value}); 
		});
       
        return items;
	}
	selectMovementTypeOptionsItems(){
        let items = [];     
		items.push({value:"all",label:"All"});
		items.push({value:true,label:"Local Movement"});
		items.push({value:false,label:"Non Local Movement"});
		return items;
	}
	/*Venkatesh Jalla : New code for filters*/
	onGetConsigners = async (event) => {

		var transporter_code =localStorage.getItem('transportercode')
		if(transporter_code != undefined && transporter_code != "" && transporter_code != null){
			transporter_code = transporter_code
		}
		let consigneeCity = this.state.flCity
		if(this.props.match.path == "/sndconsignments" || this.props.match.path == "/prtconsignments"
		|| this.props.match.path == "/tnpconsignments")
		{
			
			if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
			{
				//var sDate = this.state.defaultsdate;
				var sDate = "";
			}
			else{
				var sDate = this.state.startDate;
			}
			if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
			{
				//var eDate = this.state.defaultedate;
				var eDate = "";
			}
			else{
				var eDate = this.state.endDate;
			}
			
		}
		else{
			if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
			{
				var sDate = this.state.defaultsdate;
			}
			else{
				var sDate = this.state.startDate;
			}
			if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
			{
				var eDate = this.state.defaultedate;
			}
			else{
				var eDate = this.state.endDate;
			}
		}
		let dCheck = dateCheck(sDate,eDate)
		if(dCheck === 1){
			this.setState({
				basicTitle : "From Date should be less than To Date",
				basicType : "danger",
				show : true,
				loadshow : "show-n",
				overly : "show-n"
			})
			return null
		}

		var selectconsigners=[]
		if(this.state.consigner == null)
		{
			selectconsigners = [{"value":'all', "label":'All'}]
		}
		else{
			selectconsigners=this.state.consigner
		}

		var selectconsignees=[]
		if(this.state.consignee == null)
		{
			selectconsignees = [{"value":'all', "label":'All'}]
		}
		else{
			selectconsignees=this.state.consignee
		}

		var mvmtype = []
		if(this.state.movementtype == null)
		{
			mvmtype = [{"value":'all', "label":'All'}]
		}
		else{
			mvmtype=[this.state.movementtype]
		}
		var dispatch_type = '';
		if(this.state.dispatch_type.value == "ALL")
		{
			dispatch_type = 'ALL'
		}
		else{
			dispatch_type=this.state.dispatch_type.value
		}
		

		//console.log("this.state.movementtype ", this.state.movementtype)
		//console.log("mvmtype ", mvmtype)
		var selecttrip=[]
		// if(this.state.triptype == null)
		// {
		// 	selecttrip = [{"value":'all', "label":'All'}]
		// }
		// else{
			selecttrip=[this.state.triptype]
		// }
		let consignmentState = this.state.flState
		if(this.state.deptcode == 'SNDG')
		{
			let cluster = this.state.flCluster
			//console.log("S1 ")
			var requestparams = {
				consigner: selectconsigners,
				dept_code: this.state.deptcode,				
				startDate:sDate,
				endDate:eDate,
				dispatch_type:dispatch_type
			}
			if(consignmentState !== '' && consignmentState !== undefined){
				requestparams['consignment_state'] = consignmentState.value
			}
			else if(cluster !== '' && cluster !== undefined){
				requestparams['cluster'] = cluster.value
			}
			else if(consigneeCity !== '' && consigneeCity !== undefined){
				requestparams['consigneeCity'] = consigneeCity.value
			}
		}
		else if(this.state.deptcode == 'LOG-PRT')
		{
			//console.log("S2 ")
			var requestparams = {
				consigner: selectconsigners,
				dept_code: this.state.deptcode,
				startDate:sDate,
				endDate:eDate,
				movement:mvmtype
			}
			if(consignmentState !== '' && consignmentState !== undefined){
				requestparams['consignment_state'] = consignmentState.value
			}
			else if(consigneeCity !== '' && consigneeCity !== undefined){
				requestparams['consigneeCity'] = consigneeCity.value
			}
		}
		else{
			//console.log("S3 ")
			var requestparams = {
				consigner: selectconsigners,
				consignee:selectconsignees,
				dept_code: this.state.deptcode,
				startDate:sDate,
				endDate:eDate,
				triptype:selecttrip
			}
		}
		if(localStorage.getItem("is_sales_type") == 1)
		{
			requestparams.sales_type = localStorage.getItem("sales_type");
			requestparams.region = localStorage.getItem("region");
		}
		let transporter = this.state.flTransporter
		if(transporter !== '' && transporter !== undefined) {
			requestparams['transporter'] = transporter.value
		}
		// console.log("requestparamsCheck", requestparams)

		var urlpath='';
		if(this.props.match.path == "/sndconsignments")
		{
			
			urlpath = '/consignments/filterSNDActiveConsignments';
			
		}
		else if(this.props.match.path == "/prtconsignments")
		{
			urlpath = '/consignments/filterPRTActiveConsignments';
			
		}
		else if(this.props.match.path == "/tnpconsignments")
		{
			urlpath = '/consignments/tnpconsignments';
		}
		else if(this.props.match.path == "/sndallconsignments")
		{
			urlpath = '/consignments/allsndconsignments';
			requestparams['screen'] = "all";
		}
		else if(this.props.match.path == "/insidemsilvicinty")
		{
			urlpath = '/consignments/allsndconsignments';
		}
		else if(this.props.match.path == "/prtallconsignments")
		{
			urlpath = '/consignments/allsndconsignments';
			requestparams['screen'] = "all";
		}
		else if(this.props.match.path == "/tnpallconsignments")
		{
			urlpath = '/consignments/alltnpconsignments';
			requestparams['screen'] = "all";
		}
		else if(this.props.match.path == "/snddeliveredconsignments")
		{
			urlpath = '/consignments/deliveredsndconsignments';
		}
		else if(this.props.match.path == "/prtdeliveredconsignments")
		{
			urlpath = '/consignments/deliveredprtconsignments';
		}
		else if(this.props.match.path == "/tnpdeliveredconsignments")
		{
			urlpath = '/consignments/deliveredtnpconsignments';
		}
		else if(this.props.match.path == "/railconsignments")
		{
			urlpath = '/consignments/railsndconsignments';
		}
		else if(this.props.match.path == "/ncrconsignments")
		{
			urlpath = '/consignments/ncrsndconsignments';
		}
		else if(this.props.match.path == "/moveconsignments")
		{
			urlpath = '/consignments/movesndconsignments';
		}
		// console.log("requestparams",requestparams)
		this.onLoadGetConsignmentsList(urlpath, requestparams)
	}
	getTruckConsignments=(event)=>{
		this.setState({
			loadshow : "show-m",
			overly : "show-m"
		})
		event.preventDefault();
		var selectedFilter = $("#selectedFilter").text();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		if(selectedFilter == "Select..." || inputTruckConsignment == "")
		{
			this.setState({
				basicTitle : "Fields Should not be Empty",
				basicType : "danger",
				show : true,
				loadshow : "show-n",
				overly : "show-n"
			})
		}
		else
		{
			// console.log(selectedFilter,inputTruckConsignment);
			var urlpath=""
			if(this.props.match.path == "/sndconsignments" || 
				this.props.match.path == "/prtconsignments" || 
				this.props.match.path == "/tnpconsignments" )
			{
				urlpath = "/consignments/filterByConsignment";
			}
			if(this.props.match.path == "/snddeliveredconsignments" || 
				this.props.match.path == "/prtdeliveredconsignments" || 
				this.props.match.path == "/tnpdeliveredconsignments" )
			{
				urlpath = "/consignments/filterDeliveredByConsignment";
			}
			if(this.props.match.path == "/ncrconsignments")
			{
				urlpath = "/consignments/filterNCRByConsignment"
			}
			if(this.props.match.path == "/sndallconsignments" || 
				this.props.match.path == "/prtallconsignments" || 
				this.props.match.path == "/tnpallconsignments" )
			{
				urlpath = "/consignments/filterAllByConsignment";
			}
			if(this.props.match.path == "/insidemsilvicinty" )
			{
				urlpath = "/consignments/filterInsideMSILByConsignment";
			}
			var transporter_code = "";
			var transportercode = localStorage.getItem('transportercode');
			if(transportercode !=undefined && transportercode !="undefined" && transportercode !="")
			{
				var tptCode = JSON.parse(transportercode);
				var transporter_code = tptCode[0];
			} 
			var parameters = {
				"selectedFilter":selectedFilter,
				"inputTruckConsignment":inputTruckConsignment,
				"dept_code" : this.state.deptcode,"transporter_code":transporter_code,
				"returnConsignments": this.state.tnpReturnConsignmentsVisibilty
			} 
			if(localStorage.getItem("is_sales_type") == 1)
			{
				parameters.sales_type = localStorage.getItem("sales_type");
				parameters.region = localStorage.getItem("region");
			}
			if(this.state.tnpReturnConsignmentsVisibilty == true){
				parameters.tnpReturnConsignmentsVisibilty = 1
			}
			redirectURL.post(urlpath,parameters)
			.then((response)=>{
				var counters = response.data.counters
				if(this.state.tnpReturnConsignmentsVisibilty)
				{
					counters.transit_delay = [{"transit_delay_count" :this.state.returnConsignments.filter(e => e.transit_delay == 1).length}];
					counters.enroute_stoppage = [{"enroute_exception_count": this.state.returnConsignments.filter(e => e.enroute_exception == 1).length}];
					counters.criticalCount = [{"criticalCount": this.state.returnConsignments.filter(e => e.is_critical == true).length}];
				}
				counters.deviation_flag = response.data.consignments.filter(e => e.deviation_flag > 0).length;
				if(this.props.match.path == "/snddeliveredconsignments"){
					var totalCO2Emission = response.data.consignments.reduce((acc, curr) => {
						const emission = curr.co2_emission > 0 ? curr.co2_emission / 1000 : 0;
						return acc + emission;
					  }, 0);
					  counters.co2_emission = totalCO2Emission
				}
				if(this.props.match.path == "/sndconsignments"){
					var totalCO2EmissionInTransit = response.data.consignments.reduce((acc, curr) => {
					  const emission = curr.co2_emission_intransit > 0 ? curr.co2_emission_intransit / 1000 : 0;
					  return acc + emission;
					}, 0);
					counters.co2_emission_intransit = totalCO2EmissionInTransit
				}
				  var totalCO2EmissionSavings = response.data.consignments.reduce((acc, curr) => {
					// console.log('currcurr',curr.co2_emission_savings)
					const emission = curr.co2_emission_savings > 0 ? curr.co2_emission_savings/1000 : 0;
					return acc + emission;
				  }, 0);
				  
				counters.co2_emission_savings = totalCO2EmissionSavings
				counters.pod_received_data = response.data.consignments.filter(e => e.pod_received == 1).length
				this.setState({
					rowData : response.data.consignments,
					allRowData :  response.data.consignments,
					countersjson : counters,
					loadshow : "show-n",
					overly : "show-n"
				})
			}).catch=((e)=>
			{
				console.log(e);
			})	
		}
		
	}
	
	onClickTripButton = async () => {

		if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var sDate = this.state.defaultsdate;
		}
		else{
			var sDate = this.state.startDate;
		}
		if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var eDate = this.state.defaultedate;
		}
		else{
			var eDate = this.state.endDate;
		}
		// var sDate=this.state.startDate;
		// var eDate = this.state.endDate;
		// console.log("consigner",selectconsigners)
		var selectconsigners=[]
		if(this.state.consigner == null)
		{
			selectconsigners = [{"value":'all', "label":'All'}]
		}
		else{
			selectconsigners=this.state.consigner
		}
		// console.log("consignee",selectconsignees)
		var selectconsignees=[]
		if(this.state.consignee == null)
		{
			selectconsignees = [{"value":'all', "label":'All'}]
		}
		else{
			selectconsignees = this.state.consignee
		}

		var selecttrip=[]
		if(this.state.triptype == null)
		{
			selecttrip = {"value":'all', "label":'All'}
		}
		else{
			selecttrip=this.state.triptype
		}
		console.log("image.png", this.state.flTransporter)
		var requestparams = {
			consigner: selectconsigners,
			consignee:selectconsignees,
			dept_code: this.state.deptcode,
			startDate:sDate,
			endDate:eDate,
			triptype:selecttrip,
			returnConsignments: this.state.tnpReturnConsignmentsVisibilty
		}
		if(localStorage.getItem("is_sales_type") == 1)
		{
			requestparams.sales_type = localStorage.getItem("sales_type");
			requestparams.region = localStorage.getItem("region");
		}
		var urlpath='';
		if(this.props.match.path == "/tnpconsignments")
		{
			urlpath = '/consignments/tnpconsignments';
			var transportercode = localStorage.getItem('transportercode');
			if(transportercode !=undefined && transportercode !="undefined" && transportercode !="")
			{
				requestparams.transporter_code = JSON.stringify(JSON.parse(transportercode));
			} 
		}
		
		else if(this.props.match.path == "/tnpallconsignments")
		{
			urlpath = '/consignments/alltnpconsignments';
			var transportercode = localStorage.getItem('transportercode');
			if(transportercode !=undefined && transportercode !="undefined" && transportercode !="")
			{
				var tptCode = JSON.parse(transportercode);
				requestparams.transporter_code = JSON.stringify(JSON.parse(transportercode));
			} 
		}
		
		else if(this.props.match.path == "/tnpdeliveredconsignments")
		{
			urlpath = '/consignments/deliveredtnpconsignments';
		}
		if(this.state.flTransporter.value !== "" && this.state.flTransporter.value !== undefined){
			requestparams.transporter_name = this.state.flTransporter.value
		}
		console.log("requestparams",requestparams)
		if(this.state.tabThreeClicked  !== 1){
			this.onLoadGetConsignmentsList(urlpath, requestparams)
		}else{
			this.getLongHaulData(requestparams,2)
		}
	}
	

	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var month = ("0" + (d.getMonth() + 1)).slice(-2); // Adding leading zero if month is less than 10
		var day = ("0" + d.getDate()).slice(-2); // Adding leading zero if day is less than 10
		var startdate = d.getFullYear() + "-" + month + "-" + day;
		if (this.state.endDate != undefined && this.state.endDate != "") {
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			if (startTime > endTime) {
				// console.log('inside fromdate')
				this.setState({
					show: true,
					basicTitle: 'To Date should be greater than From Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
			} else {
				// console.log('outside enddate')
				this.setState({
					startDate: startdate
				});
			}
		}
		 else {
			this.setState({
				startDate: startdate,
			});
		}
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var month = ("0" + (d.getMonth() + 1)).slice(-2); // Adding leading zero if month is less than 10
		var day = ("0" + d.getDate()).slice(-2); // Adding leading zero if day is less than 10
		var edate = d.getFullYear() + "-" + month + "-" + day;
		if (this.state.startDate != undefined && this.state.startDate != "") {
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			if (startTime > endTime) {
				// console.log('inside')
				this.setState({
					show: true,
					basicTitle: 'To Date should be greater than From Date',
					basicType: "danger",
					loadshow: 'show-n'
				});
			} else {
				// console.log('outside')
				this.setState({
					endDate: edate
				});
			}
		} 
		else {
			this.setState({
				endDate: edate
			});
		}
	}
	onRowSelection(event){
		var containerslist=[];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		// console.log("rwCount ", rwCount)
		//if(event.node.selected == true)
		//{
			//var containerlist=[];
			// console.log("rowselect", event.data)
			var rowCount =  event.data
			//console.log("rowCount ", rowCount)
			
			rwCount.map((item) => {
				containerslist.push(item.data.truck_no)
			});
			
			this.setState({
				containerslist:containerslist
			})
			//  console.log("checkedlist ", containerslist)
		// }
		// else{
		// 	if(containerslist.length > 0)
		// 	{
		// 		containerslist=[]
		// 		this.setState({
		// 			containerslist:containerslist
		// 		})
		// 	}
		// }
		
	}
	onClickShowMapView = async()=>{
		//this.gridApi.deselectAll();
		if(this.state.containerslist.length > 0)
		{
			var reqparms = {
				truck_no:this.state.containerslist,
				dept_code:this.state.deptcode
			}
		}
		else{
			var reqparms = {
				truck_no:[],
				dept_code:this.state.deptcode
			}
		}
		
		await redirectURL.post("/consignments/mapview",reqparms)
		.then((response) => {
			//console.log("Res ",response.data.message)
			var records = response.data.message;
			
			 var truck = JSON.parse(records.truck_info);
			 if(truck.length > 0)
			 {
				 if( truck.length > 1)
				{
					var consinees = this.state.plants;
				}
				else{
					var consinees = records.consignee_coordinates;
				}
			 }
			 else{
				var consinees = [];
			}
			//  console.log("truckinfo,",truck)
			 //console.log("truck.consignee_coordinates,",JSON.parse(records.consignee_coordinates))
			this.setState({
				open:true,
				maprowData:truck,
				rownode:truck,
				maptruckno:records.truck_no,
				consigneecoords:consinees,
				consigner_coordinates:records.consigner_coordinates
			});
			
			
			//containerslist=[];
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}
	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ open: false });
	 };
	 changeSegmentHandler=(e)=>{
		//  alert(e.consignment_code);
		this.setState({
			sliderSegmentTranslate : "slider-translate-60p",
			segementConsignmentCode : e.consignment_code,
			overly : "show-m"
		})
	}

	onOpenForceCloseModal = () => {
		this.setState({ openforceclose: true });
	 };
	
	 onCloseForceCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ openforceclose: false });
	 };

	 onCellUpdateData = (params) => {
		//  console.log("Parms ", params);
		if(params.colDef.field == "tnp_comments")
		{
			var qry = {
				rowid:params.data._id,
				tnp_comments:params.value
			}
			if(qry.tnp_comments !== "" && qry.tnp_comments !== undefined ){
				redirectURL.post("consignments/updatetnpcomment", qry)
				.then((response) =>{
					//  console.log("Res ", response);
				})
			}
		}
		if(params.colDef.field == "tnpconsignment")
		{
			var reqs={
					rowid:params.data._id,
					tnpconsignment:params.value
			}
			if(reqs.tnpconsignment !== "" && reqs.tnpconsignment !== undefined ){
				redirectURL.post("consignments/updatetnpmarking", reqs)
				.then((response) =>{
					//  console.log("Res ", response);
				})
			}
			let data = {
				rowid:params.data._id,
				gate_out_time :params.data.gate_out_time,
				consignee_code : params.data.consignee_code,
				tnp_user_dept_code : params.value
			}
			if(data.tnp_user_dept_code !== "" && data.tnp_user_dept_code !== undefined){
				redirectURL.post("/consignments/tnpuserdeptcodeupdate", data)
				.then((response) =>{
					// console.log("Res ", response);
				})

			}
		}
 	 }
	 async onShowSidebarAquireComponent(e){
		
		// console.log(e);
		if(e.colDef.field == 'truck_no')
		{
			this.setState({
				sliderTranslatesidebar:"",
				loadshow:'show-m'
			});
			let ComponentInfo = ComponentIndex['truck'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				truck_no:e.data['truck_no']
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/consignments/truckDetails', layoutdata1)
				.then((response) => {
					// console.log("trucks response", response);
					var records = JSON.parse(JSON.stringify(response)).data;
					var truckrecords = records.truck;
					var consignrecords = records.consignments;
					var currenttrucklocation = records.maplocation;
					// console.log("Current Location",currenttrucklocation);
					this.setState({
						truckInfo:truckrecords,
						consignments:consignrecords,
						currenttrucklocation:currenttrucklocation
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			this.displayData=await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo}  consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);	
			this.setState({
				showdata : this.displayData,
				sliderTranslatesidebar:"slider-translate",
				loadshow:'show-n',
				overly:'show-m'
			});
		}
		else if(e.colDef.field == 'consignment_code')
		{
			this.setState({
				sliderTranslatesidebar:"",
				loadshow:'show-m'
			});
			let ComponentInfo = ComponentIndex['consignment'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				consignment_code:e.data['consignment_code'],
				consignee_code:e.data['consignee_code']
			};
			// console.log(layoutdata1);
			await redirectURL.post('/consignments/consignmentDetails', layoutdata1)
				.then((response) => {
					// console.log("trucks response", response.data[0]);
					var consignment_details = response.data;
					// If gateouttime is current time - It shows "No Route data available"

					this.setState({
						consignment_details:consignment_details,
						//consignments:consignrecords,
						//currenttrucklocation:currenttrucklocation
					});
					// console.log(this.state.consignment_details);
				})
				.catch(function (error) {
					console.log(error);
				});
				try{
					this.displayData=await (<ComponentInfo context={this}  consignment={this.state.consignment_details}></ComponentInfo>);	
					this.setState({
						showdata : this.displayData,
						sliderTranslatesidebar:"slider-translate",
						loadshow:'show-n',
						overly:'show-m'
					});
				}
				catch(e){
					this.setState({
						showdata : "",
						sliderTranslatesidebar:"",
						loadshow:'show-n',
						overly:'show-n'
					});
				}
			
		}
	}
	 

	 handlerReachedDealerTime = (event, currentDate, selectedDate) => {
		 var d = new Date(event._d);
		 
		 var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		 
		 this.setState({
			reached_dealer_time:startdate
		 });
		 //let value = event.target.value;
		 //this.setState({'startDate':value});
	 }
	 
	 handlerLeftDealerTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		
		var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		
		this.setState({
			left_dealer_time:startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
	
	handlerInvoiceTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		
		var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		
		this.setState({
			invoice_time:startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
	 onClickForceClose = (params) =>{
		 //console.log("Pareas ", params)
		 this.setState({
			 forceclosedata:params.data,
			 forceCloseRowNode : params,
			 sliderForceCloseTranslate:"slider-translate-40p",
			 overly:'show-m',
			 reasonforceclose:""
		 })
	 }
	 handleInput(val) {
		this.setState({ reasonforceclose: val[1],selectedReason : val[0] });
		// console.log(val)
	  }
	 changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    	}
	 formForceCloseHandler(event){
		 event.preventDefault()
		 
		//  if(this.state.deptcode == 'SNDG')
		//  {
		// 	 var invoice_time = moment.parseZone(this.state.invoice_time).format("YYYY-MM-DD")+" "+this.state.invoicehh+":"+this.state.invoicemm+":"+this.state.invoicess;
		//  }
		//  else{
		// 	var invoice_time=""
		//  }
		 var consignemnt = this.state.forceCloseRowNode.data;
		 var consignment_code = consignemnt.consignment_code;
		 var consignee_code = consignemnt.consignee_code;
		 
		 var truck_no = consignemnt.truck_no;
		 var dept_code = this.state.deptcode;
		 var force_closure_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		 var userid = localStorage.getItem('userid');
		 var reasonforceclose = this.state.reasonforceclose;
		var others_Reason = this.state.selectedReason;
		//  var reached_dealer_time =  moment.parseZone(new Date(this.state.reached_dealer_time)).format("YYYY-MM-DD")+" "+this.state.reachedhh+":"+this.state.reachedmm+":"+this.state.reachedss;
		//  var left_dealer = moment.parseZone(new Date(this.state.left_dealer_time)).format("YYYY-MM-DD")+" "+this.state.lefthh+":"+this.state.leftmm+":"+this.state.leftss;
		 
	// 	var reached_dealer_time =  document.getElementById("reached_dealer_time").value;
	// 	if(reached_dealer_time != '')
	// 	{
	// 		var rtime = reached_dealer_time.split(" ");
	// 		var splitrtime = rtime[0].split("-");
	// 		var reachdealertime = splitrtime[2]+"-"+splitrtime[1]+"-"+splitrtime[0]+" "+rtime[1]+":00";  
	// 		//console.log("reached_dealer_time ", reachdealertime)
	// 	}
	//    else{
	// 	var reachdealertime = "";
	//    }
	// 	var left_dealer = document.getElementById("left_dealer_time").value;  
	// 	if(left_dealer != '')
	// 	{
	// 		var ltime = left_dealer.split(" ");
	// 		var splitltime = ltime[0].split("-");
	// 		var leftdealertime = splitltime[2]+"-"+splitltime[1]+"-"+splitltime[0]+" "+ltime[1]+":00";
	// 		//console.log("leftdealertime ", leftdealertime)
	// 	}   
	// 	else
	// 	{
	// 		var leftdealertime=""
	// 	}    
			
		if(this.state.deptcode == "SNDG")
		 {
			 //console.log(consignemnt.invoice_time);
			var invoice_time = getHyphenDDMMMYYYYHHMM(consignemnt.invoice_time);
			var reqdata = {
				dept_code:dept_code,
				consignment_code:[consignment_code],
				//consignee_code:consignee_code,
				truck_no:truck_no,
				//force_closure_time:force_closure_time,
				userid:userid,
				reasonforceclose:reasonforceclose,
			   	others_Reason : others_Reason,
				screen : ""
				// reached_dealer_time:reachdealertime,
				//invoice_time:invoice_time,
				// left_dealer:leftdealertime
			}
		 }
		 else
		 {
			var reqdata = {
				dept_code:dept_code,
				consignment_code:[consignment_code],
				//consignee_code:"",
				truck_no:truck_no,
				//force_closure_time:force_closure_time,
				userid:userid,
				screen : "",
				reasonforceclose:reasonforceclose,
			   	others_Reason : others_Reason,
				// reached_dealer_time:reachdealertime,
				//invoice_time:"",
				// left_dealer:leftdealertime
			}
		 }
		 var flag = 0;
		 if(others_Reason == "" && reasonforceclose == "Others"){
			 flag = 1;
		 }
		 if(flag == 0 && reasonforceclose == "Others")
		 {
		  //    console.log(others_Reason.replace(/\s/g,"").length,"lengt");
			 if(others_Reason.replace(/\s/g,"").length == 0)
			 {
			  flag = 1;
			 }
		 }
		// console.log("reqdata ",reqdata)
		if(flag == 0)
		{
			reqdata["returnConsignment"] = this.state.tnpReturnConsignmentsVisibilty
			redirectURL.post("/consignments/updateConsignmentForceClose", reqdata)
			.then((response) => {
				//console.log("Resposne updateConsignmentForceClose ", response)
				// console.log(response);
				if(response.data.status == 'Success')
				{
					// document.getElementById("reached_dealer_time").value='';
					// document.getElementById("left_dealer_time").value=''; 
					this.setState({
						show:true,
						basicTitle:"Success",
						basicType:"success",
						loadshow:'show-n',
						overly:'show-n',
						left_dealer_time:'',
						reached_dealer_time:'',
						reachedhh:'',
						reachedmm:'',
						reachedss:'',
						lefthh:'',
						leftmm:'',
						leftss:'',
						reasonforceclose:"",
						openforceclose:false,
						sliderForceCloseTranslate:'',
						sliderTranslatesidebar:"",
					});	
				}
				else
				{
					this.setState({
						show:true,
						basicTitle:"Error",
						basicType:"danger",
						loadshow:'show-n',
						overly:'show-n',
						left_dealer_time:'',
						sliderTranslatesidebar:"",
						reached_dealer_time:'',
						reachedhh:'',
						reachedmm:'',
						reachedss:'',
						lefthh:'',
						leftmm:'',
						leftss:'',
						reasonforceclose:'',
						openforceclose:false,
						sliderForceCloseTranslate:''
					});	
				}

			})
			.catch(function(e){
				console.log("Error ", e)
			})
		}
		else{
			this.setState({
				show: true,
				basicTitle:'Reason for closue should not be empty',
				basicType:"danger",
			});
		}
		event.target.reset();
	 }

	onClickSaveGridState = () => {
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// console.log("Columns ", window.colState)
		// console.log("Group ", window.groupState)
		// console.log("Sort ", window.sortState)
		// console.log("Filter ", window.filterState)
		let screenpage = '';

		if(this.props.match.path == "/sndconsignments")
		{
			screenpage='snd active consignments';
		}
		else if(this.props.match.path == "/prtconsignments")
		{
			screenpage='prt active consignments';
		}
		else if(this.props.match.path == "/tnpconsignments")
		{
			screenpage='tnp active consignments';
		}
		else if(this.props.match.path == "/sndallconsignments")
		{
			screenpage='snd all consignments';
			
		}
		else if(this.props.match.path == "/prtallconsignments")
		{
			screenpage='prt all consignments';
		}
		else if(this.props.match.path == "/tnpallconsignments")
		{
			screenpage='tnp all consignments';
		}
		else if(this.props.match.path == "/snddeliveredconsignments")
		{
			screenpage='snd delivered consignments';
		}
		else if(this.props.match.path == "/prtdeliveredconsignments")
		{
			screenpage='prt delivered consignments';
		}
		else if(this.props.match.path == "/tnpdeliveredconsignments")
		{
			screenpage='tnp delivered consignments';
		}
		else if(this.props.match.path == "/railconsignments")
		{
			screenpage='railconsignments';
		}
		else if(this.props.match.path == "/ncrconsignments")
		{
			screenpage='ncr consignments';
		}
		else if(this.props.match.path == "/moveconsignments")
		{
			screenpage='move consignments';
		}

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage,
			// pagetitle : this.state.pagetitle
		}
		// console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates = () =>{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

			if(!(this.state.deptcode=="SNDG")){
				this.gridColumnApi.setColumnsVisible(["cluster"], false);
			}
			if(this.props.match.path=="/tnpallconsignments" ||
			 this.props.match.path=="/tnpdeliveredconsignments" ||
			 this.props.match.path=="/tnpconsignments" 
			 ){
				this.gridColumnApi.setColumnsVisible(["inside_parking_at"], true);
				this.gridColumnApi.setColumnsVisible(["outside_parking_at"], true);
				this.gridColumnApi.setColumnsVisible(["at_parking_yard_duration"], true);
			}else{
				this.gridColumnApi.setColumnsVisible(["inside_parking_at"], false);
				this.gridColumnApi.setColumnsVisible(["outside_parking_at"], false);
				this.gridColumnApi.setColumnsVisible(["at_parking_yard_duration"], false);
			}
			
		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage,
			// pagetitle : this.state.pagetitle
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

	onShowConsignmentTickets = async (item)=>{
		//console.log(item)
		var deptcode = this.state.deptcode

		await this.setState({
					
			detailCellRendererParams:{
				detailGridOptions: {
					columnDefs: [
						{ 
							headerName: "Ticket",
							field: "ticket_type",
							valueGetter:function(params){
								var tickettype = params.data.ticket_type;
								var str = tickettype.replace(/_/g, " ")
								return str;
							},
							cellClass:"capitlize"
						},
						{
							headerName:"Ticket No",
							field:"ticket_number",                
							width:120,
						},
						{
							headerName:"Truck No",
							field:"truck_no",                
							width:120,
						},
					
						{
							headerName:"Transporter",
							field:"transporter_name",                
							width:120,
						},
						{
							headerName:"Department",
							field:"dept_code",                
							width:150,
						},
						{
							headerName:"Actual LSP User",
							field:"actual_lspuser",                
							width:150,
						},
						{
							headerName:"Status",
							field:"ticket_status",
							width:130,
							valueGetter:function(params){
								if(params.data.ticket_status == 1)
								{
									return "Open";
								}
								if(params.data.ticket_status == 2)
								{
									return "Work in progress";
								}
								if(params.data.ticket_status == 3)
								{
									return "Closed";
								}
								if(params.data.ticket_status == 4)
								{
									return "On hold";
								}
								if(params.data.ticket_status == 5)
								{
									return "Auto closed";
								}
								if(params.data.ticket_status == 6)
								{
									return "Invalid";
								}
								if(params.data.ticket_status == 7)
								{
									return "Closed - No response";
								}
								if(params.data.ticket_status == 8)
								{
									return "Force Closed";
								}
							}
						},
						{
							headerName:"Consignee City",
							field:"consignee_city",                
							width:180,
							valueGetter:function(params){
								if(typeof params.data.consignee_city == undefined){
									return '';
								}
								else{
									return params.data.consignee_city;
								}
							}
						},
						{
							headerName:"Transit Delay Hours",
							field:"transit_delay_hours",                
							width:180,
							valueGetter:function(params){
								if(typeof params.data.transit_delay_hours == undefined){
									return '';
								}
								else{
									return params.data.transit_delay_hours;
								}
							}
						},
						{
							headerName : "Exception Location",
							field : "exception_location",
							width : 170,
							resizable : true
						},
						// {
						// 	headerName : "Exception Longitude",
						// 	field : "exception_lng",
						// 	width : 140,
						// 	resizable : true
						// },
						{
							headerName:"Last Known City/Area",
							field:"area",                
							width:180,
							valueGetter : function(params)
							{
								return params.data.truck[0].area
							}
						},
						{
							headerName:"Last Known State",
							field:"area",                
							width:180,
							valueGetter : function(params)
							{
								return params.data.truck[0].state
							}
						},
						{
							headerName:"Last Packet Time",
							field:"timestamp",                
							width:180,
							valueGetter:function(params){
								return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp)
							},
							comparator: dateComparator,
						},
						{
							headerName:"Gate Out / Invoice Time",
							field:"gate_out_invoice_time",                
							width:180,
							valueGetter:function(params){
								return getHyphenDDMMMYYYYHHMM(params.data.gate_out_invoice_time)
							},
							//filter: "agDateColumnFilter",
							comparator: dateComparator,
						},
						{
							headerName:"Created Date",
							field:"ticket_creation_date",                
							width:180,
							valueGetter:function(params){
								return getHyphenDDMMMYYYYHHMM(params.data.ticket_creation_date)
							},
							//filter: "agDateColumnFilter",
							comparator: dateComparator,
						},
						{
							headerName:"Modified Date",
							field:"ticket_modification_date",                
							width:180,
							valueGetter:function(params){
								return getHyphenDDMMMYYYYHHMM(params.data.ticket_modification_date)
							},
							//filter: "agDateColumnFilter",
							comparator: dateComparator,
						},
					]							
				},
				getDetailRowData: async function(params) {
					var parameter = {
						consignment_code:params.data.consignment_code,
						dept_code:deptcode
					}
					redirectURL.post("/consignments/ticketsbyconsignment",parameter)
					.then((response)=>{
						params.successCallback(response.data)
					})
					.catch(function(e){
			
					})
					
				}
			}
		});
		
		if(item.column.colDef.field == 'tickets')
		{
			item.node.setExpanded(!item.node.expanded);
		}
		else{

			item.node.setExpanded(false);
		}
		

	}

	
    onCloseUserManualModal = () => {
        this.setState({ usermanualncrmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualncrmodal: true });
    };

    onCloseUserManualMoveModal = () => {
        this.setState({ usermanualmovemodal: false });
    };

    onClickShowUserMoveManaul = () => {
        this.setState({ usermanualmovemodal: true });
	};
	
	onShowUploadEWay()
	{
		this.setState({
			slideuploadeway:"slider-translate",
			overly:'show-m'
		})
	}

	uploadBulkPriorityFormHandler(event)
	{
		event.preventDefault();		
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var bulkMdData = this.state.pCsvContent;
		//console.log(bulkMdData);
		var flag = 0;
		// console.log("bulkMdData: =>", bulkMdData)
		bulkMdData.map(function(e,index){
			var keys = Object.keys(e);
			var consignmentKey=""
			keys.map(function(key){
				if(key == "Consignment_Code" ||
					key == "Consignment Code" ||
					key == "consignment_code" ||
					key == "consignment code" ||
					key == "ConsignmentCode" ||
					key == "consignmentcode" 
				)
				{
					consignmentKey = key;
				}
			})
			if(index != (bulkMdData.length-1))
			{
				if(e[consignmentKey] == "")
				{
					flag = 1
				}
			}
			
		})
		if(flag == 0)
		{
			var reqParams = {
				email : localStorage.getItem("email"),
				csvData : bulkMdData
			}
			redirectURL.post("/consignments/bulkUploadConsignmentPriority",reqParams).then((response)=>{
				this.setState({
					basicTitle : "Success",
					basicType : "success",
					show : true,
					file : "",
					bulkPrioritySlide:"",
					overly:"show-n",
					loadshow : "show-n"
				})
				// window.location.reload();
			}).catch((e)=>{
				console.log(e);
			})
			
		}
		else{
			$("#bulkUploadPID").val();
			this.setState({
				basicTitle : "Consignment_code fields must not be empty",
				basicType : "danger",
				show : true,
				file : "",
				pCsvContent : "",
			})
		}

		var urlpath = '/consignments/prtconsignments';

		var dpt='LOG-PRT';
		var consignData = {
			dept_code:dpt,
			startDate:this.state.startDate,
			endDate:this.state.endDate,
			consigner:this.state.consigner,
			movement:[this.state.movementtype]
		}
		

		this.onLoadGetConsignmentsList(urlpath, consignData)
	}

	uploadCriticalFormHandler = event =>{
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		let bulkCriticalData = this.state.pCsvContent;
		var flag = 0;
		// console.log("bulkCriticalData: =>", bulkCriticalData)
		bulkCriticalData.map((e,index) => {
			var keys = Object.keys(e);
			var consignmentKey=""
			keys.map((key) => {
				if(key == "Consignment_Code" ||
					key == "Consignment Code" ||
					key == "consignment_code" ||
					key == "consignment code" ||
					key == "ConsignmentCode" ||
					key == "consignmentcode" 
				)
				{
					consignmentKey = key;
				}
			})
			if(index != (bulkCriticalData.length-1))
			{
				if(e[consignmentKey] == "")
				{
					flag = 1
				}
			}
			
		})
		if(flag == 0)
		{
			var reqParams = {
				userEmail : localStorage.getItem("email"),
				token:window.atob(localStorage.getItem('_t')),
				userId:localStorage.getItem("userid"),
				csvData : bulkCriticalData
			}
			redirectURL.post("/consignments/bulkUploadConsignmentCritical",reqParams).then((response)=>{
				this.setState({
					basicTitle : "Success",
					basicType : "success",
					show : true,
					file : "",
					bulkPrioritySlide:"",
					overly:"show-n",
					loadshow : "show-n"
				})
				// window.location.reload();
			}).catch((e)=>{
				console.log(e);
			})
			
		}
		else{
			$("#bulkUploadPID").val();
			this.setState({
				basicTitle : "Consignment_code fields must not be empty",
				basicType : "danger",
				show : true,
				file : "",
				pCsvContent : "",
			})
		}

		var urlpath = '/consignments/sndconsignments';

		var dpt='SNDG';
		var consignData = {
			dept_code:dpt,
			startDate:this.state.startDate,
			endDate:this.state.endDate,
			consigner:this.state.consigner,
			movement:[this.state.movementtype]
		}
		

		this.onLoadGetConsignmentsList(urlpath, consignData)
	}
	changePriorityFileHandler = async (e) => {
		var dt = '';

		const config = {
			headers: [
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				pCsvContent:csvData.data
			});
		})
		.catch(err => {})
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []
		
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
		});
		this.setState({
			file:e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				file:"",
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
	}

	changeCriticalFileHandler = async e =>{
		const config = {
			headers: [
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}

		CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				pCsvContent:csvData.data
			});
		})
		.catch(err => {})
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
		});
		this.setState({
			file:e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				file:"",
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
	}

	onBulkPriorityBtn(){
		this.setState({
			overly : "show-m",
			bulkPrioritySlide : "slider-translate"
		})
	}

	changeFileHandler = async (e) => {
		const config = {
			headers: [
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'ewaybill_no',
					inputName: 'ewaybill_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'expiry_date',
					inputName: 'expiry_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				// console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			// console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);
		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }
		
	}

	resetUpload = () => {
		this.setState({
			slideuploadeway:'',
			overly:'show-n',
			file:'',
			showDiv:'show-n',
			uploadDivWidth:'0%',
			bulkPrioritySlide:'',
			bulkForceCloseSlide:'',
		});
	}

	uploadEway(event){
		event.preventDefault();
		
		if(this.state.file != '')
		{
			// console.log("this.state.file ",this.state.file)
			var csvdd = this.state.file;
			
			// console.log("event ",event.target.uploadFile.value);
			var formData = new FormData();
			formData.append('uploadFile',this.state.file);
			formData.append("userId", localStorage.getItem('userid'));
			formData.append("csvcontent",this.state.csvcontent);
			var rparams = {
				uploadFile:this.state.file,
				userId:localStorage.getItem('userid'),
				csvcontent:this.state.csvcontent
			}
			//console.log('file',formData);
			//console.log('rparams',rparams);
			this.setState({
				loadshow:'show-m'
			}); 
			redirectURL.post("/consignments/uploadewayexpires", rparams)
			.then(
				(response)=>{
					//console.log(response);
					// console.log(response.data);
					//document.getElementById("upform").reset();
					if(response.data.status == 'failure')
					{
						if(response.data.message.empty!="")
						this.setState({
							//csverrmessage:response.data.status,
							show: true, 
							basicType:'danger', 
							basicTitle:"Failed",
							slideuploadeway:'',
							overly:'show-n',
							showDiv:'show-n',
							loadshow:'show-n',
							uploadFile:'',
							file:''
						});
						
					}
					else
					{
						//console.log("here")
						this.setState({
							show: true, 
							basicType:'success', 
							basicTitle:"Successfully Uploaded.",
							slideuploadeway:'',
							overly:'show-n',
							showDiv:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:''
						});
						
					}
					
					 
				}
			)
			.catch(function(error){
				console.log(error);
			});
		}
		
	}

	onCellClicked = async (cell) => {

		if(cell.colDef.field == "srv_logs"){
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{"headerName": "consignment code",
							field: "consignment_code",
							width: 120, resizable: true},
							{"headerName": "Invoice No",
							field: "invoice_no",
							width: 120, resizable: true},
							{"headerName": "SRV/GRN No",
							field: "srv_no",
							width: 110, resizable: true,
							valueGetter: function(resp){
								// if(resp.data.srv_no != "nan" && resp.data.srv_no != undefined)
								// {
									return resp.data.srv_no
								// }
								// else
								// {
								// 	return ""
								// }
							}},
							{
								"headerName": "SRV/GRN Status",
								field: "srv_status",
								width: 120,
								resizable: true,
								valueGetter: function(resp){
									if(resp.data.srv_status != "nan" && resp.data.srv_status != undefined && resp.data.srv_status != "NA")
									{
										if(resp.data.srv_status == "Material is inside Maruti")
										{
											return "Material Accepted";
										}
										else
										{
											return resp.data.srv_status;
										}
									}
									else
									{
										return ""
									}
								}
							},
							{"headerName": "DLoc Code",
							field: "dloc_code",
							width: 120, resizable: true},
							{"headerName": "Part Name",
							field: "part_name",
							width: 120, resizable: true},
							{"headerName": "Part No",
							field: "part_no",
							width: 120, resizable: true},
							{"headerName": "Quantity",
							field: "quantity",
							width: 120, resizable: true},
							{"headerName": "Transaction Type",
							field: "transaction_type",
							width: 120, resizable: true},
							{"headerName": "Unload No",
							field: "unload_no",
							width: 120, resizable: true,
							valueGetter: function(resp){
								if(resp.data.unload_no != "nan" && resp.data.unload_no != undefined)
								{
									return parseInt(resp.data.unload_no).toString()
								}
								else
								{
									return ""
								}
							}}, 							
						],
						overlayNoRowsTemplate: 'No rows to show',
														
					},
					getDetailRowData:async function(param) {
						param.successCallback([]);
						await redirectURL.post("/dashboard/getSRVLogs", {"consignment_code": param.data.consignment_code,
						"invoice_no": param.data.invoice_no}).then((response)=>{
							param.successCallback(response.data);
						}).catch(function(error){
							console.log(error);
						})
						 
					},
					masterDetail: true
				}
			});
			

			cell.node.setExpanded(!cell.node.expanded);
		}
		if(cell.colDef.field == "delete"){
			var rowId = cell.data._id;
			var consigner_code = cell.data.consigner_code;
			var qry = {
				rowId : rowId,
				rowData : cell.data,
				user_id: localStorage.getItem('userid'),
				user_name: localStorage.getItem('username'),
            	user_email: localStorage.getItem('email'),
			}
			if(consigner_code == "SMG")
			{
				if (window.confirm("Are You Sure, Consignment and related details will be deleted"))
				{
					// console.log(rowId,"rowId")
					redirectURL.post("/consignments/deleteReturnPallets",qry).then((response)=>{
						// console.log("responseData:",response.data);
						if(response.data.message == "Success")
						{
							this.setState({
								basicTitle:"Deleted Successfully.",
								basicType : "success",
								show : true
							})
							window.location.reload();
						}
						else
						{
							this.setState({
								basicTitle:"Failed to Delete.",
								basicType : "warning",
								show : true
							})
						}
					}).catch((e)=>{
						console.log(e);
					})
				}
			}
		}
		if(cell.colDef.field == "priority")
		{
			if(cell.data.is_priority !=1)
			{
				this.setState({
					loadshow : "show-m",
					overly:"show-m",
				});
				var consignmentId = cell.data.consignment_code;
				var email = localStorage.getItem("email");
				var reqParams = {
					consignmentId : consignmentId,
					email : email
				}
				redirectURL.post("/consignments/updateConsignmentPriority",reqParams).then((response)=>{
					this.setState({
						loadshow : "show-n",
						overly:"show-n",
						basicTitle : "Consignment Marked As Priority",
						basicType : "success",
						show : true,
					});					
					// window.location.reload();
					var urlpath = '/consignments/prtconsignments';

					var dpt='LOG-PRT';
					var consignData = {
						dept_code:dpt,
						startDate:this.state.startDate,
						endDate:this.state.endDate,
						consigner:this.state.consigner,
			            movement:[this.state.movementtype]
					}
					

					this.onLoadGetConsignmentsList(urlpath, consignData)
					
				}).catch((cell)=>{
					// console.log("cell:", cell)
				})
			}
		}
		if(cell.colDef.field == "sent_spl_notification")
		{
			if(cell.data.status == 2)
			{
				redirectURL.post("/consignments/notifySpotCourier", {"row_id": cell.data._id}).then((resp)=>{
					this.setState({
						basicTitle: "success",
						basicType: "success",
						show: true
					})
					// setTimeout(()=>{
					// 	window.location.reload()
					// }, 2000)
				})
			
			}
		}

		if(cell.colDef.field == 'delay_acceptance_btn'){
			// console.log('updateDelay',cell)
			let consignment_code = cell.data.consignment_code
			if(cell.data.delay_reason) var delay_reason = cell.data.delay_reason
			
			if(cell.data.delay_acceptance) var delay_acceptance = cell.data.delay_acceptance
			let params = {
				consignment_code,
			}
			
			if((delay_reason !== ""  && delay_reason !== undefined) || (delay_acceptance !== ""  && delay_acceptance !== undefined) ){
				redirectURL.post("/consignments/getDelayReasonOnClick", params).then((resp)=>{
					// console.log("data", resp)
					if(resp.data.length !== 0){
						this.setState({
							delayRowData : resp.data,
							logsSLider : 'slider-translate-45p',
							overly:"show-m",
							// basicTitle: "success",
							// basicType: "success",
							// show: true
						})
					}
				})
			}
			 
		}
	}

	onClickTab = (clickedTab) =>{
		var colsToHide = [ "overspeeding_exception", "nightdriving_exception", "loading_delay_exception", "sent_spl_notification"]
		if(clickedTab == 1)
		{
			var title = this.state.pagetitle;
			// console.log("initialTitle:", title)
			title = title.split(" ")
			
			if(title.includes("Return", false)){title.splice(title.indexOf("Return"), 1)}
			title = join(title, " ")	
			this.setState({
				rowData: this.state.originalData,
				tnpReturnConsignmentsVisibilty: false,
				consBtn: "btn-danger",
				returnConsBtn: "btn-default",
				longHaulBtn : "btn-default",
				countersjson: this.state.originalcountersjson,
				allRowData: this.state.originalData,
				pagetitle: 'Active Consignments',
				showFiltersForLongHaulScreenTab : 'show-m',
				showFiltersForPartLevelScreenTab : 'show-n',
				tabThreeClicked : 0,
			})
			this.gridColumnApi.setColumnsVisible(colsToHide,true)
			this.getUserGridState()
		}
		else if(clickedTab == 3){
			// console.log("test")	
			let consignment_codes = []
			this.state.originalData.map(e =>{
				consignment_codes.push(e.consignment_code)
			})
			this.getLongHaulData(consignment_codes,1)
			this.setState({
				tabThreeClicked : 1,
				showFiltersForPartLevelScreenTab : 'show-m',
				loadshow: "show-m",
				overly: "show-m",		
				longHaulBtn : "btn-danger",
				consBtn: "btn-default",
				returnConsBtn: "btn-default",	
				showFiltersForLongHaulScreenTab : 'show-n'			
			})		
		}
		else
		{
			this.state.countersjson.transit_delay = [{"transit_delay_count" :this.state.returnConsignments.filter(e => e.transit_delay == 1).length}];
			this.state.countersjson.enroute_stoppage = [{"enroute_exception_count": this.state.returnConsignments.filter(e => e.enroute_exception == 1).length}];
			this.state.countersjson.criticalCount = [{"criticalCount": this.state.returnConsignments.filter(e => e.is_critical == true).length}];
			this.state.countersjson.no_gps = [{"no_gps_data_count": this.state.returnConsignments.filter(e => e.no_gps_data == 1).length}]
			this.state.countersjson.unloading_delay = [{"unloading_delay_exception_count": 
														this.state.returnConsignments.filter(e => e.unloading_delay_exception == 1).length}]
			this.state.countersjson.nogpslifetime = [{"no_gps_data_lifetime_count": 
														this.state.returnConsignments.filter(e => e.no_gps_data_lifetime == 1).length}]
			// var title = this.state.pagetitle;			
			// // console.log("initialTitle", title)		
			// title = title.split(" ")
			// // console.log(typeof(title))
			// // console.log(title,"title")
			// var tarray = [];
			// var  ttl;
			// title.map(function(t){
			// 	ttl = t.replace(/Return/g, "");
			// 	tarray.push(ttl);
			// })
			// tarray.splice(1, 0 , "Return")
			// var title = join(tarray, " ")
			
			this.setState({
				rowData: this.state.returnConsignments,
				tnpReturnConsignmentsVisibilty: true,
				consBtn: "btn-default",
				returnConsBtn: "btn-danger",
				longHaulBtn : "btn-default",
				countersjson: this.state.countersjson,
				allRowData: this.state.returnConsignments,
				pagetitle: 'Active Return Consignments',
				showFiltersForLongHaulScreenTab : 'show-m',
				showFiltersForPartLevelScreenTab : 'show-n',
				tabThreeClicked : 0,		

			})
			
			// colsToHide.map((e)=>{
			this.gridColumnApi.setColumnsVisible(colsToHide,false)
			// })
		}
		
	}

	// getting data for part level long haul data for tnp 
	getLongHaulData = (arg1,arg2) =>{
		if(arg2 === 1){
			var param = {consignment_codes : JSON.stringify(arg1)}
		}
		else if(arg2 === 2){
			param = arg1
		}
		// console.log("params",param)
		redirectURL.post('/consignments/getPartWiseLongHaulDataForTNP',param).then( response =>{
			let longHaulRowData = JSON.parse(response.data.result_data)
			// console.log("getPartWiseLongHaulDataForTNP",longHaulRowData)
			let columnsWithDefs = [
				{
					headerName: "",
					field: "_id",
					colId: "_id",
					width: 50,
					pinned: 'left',
					cellRenderer:'consignmentActions',
					filter: false,resizable: true
					
				},
				{
					headerName : 'Part Number',
					field  : 'part_no',
					width: 100,
					filter: true,
					resizable: true,
				},							
				{
					headerName : 'Part Name',
					field  : 'part_name',
					width: 190,
					filter: true,
					resizable: true,
				},
				{
					headerName : 'Supply Date Time',
					field  : 'supply_date_time',
					width: 130,
					filter: true,
					resizable: true,
					valueGetter: (params) => {
						return getHyphenDDMMMYYYYHHMM(params.data.supply_date_time)
					},
				},
				{
					headerName : 'Truck No',
					field  : 'truck_no',
					width: 100,
					filter: true,
					resizable: true,
				},
				{
					headerName : 'Last Packet Time',
					field  : 'timestamp',
					width: 130,
					filter: true,
					resizable: true,
					valueGetter: (params) => {
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					},
				},
				{
					headerName : 'Consigner Code',
					field  : '',
					width: 130,
					filter: true,
					resizable: true,
				},
				{
					headerName : 'Consignee Code',
					field  : 'consignee_code',
					width: 130,
					filter: true,
					resizable: true,
				},
				{
					headerName : 'Transporter Code',
					field  : 'transporter_code',
					width: 130,
					filter: true,
					resizable: true,
				},
				{
					headerName : 'Quantity',
					field  : 'quantity',
					width: 75,
					filter: true,
					resizable: true,
				},
				{
					headerName : 'Gate Out Time',
					field  : 'gate_out_time',
					width: 130,
					filter: true,
					resizable: true,
					valueGetter: (params) => {
						return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time)
					},
				},		
				{
					headerName : 'Scheduled ETA',
					field  : 'expected_trip_end',
					width: 130,
					filter: true,
					resizable: true,
					valueGetter: (params) => {
						return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end)
					},
				},							
				{
					headerName : 'Current ETA',
					field  : 'revised_trip_end',
					width: 130,
					filter: true,
					resizable: true,
					valueGetter: (params) => {
						return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end)
					},
				},
				{
					headerName : 'Revised ETA',
					field  : 'revised_trip_end',
					width: 130,
					filter: true,
					resizable: true,
					valueGetter: (params) => {
						return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end)
					},
				},
				{
					headerName : 'Current Location',
					field  : 'last_known_address',
					width: 180,
					filter: true,
					resizable: true,
				},		
				{
					headerName : 'Distance To Be Covered',
					field  : 'distance_from_dealer_location',
					width: 130,
					filter: true,
					resizable: true,
				},	
				{
                    headerName: "Travel Status",
                    field: "travel_status",
                    width: 100,
                    // hide: tStatusShowOrHide,
                    // colId:"elaped_time_from_last_moved_seconds",
                    valueGetter: function (params) {
                        try {
                            // console.log("params.data.travel_sttaus ", params.data.travel_sttaus)
                            // if(params.data.travel_sttaus != "" && params.data.travel_sttaus != undefined){
                            //     return "Inactive";
                            // }else{                            
                            if (params.data.speed > 0) {
                                return "Travelling";
                            }
                            else if(params.data.travel_status != undefined && params.data.travel_status != "" && params.data.travel_status !== null){
                                var mre = secondsToHM(params.data.travel_status);
                                return "Idle (" + mre + ")";
                            }
                            // }
                        }
                        catch (e) {
                            return "";
                        }
                    },
                    cellClass: function (params) {
                        try {
                            // if (params.data.travel_sttaus == "Inactive") {
                            //     return ["maroon", "fbold"];
                            // }
                            // else {
                                if (params.data.speed > 0) {
                                    return ["greenfont", "fbold"];
                                }
                                else {
                                    return ["redfont", "fbold"];
                                }
                            // }

 

                        }
                        catch (e) {
                            return "";
                        }
                    }
				},
				
				{
					headerName : 'Reached destination at',
					field  : 'recent_dealer_reported',
					width: 130,
					filter: true,
					resizable: true,
					valueGetter: (params) => {
						return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported)
					},
				},
				{
					headerName : 'Schedule date & time',
					field  : 'schedule_date_time',
					width: 130,
					filter: true,
					resizable: true,
					valueGetter: (params) => {
						return getHyphenDDMMMYYYYHHMM(params.data.schedule_date_time)
					},
				},
				{
					headerName : 'Schedule location',
					field  : 'schedule_location',
					width: 130,
					filter: true,
					resizable: true,
				},
				{
					headerName : 'Near parking at',
					field  : 'parking_radius_entered_on',
					width: 130,
					filter: true,
					resizable: true,
					valueGetter: (params) => {
						return getHyphenDDMMMYYYYHHMM(params.data.parking_radius_entered_on)
					},
				},
				{
					headerName : 'Yard duration',
					field  : 'calculation',
					width: 130,
					filter: true,
					resizable: true,
					valueGetter: (params)=>{
                        let diffInHours=""
                        if([undefined, "", NaN].includes(params.data.parking_radius_entered_on) == false){
                            var start_date = params.data.parking_radius_entered_on;
                            start_date = moment.parseZone(new Date(start_date)).format("YYYY-MM-DD HH:mm:ss")
                            start_date = moment.parseZone(new Date(start_date)).utcOffset("+05:30")._d;
                            // var start_date = moment.parseZone(new Date()).utcOffset("+05:30")._d;
                            console.log(start_date,end_date,"8431")
                            if([undefined, "", NaN].includes(params.data.parking_radius_exited_on) == false){
                                var end_date = params.data.parking_radius_exited_on;
								// console.log(end_date,'end_date')
                                end_date = moment.parseZone(new Date(end_date)).format("YYYY-MM-DD HH:mm:ss")
                                end_date = moment.parseZone(new Date(end_date)).utcOffset("+05:30")._d;
                                // console.log("data for the time in duration start duration",start_date)
                            var diff = end_date.valueOf() - start_date.valueOf();
                            // console.log("data for the time in duration difference",diff)
                            diffInHours = (diff/1000/60/60).toFixed(2);
                            // console.log("data for the time in duration",diffInHours)
                            }
                           
                            return diffInHours
                        }
                    }
				},
			]
			
			this.setState({
				longHaulRowData,
				rowData : longHaulRowData,
				columnsWithDefs,
				pagetitle : "Part Level Details",
				tnpReturnConsignmentsVisibilty: false,
				showFiltersForLongHaulScreenTab : 'show-n',
				loadshow: "show-n",
				overly: "show-n",
			})
		})
	}

	// filter fuction for part level by part number 
	getPartConsignments = event =>{
		event.preventDefault()
		// this.setState({
		// 	loadshow : "show-m",
		// 	overly : "show-m"
		// })
		const partRowData = _.cloneDeep(this.state.longHaulRowData)
		// const partRowData = JSON.parse(JSON.stringify(this.state.longHaulRowData))
		var inputPartNumber = $("#inputPartNumber").val();
		var inputTruckNumber = $("#inputTruckNumber").val();
		// console.log("inputPartNumber",inputPartNumber)
		// console.log("inputTruckNumber",inputTruckNumber)
		if(inputPartNumber !== "" && inputPartNumber !== undefined){
			// let filteredPartData = partRowData.filter(e => e.part_no_copy === inputPartNumber)
			let filteredPartData = []
			partRowData.map(e =>{
				if(e.part_no_copy === inputPartNumber){
					e.part_no = e.part_no_copy
					e.part_name = e.part_name_copy
					filteredPartData.push(e)
				}
			})
			// console.log("filterdPartd", filteredPartData)
			this.setState({
				rowData : filteredPartData,
				loadshow : "show-n",
				overly : "show-n"
			})
			$("#inputPartNumber").val("")
		}
		if(inputTruckNumber !== "" && inputTruckNumber !== undefined){
			
			let filterdTruckDetails = []
			partRowData.map(e =>{
				if(e.truck_no === inputTruckNumber){
					e.part_no = e.part_no_copy
					e.part_name = e.part_name_copy
					filterdTruckDetails.push(e)
				}
			})
			// console.log('filterdTruckDetails',filterdTruckDetails)
			this.setState({
				rowData : filterdTruckDetails,
				loadshow : "show-n",
				overly : "show-n"
			})
			$("#inputTruckNumber").val("")
		}
	}

	getResetConsignments = ( ) =>{
		// console.log("clicked")
		const {longHaulRowData} = this.state
		// console.log("longHaulRowData",longHaulRowData)
		this.setState({
			rowData : longHaulRowData,
			loadshow : "show-n",
			overly : "show-n"
		})
	}

	onSetCritical(params, status){
		// console.log("onSetCritical ", params);
		// console.log("onSetCritical value ", status);
		redirectURL.post('/consignments/setCriticalStatus',{
			params:params,
			is_critical:status,
			token:window.atob(localStorage.getItem('_t')),
			userId:localStorage.getItem("userid"),
			returnConsignment: this.state.tnpReturnConsignmentsVisibilty,
			
		})
			.then((response) => {
			
			//var records = JSON.parse(JSON.stringify(response)).data;
			// console.log(response.data);
			});
			
	}
	
	getNewFilterConsignmnets=(event)=>{
		this.setState({
			loadshow : "show-m",
			overly : "show-m"
		})
		event.preventDefault();
		var selectedCity = $("#selectedCity").text();
		var selectedState = $("#selectedState").text();
		var selectedCluster = $("#selectedCluster").text();
		var selectedTransporter = $("#selectedTransporter").text();
		var beforeNewFilterRowData = this.state.rowData;
		var filterRowData = this.state.rowData;
		// console.log(selectedCity,selectedState,selectedCluster,selectedTransporter,"Bhanu Teja")
		//console.log(filterRowData,"0")
		if(selectedCity !=undefined && selectedCity !='' && selectedCity !="Select...")
		{
			filterRowData = filterRowData.filter(e => e.consignee_city == selectedCity);
			// console.log(filterRowData,"1")
		}
		if(selectedState !=undefined && selectedState !='' && selectedState !="Select...")
		{
			filterRowData = filterRowData.filter(e => e.consignee_state == selectedState);
		}
		if(selectedCluster !=undefined && selectedCluster !='' && selectedCluster !="Select...")
		{
			filterRowData = filterRowData.filter(e => e.cluster == selectedCluster);
		}
		if(selectedTransporter !=undefined && selectedTransporter !='' && selectedTransporter !="Select...")
		{
			filterRowData = filterRowData.filter(e => e.transporter_name == selectedTransporter);
		}
		var conCodes = [];
		filterRowData.map(function(f){
			conCodes.push(f.consignment_code);
		});
		redirectURL.post('/consignments/getConsignmentsNewFilterCounters',{
			consignmentCodes:conCodes,
			filterType:1,
		}).then((response) => {
			//var records = JSON.parse(JSON.stringify(response)).data;
			// console.log(response.data.counters,"filter counters");
			var pagecounters = response.data.counters
			// console.log(filterRowData,"final")
			var beforeNewFiltercountersjson = this.state.countersjson;
			this.setState({
				rowData : filterRowData,
				allRowData : filterRowData,
				beforeNewFilterRowData : beforeNewFilterRowData,
				beforeNewFiltercountersjson : beforeNewFiltercountersjson,
				countersjson:pagecounters,
				loadshow : "show-n",
				overly : "show-n"
			})
			this.gridApi.refreshCells();
		});
	}

	ClearNewFilter=()=>{
		this.setState({
			loadshow : "show-m",
			overly : "show-m"
		})
		this.setState({
			flCity : "",
			flState : "",
			flCluster : "",
			flTransporter : ""
		});
		this.setState({
			rowData : this.state.beforeNewFilterRowData,
			allRowData : this.state.beforeNewFilterRowData,
			countersjson : this.state.beforeNewFiltercountersjson,
			loadshow : "show-n",
			overly : "show-n"
		});
		this.gridApi.refreshCells();
	}

	newFilter=(filterType,selOption)=>{
		// console.log(filterType,selOption)
		this.setState({ [filterType]: selOption });
	}
	reloadPageData=()=>{
		window.location.reload();
	}
	handleChange = event => {
		const result = event.target.value.replace(/[^a-z0-9]/gi, '');
		$("#inputTruckConsignment").val(result);
	};
	handleChange1 = event => {
		const result = event.target.value.replace(/[^a-z0-9-]/gi, '');
		$("#inputPartNumber").val(result);
	};
	handleChange2 = event => {
		const result = event.target.value.replace(/[^a-z0-9-]/gi, '');
		$("#inputTruckNumber").val(result);
	};

	returnPageUrl = (btn, arr)=>{
		if(btn === "active"){
			return arr[0];
		}
		else if(btn === "delivered"){
			return arr[1];
		}
		else{
			return arr[2];
		}
	}

	reloadPageData1 = (btn)=>{
		let currentUrl = this.props.match.path;

		let snd = ["/sndconsignments", "/snddeliveredconsignments", "/sndallconsignments"];
		let tnp = ["/tnpconsignments", "/tnpdeliveredconsignments", "/tnpallconsignments"];
		let prt = ["/prtconsignments", "/prtdeliveredconsignments", "/prtallconsignments"];

		let pageUrl = "/";

		if(snd.includes(currentUrl)){
			pageUrl = this.returnPageUrl(btn, snd);
		}
		else if(tnp.includes(currentUrl)){
			pageUrl = this.returnPageUrl(btn, tnp);
		}
		else if(prt.includes(currentUrl)){
			pageUrl = this.returnPageUrl(btn, prt);
		}
		this.props.history.push(pageUrl);       
    }
	
	render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		const { open,openforceclose,usermanualncrmodal,usermanualmovemodal} = this.state;		
		var maptransporter='';
		var dist_header_param_name = "Dist. From Destination (km)";
		var reached_dist_name = "Reached Destination At";
		if(this.props.match.path == "/tnpconsignments" || this.props.match.path == '/tnpdeliveredconsignments'){
			if(this.state.tnpReturnConsignmentsVisibilty == true){
				dist_header_param_name = 'Dist. From Source Plant (km)';
				reached_dist_name = 'Reached Source Plant At';
			}
		}
		try{
			if(this.state.rownode.transporter)
			{
				///console.log("map rownode ",this.state.rownode.transporter[0])
				maptransporter=this.state.rownode.transporter[0].transporter_name;
			}
		}
		catch(e){
			console.log(e)
		}
		var screen_type = this.state.deptcode
		//console.log("Rendere ",this.state.deptcode)
		let transitTimeHeading = (this.state.deptcode === "LOG-TNP") ? "Transit Time (Hours)" : "Transit Time (Days)";
		var department_code = this.state.deptcode;
		let isHiddenLocalMovement = (this.state.deptcode === "LOG-PRT") ? false : true;
		//console.log("isHiddenLocalMovement = ", isHiddenLocalMovement, this.state.deptcode);
		let hideonlyPRT = (this.state.deptcode === "LOG-PRT") ? true : false;
		let hideonlyTNP = (this.state.deptcode === "LOG-TNP") ? true : false;
		// let hideTNPconsignment = (this.state.deptcode === "LOG-TNP" && this.state.tnpReturnConsignmentsVisibilty == false) ? false : true;
		let hideTNPconsignment = (this.state.deptcode === "LOG-TNP") ? false : true;
		//let hideOnlyActive = 
		let hideonlyCol = ((this.props.match.path == "/sndconsignments") 
		|| (this.props.match.path == "/prtconsignments")) ? true : false;

		
		let showOnlyCol = false;
		let showtimestamp = false;
		if((this.props.match.path == "/sndconsignments") 
		|| (this.props.match.path == "/prtconsignments") 
		|| (this.props.match.path == "/tnpconsignments")
		|| (this.props.match.path == "/railconsignments")
		|| (this.props.match.path == "/ncrconsignments"))
		{
			showOnlyCol = false;
			showtimestamp = false;
		}
		else if(localStorage.getItem("roles")  == "PACC")
		{
			showOnlyCol = true;
		}
		else{
			showOnlyCol = true;
		}
		if(this.props.match.path == "/insidemsilvicinity")
		{
			showtimestamp = false;
		}
		else
		{
			if((this.props.match.path == "/sndconsignments") 
			|| (this.props.match.path == "/prtconsignments") 
			|| (this.props.match.path == "/tnpconsignments")
			|| (this.props.match.path == "/railconsignments")
			|| (this.props.match.path == "/ncrconsignments"))
			{
				showtimestamp = false;
			}
			else{
				showtimestamp = true;
			}
			
		}
		
		let showOnlyColDeliver = ((this.props.match.path == "/snddeliveredconsignments")
		|| (this.props.match.path == "/prtdeliveredconsignments")
		|| (this.props.match.path == "/tnpdeliveredconsignments")) ? false : true;

		let showonlyactivencr = ((this.props.match.path == "/sndconsignments")
		|| (this.props.match.path == "/prtconsignments")
		|| (this.props.match.path == "/tnpconsignments")
		|| (this.props.match.path == "/ncrconsignments")) ? false : true;

		let showdistance = ((this.props.match.path == "/sndconsignments")
		|| (this.props.match.path == "/prtconsignments")
		|| (this.props.match.path == "/tnpconsignments"))?false:true;

		let invoicedisable=(this.state.deptcode == 'SNDG')?false:true;
		let showOperations=(this.state.deptcode === "LOG-TNP")?false:true;
		let showonlysnd = (this.props.match.path == "/sndconsignments" || this.props.match.path == "/snddeliveredconsignments" || this.props.match.path == "sndallconsignments") ? false : true;
		//console.log("hideonlyCol ", hideonlyCol)
		var pagepath = this.props.match.path;
		if((this.state.counter_screen == "active" && this.state.deptcode != "LOG-TNP") || this.props.match.path == "/insidemsilvicinty"){
			var checkboxSelectionval = true;
			var hideStatus = true;
			var truckNoHeader ={
				headerName: "Truck No",
				field: "truck_no",
				width: 100,
				filter: "agSetColumnFilter",
				pinned : "left",
				cellRenderer:'',resizable: true
			}
		}
		else{
			var checkboxSelectionval = false;
			var hideStatus = false;
			var truckNoHeader ={
				headerName: "Truck No",
				field: "truck_no",
				width: 100,
				filter: "agSetColumnFilter",
				cellRenderer:'',resizable: true
			}
		}
		var hideNCR;
		if(this.props.match.path == "/ncrconsignments" || this.state.tnpReturnConsignmentsVisibilty)
		{
			hideNCR = true;
		}else{
			hideNCR = false;
		}
		if(this.state.screenpage == "deliver")
		{
			var hideOnTime = false
		}else{var hideOnTime = true}
		var hideonlypacc = (localStorage.getItem("roles")  == "PACC")? true : false;
		var hideTransporterCode =  (this.state.deptcode === 'LOG-PRT' || this.state.deptcode === 'SNDG' )? false : true;
		let hideGPSFields = ((this.props.match.path == "/snddeliveredconsignments")
		|| (this.props.match.path == "/prtdeliveredconsignments")
		|| (this.props.match.path == "/tnpdeliveredconsignments")
		|| (this.props.match.path == "/sndallconsignments")
		||(this.props.match.path == "/prtallconsignments")
		||(this.props.match.path == "/tnpallconsignments")) ? false : true;

		let viewforTNPOnly = ((this.props.match.path == "/tnpdeliveredconsignments") || (this.props.match.path == "/tnpallconsignments") || (this.props.match.path == "/tnpconsignments")) ? false : true ;
		
		let showOnlyDeliver = ((this.props.match.path == "/snddeliveredconsignments")
		|| (this.props.match.path == "/sndallconsignments")) ? false : true;
		var hideAccidentColumn = true;
		if(this.state.deptcode == "SNDG")
		{
			hideAccidentColumn = false;
		}
		var hidePalletColumns = (this.props.match.path == "/tnpconsignments")?false:true;
		var hideCritical = (this.props.match.path == "/insidemsilvicinty")?true:false;
		var hideDuration = (this.props.match.path == "/insidemsilvicinty")?false:true;
		var last_consignment_zone  = ((this.props.match.path == "/prtallconsignments") ||
									  (this.props.match.path == "/prtdeliveredconsignments") ||
									  (this.props.match.path == "/prtconsignments")) ? false: true;
		var screen_url = this.props.match.path;
		var hideDealerGateIn = true;
		if(screen_type == "LOG-PRT")
		{
			if(screen_url != "/prtconsignments")
			{
				hideDealerGateIn =  false;
			}else{ hideDealerGateIn = true};
		}else{hideDealerGateIn = true;};
		const mgpa_fields = (this.state.deptcode == "LOG-PRT")?false:true;
		var hideDeleteOpt = true;
		var hidetnp = true;
		var userType = localStorage.getItem('user_type');

		if(this.props.match.path == "/tnpconsignments" && this.state.tnpReturnConsignmentsVisibilty == false){
			hidetnp = false;
			// console.log(userType,"userType")
			if(userType == 'TRANSPORTER'){
				// console.log(hideDeleteOpt,"hideDeleteOpt1")
				hideDeleteOpt = true;
			}
			else
			{
				// console.log(hideDeleteOpt,"hideDeleteOpt2")
				hideDeleteOpt = false;
			}
		}

		// console.log("this.state.deptcode",this.state.deptcode)
		var hideVinsbvh = (this.state.deptcode == "SNDG")?false:true;
		var co2columnHide = ((this.props.match.path == "/sndallconsignments") || (this.props.match.path == "/snddeliveredconsignments")) ? false: true;
		var hidePriority  = ((this.props.match.path == "/prtconsignments")) ? false: true;
		var co2Active = this.props.match.path == "/sndconsignments"? true:false;
		var co2Delivered = this.props.match.path == "/snddeliveredconsignments"? true:false;
			var columnwithDefs = 
		[
				{
					headerName: "Actions",
					field: "_id",
					colId: "_id",
					width: 40,
					pinned: 'left',
					filter: false,resizable: true,
					headerCheckboxSelection: checkboxSelectionval,
					//headerCheckboxSelectionFilteredOnly: true,
					checkboxSelection: true
					
				},
				{
					headerName: "",
					field: "_id",
					colId: "_id",
					width: 50,
					pinned: 'left',
					cellRenderer:'consignmentActions',
					filter: false,resizable: true					
				},				
				{
					headerName: "Transit Delay",
					field: "transit_delay",
					colId: "transit_delay",
					width: 40,
					pinned: 'left',
				    pivot: false,
					cellRendererSelector:function(params){
						if(params.data.transit_delay == 1)
						{
							var rendComponent = {
								component: 'kpiTransitDelayActions'
							};
							return rendComponent
						}
						else{
							var rendComponent = {
								component: 'notransit'
							};
							return rendComponent
						}
					},
					filter: true,resizable: true
				},
				{
					headerName: "",
					field: "no_gps_data",
					colId: "no_gps_data",
					width: 40,
					pinned: 'left',
					pivot: false,
					cellRendererSelector:function(params){
						var comp='gps';	
						if(params.data.no_gps_data == 0 && params.data.no_gps_data_lifetime == 1)
						{
							comp='noGpsLifetime'
							
						}
						else if(params.data.no_gps_data == 1) 
						{
							comp='nogps'
						}
						
						else if(params.data.no_gps_data == 0)
						{
							comp='gps'
						
						}
						var rendComponent = {
							component:comp
						};
						return rendComponent
					},
					filter: true,resizable: true
				},
				{
					headerName: "Overspeed",
					field: "overspeeding_exception",
					colId: "overspeeding_exception",
					width: 40,
					pinned: 'left',
				    pivot: false,
					cellRendererSelector:function(params){
						if(params.data.overspeeding_exception == 1)
						{
							var rendComponent = {
								component: 'kpiActions'
							};
							return rendComponent
						}
						else{
							var rendComponent = {
								component: 'nooverspeed'
							};
							return rendComponent
						}
					},
					filter: true,resizable: true
				},
				{
					headerName: "Night Drive",
					field: "nightdriving_exception",
					colId: "nightdriving_exception",
					width: 40,
					pinned: 'left',					
					pivot: true,
					hide: hideonlyPRT,
					cellRendererSelector:function(params){
						//console.log("Except ",params.data.nightdriving_exception)
						//console.log("Consignemnt code ", params.data.consignment_code)
						if(params.data.nightdriving_exception != undefined && params.data.nightdriving_exception == 1)
						{
							var rendComponent = {
								component: 'kpiNightDriveActions'
							};
							return rendComponent
						}
						else{
							var rendComponent = {
								component: 'nonightdrive'
								//component: 'kpiNightDriveActions'
							};
							return rendComponent
						}
					},
					filter: true,resizable: true
				},
				{
					headerName: "Loading Delay",
					field: "loading_delay_exception",
					colId: "loading_delay_exception",
					width: 40,
					pinned: 'left',
					pivot: false,
					hide: hideonlyPRT,
					cellRendererSelector:function(params){
						var rendComponent = {
							component: 'kpiLoadingDelayActions'
						};
						return rendComponent;
					},
					filter: true,resizable: true
				},
				{
					headerName: "Unloading Delay",
					field: "unloading_delay_exception",
					colId: "unloading_delay_exception",
					width: 40,
					pinned: 'left',
					pivot: false,
					hide: hideonlyCol,
					cellRendererSelector:function(params){
						if(params.data.unloading_delay_exception != undefined && params.data.unloading_delay_exception == 1)
						{
							var rendComponent = {
								component: 'kpiUnLoadingDelayActions'
							};
							return rendComponent;
						}
						else{
							var rendComponent = {
								component: 'kpiUnLoadingDelayActions'
							};
							return rendComponent;
						}
						
					},
					filter: true,resizable: true
				},
				{
					headerName: "Enroute Stoppages",
					field: "enroute_exception",
					colId:"enroute_exception",
					width: 40,
					pinned: 'left',
				    pivot: false,
					cellRendererSelector:function(params){
						
						var rendComponent = {
							component: 'kpiEnrouteStoppagesActions'
						};
						return rendComponent
					
					},
					filter: true,resizable: true,
				},
				{
					headerName: "Actions",
					field: "comment_exists",
					colId: "comment_exists",
					//colId: "_id",
					width: 40,
					pinned: 'left',
					cellRenderer:'commentActions',
					filter: true,
					resizable: true,
					hide: (this.state.tnpReturnConsignmentsVisibilty)?true:false
					
				},
				{
					headerName: "",
					field: "tickets",
					colId: "tickets",
					//colId: "_id",
					width: 40,
					pinned: 'left',
					filter: true,
					resizable: true,
					hide: (this.state.tnpReturnConsignmentsVisibilty)?true:false,
					cellRendererSelector:function(params){
						
						var rendComponent = {
							component: 'Consignmenttickets'
						};
						return rendComponent
					
					},
					
				},
				{
					headerName: "",
					field: "srv_logs",
					colId: "srv_logs",
					//colId: "_id",
					width: 40,
					pinned: 'left',
					filter: true,
					resizable: true,
					hide: hideTNPconsignment,
					cellRenderer:function(params){
						return "<i class='fa fa-file'><i>"
					},
					
				},
				// {
				// 	headerName: "Actions",
				// 	field: "_id",
				// 	colId: "_id",
				// 	width: 40,
				// 	pinned: 'left',
				// 	resizable: true,
				// 	cellRenderer : "SegmentAction"
					
				// },
				
				{
					pinned:"left",
					headerName : "",
					field:"delete",
					width:40,
					resizable : true,
					editable : false,
					cellRenderer:function(params){ 
						if(params.data.consigner_code == "SMG")
						{      
							return '<i class="icofont icofont-ui-delete"></i>';
						}
					},
					hide:hideDeleteOpt
				},
				{
					headerName: "Consignment Code",
					field: "consignment_code",
					colId : "consignment_code",
					width: 100,
					pinned: 'left',
					editable:false,
					filter: "agSetColumnFilter",resizable: true,
					//cellRenderer : "agGroupCellRenderer"
				},
				{
					headerName: "VIN No(s)",
					field: "vin_list",
					// colId: "vin_list",
					width: 120,
					// pinned: "left",
					editable: false,
					resizable: true,
					hide: hideVinsbvh
				},
				{
					headerName: "Sales Type",
					field: "sales_type",
					colId: "sales_type",
					width: 100,
					pinned: "left",
					editable: false,
					resizable: true,
					hide: hideVinsbvh
				},
				
				{
					headerName: "Region",
					field: "region",
					colId: "region",
					width: 80,
					pinned: "left",
					editable: false,
					resizable: true,
					hide: hideVinsbvh
				},
				
				// {
				// 	headerName: "Arean Vin(s)",
				// 	field: "arena_list",
				// 	width: 120,
				// 	pinned: "left",
				// 	editable: false,
				// 	resizable: true,
				// },
				// {
				// 	headerName: "Nexa Vin(s)",
				// 	field: "nexa_list",
				// 	width: 120,
				// 	pinned: "left",
				// 	editable: false,
				// 	resizable: true,
				// },
				{
					headerName: "SBVH Code",
					field: "sbvh",
					// colId: "sbvh",					
					width: 120,
					// pinned: "left",
					editable: false,
					resizable: true,
					hide: hideVinsbvh
				},
				{
					headerName: "Status",
					field: "status",
					colId: "status",
					width: 120, 
					pinned: 'left',
					hide : hideStatus,            
					filter: "agSetColumnFilter",
					resizable: true,
					valueGetter:(params)=>{
						//console.log("GPS ", params.data.gps_data_available);
						if(([undefined, "", NaN].includes(params.data.inside_parking_at) == false || [undefined, "", NaN].includes(params.data.parking_radius_entered_on) == false) && [undefined, "", NaN].includes(params.data.outside_parking_at) == true && this.props.match.path == "/tnpconsignments" && [2,3].includes(params.data.status)){
								return "At Parking Yard"
						}
						else if([undefined, "", NaN].includes(params.data.inside_parking_at) == false && [undefined, "", NaN].includes(params.data.outside_parking_at) == false && [2,3].includes(params.data.status) 
						&& this.props.match.path == "/tnpconsignments"){
							return "Left Parking Yard"
						}
						else if(params.data.status == 1)
						{
							return "Inside Plant"
						}
						else if(params.data.status == 2)
						{
							return "In Transit"
						}
						else if(params.data.dest_geofence_id != undefined && screen_type == "LOG-PRT")
						{
							if(screen_url != "/prtconsignments")
							{
								return "Reached Plant Gate";
							}
						}
						else if(params.data.status == 3)
						{
							return "Near Destination"
						}
						else if(params.data.status == 4)
						{
							if(this.state.tnpReturnConsignmentsVisibilty == true){
								if(params.data.next_trip_created  == 0  || params.data.next_trip_created == undefined) return 'Reached Source Plant'
							}
								return "Reached Destination"
						}	
						
						else if(params.data.status == 5)
						{
							return "Left Destination"
						}					
						else if(params.data.status == 6)
						{
							return "Force Closed"
						}
						else if(params.data.status == 7)
						{
							return "Cancelled As Per MSIL"
						}
						else if(params.data.status == 8 && this.state.deptcode == "LOG-PRT")
						{
							return "POD Received From MSIL"
						}
						else{
							return ""
						}
						 
					} 	          
				},  
				{
					headerName: "CO2 Emission kgCO2e (GPS Distance)",
					field: "co2_emission",
					colId:"co2_emission",
					width: 180,
					// pinned : "left",
					hide: co2columnHide,
					filter: true,resizable: true,
					valueGetter:function(params){
						if(co2Active){
							if(params.data.co2_emission_intransit != undefined && params.data.co2_emission_intransit != "" && params.data.co2_emission_intransit > 0)
								{
									return (params.data.co2_emission_intransit).toFixed(2);
								}
								else
								{
									return "";
								}
						}else if(co2Delivered){
							if(params.data.co2_emission != undefined && params.data.co2_emission != "" && params.data.co2_emission > 0)
								{
									return (params.data.co2_emission).toFixed(2);
								}
								else
								{
									return "";
								}
						}
					}
				},
				{
					headerName: "Distance for CO2 Emission (KM)",
					field: "distance_calculated_for_co2",
					colId:"distance_calculated_for_co2",
					width: 180,
					// pinned : "left",
					hide: co2columnHide,
					filter: true,resizable: true,
				
				},
				{
					headerName: "CO2 Emission kgCO2e (Standard Distance)",
					colId:"co2_emission_standard_dist",
					field: "co2_emission_standard_dist",
					width: 180,
					// pinned : "left",
					hide: co2columnHide,
					filter: true,resizable: true,
					valueGetter: function (params) {
						console.log("Params: ", params);
						if (params.data.co2_emission_standard_dist != undefined && params.data.co2_emission_standard_dist != "" && params.data.co2_emission_standard_dist > 0) {
							return (params.data.co2_emission_standard_dist).toFixed(2);
						}
						else {
							return "";
						}
					}
				},
				{
					headerName: "CO2 Emission kgCO2e Savings",
					colId:"co2_emission_savings",
					field: "co2_emission_savings",
					width: 180,
					// pinned : "left",
					hide: co2columnHide,
					filter: true,resizable: true,
					valueGetter:function(params){
						if(co2columnHide){
						if(params.data.co2_emission_savings != undefined && params.data.co2_emission_savings != "" && params.data.co2_emission_savings > 0)
							{
								return (params.data.co2_emission_savings).toFixed(2);
							}
							else
							{
								return "";
							}
						}else return "";
					}
				},
				{
					headerName: "Original ETA",
					field: "expected_trip_end",
					colId: "expected_trip_end",					
					id:"expected_trip_end",
					width: 120,   
					//pinned: 'left',              
					resizable: true,
					valueGetter:function(params){
						if(params.data.expected_trip_end != "" && params.data.expected_trip_end != undefined && params.data.expected_trip_end != null){
						return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);	 
						}
						else
						{
							return "";	
						}
						
						
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// cellClass : "dateFormat",
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		// cellValue = cellValue.replace(/\//g,"-")
					// 		//console.log("cellve ", cellValue)
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			// console.log(cellValue)
					// 			var cellDate = new Date(cellValue);
					// 			// console.log(cellDate);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			// console.log(filterValue);
					// 			var filteredDate = new Date(filterValue);
					// 			// console.log(filteredDate);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 	          
				},          
				{
					headerName: "Revised ETA",
					field: "revised_trip_end",
					colId: "revised_trip_end",					
					width: 120,   
					//pinned: 'left',              
					resizable: true,
					// cellClass : "dateFormat",
					valueGetter:function(params){
						//console.log(" ETA ", params.data)
						  if(params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined)
						  {
							  return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
						  }
						  else{
							  return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
						  }
					
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 
					
					cellClass:function(params){
						let hourDifference = 0;
						if (params.data.revised_trip_end && params.data.expected_trip_end) {
							let revisedDateOnlyMoment  = moment(params.data.revised_trip_end);
							let expectedDateOnlyMoment  = moment(params.data.expected_trip_end);
							hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
							//console.log("diff= ", params.data.consignment_code, hourDifference);
						} else {
							//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
						}

						if(hourDifference >= 1)
						{
							return 'bgColorDangerMedium'
						}

						if(hourDifference <= -1)
						{
							return 'bgColorSuccessMedium'
						}
						else{
							return ''
						}
					}
				},
				{
					headerName: "Transit Delay (Days)",
					field: "consignment_code",
					colId: "consignment_code",					
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer:'',
					resizable: true,
					valueGetter:function(params){
                        var expected = params.data.expected_trip_end;
                        if(params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined)
                          {
                              var revised = params.data.expected_trip_end;
                          }
                          else{
                            var revised = params.data.revised_trip_end;
                          }
                          //console.log("Exp ", expected)
                          //console.log("revised ", revised)
                          var totaldays = moment(new Date(revised)).diff(moment(new Date(expected)) , "days")
 
                          if(totaldays > 0)
                          {
                                return totaldays;                            
                          }
                          else{
                              return 0;
                          }
                          //return Math.round(days/1000);
 
                    }
				},
				{
					headerName: "Truck No",
					field: "truck_no",
					colId: "truck_no",					
					width: 120,
					pinned : "left",
					filter: "agSetColumnFilter",
					resizable: true
				},

				{
					headerName: "Proximity Closure",
					field: "proximity_closure",
					colId: "proximity_closure",
					width: 120,
					// pinned: "left",
					filter: "agSetColumnFilter",
					resizable: true,
					// filter:true,
					hide:true,
					valueGetter: function (params) {
						if (params.data.proximity_closure == 1 && params.data.status != 5) {
							return "Proximity Closure";
						} else {
							return "";
						}
					},
				},
				{
                    headerName: "Travel Status",
                    field: "elaped_time_from_last_moved_seconds",
                    width: 100,
                    // hide: tStatusShowOrHide,
                    // colId:"elaped_time_from_last_moved_seconds",
                    valueGetter: function (params) {
                        try {
                            // console.log("params.data.travel_sttaus ", params.data.travel_sttaus)
                            // if(params.data.travel_sttaus != "" && params.data.travel_sttaus != undefined){
                            //     return "Inactive";
                            // }else{                            
                            if (params.data.speed > 0) {
                                return "Travelling";
                            }
                            else {
                                var mre = secondsToHM(params.data.elaped_time_from_last_moved_seconds);
                                return "Idle (" + mre + ")";
                            }
                            // }
                        }
                        catch (e) {
                            return "";
                        }
                    },
                    cellClass: function (params) {
                        try {
                            // if (params.data.travel_sttaus == "Inactive") {
                            //     return ["maroon", "fbold"];
                            // }
                            // else {
                                if (params.data.speed > 0) {
                                    return ["greenfont", "fbold"];
                                }
                                else {
                                    return ["redfont", "fbold"];
                                }
                            // }

 

                        }
                        catch (e) {
                            return "";
                        }
                    }
                },
				{
					headerName: "Last Speed (kmph)",
					field: "speed",
					width: 100,
					hide:viewforTNPOnly,
					valueGetter: (params) => {
						if (params.data.speed !== "" && params.data.speed !== undefined) {
							const speedValue = parseFloat(params.data.speed);
							if (!isNaN(speedValue)) {
								return speedValue.toFixed(2);
							}
						}
						return null; 
					}
				},
				{
					headerName: "Duration from Gate Out",
					field: "duration_from_gate_out",
					colId: "duration_from_gate_out",					
					width: 170,
					pinned : "left",
					filter: true,
					resizable: true,
					hide : hideDuration,
					valueGetter : function(params)
					{
						if(params.data.gate_out_time != undefined &&
							params.data.gate_out_time != "" &&
							params.data.gate_out_time != null
						)
						{
							var gateout = new Date(params.data.gate_out_time);
							var lastpacket = new Date();
							var diff = lastpacket.getTime() - gateout.getTime();
							var diffdate = new Date(diff);
							var days = Math.floor(diff / (1000 * 60 * 60 * 24))
							days = (days < 0)?0:days;
							return days+" Day(s) : "+diffdate.getHours()+" hours : "+diffdate.getMinutes()+" Minutes"
						}
						else
						{
							return "";
						}
						
					}
				},
				//truckNoHeader,
				// {
				// 	headerName: "Load No",
				// 	field: "load_no",
				// 	width: 150,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer:'',
				// 	resizable: true,
				// 	hide:showonlysnd   
				// },
				// {
				// 	headerName: "Club Load No",
				// 	field: "club_load_no",
				// 	width: 150,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer:'',
				// 	resizable: true,
				// 	hide:showonlysnd,
				// 	valueGetter:function(params){
				// 		try{
				// 			if(typeof params.data.club_load_no == 'undefined' || params.data.club_load_no == ''  || params.data.club_load_no == 0)
				// 			{
				// 				return '';
				// 			}
				// 			else
				// 			{
				// 				return  params.data.club_load_no;
				// 			}
				// 		}
				// 		catch(e){
				// 			return '';
				// 		}
				// 	}   
				// },
				// {
				// 	headerName: "VIN List",
				// 	field: "vin_list",
				// 	width: 180,
				// 	filter: "agSetColumnFilter",
				// 	cellClass:["wraptext"],
				// 	resizable: true,
				// 	hide:showonlysnd  
				// },
				// {
				// 	headerName: "No. of Cars",
				// 	field: "vin_list",
				// 	width: 150,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer:'',
				// 	resizable: true,
				// 	hide:showonlysnd,
				// 	valueGetter:function(params){
				// 		try{
				// 			if(typeof params.data.vin_list != "undefined" || params.data.vin_list != "" )
				// 			{
				// 				return  params.data.vin_list.length;
				// 			}
				// 			else{
				// 				return  0;
				// 			}
				// 		}
				// 		catch(e){
				// 			return 0;
				// 		}
				// 	}   
				// },
 	  	        {
		          headerName: "Transporter Name",
                  field: "transporter_name",
                  colId: "transporter_name",				  
                  width: 150,
                  filter: "agSetColumnFilter",
				  cellRenderer:'',
				  resizable: true   
				}, 
				{
					headerName: "Transporter Code",
					field: "transporter_code",
					colId: "transporter_code",					
					width: 110,
					filter: "agSetColumnFilter",
					hide : hideTransporterCode,
					cellRenderer:'',resizable: true	          
				},
				{
					headerName: "MGPA Date Time",
					field: "mgpa_date_time",
					colId: "mgpa_date_time",					
					width: 130,
					filter: "agSetColumnFilter",
					hide : mgpa_fields,
					resizable: true,
					valueGetter: function(params)
					{
						return getHyphenDDMMMYYYYHHMM(params.data.mgpa_date_time)
					}       
				},
				{
					headerName: "MGPA No",
					field: "mgpa_no",
					colId: "mgpa_no",					
					width: 120,
					filter: "agSetColumnFilter",
					hide : mgpa_fields,
					resizable: true	          
				},
				  {
                    headerName:"Last City/Town",
                    field:"truck",
                    colId:"truckCity",					
					width:150,
					// hide:showOnlyCol,
					valueGetter:function(params){
						//console.log(params.data.truck);
						if (params.data.hasOwnProperty('truck'))
						{
							if (params.data.truck.length > 0)
							{
							  return params.data.truck[0].area;
							}
						}
						  
					},
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },    
				  {
                    headerName:"Last State",
                    field:"truck",
					colId:"truckState",
                    width:120,
					// hide:showOnlyCol,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true,
					valueGetter:function(params){
						//console.log(params.data.truck);
						if (params.data.hasOwnProperty('truck'))
						{
							if (params.data.truck.length > 0)
							{
							  return params.data.truck[0].state;
							}
						}
						  
					}
                },             
				   
				{
					headerName: "Last Packet Time",
					field: "truck",
					colId:"truckPacketTime",
					// hide:showtimestamp,
					valueGetter:function(params){
						//console.log(params.data.transporter);
						if (params.data.hasOwnProperty('truck'))
						{
							if (params.data.truck.length > 0)
							{
								if(params.data.truck[0].manual_timestamp == undefined)
								{
									return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp);
								}
								else
								{
									var s1 = moment.parseZone(params.data.truck[0].timestamp).format("x");
									var s2 = moment.parseZone(params.data.truck[0].manual_timestamp).format("x");
									if(s1>s2)
									{
										return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp);
									}
									else
									{
										var tstamp = getHyphenDDMMMYYYYHHMM(params.data.truck[0].manual_timestamp);
										return tstamp+" (M)";
									}
								}
							}
						}
							
					},
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },  
					width: 150,
					//filter: "agDateColumnFilter",
					cellRenderer:'',resizable: true	          
				},
				{
					headerName: "GPS Provider",
					field: "truck",
					colId:"truckGPS",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer:'',resizable: true,
					hide:showOnlyCol,
					valueGetter:function(params){
						//console.log(params.data.transporter);
						if (params.data.hasOwnProperty('truck'))
						{
							if (params.data.truck.length > 0)
							{
							  return params.data.truck[0].actual_lspuser;
							}
						}
						  
					},	          
				},    
				{
					headerName: "Invoice No",
					field: "invoice_no",
					colId:"invoice_no",
					width: 150,
					resizable: true,
					hide:hideTNPconsignment,
					comparator: dateComparator,
					valueGetter:function(params){
						  if(params.data.invoice_no != '' && params.data.invoice_no != undefined)
						  {
							  return parseInt(params.data.invoice_no).toString();
						  }
						  else{
							  return "";
						  }
					},	          
				},  
				{
					headerName: "Invoice Time",
					field: "invoice_time",
					// colId: "invoice_time",
					width: 150,
					//filter: "agDateColumnFilter",
					cellRenderer:'',resizable: true,
					comparator: dateComparator,
					// filterParams: {
					// 	// browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					valueGetter:function(params){
						  if(params.data.invoice_time != '' && params.data.invoice_time != undefined)
						  {
							  return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
						  }
						  else{
							  return "NA";
						  }
					},	
					hide: (this.state.tnpReturnConsignmentsVisibilty)?true:hideonlyPRT,
				},             
	  	        {
                    headerName: "Consigner Code",
                    field: "consigner_code",
                    colId: "consigner_code",					
                    width: 150,
                    filter: "agSetColumnFilter",
					cellRenderer:''	,resizable: true ,
					// valueGetter: (params) => {
					// 	if(params.data.consigner_code == "BP"){
					// 		return "B"
					// 	}
					// 	return params.data.consigner_code
					// },  	          
                },               
                {
                  headerName: "Consignee Code",
                  field: "consignee_code",
                  colId: "consignee_code",				  
                  width: 150,                 
                  filter: "agSetColumnFilter",
				  cellRenderer:'',resizable: true,

				  editable: (this.state.deptcode=="LOG-TNP" && this.state.tnpReturnConsignmentsVisibilty)?true:false,
				  cellEditor: 'agSelectCellEditor',
           		 	cellEditorParams: {
                	values: this.state.statusList,
            		}, 
					valueSetter:(params)=>{
						console.log(params,"params from slectino")
						if(params.oldValue != params.newValue){
							
							var formData={
								_id:params.data._id,
								consignee_code:params.newValue,
							}
							params.data.consignee_code=params.newValue;
							console.log(formData,"params for the consignee")
							redirectURL.post("/consignments/saveTnpReturnConsignee", formData,{
                                headers:{
                                 'Content-Type': 'application/json'
                                }
                            }).then((response)=>{
                            })
						}
					}   	          
				},      
                 
					   
                {
					headerName: dist_header_param_name,
					field: "distance_from_dealer_location",
					colId: "distance_from_dealer_location",					
					width: 150,                 
					filter: "agSetColumnFilter",
					cellRenderer:'',resizable: true	,
					valueGetter:function(params){
						//console.log("params ", params.data);
						if(typeof params.data.distance_from_dealer_location == 'undefined')
						{

						}
						else
						{
							return Math.round(params.data.distance_from_dealer_location);
						}
						
					},
					hide:showonlyactivencr    	          
				  },  
				  
				{
					headerName: "Consignee Name",
					field: "consignee_name",
					colId: "consignee_name",					
					width: 150,            
					hide:hideonlyTNP,     
					filter: "agSetColumnFilter",
					cellRenderer:'',resizable: true	    	          
				  },               
				  {
					headerName: "Consignee City",
					field: "consignee_city",
					colId: "consignee_city",					
					width: 150,            
					hide:hideonlyTNP,     
					filter: "agSetColumnFilter",
					cellRenderer:'',resizable: true	    	          
				  },
				  
				  {
					headerName: "Consignee State",
					field: "consignee_state",
					colId: "consignee_state",					
					width: 150,        
					hide:hideonlyTNP,         
					filter: "agSetColumnFilter",
					cellRenderer:'',resizable: true	    	          
				  },  
				  {
					headerName: "Zone",
					field: "zone",
					colId: "zone",					
					width: 150,
					hide: last_consignment_zone,
					resizable: true
				  },  
				  {
					headerName: "Last Consignment Zone",
					field: "last_consignment_zone",
					colId: "last_consignment_zone",					
					width: 150,
					hide: last_consignment_zone,
					resizable: true
				  },
                // {
                //   headerName: "Department Code",
                //   field: "dept_code",
                //   width: 120,                 
                //   filter: "agSetColumnFilter",
				//   cellRenderer:''  ,resizable: true  	          
                // },          
                
                {
                    headerName: "Gate in Time",
                    field: "gate_in_time",
                    colId: "gate_in_time",					
                    width: 180,                 
                    //filter: "agDateColumnFilter",
					//cellRenderer:'dateFormater',
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
					},
					resizable: true	,
					hide: (this.state.tnpReturnConsignmentsVisibilty)?true:false 	          
				},
				{
                    headerName: "GPS Gate in Time",
                    field: "inside_fence",
                    colId: "inside_fence",					
                    width: 180,                 
                    //filter: "agDateColumnFilter",
					//cellRenderer:'dateFormater',
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.inside_fence);
					},
					resizable: true ,
					hide: (this.state.tnpReturnConsignmentsVisibilty)?true:false
                },				
                {
                    headerName: "Gate out Time",
                    field: "gate_out_time",
                    colId: "gate_out_time",					
                    width: 180,                 
					//filter: "agDateColumnFilter",
					//cellRenderer:'dateFormater',
					// sortable:true,
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
					},
					resizable: true    
                },
                {
                    headerName: "GPS Gate out Time",
                    field: "outside_fence",
                    colId: "outside_fence",					
                    width: 180,     
					hide: (this.state.tnpReturnConsignmentsVisibilty)?true:false,            
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					//cellRenderer:'dateFormater',
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.outside_fence);
					},
					resizable: true    
				},
				{
                    headerName: "GPS Distance (KM)",
                    field:"total_travelled_trip_dist_km",
                    colId:"total_travelled_trip_dist_km",					
					width:130,
					editable:false,
					// cellEditor:DateEditor,
					valueGetter:(params)=>{
						if(params.data.total_travelled_trip_dist_km != undefined && params.data.total_travelled_trip_dist_km != null)
						{
							return (params.data.total_travelled_trip_dist_km)
						}
						else{
							return ""
						}
					},
					resizable: true,
					hide: hideAccidentColumn

				},
				{
					headerName: "Dist. Traveled last 24Hr (KM)",
					field: "distance_travelled_in_24_hrs_kms",
					colId: "distance_travelled_in_24_hrs_kms",					
					//colId : "consignment_code",
					width: 120,
					editable:false,
					filter: "agSetColumnFilter",resizable: true,
					hide : showdistance,
					valueGetter : function(params)
					{
						//console.log(params.data.distance_travelled_in_24_hrs_kms);
						if(params.data.distance_travelled_in_24_hrs_kms != "" && params.data.distance_travelled_in_24_hrs_kms != undefined)
						{
							return params.data.distance_travelled_in_24_hrs_kms;
						}
						else{
							return 0;
						}
					}
					//cellRenderer : "agGroupCellRenderer"
				},
				{
                    headerName: "MSIL POD Date",
                    field:"pod_date",
                    colId:"pod_date",
                    width:110,
					valueGetter:(params)=>{
						if(params.data.pod_date != undefined && params.data.pod_date != null)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.pod_date)
						}
						else{
							return ""
						}
					},
					resizable: true,
					hide: isHiddenLocalMovement

				},
				{
                    headerName: "Truck Reporting Date",
                    field:"truck_reporting_date",
                    colId:"truck_reporting_date",					
					width:130,
					editable:false,
					// cellEditor:DateEditor,
					valueGetter:(params)=>{
						if(params.data.truck_reporting_date != undefined && params.data.truck_reporting_date != null)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.truck_reporting_date)
						}
						else{
							return ""
						}
					},
					resizable: true,
					hide: isHiddenLocalMovement

				},
				{
                    headerName: "Transshipment",
                    field:"is_transshipment",
                    colId:"is_transshipment",					
                    width:110,
                    filter:"agSetColumnFilter",
					valueGetter:function(params){
						if(params.data.is_transshipment == 1)
						{
							return "Yes";
						}
						else{
							return "No";
						}
					},
					resizable: true,
					hide: (this.state.tnpReturnConsignmentsVisibilty)?true:false
				},
				
				{
                    headerName: "Old Truck No",
                    field:"old_truck_no",
                    colId:"old_truck_no",
                    width:200,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true,
					hide: (this.state.tnpReturnConsignmentsVisibilty)?true:false
				},
				{
                    headerName: "Transshipment Date",
                    field:"transshipment_date",
                    colId:"transshipment_date",					
                    width:200,
                    filter:"agSetColumnFilter",
					resizable: true,
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.transshipment_date);
					},
					hide: (this.state.tnpReturnConsignmentsVisibilty)?true:false
                },
				{
                    headerName: "TPT Delivered",
                    field:"tpt_confirm_delivery",
                    colId:"tpt_confirm_delivery",					
                    width:110,
                    filter:"agSetColumnFilter",
					valueGetter:function(params){
						if(params.data.tpt_confirm_delivery == 1)
						{
							return "Yes";
						}
						else{
							return "No";
						}
					},
					resizable: true,
					hide : hideNCR,
				},
				{
                    headerName: "TPT Delivered On",
                    field:"tpt_delivery_date",
                    colId:"tpt_delivery_date",					
					width:150,
					hide : hideNCR,
                    filter:"agSetColumnFilter",
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.tpt_delivery_date);
					},
					resizable: true,
					//filter: "agDateColumnFilter",
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },   
				},
				{
                    headerName: "TPT Delivered Reason",
                    field:"tpt_delivery_reason",
                    colId:"tpt_delivery_reason",					
                    width:110,
                    filter:true,
					resizable: true,
					hide : hideNCR,
				},
				{
                    headerName: "Transit Time (until parking)",
                    field:"",
                    colId:"transit_time_until_parling",					
                    width:110,
                    filter:true,
					resizable: true,
					hide: (this.state.deptcode == "LOG-TNP")?false:true,
					valueGetter: (params)=>{
						if([undefined, "", NaN].includes(params.data.parking_radius_entered_on) == false){
							var end_date = params.data.parking_radius_entered_on;
							end_date = getHyphenDDMMMYYYYHHMM(end_date)
							end_date = moment.parseZone(new Date(end_date)).utcOffset("+05:30")._d;
							var start_date = moment.parseZone(new Date(params.data.gate_out_time)).utcOffset("+05:30")._d;
							// console.log("data for the time in duration start duration",start_date)
							var diff = end_date.valueOf() - start_date.valueOf();
							// console.log("data for the time in duration difference",diff)
							var diffInHours = (diff/1000/60/60/24).toFixed(2);
							// console.log("data for the time in duration",diffInHours)
							return diffInHours
						}
					}
				},
				{
                    headerName: "Near Parking Yard At",
                    field:"parking_radius_entered_on",
                    colId:"parking_radius_entered_on",					
                    width:110,
                    filter:true,
					resizable: true,
					valueGetter:(params)=>{
						if(params.data.parking_radius_entered_on != '' && params.data.parking_radius_entered_on != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.parking_radius_entered_on)
                        }
						else{
							return ""
						}
					}
				},
				{
                    headerName: "Reached Parking Yard At",
                    field:"inside_parking_at",
                    colId:"inside_parking_at",					
                    width:130,
                    filter:true,
					resizable: true,
					cellEditor: DateEditor,
                    editable:true,
                   comparator: dateComparator1,
					valueGetter:(params)=>{
						// console.log(params.data.inside_parking_at,'datain colsole')
						if(params.data.inside_parking_at != '' && params.data.inside_parking_at != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.inside_parking_at)
                        }
						
						else{
							return ""
						}
					}, 
					
					valueSetter: function (params) {
						
						const newValue = params.newValue; 
						const data = params.data; 
						
							console.log(params,'helllllo',newValue,'---',data.truck_no);
							var params = {
								// truck_no : data.truck_no,
								inside_parking_at : newValue,
								// transporter_code : data.transporter_code,
								id:params.data._id,
								// consignment_code : data.consignment_code
								
									}
									console.log(params,'params----')
									redirectURL.post("/consignments/insideparkingat",params).then((resp)=>{
										window.location.reload();
										// this.initComponent();
										
									})
								}
				
		        },

				
				{
                    headerName: "Left Parking Yard At",
                    field:"outside_parking_at",
                    colId:"outside_parking_at",					
                    width:110,
                    filter:true,
					resizable: true,
					// hide: (this.state.deptcode == "LOG-TNP")?false:true,
					valueGetter:(params)=>{
						if(params.data.outside_parking_at != '' && params.data.outside_parking_at != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.outside_parking_at)
                        }
						else{
							return ""
						}
					}
				},
				{
					headerName: "At Parking Yard Duration (Hrs)",
					field: "",
					colId: "at_parking_yard_duration",
					width: 110,
					filter: true,
					resizable: true,
					valueGetter: (params) => {
						let diffInHours = "";
						if (params.data.inside_parking_at && params.data.outside_parking_at) {
							const startTime = moment.utc(params.data.inside_parking_at);
							const endTime = moment.utc(params.data.outside_parking_at);
							const diffInMinutes = endTime.diff(startTime, 'minutes');
							const hours = Math.floor(diffInMinutes / 60);
							const minutes = diffInMinutes % 60;
							diffInHours = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
							// console.log(startTime, '&&', endTime, 'diffInHours', diffInHours);
						}

						return diffInHours;
					}
				},
                {
                    // headerName: reached_dist_name,
                    // field: "first_dealer_reported",
                    // colId: "first_dealer_reported",					
                    // width: 180,
					// hide:hideonlyCol,
					// //cellRenderer:'dateFormater',
					// valueGetter:function(params){
					// 	if(params.data.status == 4 || params.data.status == 5)
					// 	{
					// 		return getHyphenDDMMMYYYYHHMM(params.data.first_dealer_reported);
					// 	}
					// 	else{
					// 		return "";
					// 	}
						
					// },

					headerName: reached_dist_name,
					colId: "first_dealer_reported",
					width: 180,
					hide:hideonlyCol,
					valueGetter: function (params) {
						if(params.data.status == 3 || params.data.status == 4 || params.data.status == 5)
						{
							const fieldToUse = this.state.deptcode === "LOG-TNP" ? "recent_dealer_reported" : "first_dealer_reported";
							return getHyphenDDMMMYYYYHHMM(params.data[fieldToUse]);
						} else {
							return "";
						}
					}.bind(this), // Binding the context to access this.state
				
					resizable: true,
					//filter: "agDateColumnFilter",
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },    
				},
				{
					headerName: "Reached Plant Gate In",
					field: "locked_dealer_reported",
					colId: "locked_dealer_reported",					
					width: 150,
					//filter: "agDateColumnFilter",
					cellRenderer:'',resizable: true,
					hide:hideDealerGateIn,
					comparator: dateComparator,
					valueGetter:function(params){

						  if(params.data.locked_dealer_reported != '' && params.data.locked_dealer_reported != undefined
						  	&& params.data.dest_geofence_id != undefined)
						  {
							  return getHyphenDDMMMYYYYHHMM(params.data.locked_dealer_reported);
						  }
						  else{
							  return "";
						  }
					},	          
				}, 
                {
                    headerName: "Left Destination At",
                    field: "left_dealer_marked",
                    colId: "left_dealer_marked",					
                    width: 180,                 
                    //filter: "agDateColumnFilter",
					hide: (this.state.tnpReturnConsignmentsVisibilty)?true:hideonlyCol,
					//cellRenderer:'dateFormater',
					valueGetter:function(params){
						if(params.data.status == 5)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
						}
						else{
							return "";
						}
						
					},
					resizable: true,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },    
				},
				 
				{
					headerName: "Unloading Time (In Hrs)",
					field: "unloading_time_in_hrs",
					colId: "unloading_time_in_hrs",
					width: 180,
					hide: hidetnp,
					valueGetter: function(params) {
						const status = params.data.status;
						const firstReported = moment.parseZone(new Date(getHyphenDDMMMYYYYHHMM(params.data.first_dealer_reported)))._d;
						const recentReported = moment.parseZone(new Date(getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked)))._d;
						// console.log(firstReported,'----',recentReported)
						// console.log(( firstReported != "Invalid Date") && (recentReported != "Invalid Date"),'status')
						if(( firstReported != "Invalid Date") && (recentReported != "Invalid Date") && (firstReported != recentReported)){
						if (status === 5 && !isNaN(firstReported) && !isNaN(recentReported)) {
						// console.log(firstReported,recentReported,params.data.consignment_code,'recentReported')
							const diffMilliseconds = recentReported - firstReported;
							// console.log(diffMilliseconds,'differance')
							const seconds = Math.floor(diffMilliseconds / 1000);
							const hours = Math.floor(seconds / 3600);
							const minutes = Math.floor((seconds % 3600) / 60);
							// console.log(hours,minutes,seconds)
							// console.log(hours + minutes / 60)
							// console.log(hours + ":" + minutes)
							return hours + "." + minutes;
						} else {
							return "";
						}
					}else {
						return "";
					}
					},
					resizable: true
				},
				{
					headerName: "OnTime Delivery",
                    field: "ontime_calc",
                    colId: "ontime_calc",					
                    width: 110,                 
					hide:hideOnTime,
					valueGetter:function(params){
						if(params.data.ontime_calc == 1)
						{
							return "On Time";
						}
						else if(params.data.ontime_calc == 0)
						{
							return "Delayed";
						}else{
							return "";
						}
					},
					cellClass : function(params){
						if(params.data.ontime_calc == 1)
						{
							return "bgColorSuccessMedium";
						}
						else if(params.data.ontime_calc == 0)
						{
							return "bgColorDangerMedium";
						}else{
							return "";
						}
					}
					

				},
                {
                    headerName:"Local Movement(Yes/No)",
                    field:"local_movement",
                    colId:"local_movement",					
                    width:200,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true, 
					hide: isHiddenLocalMovement,
					valueGetter:function(params){
						if(params.data.local_movement == true){
							return "Yes";
						}
						else
						{
							return "No";
						}
						
					}
				},
				
                {
                    headerName:"TVP Gate In",
                    field:"tvp_gate_in",
                    colId:"tvp_gate_in",					
                    width:160,
                    filter:"agSetColumnFilter",
					resizable: true,
					valueGetter:function(params){
						try{
							if(params.data.tvp_gate_in != '1970-01-01 00:00:00.000Z' && params.data.tvp_gate_in != '')
							{
								return getHyphenDDMMMYYYYHHMM(params.data.tvp_gate_in);
							}
						}
						catch(e){
							return e;
						}
					},
					hide:(this.state.tnpReturnConsignmentsVisibilty)?true:showOnlyDeliver
					
					// valueGetter:function(params){
					// 	try{
					// 		if(params.data.tvp_gate_in != '1970-01-01 00:00:00.000Z' && params.data.tvp_gate_in != '')
					// 		{
					// 			console.log("tvp ", getHyphenDDMMMYYYYHHMM(params.data.tvp_gate_in))
							
					// 			return getHyphenDDMMMYYYYHHMM(params.data.tvp_gate_in);
					// 		}
					// 		else
					// 		{
					// 			return '';
					// 		}
							
					// 	}
					// 	catch(e){
					// 		return "";
					// 	}
					// }
				},

                {
                    headerName:"POD Received",
                    field:"pod_received",
                    colId:"pod_received",					
                    width:100,
                    filter:"agSetColumnFilter",
					hide:(this.state.tnpReturnConsignmentsVisibilty)?true:showOnlyDeliver,
					resizable: true,
					valueGetter:function(params){
						try{
							if(params.data.pod_received == 1)
							{
								return "Yes";
							}
							else
							{
								return "No";
							}
						}
						catch(e){
							return "No";
						}
					}
				},
				
                {
                    headerName:"POD Date",
                    field:"trip_delivery_date",
                    colId:"trip_delivery_date",					
					width:100,
					hide:(this.state.tnpReturnConsignmentsVisibilty)?true:showOnlyDeliver,
                    filter:"agSetColumnFilter",
					resizable: true,
					valueGetter : function(params)
					{
						try{
							if(params.data.trip_delivery_date != '')
							{
								return getHyphenDDMMMYYYYHHMM(params.data.trip_delivery_date);
							}
							else
							{
								return "";
							}
						}catch(e){
							return "";
						}
						
					}
				},			
                {
                    headerName: transitTimeHeading,
                    field:"transit_time",
                    colId:"transit_time",					
                    width:200,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true,
					valueGetter : function(params)
					{
						if(department_code == "LOG-PRT")
						{
							if(params.data.transit_time == 0)
							{
								return "-";
							}
							else{
								return params.data.transit_time;
							}
						}
						else{
							return params.data.transit_time;
						}
						
					}
                },
                {
                    headerName:"Standard Distance (KM)",
                    field:"distance_in_km",
                    colId:"distance_in_km",					
                    width:200,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
				},
				
                {
                    headerName:"Cluster",
                    field:"cluster",
                    colId:"cluster",					
                    width:100,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },
                {
                    headerName:"SPOC Contact Name",
                    field:"spoc_contact_name",
                    colId:"spoc_contact_name",					
                    width:180,
                    filter:"agSetColumnFilter",
					resizable: true,
					valueGetter:function(params){
						if(typeof params.data.spoc_contact_name != 'undefined' && params.data.spoc_contact_name != '' > 0){
							return params.data.spoc_contact_name;
						}
					}
                },
                {
                    headerName:"SPOC Contact Number",
                    field:"spoc_contact_number",
                    colId:"spoc_contact_number",					
                    width:180,
                    filter:"agSetColumnFilter",
					resizable: true,
					valueGetter:function(params){
						if(typeof params.data.spoc_contact_number != 'undefined' && params.data.spoc_contact_number != ''){
							return params.data.spoc_contact_number;
						}
					}
				},
				{
                    headerName:"SRV/GR NO",
                    field:"srv_no",
                    colId:"srv_no",					
                    widht:100,
					resizable: true,
					// hide: (this.state.tnpReturnConsignmentsVisibilty)?hideTNPconsignment : true,
					hide: showOperations,
					valueGetter: function(params){
						// if(params.data.srv_no != "" && params.data.srv_no != undefined)
						// {
							return params.data.srv_no
						// }
						// else
						// {
						// 	return params.data.srv_no;
						// }
					}
				},
				{
                    headerName:"SRV/GR Status",
                    field:"srv_status",
                    colId:"srv_status",					
                    widht:100,
					resizable: true,
					hide: showOperations,
					valueGetter: function(params){
						try{
							if(params.data.srv_status == "Material is inside Maruti")
							{
								return "Material Accepted";
							}
							else
							{
								return params.data.srv_status;
							}
						}
						catch(e){

						}
					}
                },
                {
                    headerName:"Vehicle Mode",
                    field:"vehicle_mode",
                    colId:"vehicle_mode",					
                    widht:100,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },  
                {
                    headerName:"Trip Type",
                    field:"trip_type",
                    colId:"trip_type",					
                    widht:100,
					hide: hideonlyPRT,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
				},           
				  				
                {
                    headerName: "Items",
                    field: "items",
                    colId: "items",					
                    width: 120,                 
                    filter: "agSetColumnFilter",
					cellRenderer:'' ,resizable: true,
					hide: (this.state.tnpReturnConsignmentsVisibilty)?true:false         
				},    				
                {
                    headerName: "Probable Accident",
                    field: "probable_accident",
                    colId: "probable_accident",					
                    width: 140,                 
                    filter: "agSetColumnFilter",
					resizable: true,
					hide : (this.state.tnpReturnConsignmentsVisibilty)?true:hideAccidentColumn,
					cellRenderer : "ProbableAction",
					// valueGetter:function(params){
					// 	if(params.data.probable_accident == 1)
					// 	{
					// 		return "Yes";
					// 	}
					// 	else
					// 	{
					// 		return "No";
					// 	}
					// }   	          
				},
				{
					headerName : "No. of Pallets",
					field : "no_of_pallets",
					colId : "no_of_pallets",					
					width:130,
					filter : true,
					resizable : true,
					hide : (this.state.tnpReturnConsignmentsVisibilty)?true:hidePalletColumns,

				},
				{
					headerName : "No. of Covers",
					field : "no_of_covers",
					colId : "no_of_covers",					
					width:130,
					filter : true,
					resizable : true,
					hide : (this.state.tnpReturnConsignmentsVisibilty)?true:hidePalletColumns,
				},
				{
					headerName : "Other Parts",
					field : "anyother_part",
					colId : "anyother_part",					
					width:140,
					filter : true,
					resizable : true,
					hide : (this.state.tnpReturnConsignmentsVisibilty)?true:hidePalletColumns,
				},
				{
					headerName : "Remarks",
					field : "remarks",
					colId : "remarks",					
					width:130,
					filter : true,
					resizable : true,
					hide : (this.state.tnpReturnConsignmentsVisibilty)?true:hidePalletColumns,
				},
				{
					headerName : "Reached Parking",
					field : "reached_parking",
					colId : "reached_parking",					
					width:130,
					filter : true,
					resizable : true,
					hide : (this.state.tnpReturnConsignmentsVisibilty)?true:hidePalletColumns,
					valueGetter:function(params){
							if(params.data.reached_parking != undefined && params.data.reached_parking != "")
							{
								return getHyphenDDMMMYYYYHHMM(params.data.reached_parking);
							}
							else
							{
								return "";
							}
					}
				},
				{
					headerName : "POD Date & Time",
					field : "pod_date_and_time",
					colId : "pod_date_and_time",					
					width:130,
					filter : true,
					resizable : true,
					hide : (this.state.tnpReturnConsignmentsVisibilty)?true:hidePalletColumns,
					valueGetter:function(params){
							if(params.data.pod_date_and_time != undefined && params.data.pod_date_and_time != "")
							{
								return getHyphenDDMMMYYYYHHMM(params.data.pod_date_and_time);
							}
							else
							{
								return "";
							}
					}
				},
				{
					headerName : "Comments",
					field : "tnp_comments",
					colId : "tnp_comments",					
					width:130,
					filter : true,
					resizable : true,
					editable : true,
					hide : hidetnp,
				},
				{
					headerName :"Additional Items",
					field :"sent_spl_notification",
					colId :"sent_spl_notification",					
					width: 200,
					hide : (this.props.match.path == "/tnpconsignments" && this.state.tnpReturnConsignmentsVisibilty == false)?false:true,
			        cellRenderer : function(params)
					{
						if(params.data.status == 2 && params.data.spotboat_updated_on == undefined)
						{
							var htmloption ='<button class="btn btn-info" style="padding-top:0 px">Spotbot/courier</button>';
                			return htmloption;
						}
						else if(params.data.status == 2 && params.data.spotboat_updated_on != undefined)
						{
							var htmloption ='<button class="btn btn-success" style="padding-top:0px">Spotbot/courier</button>';
                			return htmloption;
						}
						else
						{
							return ""
						}
					}
				},
				{
                    headerName: "TNP Consignment",
                    field: "tnpconsignment",
                    colId: "tnpconsignment",					
                    width: 120,                 
					filter: "agSetColumnFilter",
					hide:hideTNPconsignment,
					cellEditor: 'agRichSelectCellEditor',
					cellEditorParams: {
						values: [
						'Yes',
						'No'
						],
					},
					editable: true,
					resizable: true,
					valueGetter:function(params){
						// console.log("params.data.tnpconsignment",params.data.tnpconsignment);
						var value = params.data.tnpconsignment;
						if(value == undefined)
						{
							value = 1;
						}
						if(value == 1)
						{
							return "Yes";
						}
						else
						{
							return "No";
						}
					}   	          
				}, 
				{
					headerName: "Critical",
					field: "is_critical",
					colId: "is_critical",					
					width: 90,
					hide : hideCritical,
					cellRenderer: (params)=>{ 
						var input = document.createElement('input');
						input.type="checkbox";
						params.value=0;
						var status = false;
						if(params.data.is_critical == true)
						{
							status=true;
							input.checked=true;
							params.value=1;
						}
						input.addEventListener('click',  (event)=> {
							//console.log(event);
							params.value = !params.value;
							//console.log(params);
							this.onSetCritical(params.data, params.value)
						});
						return input;
					},
					filter: false,resizable: true
					
				},
				
				
				
                {
                    headerName:"EWay Bill No",
                    field:"ewaybill_no",
                    colId:"ewaybill_no",					
					width:140,
					hide:(this.state.tnpReturnConsignmentsVisibilty)?true:isHiddenLocalMovement,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
				},
				
                {
                    headerName:"EWay Bill Expiry Date",
                    field:"ewaybill_expiry_date",
                    colId:"ewaybill_expiry_date",					
					width:180,
					hide: (this.state.tnpReturnConsignmentsVisibilty)?true:isHiddenLocalMovement,
                    filter:"agSetColumnFilter",
					resizable: true,
					valueGetter:function(params){
						try{
							if(params.data.ewaybill_expiry_date != undefined && params.data.ewaybill_expiry_date != "")
							{
								return getHyphenDDMMMYYYYHHMM(params.data.ewaybill_expiry_date);
							}
							else
							{
								return "";
							}
						}
						catch(e){
							return "";
						}
					}
                },
				
				// {
				// 	headerName: "DCT Comment",
				// 	field: "dct_comment",
				// 	width: 240,
				// 	filter: true,
				// 	resizable: true,
				// 	cellClass: ['wraptext'],
				// 	autoHeight: true,
				// 	valueGetter:function(params){
				// 		//console.log("params.data.dct_marked_user ",params.data.dct_marked_user)
				// 		if(typeof params.data.dct_marked_user != 'undefined')
				// 		{
				// 			var dctuser = params.data.dct_marked_user;
				// 			var dctdate = getHyphenDDMMMYYYYHHMM(params.data.dct_comment_on);
				// 			var dctcomment = params.data.dct_comment;
				// 			return dctuser+": Updated on "+dctdate+": "+dctcomment;
				// 		}
				// 		else{
				// 			return "";
				// 		}
						
				// 	}
				// },
				{
					headerName : "Priority",
					field : "is_priority",
					colId : "is_priority",					
					width: 120,
					hide : hidePriority,
					valueGetter : function(params)
					{
						if(params.data.is_priority == 1)
						{
							return "Yes";
						}
						else
						{
							return "No";
						}
					}
				},
				
				{
					headerName: "Mark Priority",
					field: "priority",
					colId: "priority",					
					width: 130,
					cellRenderer:function(params){
						if(params.data.is_priority == 1)
						{
							return "";
						}
						else
						{
							var htmloption = '<button class="btn btn-info" style="padding-top:0px">Priority</button>';
                			return htmloption;
						}
					},
					hide : hidePriority,
					filter: false,
					resizable: true,	
				},
				{
					headerName: "Force Close",
					field: "consignment_code",
					colId: "consignment_code",					
					width: 80,
					hide: showOnlyCol,
					cellRendererSelector:function(params){					
						var rendComponent = {
							component: 'consignmentforceclose'
						};
						return rendComponent;
					},
					filter: false,resizable: true,
					
					
				},

				// {
				// 	headerName: "Force Closed By",
				// 	field: "force_closure_performed_by",
				// 	width: 150,
				// 	hide:showOnlyColDeliver,
				// 	filter: false,resizable: true,
					
					
				// },
				
				{
					headerName: "Force Closed Reason",
					field: "force_closure_reason",
					colId: "force_closure_reason",					
					width: 200,
					hide:showOnlyColDeliver,
					cellClass: ['wraptext'],
					filter: false,resizable: true,
				},
				
				// {
				// 	headerName: "Force Closed On",
				// 	field: "force_closure_time",
				// 	width: 180,
				// 	hide:showOnlyColDeliver,
					
				// 	filter: false,resizable: true,
				// 	valueGetter:function(params){
				// 		return getHyphenDDMMMYYYYHHMM(params.data.force_closure_time)
				// 	}
					
				// }
				{
                    headerName: "Operations",
                    field: "operation_name",
                    colId: "operation_name",					
                    width: 120,                 
                    filter: "agSetColumnFilter",
					cellRenderer:'' ,resizable: true,
					hide: showOperations,
					// editable : param =>{
					// 	if(param.data.operation_name === ""){
					// 		return true
					// 	} 
					// 		return false						
					// } 					
				}, 
				{
                    headerName: "TNP User Dept Code",
                    field: "tnp_user_dept_code",
                    colId: "tnp_user_dept_code",					
                    width: 120,                 
                    filter: "agSetColumnFilter",
					cellEditor: 'agSelectCellEditor',
					cellEditorParams: {
						values: this.state.distinctTnpUserDeptCodes,
					},     
					resizable: true,
					hide: showOperations,
					editable : (param) =>{
						if(param.data.tnp_user_dept_code == "" || param.data.tnp_user_dept_code == undefined){
							return true
						}else{
							return false
						}					
					}  
				}, 
		

	  	        
		];
		// new additions of delay reason delay acceptance in tnp columns
		if(['/tnpconsignments','/tnpdeliveredconsignments','/tnpallconsignments'].includes(this.props.match.path)){
			// let columnLength = columnwithDefs.length
			// console.log('columnLength',columnLength)
			let editable_user  = true
			if(userType === 'TRANSPORTER'){
				editable_user = false
				var onClickByTpt = true
			}
			var userName = localStorage.getItem('username');
			columnwithDefs.splice(85,0,{
					headerName: "Delay Reason",
					field: "delay_reason",
					colId: "delay_reason",
					width: 180,
					filter: true,
					resizable: true,
					editable: params =>{
						if(userType === 'TRANSPORTER' || params.data.delay_acceptance === 'Edit') return true
							return false
					},
					// cellEditor: 'agSelectCellEditor',
					cellEditor: 'agRichSelectCellEditor',
					cellEditorParams: params => {
						if(params.data.consignee_code !== "TKM" && params.data.consignee_code !== 'SMG'){
							return {values : ['Vehicle Challan/Impound Issue', 'Unscheduled Breaks', 'Deliver as per MSIL unloading schedule', 'Vehicle Breakdown', 'Accident', 'En-route agitation/road blockage/Traffic jam','System calculation error', 'Fuel filling', 'MSIL holiday/MSIL shutdown', 'Data incorrect due to GPS error']}
						}else{
							return {values : ['Stopped for maintenance', 'Vehicle Challan/Impound Issue','Vehicle Breakdown', 'Accident','System calculation error', 'En-route agitation/road blockage/Traffic jam','Lock Down/Natural disaster','MSIL holiday/MSIL shutdown', 'Data incorrect due to GPS error']}
						}
					},
					valueSetter : params =>{
						// console.log("params", params)
						if(params.oldValue !== params.newValue){
							params.data.delay_reason = params.newValue
							let id = params.data._id			
							let query = {
								rowId : id,
								userName,
								consignment_code : params.data.consignment_code
							}
							let data = params.newValue
							if(data !== ""  && data !== undefined){
								query.delay_reason = data
							}
							if(query.delay_reason !== ""  && query.delay_reason !== undefined){
								redirectURL.post("/consignments/updateDelayReasonOnClick", query).then((resp)=>{
									if(resp.data.modifiedCount == 1){
										this.setState({
											basicTitle: "Updated Delay Reason",
											basicType: "success",
											show: true
										})
									}
								})
							}
						}
					}
			},
			{
				headerName: "GRN Date",
                field: "grn_date",
				colId: "grn_date",
                width: 120,
				resizable: true,
                valueGetter: (params)=>{
					if(params.data.grn_date !== undefined && params.data.grn_date !== ''){
						return getHyphenDDMMMYYYYHHMM(params.data.grn_date);
					}else{
						return ''
					}
                }
			},
			{
				headerName: "Schedule Date & Time",
				colId: "schedule_date_time",
                field: "schedule_date_time",
                width: 120,
				resizable: true,
				// hide:showOperations,
                valueGetter: (params)=>{
					if(params.data.schedule_date_time !== undefined && params.data.schedule_date_time !== '' && params.data.schedule_date_time !== null){
						return getHyphenDDMMMYYYYHHMM(params.data.schedule_date_time);
					}else{
						return ''
					}
                }
			},
			{
				headerName: "Schedule Location",
                field: "schedule_location",
				colId: "schedule_location",
                width: 120,
				resizable: true,
				// hide:showOperations
			},
			{
				headerName: "No.of Hours delayed",
				field: "expected_trip_end",
				colId: "expected_trip_end",
				width: 120,
				resizable: true,
				valueGetter: (params) => {
				  let delayedDuration = "";
				  let d1 = "";
				  let d2 = "";
			
				  if ([2, 3].includes(params.data.status)) {
					d1 = moment().utcOffset("+05:30").toDate();
				  } else {
					if (params.data.consignee_code && (params.data.consignee_code == "MSIL-G" || params.data.consignee_code == "MUL")) {
					  d1 = moment.parseZone(new Date(params.data.parking_radius_entered_on)).utcOffset("-05:30")._d;
					} else {
					  d1 = moment.parseZone(new Date(params.data.recent_dealer_reported)).utcOffset("-05:30")._d;
					}
				  }
			
				  if (params.data.expected_trip_end != undefined && params.data.expected_trip_end != "" && params.data.expected_trip_end != null) {
					d2 = moment.parseZone(new Date(params.data.expected_trip_end)).utcOffset("-05:30")._d;
				  }
				  console.log(d1,'d1',d2,"d2")
				  const timeDiff = d1 - d2;
				  console.log(timeDiff,'timeDiff')
				  const hours = Math.floor(timeDiff / (1000 * 60 * 60));
				  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
				  delayedDuration = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
			console.log(delayedDuration,'delayedDuration')
				 
					if (timeDiff > 0 && !isNaN(timeDiff) && d2 != "") {
						return delayedDuration;
					} else {
						return "";
					}
				}
			  },
			{
				headerName: "Delay Acceptance",
                field: "delay_acceptance",
				colId: "delay_acceptance",
                width: 120,
				resizable: true,
				// cellEditor: 'agSelectCellEditor',
				cellEditor: 'agRichSelectCellEditor',
				editable: editable_user,
				// cellEditor: 'agRichSelectCellEditor',
				cellEditorParams: {
					values: ['Accept','Refer Back', 'Edit'],
				},
				valueGetter: params =>{
					if(params.data.delay_acceptance){
						return params.data.delay_acceptance
					}else{
						return ''
					}
				},
				valueSetter : params =>{
					// console.log('paramsOld',params.oldValue,'newVal', params.newValue)
					if((params.oldValue !== params.newValue)){
						params.data.delay_acceptance = params.newValue
						let id = params.data._id					
						// var userType = localStorage.getItem('user_type');
						let query = {
							rowId : id,
							userName,
							consignment_code : params.data.consignment_code
						}
						if(userType !== 'TRANSPORTER'){
							// console.log('comm check')
							let data = params.newValue
							if(data !== ""  && data !== undefined){
								query.updateDA = 1
								query.delay_acceptance = data
							}
							if(query.delay_acceptance !== ""  && query.delay_acceptance !== undefined){
								// console.log("hello code testing")
								redirectURL.post("/consignments/updateDelayReasonOnClick", query).then((resp)=>{
									if(resp.data.modifiedCount == 1){
										this.setState({
											basicTitle: "Updated Delay Acceptance",
											basicType: "success",
											show: true
										})
									}
								})
							}
						}
					}
				}
			},
			{
				headerName: "View Delay Logs",
				field: "delay_acceptance_btn",
				colId: "delay_acceptance_btn",
				resizable: true,
				// hide: onClickByTpt,
				cellRenderer: (params) => {
					return "<i class='fa fa-file'><i>"
				},
				width: 80
			}
			)

		}

		if(this.state.longHaulBtn == 'btn-danger'){
			columnwithDefs = this.state.columnsWithDefs
		}
		const dStyles={
			width:this.props.width,
			height:'500px',
			marginLeft:this.props.marginLeft,
		}

		const delayColumns = [
			{
				headerName : "User Name",
				field : "userName",
				resizable : true,
				width:120,
				filter : true
			},
			{
				headerName : "Consignment Code",
				field : "consignment_code",
				resizable : true,
				width:120,
				filter : true
			},
			{
				headerName : "Delay Acceptance",
				field : "delay_acceptance",
				resizable : true,
				width:120,
				filter : true,
				valueGetter : pts =>{
					if(pts.data.delay_acceptance !== undefined || pts.data.delay_acceptance !== '' ) return pts.data.delay_acceptance
						return ''
				}
			},
			{
				headerName : "Delay Reason",
				field : "delay_reason",
				resizable : true,
				width:120,
				filter : true,
				valueGetter : pts =>{
					if(pts.data.delay_reason !== undefined || pts.data.delay_reason !== '' ) return pts.data.delay_reason
						return ''
				}
			},
			{
				headerName : "Created Date",
				field : "createdDate",
				resizable : true,
				filter : true,
				width:150,
				valueGetter : function(params){
					if(params.data.createdDate != ""){
					return getHyphenDDMMMYYYYHHMM(params.data.createdDate);
					}
				}
			},

		]

		const commentColumns = [
			{
			headerName : "Truck No",
			field : "truck_no",
			resizable : true,
			width:100,
			filter : true
			},
			{
			headerName : "Consignment Code",
			field : "consignment_code",
			resizable : true,
			width:120,
			filter : true
			},
			{
			headerName : "User Type",
			field : "user_type",
			width:100,
			resizable : true,
			filter : true,
			valueGetter : function(params){
				if(params.data.user_type == "ORGUSER"){
				return "MSIL"
				}
				if(params.data.user_type == "DCTUSER"){
				return "DCT"
				} 
				if(params.data.user_type == "TRANSPORTER"){
				return "TRANSPORTER"
				} 
			}
			},
			{
			headerName : "Comment",
			field : "comment",
			width:250,
			cellClass: ['wraptext'],
			resizable : true,
			filter : true
			},
			{
			headerName : "Commented By",
			field : "username",
			width:150,
			resizable : true,
			filter:true
			},
		
			{
			headerName : "Commented Date",
			field : "comment_date",
			resizable : true,
			filter : true,
			width:150,
			valueGetter : function(params){
				if(params.data.comment_date != ""){
				return getHyphenDDMMMYYYYHHMM(params.data.comment_date);
				}
			}
			}
		
		]
		var hText = 'All';
		// var filterClassName = "form-group col-xl-6 col-lg-6";
		// var filterCardStyle1 = {};
		// var filterCardStyle2 = {padding:'10px 30px'};	
		var filterClassName = "form-group col-xl-12 col-lg-12";
		var filterCardStyle1 = {minHeight:"234px"};
		var filterCardStyle2 = {padding:'10px'};
		var mnFilterClass = {marginLeft:"0px", padding:"0px"};
		if(this.state.deptcode == 'SNDG')
		{
			hText = 'Sales and Dispatch';
			filterClassName = "form-group col-xl-12 col-lg-12";
			filterCardStyle1 = {minHeight:"234px"};
			filterCardStyle2 = {padding:'10px'};
		}
		if(this.state.deptcode == 'LOG-TNP')
		{
			hText = 'Train and Production';
			filterCardStyle1 = {minHeight:"240px"};
		}

		if(this.state.deptcode == 'LOG-PRT')
		{
			hText = 'Spare Parts';
			mnFilterClass = {marginLeft:"0px"};
		}
		const tripoptoins = [
			{"value":"all", label:"All"},
			{"value":"S", label:"Short Haul"},
			{"value":"L", label:"Long Haul"}
		]

		var user_type = localStorage.getItem("user_type");
		var user_name = localStorage.getItem("username")

		var pdfColumns=[
			{header:"Truck No",binding:"truck_no",align:"center"},
			{header:"Invoice Date",binding:"invoice_time"},
			{header:"Gate In Time",binding:"gate_in_time"},
			{header:"Gate Out Time",binding:"gate_out_time"},
			{header:"Consigner Code",binding:"consigner_code"},
			{header:"Consignee code",binding:"consignee_code"},
			{header:"Reached Destination At",binding:"recent_dealer_reported"},
			{header:"Left Destination At",binding:"left_dealer_marked"},
		]

		return(
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}>
	        	</SweetAlert>
				<div className="row" style={{marginBottom:"1%"}}>
					<div className="col-xl-12 col-lg-12">
                        <button 
							onClick={this.reloadPageData1.bind(this, "active")} 
							className={"btn "+this.state.activeBtn}
							style={{
								display: (this.state.activeBtn || this.state.deliveredBtn || this.state.allBtn) ? "inline-flex" : "none"
							}}
						>
							Active
						</button>
                        <button 
							onClick={this.reloadPageData1.bind(this,"delivered")} 
							className={"btn "+this.state.deliveredBtn}
							style={{
								display: (this.state.activeBtn || this.state.deliveredBtn || this.state.allBtn) ? "inline-flex" : "none"
							}}
						>
							Delivered
						</button>
                        <button 
							onClick={this.reloadPageData1.bind(this,"all")} 
							className={"btn "+this.state.allBtn}
							style={{
								display: (this.state.activeBtn || this.state.deliveredBtn || this.state.allBtn) ? "inline-flex" : "none"
							}}
						>
							All
						</button>
						<button 
							onClick={this.reloadPageData.bind(this)} 
							className={"btn btn-info " + this.state.showFiltersForLongHaulScreenTab}
							style={{float:"right"}}
						>
							Reset Filters
						</button>
					
                    </div>
				</div>
				<div 
					style = {{
						padding: this.state.deptcode == "LOG-TNP" && "10px",
						background: this.state.deptcode == "LOG-TNP" && "rgb(177 170 170 / 32%)",
						borderRadius: "4px"
					}}
				>

				
				{(this.state.deptcode == "LOG-TNP")?
					<div className="button-group" style={{ marginBottom: "1%" }}>
						<button className={"btn "+this.state.consBtn} onClick={() => this.onClickTab(1)}>{this.state.tnpButtonTitle} Consignments</button>
						{(user_name != "M/S MLPL" && user_name != "BARODA FREIGHT CARRIERS" && user_name != "PCL Logistics" && user_name != "M/S NS Logistics" && user_name != "Buoyant" )?
						<button className={"btn "+this.state.returnConsBtn} onClick={() => this.onClickTab(2)}>{this.state.tnpButtonTitle} Return Consignments</button>
						:""}
						
						{(this.props.match.path == "/tnpconsignments" && user_type != "TRANSPORTER") ? <button className={"btn "+this.state.longHaulBtn} onClick={() => this.onClickTab(3)}>Part Level Details</button> : ""}
					</div>
				:""}
				<div className="row">

					<div className={"col-xl-4 col-lg-4 " +  this.state.showFiltersForLongHaulScreenTab}>
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body" style={{padding:"10px 30px"}}>
								<form className="row" onSubmit={this.getTruckConsignments.bind(this)}>
									<div className={filterClassName}>
										<label>Select Type  </label>
										<Select 
										className="border-radius-0"
										isMulti={false}
										id="selectedFilter"
										style={{borderRadius:"0px"}} 
										options={[
											{label:"Truck",vaue:"Truck"},
											{label:"Consignments",value:"Consignments"},
											{label:"Vin",value:"Vin"},
											]} required />
									</div>
									<div className={filterClassName}>
										<label className="reason">Truck / Consignment No / Vin</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" onChange={this.handleChange}/>
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{textAlign:"left"}}>
										<button type="submit"  className="btn btn-success">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>

					{/* filter div for tnp part level details */}
					<div className={"col-xl-4 col-lg-4 " +  this.state.showFiltersForPartLevelScreenTab}>
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body">
								<form className="row" onSubmit={this.getPartConsignments}>
									<div className={filterClassName}>
										<label className="reason">Search By Part Number</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputPartNumber" onChange={this.handleChange1}/>
									</div>
									<div className={filterClassName}>
										<label className="reason">Search By Truck Number</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckNumber" onChange={this.handleChange2}/>
									</div>
									<div className='row' style={{marginLeft: '1px'}}>
										<div className="form-group col-xl-6 col-lg-6" style={{textAlign:"left"}}>
											<button type="submit"  className="btn btn-success">Submit</button>
										</div>
										<div className="form-group col-xl-6 col-lg-6" style={{textAlign:"right"}} >
											<button type="button" className="btn btn-success" onClick={this.getResetConsignments}>Reset</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>

					<div className={"col-xl-8 col-lg-8"}>
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body" style={filterCardStyle2}>
						 {(this.state.deptcode == 'LOG-TNP')?
							<div className="row col-xl-12 col-lg-12">
								{/* <div className="row col-xl-12 col-lg-12"> */}
									<div className="form-group col-xl-4 col-lg-4">
										<label>Trip Type </label>
										<Select placeholder={"All"} value={this.state.triptype} className="border-radius-0" onChange={this.onSelectTrip.bind(this)} style={{borderRadius:"0px"}} options={tripoptoins} required />
									</div>
									
									<div className="form-group col-xl-4 col-lg-4">
										<label>Select Consigner </label>
										<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectConsignerOptionsItems()} required />
													
									</div>
									{/* {(this.state.tnpReturnConsignmentsVisibilty == false)? */}
									<div className="form-group col-xl-4 col-lg-4">
						                <label>Select Consignee </label>
										<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consignee} isMulti="true"   className="border-radius-0"   onChange={this.changeConsigneeArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectConsigneeOptionsItems()} required />
									</div>			
									{/* :""} */}
								<div className="row col-xl-12 col-lg-12">
									<div className="form-group col-xl-4 col-lg-4">
										<label>Transporter   </label>
										<Select 
										className="border-radius-0"
										isMulti={false}
										id="selectedTransporter"
										style={{borderRadius:"0px"}} 
										value={this.state.flTransporter}
										onChange={this.newFilter.bind(this,"flTransporter")}
										options={this.state.filterTransporters}
										/>
									</div>
								 
									<div className="col-xl-4 col-lg-4 form-group">
										<label>From Date</label>
										<Datetime value={this.state.startDate} 
										disableCloseOnClickOutside={false} 
										closeOnSelect={true} 
										inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: true }}
										dateFormat="YYYY-MM-DD"
										name="startDate"
										timeFormat={false}
										onChange={this.handlerStartDateTime.bind(this)}
										isValidDate={current => {
											return current.isSameOrBefore(moment(), 'day');
										}} />
										{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}

										{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}

									</div>

									<div className="col-xl-4 col-lg-4 form-group">
										<label>To Date</label>
										{/* <input type="text" placeholder="To Date" autoComplete="off" id="to_date" value={this.state.endDate}  className="datetimepicker_date form-control"  required /> */}
										<Datetime value={this.state.endDate} 
										disableCloseOnClickOutside={false}
										closeOnSelect={true} 
										timeFormat={false}
										inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: true }}
										dateFormat="YYYY-MM-DD"
										onChange={this.handlerEndDateTime.bind(this)}
										isValidDate={current => {
											return current.isSameOrAfter(moment(new Date(this.state.startDate)), 'day');
										}} />
										
									</div>
								
									{/* <div className="form-group col-xl-12 col-lg-12">
										<button type="button" style={{margin:"0 auto"}} className="btn btn-success" onClick={this.onClickTripButton.bind(this)}>Submit</button>
									</div> */}
								</div>	
								
							</div>
							:""}

							{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG')?
							<div className="row col-xl-12 col-lg-12" style={mnFilterClass}>
								{(this.state.deptcode == 'LOG-PRT')?
								
								<div className="form-group col-xl-3 col-lg-3 n-p-0">
									<label>Select Type </label>
									<Select placeholder={"All"} closeMenuOnSelect={true} value={this.state.movementtype} className="border-radius-0" onChange={this.changeMovementArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectMovementTypeOptionsItems()} required />
												
								</div>
								:""}
								<div className="form-group col-xl-3 col-lg-3">
									<label>Select Consigner </label>
									<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectConsignerOptionsItems()} required />
												
								</div>
								<div className="col-xl-3 col-lg-3 form-group">
									<label>From Date</label>
									{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}
									<Datetime 
									value={this.state.startDate} 
									disableCloseOnClickOutside={false} 
									closeOnSelect={true} 
									inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: true }}
										dateFormat="YYYY-MM-DD"
										name="startDate"
										timeFormat={false}
										onChange={this.handlerStartDateTime.bind(this)}
										isValidDate={current => {
											return current.isSameOrBefore(moment(), 'day');
										}} />
								</div>

								<div className="col-xl-3 col-lg-3 form-group">
									<label>To Date</label>
									{/* <input type="text" placeholder="To Date" id="to_date" value={this.state.endDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}
									<Datetime 
										value={this.state.endDate} 
										disableCloseOnClickOutside={false} 
										closeOnSelect={true} 
										inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: true }}
										dateFormat="YYYY-MM-DD"
										timeFormat={false}
										onChange={this.handlerEndDateTime.bind(this)}
										isValidDate={current => {
											return current.isSameOrAfter(moment(new Date(this.state.startDate)), 'day');
										}}
									/>
								</div>
								{(this.state.deptcode == 'SNDG')?
								<div className="col-xl-3 col-lg-3 form-group">
									<label>Select Dispatch Type</label>
									<Select 
									className="border-radius-0"
									value={this.state.dispatch_type}
									isMulti={false}
									id="selectedFilter"
									onChange={this.newFilter.bind(this,"dispatch_type")}
									style={{borderRadius:"0px"}} 
									options={[{label:"ALL",vaue:"ALL"},{label:"Exports",value:"Exports"},{label:"Domestic",value:"Domestic"}]} required />
								</div>
								:""}
								{/* {(this.state.deptcode == 'LOG-PRT')?
								<div className="form-group col-xl-12 col-lg-12 n-p-0">
									<button type="button" className="btn btn-success" onClick={this.onGetConsigners.bind(this)}>Submit</button>
								</div>:""} */}
								
							</div>
							:""}
							
								<div className="row col-xl-12 col-lg-12" style={{marginLeft:"0px", padding:"0px"}}>
									{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG')?
									<div className="form-group col-xl-3 col-lg-3">
										<label>Consignment City   </label>
										<Select 
										className="border-radius-0"
										isMulti={false}
										id="selectedCity"
										style={{borderRadius:"0px"}}
										value={this.state.flCity}
										onChange={this.newFilter.bind(this,"flCity")}
										options={this.state.filterCities}
										/>
									</div>
									:""}
									{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG')?
									<div className="form-group col-xl-3 col-lg-3">
										<label>Consignment State   </label>
										<Select 
										className="border-radius-0"
										isMulti={false}
										id="selectedState"
										style={{borderRadius:"0px"}}
										value={this.state.flState}
										onChange={this.newFilter.bind(this,"flState")}
										options={this.state.filterStates}
										/>
									</div>
									:""}
									{(this.state.deptcode == 'SNDG')?
									<div className="form-group col-xl-3 col-lg-3">
										<label>Cluster   </label>
										<Select 
										className="border-radius-0"
										isMulti={false}
										id="selectedCluster"
										style={{borderRadius:"0px"}}
										value={this.state.flCluster}
										onChange={this.newFilter.bind(this,"flCluster")}
										options={this.state.filterClusters}
										/>
									</div>
									:""}
								  {(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG')? 
									<div className="form-group col-xl-3 col-lg-3">
										<label>Transporter  </label>
										<Select 
										className="border-radius-0"
										isMulti={false}
										id="selectedTransporter"
										style={{borderRadius:"0px"}} 
										value={this.state.flTransporter}
										onChange={this.newFilter.bind(this,"flTransporter")}
										options={this.state.filterTransporters}
										/>
									</div>
									 :""} 
									{/* <div className="form-group col-xl-12 col-lg-12" style={{textAlign:"left"}}>
										<button type="submit"  className="btn btn-success">Submit</button> &nbsp;&nbsp;
										<button type="button" onClick={this.ClearNewFilter.bind(this)} className="btn btn-danger">Clear</button>
									</div> */}
								</div>
								{(this.state.deptcode == "LOG-TNP")?
								<div className="form-group col-xl-12 col-lg-12">
									<button type="button" style={{margin:"0 auto"}} className="btn btn-success" onClick={this.onClickTripButton.bind(this)}>Submit</button>
								</div>
								:
								<div className="row col-xl-12 col-lg-12" style={{marginLeft:"0px"}}>
									<button type="button" className="btn btn-success" onClick={this.onGetConsigners.bind(this)}>Submit</button>
								</div>
								}
							</div>
					</div>
				</div>
			</div>
				{
					<div className = {this.state.showFiltersForLongHaulScreenTab}>
						{(this.state.isCounterDisplay == 1)?
							<Counters 
							hide={hideonlyCol} 
							context={this} 
							countersjson={this.state.countersjson} 
							unloadshow={hideonlyCol} 
							gridData={this.state.allRowData} 
							deptcode={this.state.deptcode} 
							triptype={this.state.tripvalue} 
							counterScreen = {this.state.counter_screen} 
							path={this.props.match.path}
							returnConsignments={this.state.tnpReturnConsignmentsVisibilty}
							priority_counter_display={this.state.priority_counter_display}
							truckReportedDate = {this.state.truckReportedDate.length}
							/>					
						:""}
					</div>
				}
				
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
								<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.isCounterDisplay === 1 ? `${this.state.pagetitle} - ${this.state.textContent}` : this.state.pagetitle}</span>
									
								</h5>
				   			</div>				   			
		            		<div className="card-body pt-10px" >
								<div className="row">
									<div className={"col-xl-12 col-lg-12 mb-10p " + this.state.showFiltersForLongHaulScreenTab}>

										<span className="float-right" style={{fontSize:"13px",fontWeight:"100"}}>
											<i className="icofont icofont-clock-time" style={{color:"#ff0000", fontSize:"22px	"}}></i> - Transit Delays
											&nbsp;<i className="icofont icofont-social-google-map txt-danger" style={{ fontSize:"22px"}}></i> - GPS Not Available
											&nbsp;<i className="icofont icofont-social-google-map txt-secondary" style={{ fontSize:"22px"}}></i> - GPS Data Not Adequate
											&nbsp;
											{(this.state.tnpReturnConsignmentsVisibilty == false)?
												<span>
													<i className="fa fa-tachometer txt-success" style={{fontSize:"22px"}}></i> - Overspeeding
												</span>
											:""}
											{!(this.state.deptcode == 'LOG-PRT')?
											<span>
												{(this.state.tnpReturnConsignmentsVisibilty == false)?
													<span>&nbsp;<i className="icofont icofont-full-night txt-primary" style={{fontSize:"22px"}}></i> - Night Driving
													</span>
												:""}
												{(this.state.tnpReturnConsignmentsVisibilty == false)?
													<span>&nbsp;<i className="icofont icofont-truck-alt darkorange" style={{fontSize:"22px"}}></i> - Loading Delay
													</span>
												:""}
												
												{((this.props.match.path == "/sndconsignments") 
												|| (this.props.match.path == "/prtconsignments") 
												|| (this.props.match.path == "/tnpconsignments"))
															?"":
															<span>&nbsp;<i className="icofont icofont-vehicle-delivery-van mildgreen" style={{fontSize:"22px"}}></i> - Unloading Delay
															</span>
												}
											</span>
											:""}
											&nbsp;<i className="icofont icofont-pause maroon" style={{fontSize:"22px"}}></i> - Stoppages
											{(this.state.deptcode == "SNDG")?
											<span>
												&nbsp;<i className="icofont icofont-barricade txt-danger" style={{fontSize:"22px"}}></i> - Probable Accident
											</span>
											:""}

										</span>
										
									</div>
									
									<div className={"col-xl-12 mb-10p "+ this.state.showFiltersForLongHaulScreenTab}>
									{localStorage.getItem("user_type")=="TRANSPORTER"
									?
									<ExportPDF rowData={this.state.rowData} pdfColumns={pdfColumns} pagetitle={"Consignments"}/>										
									:""
									}	
									
										{((this.props.match.path == "/sndconsignments" || this.props.match.path == "/prtconsignments" || this.props.match.path == "/tnpconsignments") && (this.state.tnpReturnConsignmentsVisibilty == false))?
											<button className="float-left custom-btn btn-warning" onClick={this.onClickShowMapView.bind(this)}>
												<i className="icofont icofont-social-google-map"></i> Map View
											</button>
										:""}
										<span className="layoutbtns">											
											<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState}>
												<i className="icofont icofont-save"></i> Save Grid Layout
											</button>
											<button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState}>
												<i className="icofont icofont-refresh"></i> Reset Default Layout
											</button>
											{(this.props.match.path == "/prtconsignments")?
												<button type="button" className="float-right custom-btn btn-success" style={{marginRight:"10px"}} onClick={this.onShowUploadEWay}>
													Upload Eway Enquiry
												</button>
											:""}
											{(this.props.match.path == "/prtconsignments")?
												<div className="float-right">
												<button
													title="Bulk Upload"
													onClick={this.onBulkPriorityBtn}
													className={"float-right custom-btn white btn-danger mr-2 "+(this.state.hideTransporterBtns)}
													type="button">
													Bulk Mark Priority
												</button>
											</div>
											:""}
											{(this.props.match.path == "/sndconsignments")?
												<div className="float-right">
												<button
													title="Bulk Upload"
													onClick={this.onBulkPriorityBtn}
													className={"float-right custom-btn white btn-danger mr-2 "+(this.state.hideTransporterBtns)}
													type="button">
													Bulk Mark Critical
												</button>
											</div>
											:""}
										</span>
									</div>
								</div>
								{/*		
				   				   <Grid 
								   ashow={this.state.show} 
								   atype={this.state.basicType} 
								   atitle={this.state.basicTitle}
								   actionCall={"manage"}
									actionMethod={"updateTruckRow"}  
									table={"trucklocation"} 
									columnyaxis={this.state.rowData} 
									columnaxis={columnwithDefs} 
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									childconfs={this.state.childconfs}
									childrow={this.state.childrow}
									context={this }
									
									/>
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
								*/} 
								
								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
									rowData={this.state.rowData}
									enableCharts={true}
									enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									stopEditingWhenGridLosesFocus={true}									
									enableCellChangeFlash={false}
									rowClassRules={this.state.rowClassRules}
									onCellDoubleClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									onCellEditingStopped={this.onCellUpdateData.bind(this)}									
									rowSelection={this.state.rowSelection}
									onRowSelected={this.onRowSelection.bind(this)}
									suppressRowClickSelection={true}
									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
									excelStyles= {this.state.excelStyles}
									onCellClicked={this.onCellClicked}
									// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
									//floatingFilter={true}
									//editType={this.state.editType}
									//suppressCellFlash={true}
    								// suppressCellSelection= {true}
									// getRowHeight={this.state.getRowHeight}
		          		          />
									
									{/* 
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div> */}

		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 </div>
				 {this.state.sliderTranslate != ''?
					<div className={"slide-r "+(this.state.sliderTranslate)} style={{overflow:"auto"}}>
						<div className="slide-r-title">
							<h4>
								Timeline #{this.state.timelinedata[0].consignment_code}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseUploadDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>							
							<div className="row col-xl-12 col-lg-12"> 
							<VerticalTimeline>
								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' ,borderTopLeftRadius: "20%", borderBottomRightRadius: "20%"}}
									contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
									date="Basic Information"
									iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
								
								>
									<h3 className="vertical-timeline-element-title">Truck No</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].truck_no }</h4>
									<h3 className="vertical-timeline-element-title">Transporter Code</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].transporter_code }</h4>
									<h3 className="vertical-timeline-element-title">Department Code</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].dept_code }</h4>
									<h3 className="vertical-timeline-element-title">Vehicle Mode</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].vehicle_mode }</h4>
									<h3 className="vertical-timeline-element-title">Items</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].items }</h4>
									<h3 className="vertical-timeline-element-title">Distance (Kms)</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].distance_in_km }</h4>
									
								</VerticalTimelineElement>


								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									date="Gate in time"
									contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff' ,borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
									contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
									iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
									
								>
									<h3 className="vertical-timeline-element-title">MSIL Gate intime</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_in_time }</h4>
									
									<h3 className="vertical-timeline-element-title">GPS Gate intime</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].inside_fence }</h4>
									
									<h3 className="vertical-timeline-element-title">Invoice time</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].invoice_time }</h4>
									
								</VerticalTimelineElement>

								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									date="MSIL Gate out time"
									contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' ,borderTopLeftRadius: "20%", borderBottomRightRadius: "20%" }}
									contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
									iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
								
								>
									<h3 className="vertical-timeline-element-title">MSIL Gate out time</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_out_time }</h4>
									
									<h3 className="vertical-timeline-element-title">GPS Gate out time</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].outside_fence }</h4>
									
								</VerticalTimelineElement>
									
								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									date="Consignee Code"
									contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff' ,borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
									contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
									iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
								
								>
									<h3 className="vertical-timeline-element-title">Consignee Code</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].consignee_code }</h4>
									
								</VerticalTimelineElement>
								
								</VerticalTimeline>

							</div>
						</div>
					 </div>
				:""	
				}
				
				 {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap 
								context={this} 
								rownode={this.state.rownode} 
								mapFor={"consignment"} 
								tolls={this.state.tolls} 
								googleroutes={this.state.googelRoutes} 
								truckno={this.state.maptruckno} 
								dealer={this.state.dealer}
								mapinfo={this.state.mapinfo} 
								deptcode={this.state.deptcode}
								defTransitCoords={this.state.defTransitCoords} 
								baseRouteCoords={this.state.baseRouteCoords}
								geofence_coordinates={this.state.geofence_coordinates}
								parking_coordinates = {this.state.parking_coordinates}
								/>
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '')?
											<div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div>
											
											:""}
											{/* <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?getHyphenDDMMMYYYYHHMM(this.state.routeTruck.startTime):"NA"}</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?(this.state.routeTruck.startTime):"NA"}</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?(this.state.routeTruck.endTime):"NA"}</div>
											</div> */}
											 <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.StartDateForMap))?getHyphenDDMMMYYYYHHMM(this.state.StartDateForMap):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.EndDateForMap))?getHyphenDDMMMYYYYHHMM(this.state.EndDateForMap):"-"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance_without_google/1000):"0"} Kms</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{Math.round(this.state.mapinfo.route_details.distance/1000)} Kms</div>
											</div>
											
											{/* {(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											}
											 */}
											
										
										
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				:""	
				}
				{/* slider for delay logs */}
				{this.state.logsSLider != ''? 
				<div className = {'slide-r '+ this.state.logsSLider} style = {{overflow:"auto"}}  >
					<div className="slide-r-title">
						<h4>
							Delay Logs
							<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>	
						</h4>
					</div>
					<div className="slide-r-body" style={{position:"relative"}}>
							<div id="myGrid1" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={delayColumns}
		          		            defaultColDef={this.state.defaultColDef}
									rowData={this.state.delayRowData}
									// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            // onGridReady={this.onGridReady}
		          		            // onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									// sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
							
		          		          />
							</div>
						</div>
				</div> : ""}
				
				{this.state.sliderCommentTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderCommentTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								Consignment Comments
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>	
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							<div id="myGrid1" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={commentColumns}
		          		            defaultColDef={this.state.defaultColDef}
									rowData={this.state.commentsRowData}
									onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            // onGridReady={this.onGridReady}
		          		            // onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									// sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
							
		          		          />
									
									

		          		    </div>
						</div>
						
					</div>
				:""	
				}
				 
                
                {this.state.sliderSegmentTranslate != ''?
                    <SegmentSideBar context={this} closeSegmentSideBar={this.closeSegmentSideBar} sliderValue={this.state.sliderSegmentTranslate} segmentConsignmentCode={this.state.segementConsignmentCode} onCloseRouteDiv={this.onCloseRouteDiv.bind(this)}/>                    
                :""	
                }
				<div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
				<Modal open={open} onClose={this.onCloseModal} classNames={{overlay: 'customOverlay',modal: 'customModal',}}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
						{(this.state.maprowData != '')?
							<ConsignmentDrawMap 
							context={this} 
							griddata={this.state.maprowData} 
							consigner_coordinates={this.state.consigner_coordinates} 
							consigneecoords={this.state.consigneecoords} 
							rownode={this.state.rownode} mapFor={"consignment"} />
							:""}
						
					</div>
				</Modal>


				<Modal open={openforceclose} onClose={this.onCloseForceCloseModal} styles={modalStyles}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
					<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reached Dealer Time</label>
								
                                <input type="text" placeholder="Reached Dealer " id="reached_dealer_time" className="datetimepicker_mask form-control" required />
							
							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Left Dealer Time</label>
								
                                <input type="text" placeholder="Left Dealer " id="left_dealer_time" className="datetimepicker_mask form-control" required />
							
							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reason for closure</label>
								<textarea name="reasonforceclose" id="reasonforceclose" onChange={this.changeHandler.bind(this)} rows="5" value={this.state.reasonforceclose} className="form-control col-xl-12 col-lg-12"></textarea>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
					</div>
				</Modal>

				<div className={"slide-r "+(this.state.sliderForceCloseTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Force Closure
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"100em"}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reached Dealer Time</label>
								
                                <input readonly type="text" placeholder="Reached Dealer " value={this.state.startDatetime} id="reached_dealer_time" className="datetimepicker_mask form-control" />
							
							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Left Dealer Time</label>
								
                                <input readonly type="text" placeholder="Left Dealer " value={this.state.endDatetime} id="left_dealer_time" className="datetimepicker_mask form-control" />
							
							</div> */}
							<ForceCloseSideBar context={this} handleInput = {this.handleInput} deptcode={this.state.deptcode} />
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reason for closure</label>
								<textarea name="reasonforceclose" onChange={this.changeHandler.bind(this)} rows="5" value={this.state.reasonforceclose} className="form-control col-xl-12 col-lg-12" required></textarea>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div> */}
						</form>
                        </div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.sliderTranslatesidebar)} >
					{this.displayData}
				</div>
			
				{(this.state.showumncr == 1)?
				<div>
					<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
					<Modal open={usermanualncrmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
						<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
							<h5 className="model-title">NCR Consignments</h5>
							<div className="col-xl-12 col-lg-12 mt-20p">
								<div className="col-xl-12 col-lg-12">
									This screen is for SND department only. This screen shows those consignments which have cluster as NCR and originate from F,M plants. These are generally local movements happening. For these consignments there are no violation tickets generated except for No GPS data. The grid columns are similar to that of active consignment screen. 
								</div>
								<div className="col-xl-12 col-lg-12">
									Image Here
								</div>
								<div className="col-xl-12 col-lg-12">
									The trips that are seen in this screen are active in system.
								</div>
								<div className="col-xl-12 col-lg-12">
									Save Grid Layout :  this filter is used to save the  interchanged columns. 
								</div>
								<div className="col-xl-12 col-lg-12">
									Reset default layout :  this filter is used to reset the grid layout  to the default layout.
								</div>
							</div>
						</div>
					</Modal>
				</div>
				:""}
				{/* {console.log("this.state.showummove ", this.state.showummove)} */}
				{(this.state.showummove == 1)?
				<div>
					<span className="usermanual f22" onClick={this.onClickShowUserMoveManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
					<Modal open={usermanualmovemodal} onClose={this.onCloseUserManualMoveModal.bind(this)} styles={modalStyles}>
						<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
							<h5 className="model-title">Move Consignments</h5>
							<div className="col-xl-12 col-lg-12 mt-20p">
								<div className="col-xl-12 col-lg-12">
								Those consignments which are dispatched from Gurgaon plant to Manesar Plant (interplant movement) are shown in this screen. All those consignments that are active in system and have MOV as cluster are shown in this screen. The trips can be searched with the filters available in the screen.
								</div>
								<div className="col-xl-12 col-lg-12">
									Image Here
								</div>
								<div className="col-xl-12 col-lg-12">
									Grid option available in system are:
								</div>
								
								<div className="col-xl-12 col-lg-12">
									<table className="table table-border">
										<tr>
											<th>
												Column
											</th>
											<th>
												Remarks
											</th>
										</tr>
										<tr>
											<td>
											Truck No
											</td>
											<td>
											vehicle details
											</td>
										</tr>
										<tr>
											<td>
											Transporter code
											</td>
											<td>
											MSIL code for transporter
											</td>
										</tr>
										<tr>
											<td>
											Transporter Name
											</td>
											<td>
											Service provider name
											</td>
										</tr>
										<tr>
											<td>
											Consignment Code
											</td>
											<td>
											Destination code
											</td>
										</tr>
										<tr>
											<td>
											Move Number
											</td>
											<td>
											MSIL parameter		
											</td>
										</tr>
										<tr>
											<td>
											Selection
											</td>
											<td>
											default name
											</td>
										</tr>
										<tr>
											<td>
											VIN
											</td>
											<td>
											MSIL parameter
											</td>
										</tr>
										<tr>
											<td>
											Model
											</td>
											<td>
											Model of consignment
											</td>
										</tr>
										<tr>
											<td>
											Spec
											</td>
											<td>
											MSIL parameter
											</td>
										</tr>
										<tr>
											<td>
											Source
											</td>
											<td>
											Origin point
											</td>
										</tr>
										<tr>
											<td>
											Out Date
											</td>
											<td>
											Gate out details	
											</td>
										</tr>
										<tr>
											<td>
											Destination
											</td>
											<td>
											Destination location
											</td>
										</tr>
										<tr>
											<td>
											In Date
											</td>
											<td>
											Gate in details
											</td>
										</tr>
										<tr>
											<td>
											Last City
											</td>
											<td>
											Last known GPS location
											</td>
										</tr>
										<tr>
											<td>
											Last State
											</td>
											<td>
											Last known GPS location
											</td>
										</tr>
										<tr>
											<td>
											Last Packet Time
											</td>
											<td>
											Data time packet
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</Modal>
				</div>
				:""}

				
				<div className={"slide-r "+(this.state.slideuploadeway)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
						Upload Eway Expiry
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadEway}>

							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Submit</button>
								<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
							</div>
							</form>
							
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_upload_eway_expiry.csv')} target="_blank">Sample Template</a>
							
							</div>		
						</div>
					</div>
				</div>
				{(this.props.match.path == "/prtconsignments")? <div className={"slide-r "+(this.state.bulkPrioritySlide)} style={{overflow:"hidden"}}>
					<h3 className="subH">Bulk Priority</h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkPriorityFormHandler.bind(this)}>

							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" id="bulkUploadPID" onChange={this.changePriorityFileHandler}  className="form-control" required  />
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Submit</button>
								<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
							</div>
							</form>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_couriers_mark_deliver_sample.csv')} target="_blank">Sample Template</a>
							</div>
							<div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
							
							</div>		
						</div>
					</div>
				</div>  :"" }
				
				{(this.props.match.path == "/sndconsignments")? <div className={"slide-r "+(this.state.bulkPrioritySlide)} style={{overflow:"hidden"}}>
					<h3 className="subH">Bulk Critical</h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadCriticalFormHandler}>

							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" id="bulkUploadCritical" onChange={this.changeCriticalFileHandler}  className="form-control" required  />
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Submit</button>
								<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
							</div>
							</form>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_couriers_mark_deliver_sample.csv')} target="_blank">Sample Template</a>
							</div>
							<div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
							
							</div>		
						</div>
					</div>
				</div>  : ""}
				
			</div>
              	
		);
	}
}



// function loadScript(url){
//     var index  =window.document.getElementsByTagName("script")[0]
//     var script = window.document.createElement("script")
//     script.src=url
//     script.async=true
//     script.defer = true
//     index.parentNode.insertBefore(script,index)
// }
function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
	

	return diffHrs + " hour(s) and " + (diffMins/60) + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}



function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}
function CheckForHtmlTag(){
	var rs = document.getElementById("invalid_reason");
	var reg =/<(.|\n)*?>/g; 
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";		
		rs.value=''
		//alert();
		return 1;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript(){
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
		onShow:false
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false,
		onShow:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
}

function dateCheck(start, end){
	let startDate = new Date(start);
	let endDate = new Date(end);

	if(startDate > endDate) return 1
		return 0
}
function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);
    
    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;
    
    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh*3600));
    var mm = Math.floor(hrr/60)
    if(mm < 10)
    {
        var mins = "0"+mm;
    }
    else
    {
        var mins = mm;
    }

    if(hh < 10)
    {
        var hrs = "0"+hh;
    }
    else
    {
        var hrs = hh;
    }
    return hrs+":"+mins;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	//console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
    console.log(date1Number, date2Number, "comparator")
	return date1Number - date2Number;
	}	
}
function monthToComparableNumber1(date) {
	//console.log(date.length);
	//console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // Get today's date and format it as dd-mm-yy
    var today = new Date();
    var formattedToday = $.datepicker.formatDate("dd-mm-yy", today);

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "dd-mm-yy",
        changeMonth: true,
        changeYear: true,
        minDate: formattedToday // Set the minDate option to today's date
    });
};


// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};