import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Modal from 'react-responsive-modal';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';

var redirectURL = require('../redirectURL');

const HolidayCalendar = () => {
    const [events, setEvents] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentEvent, setCurrentEvent] = useState({ id: '', title: '', startDate: '', endDate: '' });
    const [isEdit, setIsEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [basicTitle, setBasiTitle] = useState("");
    const [basicType, setBasicType] = useState("default");

    // // Mock initial holidays data
    // const mockHolidays = [
    //     { id: 1, title: 'New Year', startDate: '2024-01-01', endDate: '2024-01-01', allDay: true, display: 'background' },
    //     { id: 2, title: 'Independence Day', startDate: '2024-07-04', endDate: '2024-07-04', allDay: true, display: 'background' },
    // ];

    // Fetch holidays
    const fetchHolidays = async () => {
        try {
            const response = await redirectURL.post("/dashboard/getAllHolidays");
            if (response && response.status === 200) {
                const holidays = response.data.map(holiday => ({
                    // FullCalendar expects `title`, `start`, and `end`
                    title: holiday.holiday_name, // Map MongoDB `holiday_name` to FullCalendar `title`
                    start: holiday.calendar_start_date, // Use the calendar start date for FullCalendar `start`
                    end: holiday.calendar_end_date, // Use the calendar end date for FullCalendar `end`
                    id: holiday._id // Ensure to map the MongoDB _id for updating/deleting purposes
                }));
                setEvents(holidays);
                console.log("Holidays: ", holidays);
                // setEvents(response.data);
            } else {
                console.error("Failed to fetch holidays");
            }
        } catch (error) {
            console.error("Error fetching holidays: ", error);
        }
    };

    useEffect(() => {
        fetchHolidays();
    }, []);

    const handleSave = async () => {
        // Dates for FullCalendar (local time, start at 00:00:00 and end at 23:59:59 for display)
        const fullCalendarStart = moment(currentEvent.startDate).startOf('day'); // Start at 00:00:00 local time
        const fullCalendarEnd = moment(currentEvent.endDate).endOf('day'); // End at 23:59:59 local time

        // Dates for calculation (forcing them to be in UTC without any offset)
        const calculationStartDate = moment.utc(currentEvent.startDate).startOf('day').toISOString(); // Force UTC start at 00:00:00Z
        const calculationEndDate = moment.utc(currentEvent.endDate).endOf('day').toISOString(); // Force UTC end at 23:59:59.999Z

        console.log("FullCalendar Start Date (local): ", fullCalendarStart.format('YYYY-MM-DD HH:mm:ss'));
        console.log("FullCalendar End Date (local): ", fullCalendarEnd.format('YYYY-MM-DD HH:mm:ss'));
        console.log("Calculation Start Date (UTC): ", calculationStartDate);
        console.log("Calculation End Date (UTC): ", calculationEndDate);

        // Check if there is already an event on the selected start date
        const isDuplicate = events.some(event => {
            return moment(event.start).isSame(fullCalendarStart, 'day') && event.id !== currentEvent.id;
        });

        if (isDuplicate) {
            setShow(true);
            setBasiTitle("A holiday already exists on this date. Please select another day.");
            setBasicType("danger");
            return;
        }

        const holidayData = {
            id: isEdit ? currentEvent.id : "", // Include id for updates
            holiday_name: currentEvent.title,
            calendar_start_date: fullCalendarStart.toISOString(), // Save in local time for FullCalendar
            calendar_end_date: fullCalendarEnd.toISOString(),     // Save in local time for FullCalendar
            holiday_from_date: calculationStartDate, // For UTC-based calculations (forced to UTC)
            holiday_to_date: calculationEndDate,     // For UTC-based calculations (forced to UTC)
            allDay: true,                    // Mark the event as an all-day event
        };

        try {
            const response = await redirectURL.post("/dashboard/addOrModifyHoliday", holidayData);

            if (response && (response.status === 200 || response.status === 201)) {
                console.log(isEdit ? "Holiday updated successfully" : "Holiday added successfully");
                await fetchHolidays(); // Refresh the holiday list
            } else {
                console.error("Failed to save holiday");
            }

            setOpen(false); // Close modal
        } catch (error) {
            console.error("Error saving holiday: ", error);
        }
    };

    // Handle deleting a holiday
    const handleDelete = async () => {
        console.log("Current event: ", currentEvent);
        try {
            const response = await redirectURL.post("/dashboard/deleteHoliday", { id: currentEvent.id });
            if (response && response.status === 200) {
                await fetchHolidays(); // Refresh the holiday list
                setOpen(false); // Close modal
            } else {
                console.error("Failed to delete holiday");
            }
        } catch (error) {
            console.error("Error deleting holiday: ", error);
        }
    };

    // Handle selecting a date to add or update a holiday
    const handleDateSelect = (selectInfo) => {
        // console.log("Select Info: ", selectInfo);
        const startDate = selectInfo.startStr;
        let endDate;

        // If it's a single day selection
        if (moment(selectInfo.startStr).isSame(moment(selectInfo.endStr).subtract(1, 'days'), 'day')) {
            endDate = startDate; // Single day selection
        } else {
            // For multi-day selection, set endDate to the day before endStr to include the full day
            endDate = moment(selectInfo.endStr).subtract(1, 'days').format('YYYY-MM-DD');
        }

        // Check if there is already a holiday on this date
        const existingEvent = events.find(event =>
            moment(event.start).isSame(startDate, 'day')
        );

        // Check if the selected date range overlaps with any existing holiday event
        const isOverlap = events.some(event => {
            const eventStart = moment(event.start).startOf('day');
            const eventEnd = moment(event.end).endOf('day');
            // Check if the selected range overlaps with any event range
            return (
                moment(startDate).startOf('day').isBetween(eventStart, eventEnd, null, '[]') ||
                moment(endDate).endOf('day').isBetween(eventStart, eventEnd, null, '[]') ||
                eventStart.isBetween(startDate, endDate, null, '[]') ||
                eventEnd.isBetween(startDate, endDate, null, '[]')
            );
        });

        if (isOverlap) {
            // If the clicked date falls within an existing holiday event range, show the update modal
            const overlappingEvent = events.find(event => {
                const eventStart = moment(event.start).startOf('day');
                const eventEnd = moment(event.end).endOf('day');
                return moment(startDate).startOf('day').isBetween(eventStart, eventEnd, null, '[]') || moment(endDate).endOf('day').isBetween(eventStart, eventEnd, null, '[]');
            });

            if (overlappingEvent) {
                setCurrentEvent({
                    title: overlappingEvent.title,
                    startDate: overlappingEvent.start,
                    endDate: overlappingEvent.end,
                    id: overlappingEvent.id
                });
                setIsEdit(true); // Edit mode
                setOpen(true); // Open modal for editing
            }
        } else {
            // New event mode, set both startDate and endDate
            setCurrentEvent({ id: '', title: '', startDate: startDate, endDate: endDate });
            setIsEdit(false); // New event mode
            setOpen(true); // Open modal
        }
    };

    // Handle clicking an event for editing
    const handleEventClick = (clickInfo) => {
        const event = events.find(event => event.id === clickInfo.event.id);

        if (event) {
            setCurrentEvent({
                title: event.title,
                startDate: event.start,
                endDate: event.end,
                id: event.id,
                // display: 'background'
            });
            setIsEdit(true); // Edit mode
            setOpen(true); // Open modal
        } else {
            console.error('Event not found');
        }
    };

    const isFormValid = currentEvent.title && currentEvent.startDate && currentEvent.endDate;

    const closeAlert = () => {
        setShow(false);
    }

    const handlerStartDateTime = (date) => {
        const startDate = date; // Directly use the selected date without formatting
    
        if (currentEvent.endDate && currentEvent.endDate !== "") {
            const startDateObj = moment(startDate);
            const endDateObj = moment(currentEvent.endDate);
    
            if (startDateObj.isAfter(endDateObj)) {
                // Handle invalid date range (startDate is after endDate)
                setShow(true);
                setBasiTitle("End Date should be greater than or equal to Start Date");
                setBasicType("danger");
            } else {
                // Valid date, update the state
                setCurrentEvent({ ...currentEvent, startDate });
                setShow(false);
            }
        } else {
            setCurrentEvent({ ...currentEvent, startDate });
        }
    }

    const handlerEndDateTime = (date) => {
        // Strip time from the date and set it to the end of the day (23:59:59)
        const endDate = moment(date).endOf('day');
    
        if (currentEvent.startDate && currentEvent.startDate !== "") {
            const startDateObj = moment(currentEvent.startDate).startOf('day'); // Make sure start date is at 00:00:00
            const endDateObj = moment(endDate); // End date at 23:59:59
    
            if (startDateObj.isAfter(endDateObj)) {
                // Handle invalid date range (startDate is after endDate)
                setShow(true);
                setBasiTitle("End Date should be greater than or equal to Start Date");
                setBasicType("danger");
            } else {
                console.log("End Date: ", endDate.format('YYYY-MM-DD HH:mm:ss')); // Log in local time without UTC conversion
                // Valid date, update the state
                setCurrentEvent({ ...currentEvent, endDate });
                setShow(false);
            }
        } else {
            console.log("End Date else: ", endDate.format('YYYY-MM-DD HH:mm:ss')); // Log in local time
            setCurrentEvent({ ...currentEvent, endDate });
        }
    };
    

    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className="holiday_calendar_container">
                <h1>Holiday Calendar</h1>
                <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={events}
                    selectable={true}
                    select={handleDateSelect}
                    eventClick={handleEventClick}
                    // eventColor='lightblue'
                    eventDisplay="block"
                    firstDay={1}
                    buttonText={{
                        today: 'Today',
                    }}
                // eventDidMount={(info) => {
                //     // Apply background color to the cells containing the event
                //     if (info.event.allDay) {
                //         info.el.style.backgroundColor = 'yellow'; // Change background color to light yellow
                //     }
                // }}
                />

                {/* Modal for adding/updating holidays */}
                <Modal open={open} onClose={() => setOpen(false)} center styles={{ modal: { maxWidth: '28vw', width: "28vw" } }} showCloseIcon={false}>
                    <div className="holiday_form_container">
                        <h2>{isEdit ? 'Update Holiday' : 'Add Holiday'}</h2>
                        <form>
                            <div className="holiday_title_input_container">
                                <label>Title</label>
                                <input
                                    type="text"
                                    value={currentEvent.title}
                                    onChange={(e) => setCurrentEvent({ ...currentEvent, title: e.target.value })}
                                    required
                                    className='form-control'
                                />
                            </div>
                            <div className="holiday_start_input_container">
                                <label>Start Date</label>
                                <Datetime
                                    value={moment(currentEvent.startDate)}
                                    // onChange={(date) => setCurrentEvent({ ...currentEvent, startDate: date })}
                                    onChange={handlerStartDateTime}
                                    timeFormat={false}
                                    required
                                />
                            </div>
                            <div className="holiday_end_input_container">
                                <label>End Date</label>
                                <Datetime
                                    value={moment(currentEvent.endDate)}
                                    // onChange={(date) => setCurrentEvent({ ...currentEvent, endDate: date })}
                                    onChange={handlerEndDateTime}
                                    timeFormat={false}
                                    required
                                />
                            </div>
                            <div className="holiday_form_buttons_container">
                                <button
                                    type="button"
                                    onClick={handleSave}
                                    className="btn btn-success"
                                    disabled={!isFormValid} // Disable button if the form is not valid
                                >
                                    {isEdit ? 'Update' : 'Save'}
                                </button>

                                {isEdit && (
                                    <button type="button" onClick={handleDelete} className="btn btn-danger" >
                                        Delete
                                    </button>
                                )}
                                <button type="button" onClick={() => setOpen(false)} className="btn btn-default">
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default HolidayCalendar;
