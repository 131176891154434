import React, { Component } from 'react';
import _ from "lodash";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import PendingPods from './pendingPods';
import PendingPodsTVP from './pendingpodsTVP';
$.datetimepicker.setLocale('en');    


export default class PendingpodsTabs extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
            nontvptab:'activet',
            tvptab:"",
            transporter_code:'',
            returnPathName:'',
        };
        
	}
    componentDidMount() {
      const searchParams = this.props.location.search;
    if (searchParams.includes('lspceodashboardDemo')) {
       var returnPathName = "lspceodashboardDemo";
    }
        this.setState({
          loadshow: "show-m",
          overly: "show-m",
          returnPathName:returnPathName
        });
        const { transporterCode } = this.props.match.params;
        console.log(transporterCode, 'transporterCode');
        this.setState({
          transporter_code: transporterCode
        });
    }

	
    onClickTab(pageType) {
        if (pageType == "tvptab") {
           var tvptab = 'activet';
           var nontvptab = "";
        }else  if (pageType == "nontvptab") {
          var tvptab = '';
          var nontvptab = "activet";
       }
       this.setState({
        tvptab:tvptab,
        nontvptab:nontvptab
       })
      }

    render(){
		
        return (
            
    		<div className="container-fluid">
				
            	<div className="row">
                <div style={{ textAlign: "center", background: "#171C8F", width: '100%', margin: "5px", borderRadius: "5px", height: '70px', paddingBottom: '25px' ,margin:'10px'}}>
          <h6 class="txt-dark f40" style={{ marginTop: "0px" }}>
            <span style={{ fontSize: "20px", color: "#FFFFFF" }}>
              <span>Pending PODs</span>

            </span>
          </h6>
        </div>
        <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'space-between'}}>
        <div style={{margin:'20px'}}>

<ul className="d-tabs">
<li onClick={this.onClickTab.bind(this,"nontvptab")} className={"ttabs "+(this.state.nontvptab)}>
        <button type="button" className="btn" >Non-TVP Pending POD</button>
    </li>
    <li onClick={this.onClickTab.bind(this,"tvptab")} className={"ttabs "+(this.state.tvptab)}>
        <button type="button" className="btn" >TVP Pending POD</button>
    </li>

</ul>
</div>
      
{
       this.state.returnPathName == "lspceodashboardDemo"?
       <div  style={{margin:'20px'}}>
       <h5>
     
         <span className="float-right f12 ">
           <a className="btn btn-danger" style={{ float: "left", marginLeft: "10%" }} href="/lspceodashboardDemo">Back</a>
         </span>
       </h5>
     </div>
     :
     <div  style={{margin:'20px'}}>
     <h5>
   
       <span className="float-right f12 ">
         <a className="btn btn-danger" style={{ float: "left", marginLeft: "10%" }} href="/lspceodashboard">Back</a>
       </span>
     </h5>
   </div>
        } 
</div>    	
				 </div>
         {console.log(this.state.transporter_code, 'this.state.transporter_code')}
            { 
                this.state.tvptab === 'activet' ? (
                    this.state.transporter_code && (
                        <PendingPodsTVP transporterCode={this.state.transporter_code}/>  
                    )
                ) : (
                    this.state.transporter_code && (
                        <PendingPods transporterCode={this.state.transporter_code}/>  
                    )
                )
            }

            </div>
              
        );
    }
}
