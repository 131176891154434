import React, { Component } from "react";
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import Select from "react-select";
import { getHyphenDDMMMYYYY, getHyphenDDMMMYYYYHHMM,getHyphenDDMMYYYYHHMMSS ,getDDMMYYYYHHMMSS} from '../common/utils';
import $ from "jquery";
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import DrawMap2 from '../common/drawmap2';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import * as Datetime from 'react-datetime';
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import download from 'js-file-download';
var redirectURL = require("../redirectURL");

export default class MSIBOperationsMonitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      eventFormAction: "formAction",
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      bulkslide: "",
      bulkslide1: "",
      files: [],
      filesName:'',
      alert: null,
      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      frameworkComponents: {
        consignmentActions: ConsignmentActions,
      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      overly: "show-n",
      loadshow: "show-n",
      file: "",
      rowData: [],
      rowId: "",
      show: false,
      basicTitle: "",
      basicType: "",

      // restriction_area: '',
      // restriction_area_options: [],
      // restricted_type: '',
      // no_of_rakes_impacted: '',
      msil_rake_id: '',
      emprt_rake_arrival_MSIB_station:'',
      rake_placement:'',
      rake_release:'',
      engine_on_train:'',
      rake_departure:'',
      rake_arrival_at_destination:'',
      last_mile_start:'',
      last_mile_end:'',
      calendarKey: 0,

      // restriction_start_date: '',
      // restriction_end_date: '',
      // informed_restriction_date: ''

    };
  }

  async componentDidMount() {
    loadDateTimeScript();
    await redirectURL
      .post("/consignments/msiboperationmonitoringdataonmount")
      .then((response) => {
        var totalData = response.data
        const filteredData = totalData.filter(row => !row.deleted);
        this.setState({
          rowData: filteredData
        })
      })

  }
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: '',
      bulkslide1: "",
    });
    $("#uploadFile").val("")
    var form = document.getElementById("initialEditDetails");
    if (form) {
      form.reset();
    }
  }
 

  addMsibDetails() {
    var form = document.getElementById("initialAddingDetails");
    if (form) {
      form.reset();
    }
    this.setState({
      rake_name: [],
      type: [],
      bulkslide: "slider-translate-30p",
      overly: "show-m",

      msil_rake_id: '',
      emprt_rake_arrival_MSIB_station:'',
      rake_placement:'',
      rake_release:'',
      engine_on_train:'',
      rake_departure:'',
      rake_arrival_at_destination:'',
      last_mile_start:'',
      last_mile_end:''
    })
    $("#engine_on_train").val("")
  }

  async onClickAddDetails(e) {
    e.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    })
      
    var emprt_rake_arrival_MSIB_station = this.state.emprt_rake_arrival_MSIB_station
    var rake_placement = this.state.rake_placement
    var rake_release = this.state.rake_release
    var engine_on_train = this.state.engine_on_train
    var rake_departure = this.state.rake_departure
    var rake_arrival_at_destination = this.state.rake_arrival_at_destination
    var last_mile_start = this.state.last_mile_start
    var last_mile_end = this.state.last_mile_end
    var msil_rake_id = this.state.msil_rake_id
   
var params = {
  emprt_rake_arrival_MSIB_station: emprt_rake_arrival_MSIB_station,
  rake_placement:rake_placement,
  rake_release:rake_release,
  engine_on_train:engine_on_train,
  rake_departure:rake_departure,
  rake_arrival_at_destination:rake_arrival_at_destination,
  last_mile_start:last_mile_start,
  last_mile_end:last_mile_end,
  msil_rake_id:msil_rake_id,
  updated_by:localStorage.getItem("username")
}
   console.log(params,'paramsss')
    if (emprt_rake_arrival_MSIB_station != "" && emprt_rake_arrival_MSIB_station != undefined && rake_placement != "" && rake_placement != undefined && rake_release != "" && rake_release != undefined && engine_on_train != "" && engine_on_train != undefined  && rake_departure != "" && rake_departure != undefined && rake_arrival_at_destination != "" && rake_arrival_at_destination != undefined && last_mile_start != "" && last_mile_start != undefined && last_mile_end != "" && last_mile_end != undefined && msil_rake_id != "" && msil_rake_id != undefined) {

      await redirectURL
        .post("/consignments/addmsiboperationmonitoring", params)
        .then((response) => {
          this.setState({
            show: true,
            loadshow: "show-n",
            overly: "show-n",
            basicType: "success",
            basicTitle: "New details added Successfully",
            bulkslide: '',
            msil_rake_id: '',
            emprt_rake_arrival_MSIB_station:'',
            rake_placement:'',
            rake_release:'',
            engine_on_train:'',
            rake_departure:'',
            rake_arrival_at_destination:'',
            last_mile_start:'',
            last_mile_end:'',
          })

        })
      this.componentDidMount()
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        loadshow: "show-n",
        overly: "show-n",
        basicTitle: "All (*) fields are mandatory",
      })
    }
  }
  async onCellClicked(event) {
  if (event.colDef.field == "delete")
    {
       const params = {
        rowId: event.data._id,
      type: 'msibdelete'
    };

    this.setState({ alert: (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        // title="Are you sure?"
        onConfirm={() => {
          this.setState({ alert: null });
          redirectURL.post("/consignments/msibDeleteRowData", params)
            .then((response) => {
              this.setState({
                loadshow: "show-n",
                overly: "show-n",
                show: true,
                basicTitle: "Deleted Successfully",
                basicType: 'success'
              });
              this.componentDidMount(); 
            })
            .catch((error) => {
              console.error("Error deleting:", error);
            });
        }}
        onCancel={() => {
          this.setState({ alert: null });
          console.log("Deletion cancelled.");
        }}
      >
      Are you sure?<br/>Once deleted, you will not be able to recover this data
      </SweetAlert>
    )});
  }
  // if (event.colDef.field === "Edit") {
  //   // console.log(event.data, 'onclickvalues');
  //   var data = event.data;

  //   this.setState({
  //     rowId: data._id,
      
  //     emprt_rake_arrival_MSIB_station: formatDate(data.emprt_rake_arrival_MSIB_station),
  //     rake_placement: formatDate(data.rake_placement),
  //     rake_release: formatDate(data.rake_release),
  //     engine_on_train: formatDate(data.engine_on_train),
  //     rake_departure: formatDate(data.rake_departure),
  //     rake_arrival_at_destination: formatDate(data.rake_arrival_at_destination),
  //     last_mile_start: formatDate(data.last_mile_start),
  //     last_mile_end: formatDate(data.last_mile_end),

  //     msil_rake_id: data.msil_rake_id,
  //     bulkslide1: "slider-translate-30p",
  //   });
  //   $("#uploadFile").val("")
  // }
}
 

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    // console.log(name, '---1', value)
    this.setState({ [name]: value });
  };
  handleChangeRakeId = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    // console.log(name, '---1', value)
    this.setState({ [name]: value });
  };
  closeAlert = () => {
    this.setState({
      show: false,
    });
  };




  onGridReady2 = params => {
    this.gridApi2 = params.api;

  };

//   saparateDate = (selectedDate, field) => {
//     console.log(selectedDate, field,'selectedDate, field')
//     if (!selectedDate) {
//       alert('Invalid date or time entered.');
//       this.setState({ [field]: '' });
//       return;
//     }
//     const date = new Date(selectedDate);
//     const formattedDate = `${date.getFullYear()}-${
//       ("0" + (date.getMonth() + 1)).slice(-2)  // Ensures month is zero-padded
//     }-${("0" + date.getDate()).slice(-2)} ${("0" + date.getHours()).slice(-2)}:${
//       ("0" + date.getMinutes()).slice(-2)  // Ensures minutes are zero-padded
//     }:${("0" + date.getSeconds()).slice(-2)}`; 
    
    	
//     let isValid = true;
  
//     if (field === 'rake_placement' && new Date(this.state.emprt_rake_arrival_MSIB_station) > date) {
//       isValid = false;
//     } else if (field === 'rake_release' && new Date(this.state.rake_placement) > date) {
//       isValid = false;
//     } else if (field === 'engine_on_train' && new Date(this.state.rake_release) > date) {
//       isValid = false;
//     } else if (field === 'rake_departure' && new Date(this.state.engine_on_train) > date) {
//       isValid = false;
//     } else if (field === 'rake_arrival_at_destination' && new Date(this.state.rake_departure) > date) {
//       isValid = false;
//     } else if (field === 'last_mile_start' && new Date(this.state.rake_arrival_at_destination) > date) {
//       isValid = false;
//     } else if (field === 'last_mile_end' && new Date(this.state.last_mile_start) > date) {
//       isValid = false;
//     }
//   console.log(isValid,'isValid')
//   if (!isValid) {
//     alert('The selected date must follow a logical sequence.');
//     this.setState({ [field]: '',calendarKey: this.state.calendarKey + 1  }, () => {
//         // Additional logic if needed after state update
//         document.getElementsByName(field)[0].value = ''; // Explicitly clear the field
//     });
// } else {
//     this.setState({ [field]: formattedDate });
// }
// }
saparateDate = (selectedDate, field) => {
  console.log(selectedDate, field, 'selectedDate, field');
  if (!selectedDate) {
    alert('Invalid date or time entered.');
    this.setState({ [field]: '' });
    return;
  }
  const date = new Date(selectedDate);
  const formattedDate = `${date.getFullYear()}-${
    ("0" + (date.getMonth() + 1)).slice(-2)
  }-${("0" + date.getDate()).slice(-2)} ${("0" + date.getHours()).slice(-2)}:${
    ("0" + date.getMinutes()).slice(-2)
  }:${("0" + date.getSeconds()).slice(-2)}`;

  let isValid = true;

  const dateFields = [
    'emprt_rake_arrival_MSIB_station',
    'rake_placement',
    'rake_release',
    'engine_on_train',
    'rake_departure',
    'rake_arrival_at_destination',
    'last_mile_start',
    'last_mile_end'
  ];

  const currentIndex = dateFields.indexOf(field);

  for (let i = 0; i < currentIndex; i++) {
    if (this.state[dateFields[i]] && new Date(this.state[dateFields[i]]) > date) {
      isValid = false;
      break;
    }
  }

  for (let i = currentIndex + 1; i < dateFields.length; i++) {
    if (this.state[dateFields[i]] && new Date(this.state[dateFields[i]]) < date) {
      isValid = false;
      break;
    }
  }

  console.log(isValid, 'isValid');
  if (!isValid) {
    alert('The selected date must follow a logical sequence.');
    this.setState({ [field]: '', calendarKey: this.state.calendarKey + 1 }, () => {
      document.getElementsByName(field)[0].value = '';
    });
  } else {
    this.setState({ [field]: formattedDate });
  }
}
  
  handleKeyPressCodeRakeId = (event) => {
    const currentValue = event.target.value;
    const inputChar = String.fromCharCode(event.charCode);
    // if ((currentValue + inputChar).length > 4) {
    //     event.preventDefault(); 
    // }
    if (!/^[0-9,]*$/.test(inputChar)) {
        event.preventDefault(); 
    }
};
  render() {

    const batchwisecolumns = [
      {
        headerName: 'S No.',
        valueGetter: "node.rowIndex + 1",
        width: 80,
        filter: false, 
        resizable: true
    },
      // {
      //   headerName: "Edit",
      //   field: "Edit",
      //   width: 110,
      //   resizable: true,
      //   editable: false,
      //   cellRenderer: function (params) {
      //     if (!params.data.not_planned_volumes) {
      //       var htmloption = '<button class="btn btn-warning" style="padding-top:0px">Edit</button>';
      //       return htmloption;
      //     } else {
      //       var htmloption = '<button class="btn btn-warning" disabled="on" style="padding-top:0px">Edit</button>';
      //       return htmloption;
      //     }
      //   },
      //   filter: false,
      // },
    //   {
    //     headerName: "Action",
    //     field: "delete",
    //     width: 95,
    //     resizable: true,
    //     editable: false,
    //     cellRenderer: function (params) {
    //         // Assuming requested_date_time is stored in each row's data and is in ISO format
    //         const requestedDateTime = params.data.requested_date_time;
    //         const currentTime = new Date();
    //         const requestedDate = new Date(requestedDateTime);
    
    //         // Calculate the difference in hours
    //         const diffHours = (currentTime - requestedDate) / (1000 * 60 * 60);
    
    //         if (diffHours > 24) {
    //             // More than 24 hours old, disable the delete button
    //             return '<i class="icofont icofont-ui-delete" style="color: #ccc; cursor: not-allowed;"></i>';
    //         } else {
    //             // Less than 24 hours old, active delete button
    //             return '<i class="icofont icofont-ui-delete" style="cursor: pointer;"></i>';
    //         }
    //     },
    // },
    {
      headerName: "Action",
      field: "delete",
      width: 110,
      resizable: true,
      editable: false,
      cellRenderer: function (params) {
          const requestedDateTime = params.data.requested_date_time;
          const currentTime = new Date();
          const requestedDate = new Date(requestedDateTime);
  
          // Calculate the difference in hours
          const diffHours = (currentTime - requestedDate) / (1000 * 60 * 60);
  
          if (diffHours < 24) {
            var htmloption = '<button class="btn btn-primary" style="padding-top:0px">Delete</button>';
            return htmloption;
          } else {
            var htmloption = '<button class="btn btn-primary" disabled="on" style="padding-top:0px">Delete</button>';
            return htmloption;
          }
      },
  },
  
    
      {
        headerName: 'Requesting Date & Time',
        field: "requested_date_time",
        valueGetter: (param) => {
          if (param.data.requested_date_time !== "") {
            return getHyphenDDMMMYYYYHHMM(param.data.requested_date_time);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'TRTR Code',
        field: "transporter_code",
      
      },
      {
        headerName: 'Transporter Name',
        field: "transporter_name",
      },
      {
        headerName: 'MSIL Rake id',
        field: "msil_rake_id",
      },
      {
        headerName: 'Invoice Start',
        field: "invoice_start_date",
        valueGetter: (param) => {
          if (param.data.invoice_start_date !== "" && param.data.invoice_start_date !== undefined) {
            return getHyphenDDMMYYYYHHMMSS(param.data.invoice_start_date);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Invoice End',
        field: "invoice_end_date",
        valueGetter: (param) => {
          if (param.data.invoice_end_date !== "" && param.data.invoice_end_date !== undefined) {
            return getHyphenDDMMYYYYHHMMSS(param.data.invoice_end_date);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Empty rake arrival MSIB station',
        field: "emprt_rake_arrival_MSIB_station",
        valueGetter: (param) => {
          if (param.data.emprt_rake_arrival_MSIB_station !== "" && param.data.emprt_rake_arrival_MSIB_station !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.emprt_rake_arrival_MSIB_station);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Rake placement',
        field: "rake_placement",
        valueGetter: (param) => {
          if (param.data.rake_placement !== "" && param.data.rake_placement !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.rake_placement);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Rake Release',
        field: "rake_release",
        valueGetter: (param) => {
          if (param.data.rake_release !== "" && param.data.rake_release !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.rake_release);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Engine on Train',
        field: "engine_on_train",
        valueGetter: (param) => {
          if (param.data.engine_on_train !== "" && param.data.engine_on_train !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.engine_on_train);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Rake departure',
        field: "rake_departure",
        valueGetter: (param) => {
          if (param.data.rake_departure !== "" && param.data.rake_departure !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.rake_departure);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Rake arrival at destination',
        field: "rake_arrival_at_destination",
        valueGetter: (param) => {
          if (param.data.rake_arrival_at_destination !== "" && param.data.rake_arrival_at_destination !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.rake_arrival_at_destination);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Last mile start',
        field: "last_mile_start",
        valueGetter: (param) => {
          if (param.data.last_mile_start !== "" && param.data.last_mile_start !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.last_mile_start);
          } else {
            return "";
          }
        },
      },
      {
        headerName: 'Last mile end',
        field: "last_mile_end",
        valueGetter: (param) => {
          if (param.data.last_mile_end !== "" && param.data.last_mile_end !== undefined) {
            return getHyphenDDMMMYYYYHHMM(param.data.last_mile_end);
          } else {
            return "";
          }
        },
      },
    
    ];


    return (
      <div class="container-fluid">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
         <div>
        {this.state.alert}
      </div>

        <div className="card col-xl-12">
          <div className="card-header">
            <h5>
              <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
              <span>MSIB Operations Monitoring Screen</span>
              <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.addMsibDetails.bind(this)}>Add New MSIB Details</button>
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowData}
                      enableCharts={false}
                      onGridReady={this.onGridReady2}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      onCellClicked={this.onCellClicked.bind(this)}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      enableRangeSelection={true}
                    />
                  </div>
                </div>
              </div>
            </h5>
          </div>

          <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "scroll" }}>
            <h3 className="subH">New MSIB Operations Monitoring Details
              <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
            <div className="slide-r-body" style={{ position: "relative" }}>
              <div className="container-fluid">
                <form id="initialAddingDetails"
                  onSubmit={this.onClickAddDetails.bind(this)}
                  className="row col-xl-12 col-lg-12">

                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>MSIL Rake Id<span style={{ color: 'red' }}> *</span> </label>
                                    <input
                                      type="text"
                                      value={this.state.msil_rake_id}
                                      name="msil_rake_id"
                                      id="msil_rake_id"
                                      className="form-control"
                                      onKeyPress={this.handleKeyPressCodeRakeId}
                                      autoComplete="off"
                                      onChange={this.handleChangeRakeId}
                                    />
                                  </div>
                                  
                   <div className="col-xl-12 col-lg-12 form-group">
                    <label>Empty rake arrival MSIB station<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime 
                     key={this.state.calendarKey} 
                      value={this.state.emprt_rake_arrival_MSIB_station}
                      onChange={(date) => this.saparateDate(date, 'emprt_rake_arrival_MSIB_station')}
                      closeOnSelect={false}
                      inputProps={{
                        name: 'emprt_rake_arrival_MSIB_station',
                        autoComplete: 'off',
                        readOnly: true,
                        className: 'custom-datetime-input'
                      }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                    />
                  </div>

                 
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Rake placement<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime  key={this.state.calendarKey} 
                    value={this.state.rake_placement}
                      disableCloseOnClickOutside={false}
                      onChange={(date) => this.saparateDate(date, 'rake_placement')}
                      closeOnSelect={false}
                      inputProps={{ name: 'rake_placement', autoComplete: 'off', readOnly: true ,className: 'custom-datetime-input'}}
                     dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                       />

                  </div>

                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Rake Release<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime  key={this.state.calendarKey}  value={this.state.rake_release}
                      // disableCloseOnClickOutside={false}
                      onChange={(date) => this.saparateDate(date, 'rake_release')}
                      closeOnSelect={false}
                      inputProps={{ name: 'rake_release', autoComplete: 'off', readOnly: true, className: 'custom-datetime-input' }}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                       />
                  </div>

                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Engine on Train<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime  key={this.state.calendarKey}  value={this.state.engine_on_train}
                      // disableCloseOnClickOutside={false}
                      onChange={(date) => this.saparateDate(date, 'engine_on_train')}
                      closeOnSelect={false}
                      inputProps={{ name: 'engine_on_train', autoComplete: 'off', readOnly: true ,className: 'custom-datetime-input'}}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                      />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Rake departure<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime  key={this.state.calendarKey}  value={this.state.rake_departure}
                      onChange={(date) => this.saparateDate(date, 'rake_departure')}
                      // disableCloseOnClickOutside={false}
                      closeOnSelect={false}
                      inputProps={{ name: 'rake_departure', autoComplete: 'off', readOnly: true ,className: 'custom-datetime-input'}}
                      dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                      />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Rake arrival at destination <span style={{ color: 'red' }}> *</span> </label>
                    <Datetime  key={this.state.calendarKey}  value={this.state.rake_arrival_at_destination }
                      onChange={(date) => this.saparateDate(date, 'rake_arrival_at_destination')}
                      // disableCloseOnClickOutside={false}
                      closeOnSelect={false}
                      inputProps={{ name: 'rake_arrival_at_destination', autoComplete: 'off', readOnly: true ,className: 'custom-datetime-input'}}
                       dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                      />
                  </div>
                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Last mile start<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime  key={this.state.calendarKey}  value={this.state.last_mile_start}
                      // disableCloseOnClickOutside={false}
                      onChange={(date) => this.saparateDate(date, 'last_mile_start')}
                      closeOnSelect={false}
                      inputProps={{ name: 'last_mile_start', autoComplete: 'off', readOnly: true ,className: 'custom-datetime-input'}}
                     dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                     />

                  </div>

                  <div className="col-xl-12 col-lg-12 form-group">
                    <label>Last mile end<span style={{ color: 'red' }}> *</span> </label>
                    <Datetime  key={this.state.calendarKey}  value={this.state.last_mile_end}
                      // disableCloseOnClickOutside={false}
                      onChange={(date) => this.saparateDate(date, 'last_mile_end')}
                      closeOnSelect={false}
                      inputProps={{ name: 'last_mile_end', autoComplete: 'off', readOnly: true, className: 'custom-datetime-input' }}
                       dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
                       />
                  </div>
                
                  <div className="form-group col-xl-3 col-lg-3">
                    <button type="submit" className="btn btn-success">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

       

        </div>


        <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>
    );
  }
}



function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
  this.eInput = document.createElement('input');
  this.eInput.value = params.value;

  // Get today's date and format it as dd-mm-yy
  var today = new Date();

  // https://jqueryui.com/datepicker/
  $(this.eInput).datetimepicker({
    dateFormat: "dd-mm-yy",
    changeMonth: true,
    changeYear: true,
    timepicker: true,
    // maxDate: today // Set the maxDate option to today's date
  });
};


// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
  return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
  this.eInput.focus();
  this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
  return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
  // and we could leave this method out also, false is the default
  return false;
};
function loadDateTimeScript() {
  // alert("timeDate");
  $(".datetimepicker_datew").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    // minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".datetimepicker_dateo").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
    closeOnDateSelect: true,
    scrollMonth: false,
    scrollInput: false,
  });
  $(".cargo_date").datetimepicker({
    //mask:'39-19-9999',
    format: "d-m-Y",
    minDate: true,
    timepicker: false,
  });
}
function formatDate(dateString) {
  if (dateString != "Invalid Date" && dateString != "" && dateString != "NaN-NaN-NaN" && dateString != undefined && dateString != 'undefined') {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
  else {
    return ""
  }
}

