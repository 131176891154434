import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const RouteAnalyticsChart = ({ onColumnClick, categories, yAxis, series }) => {

    // Define handlePointClick first
    // const handlePointClick = (event) => {
    //     const { category, y } = event.point;
    //     onColumnClick({
    //         category,
    //         value: y,
    //         additionalText: 'Additional text here', // Add your additional text or data here
    //     });
    // };

    const colors = ['#12DD12', '#297601'];

    // Then map over the series
    series = series.map((e, index) => ({
        ...e,
        color: colors[index % colors.length],
        point: {
            events: {
                click: null,
            },
        }
    }));

    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: '',
        },
        xAxis: {
            categories: categories,
            labels: {
                useHTML: true,
                formatter: function () {
                    return this.value;
                }
            }
        },
        yAxis: {
            title: {
                text: yAxis,
                useHTML: true,
                formatter: function () {
                    return this.value;
                }
            },
            type: 'logarithmic',
        },
        series: series,
        plotOptions: {
            column: {
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    // format: '{point.y}',
                },
            },
        },
        tooltip: {
            pointFormat: '<b>{point.y}</b>',
            backgroundColor: '#FFFFFF',
            useHTML: true,
        },
        legend: {
            enabled: true, // Adjust as needed
        },
        credits: {
            enabled: false,
        },
    };

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
};

export default RouteAnalyticsChart;