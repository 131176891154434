import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default class TvpManagementDashboardPieChart extends Component {
    render() {
        const { title, yaxistitle, series, innerSize, seriesName } = this.props;

        const highchartOptions = {
            chart: {
                type: 'pie',
                height: '250px'
            },
            title: {
                text: title,
                style: {
                    fontWeight: 'bold'
                }
            },
            subtitle: {
                text: ''
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            plotOptions: {
                pie: {
                    innerSize: innerSize || '50%',
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}%'
                    },
                    showInLegend: true,
                    colors: ['#55D8FE', '#FF8373', '#FFDA83',"#A3A0FB"]
                }
            },
            tooltip: {
                formatter: function () {
                    return `<b>${this.point.name}</b>: ${this.point.value}`;
                }
            },
            series: [{
                name: seriesName,
                colorByPoint: true,
                data: series
            }],
            credits: false
        };

        return (
            <div className="led1_piechart">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={highchartOptions}
                />
            </div>
        );
    }
}
