
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getDDMMYYYY, getDDMMYYYYHHMM } from '../common/utils';
import Counters from './consignmentkpicountersComponent';
import CountUp from 'react-countup';

import { AgGridReact } from "@ag-grid-community/react";

import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from "react-select";
import HighchartsReact from 'highcharts-react-official';

import Highcharts from 'highcharts';

var redirectURL = require("../redirectURL");

export default class TransitReturns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returns_performance: 0,
      returns_count:0,
      show: false,
      basicTitle: '',
      basicType: "default",
      overly: "show-n",
      loadshow: "show-n",
      sidebartitle: "",
      totalData: [],
      filterType: 'All',
      rowData: [],
      sliderRouteTranslate: '',
      filterType: 'All',
      totalSumedUpValues: [],
      facility: "All Facilities",
      loadshow: "show-m",
      returnPathName:'',
      overly: "show-m",
      facilitiesList: [{ "label": "All Facilities", "value": "All" },
      { "label": "Haryana", "value": "H" },
      { "label": "Bidadi", "value": "BP" }
        , { "label": "Gujarat", "value": "G" },
      { "label": "TVP-B", "value": "B" },
      { "label": "TVP-N", "value": "N" },
      { "label": "TVP-S", "value": "I" }]
    }
    this.filterDataByCategory = this.filterDataByCategory.bind(this);
  }

  componentDidMount() {
    const { transporterCode } = this.props.match.params;
    console.log(transporterCode, 'transporterCode');

    const searchParams = this.props.location.search;
    if (searchParams.includes('lspceodashboardDemo')) {
       var returnPathName = "lspceodashboardDemo";
    }
    var returns_performance = 0;
    var returns_count = 0;
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
      returnPathName:returnPathName
    })
    redirectURL
      .post("/consignments/toplspperformance")
      .then((response) => {
        returns_performance = response.data[0].returns_performance || 0;
        returns_count = response.data[0].returns_count || 0;
        this.setState({
          transporter_code: transporterCode,
          returns_performance: returns_performance,
          returns_count:returns_count
        });

        var params = {
          transporter_code: transporterCode
        };
        redirectURL
          .post("/consignments/transitReturnMount", params)
          .then((response) => {
            console.log(response.data);
            const excludeYears = new Set(["FY 18-19", "FY 19-20", "FY 20-21","FY 21-22","FY 22-23"]);
            // const filteredData = response.data.filter(item =>
              // item.month.startsWith('FY') && !excludeYears.has(item.month)
            // );
            const filteredData = response.data
            .filter(item => item.month && item.month.startsWith('FY') && !excludeYears.has(item.month))
            .sort((a, b) => {
              const yearA = extractYear(a.month);
              const yearB = extractYear(b.month);
              return yearA - yearB;
            });
            console.log(filteredData, 'filteredData');
            // console.log(filteredData, "filteredData")
            const aggregatedData = filteredData.reduce((result, item) => {
              // console.log(item, "item")
              const year = item.month;
              if (!result[year]) {
                result[year] = {
                  month: year,
                  TOTAL: 0,
                };
              }

              if (item.parameters && item.parameters.TOTAL !== null) {
                result[year].TOTAL += parseFloat(item.parameters.TOTAL);
              }
              return result;
            }, {});
            console.log(aggregatedData, 'aggregatedData')

            const aggregatedDataArray = Object.values(aggregatedData);
            console.log(aggregatedDataArray, 'aggregatedDataArray')

            aggregatedDataArray.sort((a, b) => {

            });
            const sortedAndFilteredArray = aggregatedDataArray.slice(-2);
            console.log(sortedAndFilteredArray, "sortedAndFilteredArray")
            const currentFiscalYearMonthsData = {};

            response.data.forEach((item) => {
              const month = item.month;

              if (!month.startsWith('FY')) {

                if (!currentFiscalYearMonthsData[month]) {
                  currentFiscalYearMonthsData[month] = [];
                }
                // Add the TOTAL value for this month
                if (item.parameters && typeof item.parameters.TOTAL === 'number') {
                  const total = item.parameters.TOTAL; // Directly use the value
                  currentFiscalYearMonthsData[month].push(total);
                }
              }
            });




            const monthsToExtract = DynamicSortOfMonths()
            const filteredDataArray = [];
            monthsToExtract.forEach(month => {
              if (currentFiscalYearMonthsData.hasOwnProperty(month)) {

                var totalData = 0;
                currentFiscalYearMonthsData[month].forEach((e) => {
                  totalData = totalData + e

                })
                if(totalData > 0){
                  filteredDataArray.push({
                    month: month,
                    data: [totalData]
                  });
                }
              }
            });
            const joinedArray = sortedAndFilteredArray.concat(filteredDataArray);

            console.log(joinedArray, sortedAndFilteredArray, filteredDataArray, 'joinedArray')

            this.setState({
              totalSumedUpValues: joinedArray,
              loadshow: "show-n",
              overly: "show-n"
            });
          })
      })
      .catch((error) => {
        console.error(error);
      });

  }

  newFilter = (event) => {
    this.setState({
      filterType: event.value,
      loadshow: "show-m",
      overly: "show-m"
    })

    var plantCode;
    if (event.value == "H") {
      plantCode = "HARYANA"
    } else if (event.value == "BP") {
      plantCode = "BIDADI"
    } else if (event.value == "G") {
      plantCode = "GUJARAT"
    } else if (event.value == "B") {
      plantCode = "TVP BANGALORE"
    } else if (event.value == "N") {
      plantCode = "TVP NAGPUR"
    } else if (event.value == "I") {
      plantCode = "TVP SILIGURI"
    } else if (event.value == "All") {
      plantCode = "TOTAL"
    }


    var total;
    console.log(plantCode, 'plantCode')
    var params = {
      transporter_code: this.state.transporter_code,
      plant_code: event.value

    };
    redirectURL
      .post("/consignments/transitReturnMount", params)
      .then((response) => {
        console.log(response.data);

        const excludeYears = new Set(["FY 18-19", "FY 19-20", "FY 20-21","FY 21-22","FY 22-23"]);
       
        const filteredData = response.data
        .filter(item => item.month && item.month.startsWith('FY') && !excludeYears.has(item.month))
        .sort((a, b) => {
          const yearA = extractYear(a.month);
          const yearB = extractYear(b.month);
          return yearA - yearB;
        });
        // const filteredData = response.data.filter(item => item.month.startsWith('FY'));
        console.log(filteredData, 'filteredData');

        const aggregatedData = filteredData.reduce((result, item) => {
          const year = item.month;
          if (!result[year]) {
            result[year] = {
              month: year,
              TOTAL: 0,
            };
          }

          if (item.parameters && item.parameters.TOTAL !== null) {
            result[year].TOTAL += item.parameters[plantCode];
          }
          return result;
        }, {});
        console.log(aggregatedData, 'aggregatedData')

        const aggregatedDataArray = Object.values(aggregatedData);
        console.log(aggregatedDataArray, 'aggregatedDataArray')

        aggregatedDataArray.sort((a, b) => {

        });
        const sortedAndFilteredArray = aggregatedDataArray.slice(-2);

        console.log(sortedAndFilteredArray, 'sortedAndFilteredArray');

        const currentFiscalYearMonthsData = {};

        response.data.forEach((item) => {
          const month = item.month;


          if (!month.startsWith('FY')) {

            if (!currentFiscalYearMonthsData[month]) {
              currentFiscalYearMonthsData[month] = [];
            }

            if (item.parameters && item.parameters[plantCode] !== null) {

              currentFiscalYearMonthsData[month].push(item.parameters[plantCode]);
            }
          }
        });

        // Sort the data as needed
        console.log(currentFiscalYearMonthsData, 'currentFiscalYearMonthsData')

        const monthsToExtract = DynamicSortOfMonths()


        const filteredDataArray = [];
        monthsToExtract.forEach(month => {
          if (currentFiscalYearMonthsData.hasOwnProperty(month)) {
            // console.log(currentFiscalYearMonthsData[month],'currentFiscalYearMonthsData[month]')
            var totalData = 0;
            currentFiscalYearMonthsData[month].forEach((e) => {
              totalData = totalData + e
              // console.log(e,'eeeeee',totalData)
            })
            if(totalData > 0){
            filteredDataArray.push({
              month: month,
              data: [totalData]
            });
          }
          }
        });

        console.log(filteredDataArray, 'filteredDataArray');
        const joinedArray = sortedAndFilteredArray.concat(filteredDataArray);

        console.log(joinedArray, 'joinedArrayinfilter')

        this.setState({
          totalSumedUpValues: joinedArray,
          loadshow: "show-n",
          overly: "show-n"
        });
      })
      .catch((error) => {
        console.error(error);
      });

  }


  onClickCounter(data) {
    //this.props.context.onClickCounterShowData(data)

  }

  handleDrilldown = (params) => {
    console.log("Here ,", params)
  }
  onClickHideAll() {
    this.setState({
      loadshow: 'show-n',
      overly: 'show-n',
      bulkslide: "",
      bulkslidebulk: '',
    });

  }

  filterDataByCategory = async (category) => {
    this.setState({ loadshow: 'show-m', overly: 'show-m' });
    if (category.startsWith("FY")) {
      var formattedCategory = category
    } else {
      var formattedCategory = formatCategoryToYearMonth(category);
    }
    console.log(formattedCategory, 'category', category)
    var params = {
      category: formattedCategory,
      transporter_code: this.props.match.params.transporterCode,
      consigner_code: this.state.filterType
    }
    console.log(params, 'paramssssss')
    try {
      const response = await redirectURL.post("/consignments/getTransitReturnsCountLsps", params);
      console.log(response, 'response', response.data.data)
      if (response.data.data.length > 0) {
        this.setState({
          totalData: response.data.data,
          rowData: response.data.data,
          sliderRouteTranslate: 'slider-translate-75p',
          loadshow: 'show-n',
          overly: 'show-n'
        });
        console.log(this.state.rowData, 'rowData')
      } else {
        this.setState({
          show: true,
          basicTitle: "No data to show",
          basicType: "warning",
          loadshow: 'show-n',
          overly: 'show-n'
        })
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      this.setState({ loadshow: 'show-n', overly: 'show-n' }); // Hide loading state on error
    }
  }

  onCloseRouteDiv = () => {
    this.setState({
      sliderRouteTranslate: "",
      showDiv: 'show-n',
      loadshow: "show-n",
      overly: "show-n",
    });
  }
  closeAlert = () => {
    this.setState({
      show: false
    });
  }
  render() {
    const counterCoulumDef = [
      {
        headerName: "Returned Vin Number",
        field: "vin",
        filter: true,
        resizable: true,
      },
      {
        headerName: "Truck Number",
        field: "actual_carri",
        filter: true,
        resizable: true,
      },
      {
        headerName: "Invoice Date & Time",
        field: "invoice_date",
        filter: true,
        resizable: true,
        valueGetter: function (params) {
          if (params.data.invoice_date != '' && params.data.invoice_date != undefined) {
            return getDDMMYYYYHHMM(params.data.invoice_date)
          }
          else {
            return "";
          }
        }
      },
      {
        headerName: "Return reason",
        field: "reason",
        filter: true,
        resizable: true,
      },
    ]
    const dStyles = {
      width: '100%',
      height: '100%'
    }
    const returnPerformance = this.state.returns_performance;
    const transitReturnPercentageData = this.state.totalSumedUpValues.map((ele) => {
      if (ele.TOTAL !== undefined) {
        return ele.TOTAL;
      } else if (ele.data && ele.data.length > 0) {
        const sum = ele.data.reduce((total, value) => total + value, 0);
        const average = sum / ele.data.length;
        return average;
      } else if (ele.plantCode && ele.plantCode.length > 0) {
        return ele.plantCode;
      } else {
        return 0;
      }
    });

    const monthCategories = this.state.totalSumedUpValues.map((ele) =>(ele && ele.month));
    
    var highchartoptions = {
      chart: {
        type: 'column',
        events: {
          load: function () {
            // Calculate the x position relative to the chart width
            var xPosition = this.chartWidth - 300; // Adjust the 300 based on your text length and desired margin

            this.renderer.html(
              '<div style="text-align: right;">' +
              // 'Best Performing LSP Return Vehicle Count= 0<br>' +
              '<span style="margin-left: 150px;">Target: 0</span>' +
              '</div>',
              xPosition,
              25 // You might need to adjust this as well for vertical positioning
            ).attr({
              zIndex: 5
            }).add().css({
              fontSize: 'large'
            }).align({
              align: 'right', // Right align the text
              verticalAlign: 'top', // Align it to the top
              x: -450, // Adjust this value to move text left(-) or right(+)
              y: 25 // Adjust this value to move text up(-) or down(+)
            });
          }
        }
      },
      title: {
        text: 'Return Vehicle count'
      },
      xAxis: {
        categories: monthCategories,
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'Total'
        },
        min: 0,  // Set the minimum value
        // max: 10, // Set the maximum value
        tickInterval: 5,
        plotLines: [{
          color: '#2E75B6', // Color of the target line
          value: 0, // Target line value
          width: 2, // Width of the line
          zIndex: 5, // To ensure it's above the grid
          label: {
            // text: 'Target: 0%', // Label for the line
            // align: 'right',
            style: {
              color: 'gray',
              fontSize: 'bold'
            }
          }
        }]
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.0f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          // borderWidth: 0,
          cropThreshold: 500,
          dataLabels: {
            enabled: true, // Enable data labels
            format: '{point.y:.0f} ', // Format for data labels
            // align: 'center', // Align data labels to center
            // verticalAlign: 'bottom', // Align data labels to the top of the bars
            // inside: false, // Display data labels outside the bars
          },
          cursor: 'pointer',
          point: {
            events: {
              click: (event) => {
                // Using arrow function to keep context
                const category = event.point.category;
                this.filterDataByCategory(category);
              }
            }
          }
        }
      },
      series: [{
        name: 'Return Vehicle count',
        color: "#FF7F7F",
        data: transitReturnPercentageData.map((value, index) => {
          // Change color based on condition for each point
          // console.log(transitReturnPercentageData[index],'transitReturnPercentageData')
          return {
            y: value,
            color: transitReturnPercentageData[index] <= 0 ? '#92d864' : '#FF7F7F',
          };
        }),
        // pointPadding:1.3,
        // pointWidth:50
      }]
    };
    return (
      <div className='row'>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        >
        </SweetAlert>
        <div className={"dataLoadpage " + (this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{ position: "fixed", top: "43%" }}>
          <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        <div style={{ textAlign: "center", background: "#171C8F", width: '100%', margin: "5px", borderRadius: "5px", height: '70px', paddingBottom: '25px' }}>
          <h6 class="txt-dark f40" style={{ marginTop: "0px" }}>
            <span style={{ fontSize: "20px", color: "#FFFFFF" }}>
              <span>Transit Returns</span>

            </span>
          </h6>
        </div>


        <div className="row card-body">

          <div className="col-xl-4">


            <Select
              placeholder={"Select Facility"}
              closeMenuOnSelect={true}

              value={this.state.facility.label}
              onChange={this.newFilter.bind(this)}
              style={{ borderRadius: "0px" }}
              options={this.state.facilitiesList} required
            />
          </div>
        
          {
       this.state.returnPathName == "lspceodashboardDemo"?
       <div className="col-xl-8 col-lg-8">
       <h6>

         <span className="float-right f12 ">
           <a className="btn btn-danger" style={{ float: "left", marginLeft: "10%" }} href="/lspceodashboardDemo">Back</a>
         </span>
         <span style={{ float: "right", marginRight: "5%" }}>Best Performing LSP Return Vehicle Count= {returnPerformance}<br/>Best Performing LSPs count : {this.state.returns_count}</span> 
      
       </h6>
     </div>
     :
     <div className="col-xl-8 col-lg-8">
     <h6>

       <span className="float-right f12 ">
         <a className="btn btn-danger" style={{ float: "left", marginLeft: "10%" }} href="/lspceodashboard">Back</a>
       </span>
       <span style={{ float: "right", marginRight: "5%" }}>Best Performing LSP Return Vehicle Count= {returnPerformance}<br/>Best Performing LSPs count : {this.state.returns_count}</span> 
    
     </h6>
   </div>
        } 


        </div>




        <div className='card col-xl-12 col-lg-12'>

          <HighchartsReact
            containerProps={{ style: { width: '100%', height: '100%' } }}
            highcharts={Highcharts}
            style={dStyles}
            options={highchartoptions}
            onClick={this.handleDrilldown.bind(this)}
          />
        </div>

        <div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
          <div className="slide-r-title">
            <h4>
              Transit Return
              {this.state.sidebartitle}
              <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

            </h4>
          </div>
          <div className="slide-r-body" style={{ position: "relative" }}>

            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">

              <div
                id="myGrid"
                style={{
                  height: '600px',
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  modules={this.state.modules}
                  columnDefs={counterCoulumDef}
                  defaultColDef={this.state.defaultColDef}
                  rowData={this.state.rowData}
                  paginationPageSize={this.state.paginationPageSize}
                  pagination={true}
                // onCellEditingStopped={this.updateDelayRemarks.bind(this)}
                />
              </div>

            </div>
          </div>
        </div>
      </div>


    );
  }
}


function DynamicSortOfMonths() {
  const currentDate = new Date();
  const fiscalYearStartMonth = 3;
  let currentFiscalYear;
  if (currentDate.getMonth() < fiscalYearStartMonth) {
    currentFiscalYear = currentDate.getFullYear() - 1;
  } else {
    currentFiscalYear = currentDate.getFullYear();
  }

  const fiscalYearMonths = [];
  const monthNames = [
    "Apr", "May", "Jun", "Jul", "Aug", "Sep",
    "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"
  ];

  // Dynamically generate the order array based on the current fiscal year
  const order = [];
  for (let i = fiscalYearStartMonth; i < fiscalYearStartMonth + 12; i++) {
    const monthName = monthNames[i % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    order.push(`${monthName}-${String(year).substring(2)}`);
  }

  for (let month = fiscalYearStartMonth; month < fiscalYearStartMonth + 12; month++) {
    const monthName = monthNames[month % 12];
    const year = (monthName === "Jan" || monthName === "Feb" || monthName === "Mar") ? currentFiscalYear + 1 : currentFiscalYear;
    fiscalYearMonths.push(`${monthName}-${year}`);
  }

  // Sort fiscal year months based on the dynamically generated order
  fiscalYearMonths.sort((a, b) => {
    const [monthA, yearA] = a.split('-');
    const [monthB, yearB] = b.split('-');
    // Compare years first
    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }
    // If years are the same, compare months
    return monthNames.indexOf(monthA) - monthNames.indexOf(monthB);
  });

  fiscalYearMonths.forEach((month, index) => {
    const [monthName, year] = month.split('-');
    const shortYear = year.substring(2);
    console.log(index, 'index', monthName, shortYear)
    fiscalYearMonths[index] = `${monthName}-${shortYear}`;
  });
  console.log(fiscalYearMonths, 'fiscalYearMonths')
  return fiscalYearMonths;
}
const formatCategoryToYearMonth = (category) => {
  const months = {
    'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06',
    'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12'
  };
  const parts = category.split('-');
  const year = parseInt(parts[1], 10) + 2000; // Adjust year prefix
  const month = months[parts[0]];
  return `${year}-${month}`;
};
function extractYear(month) {
  const match = month.match(/FY (\d{2})-(\d{2})/);
  if (match) {
    // Convert to a number where '20xx' is represented as '20xx' (e.g., '2023')
    return parseInt(`20${match[1]}`, 10) * 100 + parseInt(match[2], 10);
  }
  return 0; // Default value if format is unexpected
}