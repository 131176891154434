/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, AllEnterpriseModules } from '@ag-grid-enterprise/all-modules';
// import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import redirectURL from '../redirectURL';

import download from 'js-file-download';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import fastForward from 'material-ui/svg-icons/av/fast-forward';
import m from 'highcharts-drilldown';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var childJson = []

export default class EditWeeklyContainerBookingFrom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            csvcontent: "",
            file: "",
            loadshow: 'show-n',
            overly: 'show-n',

            country: "",

            container_type: "",
            container_size: [],
            dispatch_location: "",

            destination_port: "",
            container_required: "",
            incoterms: "",

            request_type: 2,
            pagetitle: "Edit Weekly Container Booking",
            lspallocationdetails: [],
            showUpopts: "",
            showUpload: "show-n",
            filteredincoterms: [
                { label: "CIF", value: "CIF" },
                { label: "CFR", value: "CFR" },
                { label: "FOB", value: "FOB" }
            ],
            destination_country: "",
            filteredispatchLocations: [
                { label: "Gurgaon", value: "Gurgaon" },
                { label: "KDO Gujarat", value: "KDO Gujarat" },
                { label: "Manesar", value: "Manesar" },
                { label: "SDN Gujarat", value: "SDN Gujarat" }
            ],
            filteredestinationPort: [
                { label: "Apapa", value: "Apapa" },
                { label: "Chongoing", value: "Chongoing" },
                { label: "Hamburg", value: "Hamburg" },
                { label: "Ho Chi Minh", value: "Ho Chi Minh" },
                { label: "Jakarta", value: "Jakarta" },
                { label: "Laem Chabang", value: "Laem Chabang" },
                { label: "Nagoya", value: "Nagoya" },
                { label: "Omaezaki", value: "Omaezaki" },
                { label: "Shanghai", value: "Shanghai" },
                { label: "Shimizu", value: "Shimizu" },
                { label: "Tema", value: "Tema" },
                { label: "Thilawa", value: "Thilawa" },
            ],
            filteredCountries: [
                { label: "Japan", value: "Japan" },
                { label: "China", value: "China" },
                { label: "Ghana", value: "Ghana" },
                { label: "Hungary", value: "Hungary" },
                { label: "Indonesia", value: "Indonesia" },
                { label: "Myanmar", value: "Myanmar" },
                { label: "Nigeria", value: "Nigeria" },
                { label: "Thailand", value: "Thailand" },
                { label: "Vietnam", value: "Vietnam" }
            ],
            container_requirement_date: '',
            rowId: '',
            no_of_containers: "",
            is_approved:0,
            booking_details_issued:0,
            container_issued:0,
            truck_no_issued:0,
            booking_details_changed:false,
            container_details_changed:false,
            vehicle_no_changed:false,
            shipping_on_board:"",
            bill_of_lading_file:"",
            ContainerDetailsFileUpload:1,
            BOLFileUpload:1
        }
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                var eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                var eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                var eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    async componentDidMount() {
        this.logPageView(); // Log page view to GA
        loadDateTimeScript();

        var inputEventval = window.atob(this.props.match.params.eventname);
        console.log(inputEventval, 'inputEventval')
        var params = {
            rowId: inputEventval,
        }
        await redirectURL.post("/consignments/fetchdataforeditonetime", params)
            .then(async(response) => {
                console.log(response.data, 'department_name')
                var date = moment.parseZone(response.data[0].container_requirement_date).format("DD-MM-YYYY")
                var result = addDaysToDate(date, 4);
                console.log(date, "date")
                var shipping_on_board=""
            if(response.data[0].shipping_on_board=="Yes" || response.data[0].shipping_on_board=="No"){
                shipping_on_board=response.data[0].shipping_on_board
            }
            var bill_of_lading_file=""
            if(response.data[0].bill_of_lading_file){
                bill_of_lading_file=response.data[0].bill_of_lading_file
            }
            console.log(bill_of_lading_file,"164")
            const formatDate = (date) => {
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
                const year = date.getFullYear();
                return `${year}-${month}-${day}`;
            };
                await this.setState({
                    // date:response.data[0].date,
                    rowId:response.data[0]._id,
                    manualincoterms:(response.data[0].manualincoterms),
                    etd_onetime:(formatDate(new Date(response.data[0].etd))),
                    // department_name:response.data[0].department_name,
                    // intender_name : response.data[0].intender_name,
                    // mobile_number:response.data[0].mobile_number,
                    // intenderMails:response.data[0].intenderMails,
                    // ringiData:ringiData,
                    // ringiDesc : (response.data[0].ringiDataLength),
                    // container_required1:(response.data[0].container_requirement),
                    // consignee_name:response.data[0].consignee_name,
                    container_type : {value:response.data[0].container_type,label:response.data[0].container_type},
                    container_size:[{value:response.data[0].container_size,label:response.data[0].container_size}],
                    dispatch_location : {value:response.data[0].dispatch_location,label:response.data[0].dispatch_location},
                    dispatch_port :{value:response.data[0].dispatch_port,label:response.data[0].dispatch_port},
                    destination_port : {value:response.data[0].destination_port,label:response.data[0].destination_port},
                    // mrd_date : response.data[0].mrd_date,
                    // container_requirement_date : response.data[0].container_requirement_date,
                    dgd_file_name : response.data[0].dgd_file_name,
                    msds_file_name:response.data[0].msds_file_name,
                    iip_file_name:response.data[0].iip_file_name,
                    grw_file_name:response.data[0].grw_file_name,
                    container_details_file_name:response.data[0].container_details_file,
                    bill_of_lading_file_name:bill_of_lading_file,
                    bill_of_lading_file:bill_of_lading_file,
                    
                    incoterms:{value:response.data[0].incoterms,label:response.data[0].incoterms},
                    payment_terms :{value:response.data[0].payment_terms,label:response.data[0].payment_terms},
                    // remarks:response.data[0].remarks,
                    certificateOfOrigin:{value:response.data[0].certificate_of_origin,label:response.data[0].certificate_of_origin},   
                    fta :{value:response.data[0].fta_required,label:response.data[0].fta_required},
                    exportIncentive:{value:response.data[0].export_incentive,label:response.data[0].export_incentive},
                    booking_id:response.data[0].booking_id,
                    truck_no:response.data[0].truck_no,
                    container_no:response.data[0].container_no,
                    vessel:response.data[0].vessel,
                    shipping_on_board:{label:shipping_on_board,value:shipping_on_board},
    
                    is_approved:response.data[0].is_approved,
    
                    container_issued:response.data[0].container_issued,
                    booking_details_issued:response.data[0].booking_details_issued,
                    truck_no_issued:response.data[0].truck_no_issued
                })
            })



    }

    async formHandler(event) {
        event.preventDefault();
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })

        var { rowId, container_type, container_size, dispatch_location,
            destination_port, container_requirement_date, no_of_containers, incoterms, requirement_id, destination_country } = this.state

        console.log(rowId, container_type, container_size, dispatch_location,
            destination_port, container_requirement_date, no_of_containers, incoterms, requirement_id, destination_country, "176")

        // var eventTarget = event.target;
        // console.log(this.state.file, "this.state.file");
        // var container_date = $("#container_dateField").val();

        // var container_type = this.state.container_type.value;

        // var dispatch_location = this.state.dispatch_location.value;
        // var dispatch_port = this.state.dispatch_port.value;
        // var destination_port = this.state.destination_port.value;
        // if (destination_port == "Other") {
        //     var destination_port = $('#other_destination_port').val();
        // }
        // var incoterms = this.state.incoterms.value;

        // var container_size = this.state.container_size;
        // var created_by = localStorage.getItem("email")

        // var date = container_date.split("-")
        // var containerDate = date[2] + "-" + date[1] + "-" + date[0] + " 00:00:00"

        var flag = 1
        let errorMessage
        console.log(container_type, "210")
        if (container_type.value == "Non haz Dry" || container_type.value == "Non Haz Dry" || container_type.value == "Non Haz High Cube") {
            var dateObject = parseDate($("#container_dateField").val());
            var compare_container_date = moment.parseZone(dateObject).utcOffset("+05:30")._d
            var currentDate = moment.parseZone(new Date()).add(3, 'days').utcOffset("+05:30")._d;
            console.log(dateObject, compare_container_date, currentDate, $("#container_dateField").val(), "214")
            if (compare_container_date > currentDate) {
                console.log("success -- Haz")
                flag = 1
            }
            else {
                console.log("fail -- Haz")
                flag = 0
                errorMessage = "Container Requirement Date Should be Greater than 4 days"
            }
        }
        if (container_type.value == "Haz High Cube" || container_type.value == "Haz Dry") {
            var dateObject = parseDate($("#container_dateField").val());
            var compare_container_date = moment.parseZone(dateObject).utcOffset("+05:30")._d
            var currentDate = moment.parseZone(new Date()).add(9, 'days').utcOffset("+05:30")._d;
            console.log(dateObject, compare_container_date, currentDate, $("#container_dateField").val(), "214")
            if (compare_container_date > currentDate) {
                console.log("success -- Haz")
                flag = 1
            }
            else {
                console.log("fail -- Haz")
                flag = 0
                errorMessage = "Container Requirement Date Should be Greater than 10 days"
            }
        }

        if (flag == 1) {
            console.log("failing")
            var params = {
                rowId: rowId,
                requirement_id: requirement_id,
                container_type: container_type.value,
                container_size: container_size.value,
                dispatch_location: dispatch_location.value,
                destination_port: destination_port.value,
                destination_country: destination_country.value,
                container_requirement_date: container_requirement_date,
                no_of_containers: no_of_containers,
                incoterms: incoterms.value
            }

            var saveData = new FormData();
            // saveData.append("csvData",csvData);
            saveData.append("rowId", rowId);
            saveData.append("requirement_id", requirement_id);
            saveData.append("container_type", container_type.value);
            saveData.append("container_size", container_size.value);
            saveData.append("dispatch_location", dispatch_location.value);
            saveData.append("destination_port", destination_port.value);
            saveData.append("destination_country", destination_country.value);
            saveData.append("container_requirement_date", $("#container_dateField").val());
            saveData.append("no_of_containers", no_of_containers);
            saveData.append("incoterms", incoterms.value);


            console.log(params, "params")
            if (no_of_containers) {
                redirectURL.post("/consignments/updateWeeklyBookingData", saveData, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        // 'Access-Control-Allow-Origin': "*",
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DEvarE',
                        'Access-Control-Allow-Headers': 'Origin, Content-Type'
                    }
                })
                    .then((response) => {
                        if (response.data.message == "Success") {
                            this.setState({
                                basicTitle: "Date Successfully updated",
                                basicType: "success",
                                show: true,
                                loadshow: "show-n",
                                overly: "show-n"
                            })
                            window.location.href = "/containerbookingsystem/onetime"
                        } else {
                            this.setState({
                                basicTitle: "Date not updated",
                                basicType: "warning",
                                show: true,
                                loadshow: "show-n",
                                overly: "show-n"
                            })
                        }
                    })
            } else {
                this.setState({
                    basicTitle: "All fields are mandatory",
                    basicType: "warning",
                    show: true,
                    loadshow: "show-n",
                    overly: "show-n"
                })
            }

        } else {
            // console.log("hit")
            this.setState({
                basicTitle: errorMessage,
                basicType: "danger",
                show: true,
                loadshow: "show-n",
                overly: "show-n"
            })
        }


        // }
        // else {
        //     // console.log("hit")
        //     this.setState({
        //         basicTitle: errorMessage,
        //         basicType: "danger",
        //         show: true,
        //         loadshow : "show-n",
        //         overly : "show-n"
        //     })
        // }
    }

    async formHandler2(event){
            event.preventDefault();
            this.setState({
                loadshow : "show-m",
                overly : "show-m"
            })
            var errorMessage = ""
            var ContainerDetailsFileUpload=this.state.ContainerDetailsFileUpload
            var BOLFileUpload=this.state.BOLFileUpload
    
            var etd_onetime = this.state.etd_onetime;
            console.log(etd_onetime,moment.parseZone(etd_onetime).format("YYYY-MM-DD"),"etd_onetime")
    
            // if(!etd_onetime){
            //     etd_onetime=""
            // }
    
            if(ContainerDetailsFileUpload==0){
                errorMessage = "Upload Container Details File"
            }
            if(BOLFileUpload==0){
                errorMessage = "Upload BOL File"
            }
            var created_by = localStorage.getItem("email")
            console.log(this.state.bill_of_lading_file,this.state.bill_of_lading_file_name,"730")
            var saveData = new FormData();
            saveData.append("etd", moment.parseZone(etd_onetime).format("DD-MM-YYYY"));
            saveData.append("booking_id", this.state.booking_id);
            saveData.append("shipping_on_board", this.state.shipping_on_board.value);
            saveData.append("container_details_file", this.state.container_details_file);
            saveData.append("bill_of_lading_file", this.state.bill_of_lading_file);
            saveData.append("vessel", this.state.vessel);
            saveData.append("container_no", this.state.container_no);
            saveData.append("truck_no", this.state.truck_no);
            saveData.append("rowId",this.state.rowId);  
            saveData.append("created_by", created_by);
            saveData.append("booking_details_changed", this.state.booking_details_changed);
            saveData.append("container_details_changed", this.state.container_details_changed);
            saveData.append("vehicle_no_changed", this.state.vehicle_no_changed);
    
            console.log(saveData,'saveData')
    
            if (ContainerDetailsFileUpload==1 && BOLFileUpload==1) {
                await redirectURL.post("/consignments/updateOnetimeBookingDataafterApproval", saveData, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        // 'Access-Control-Allow-Origin': "*",
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DEvarE',
                        'Access-Control-Allow-Headers': 'Origin, Content-Type'
                    }
                })
                    .then((response) => {
                        if (response.data.message == "Success") {
                            this.setState({
                                basicTitle: "Date Successfully updated",
                                basicType: "success",
                                show: true,
                                loadshow : "show-n",
                                overly : "show-n"
                            })
                            // window.location.href = "/containerbookingsystem/onetime"
                            this.componentDidMount()
                        } else {
                            this.setState({
                                basicTitle: "Date not updated",
                                basicType: "warning",
                                show: true,
                                loadshow : "show-n",
                                overly : "show-n"
                            })
                        }
                    })
            } else {
                this.setState({
                    basicTitle: errorMessage,
                    basicType: "warning",
                    show: true,
                    loadshow : "show-n",
                    overly : "show-n"
                })
            }
    }
    SOBchangeHandler=(e)=>{
        console.log(e,"1231")
        this.setState({
            shipping_on_board:e
        })
    }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };


    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: ''
        });

    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    containertypechangeHandler = (e) => {

        this.setState({
            container_type: e,
            container_requirement_date: ""
            // showUpopts: showUpopts
        })
    }
    containersizechangeHandler = (e) => {
        if (e == null) {
            e = [];
        }
        this.setState({
            container_size: e,
        })
    }

    newFilter = (filterType, selOption) => {
        console.log(filterType, selOption)
        this.setState({ [filterType]: selOption });
    }


    changeHandler = (event) => {
        console.log("Select", event.target.value);

        var name = event.target.name;
        var value = event.target.value;
        const re = /^[A-Za-z]+$/;
        // if (name == 'deptName') {
        //     if (value === "" || re.test(value)) {
        //         console.log("name succ")
        //         alert('hi')
        //         //   this.setState(value);
        //     }else{
        //         alert('dont enter the numerals')
        //     }
        // }

        console.log(name);
    }

    changeHandler2 = (event) => {
        console.log("Select", event.target.value);
        this.setState({
            no_of_containers: event.target.value
        })

    }
    onChangeBookingDetails=(filter,event)=>{
        console.log(event,filter,"1220")
        this.setState({[filter]:event.target.value})

        if(filter=="booking_id"){
            this.setState({booking_details_changed:true})
        }else if(filter=="container_no" || filter=="vessel"){
            this.setState({container_details_changed:true})
        }else{
            this.setState({vehicle_no_changed:true})
        }

    }
    handlerETD = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	// var startdate = (d.getDate()<10?"0"+d.getDate():d.getDate())+"-"+(d.getMonth() +1)+"-"+d.getFullYear();
    	// console.log(startdate,"1418")
        console.log(d,"1463")
    	this.setState({
    		etd_onetime:d,
            booking_details_changed:true
    	});
    }


    handleRemoveRingiFile5 =  () => {
        var ringiFile = $("#hiddenRingiFile_5").val();
        this.setState({ContainerDetailsFileUpload:0})
        console.log(ringiFile)
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/removeRingiFileEdit",rngParam).then((response)=>{
                $(".rnFile_5").html("");
                this.hide();
            }).catch(function(error){
                console.log(error);
            })
        }
    }
    handleRemoveRingiFile6 =  () => {
        var ringiFile = $("#hiddenRingiFile_6").val();
        this.setState({BOLFileUpload:0})
        console.log(ringiFile)
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/removeRingiFileEdit",rngParam).then((response)=>{
                $(".rnFile_6").html("");
                this.hide();
            }).catch(function(error){
                console.log(error);
            })
        }
    }


    downloadRingiFileoneTime5 = () => {
        var ringiFile = $("#hiddenRingiFile_5").val();
        console.log(ringiFile,'ringiFile')
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            console.log(rngParam,'rngParam')
            redirectURL.post("/consignments/downloadRingiFileoneTime",rngParam,{
                responseType: 'blob'
            }).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }
    downloadRingiFileoneTime6 = () => {
        var ringiFile = $("#hiddenRingiFile_6").val();
        console.log(ringiFile,'ringiFile')
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            console.log(rngParam,'rngParam')
            redirectURL.post("/consignments/downloadRingiFileoneTime",rngParam,{
                responseType: 'blob'
            }).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }

    uploadBOLFile = (e) => {
        console.log(e.target.files[0],"949")
        this.setState({
            bill_of_lading_file: e.target.files[0],
            BOLFileUpload:1
        });
    }
    
    uploadContainerDetailsFile = (e) => {
        console.log(e.target.files[0],"940")
        this.setState({
            container_details_file: e.target.files[0],
            ContainerDetailsFileUpload:1,
            booking_details_changed:true
        });
    }

    render() {

        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">

                    <div className={"col-xl-12 col-lg-12 " + this.state.requestData} style={{borderRadius:"10px"}}>
                        <div className="card" style={{borderRadius:"10px"}}>
                            <div className="card-header" style={{borderRadius:"10px"}}>
                            <div className='d-flex' style={{paddingLeft:"8.5px", paddingTop:"7.5px"}}>
                                    <a href="/containerbookingsystem/weekly" >
                                        <i className="fa fa-arrow-left" style={{color:"#171c8f", fontSize:"20px", width:"20px"}}></i>
                                    </a>
                                    <h1 style={{color:"#171c8f",fontWeight:"600", paddingTop:"1px",fontSize:"16px",marginBottom:"0px",paddingLeft:"10px"}}>{this.state.pagetitle}</h1>                                   
                                </div>

                            </div>
                            <div className="card-body pt-15px">
                                {this.state.is_approved == 0 &&
                                    <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">

                                        <div className="row col-xl-12">
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12 form-input-text">*Container Type</label>
                                                <Select
                                                    placeholder={"Select Container Type"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.container_type}
                                                    onChange={this.containertypechangeHandler.bind(this)}
                                                    styles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            borderRadius: "6px",
                                                            backgroundColor: "#fff",
                                                            boxShadow: "0 0 8px #d9d9d9",
                                                            border: "1px solid #d1d3e2",
                                                        }),
    
                                                    }}
                                                    options={[{ label: "Haz Dry", value: "Haz Dry" },
                                                    { label: "Haz High Cube", value: "Haz High Cube" }, { label: "Non Haz Dry", value: "Non Haz Dry" }, { label: "Non Haz High Cube", value: "Non Haz High Cube" }]} required
                                                />
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12 form-input-text">*Container Size</label>
                                                <Select
                                                    placeholder={"Select container Size"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.container_size}

                                                    // isMulti={true}
                                                    onChange={this.containersizechangeHandler.bind(this)}
                                                    styles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            borderRadius: "6px",
                                                            backgroundColor: "#fff",
                                                            boxShadow: "0 0 8px #d9d9d9",
                                                            border: "1px solid #d1d3e2",
                                                        }),
    
                                                    }}
                                                    options={[{ label: "20", value: "20" },
                                                    { label: "40", value: "40" }]} required
                                                />
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12 form-input-text">*Dispatch Location</label>
                                                <Select
                                                    placeholder={"Select Dispatch Location"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.dispatch_location}

                                                    onChange={this.newFilter.bind(this, "dispatch_location")}
                                                    styles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            borderRadius: "6px",
                                                            backgroundColor: "#fff",
                                                            boxShadow: "0 0 8px #d9d9d9",
                                                            border: "1px solid #d1d3e2",
                                                        }),
    
                                                    }}
                                                    options={this.state.filteredispatchLocations} required
                                                />
                                            </div>

                                        </div>
                                        <div class="hr-dashed"></div>
                                        <div className="row col-xl-12">

                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12 form-input-text">*Destination Port</label>
                                                <Select
                                                    placeholder={"Select Destination Port"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.destination_port}

                                                    onChange={this.newFilter.bind(this, "destination_port")}
                                                    styles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            borderRadius: "6px",
                                                            backgroundColor: "#fff",
                                                            boxShadow: "0 0 8px #d9d9d9",
                                                            border: "1px solid #d1d3e2",
                                                        }),
    
                                                    }}
                                                    options={this.state.filteredestinationPort} required
                                                />
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12 form-input-text">*Country</label>
                                                <Select
                                                    placeholder={"Select Country"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.destination_country}

                                                    onChange={this.newFilter.bind(this, "destination_country")}
                                                    styles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            borderRadius: "6px",
                                                            backgroundColor: "#fff",
                                                            boxShadow: "0 0 8px #d9d9d9",
                                                            border: "1px solid #d1d3e2",
                                                        }),
    
                                                    }}
                                                    options={this.state.filteredCountries} required
                                                />
                                            </div>
                                            {(this.state.destination_port.value == "Other") ?
                                                <div className="col-xl-4">
                                                    <label className="col-xl-12 col-lg-12 form-input-text">*Other Destination Port</label>
                                                    <input type="text" name="other_destination_port" id="other_destination_port" className="form-control alfa-cls form-input-box" onChange={this.changeHandler} required />
                                                </div> : ""
                                            }
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12 form-input-text">*Container Requirement Date</label>
                                                <input type="text" name="container_date"
                                                    autocomplete="off"
                                                    value={this.state.container_requirement_date}

                                                    id="container_dateField" className="datetimepicker_date form-control form-input-box" />
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12 form-input-text">*No. of Containers required</label>
                                                <input type="number" className="form-control form-input-box" onChange={this.changeHandler2} value={this.state.no_of_containers} />
                                            </div>

                                        </div>

                                        <div class="hr-dashed"></div>

                                        <div className="row col-xl-12">
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12 form-input-text">*Incoterms to be Used</label>
                                                <Select
                                                    placeholder={"Select Incoterms"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.incoterms}

                                                    onChange={this.newFilter.bind(this, "incoterms")}
                                                    styles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            borderRadius: "6px",
                                                            backgroundColor: "#fff",
                                                            boxShadow: "0 0 8px #d9d9d9",
                                                            border: "1px solid #d1d3e2",
                                                        }),
    
                                                    }}
                                                    options={this.state.filteredincoterms} required
                                                />
                                            </div>


                                        </div>

                                        {/* <div class="hr-dashed"></div> */}
                                        <div className="row col-xl-12 col-lg-12 form-group">
                                            <button type="submit" className="btn btn-booking" style={{ marginLeft: "45%", width: "192px", marginTop: "20px" }}>Submit</button>
                                        </div>
                                    </form>
                                }
                                {this.state.is_approved == 1 &&
                                    <form class="aircargo-form" onSubmit={this.formHandler2.bind(this)} encType="multipart/form-data">
                                        {this.state.booking_details_issued == 1 &&
                                            <>
                                                <div className="row col-xl-12">
                                                    <div className="col-xl-4">
                                                        <label className="col-xl-12 col-lg-12 form-input-text">*Booking ID</label>
                                                        <input
                                                            type="text"
                                                            name="booking_id"
                                                            id="booking_id"
                                                            className="form-control form-input-box"
                                                            value={this.state.booking_id}
                                                            onChange={this.onChangeBookingDetails.bind(this, "booking_id")} />
                                                    </div>

                                                    <div className="col-xl-4 col-lg-4">
                                                        <label className="col-xl-12 col-lg-12 form-input-text">*Booking Details File<span className={this.state.showUpopts}></span></label>
                                                        <input type="file" name="container_details_file" id="container_details_file"
                                                            //  value={this.state.msds_file_name} 
                                                            className="form-control requestFields form-input-box" onChange={this.uploadContainerDetailsFile} />
                                                        {/* <FileBase64
                                    onDone={ this.changeFileHandler.bind(this) } /> */}
                                                        {((this.state.container_details_file_name != "") && (this.state.container_details_file_name != undefined)) ?
                                                            <div className="row col-xl-12">
                                                                <div className="col-xl-10">
                                                                    <a className={"rnFile_5"}>{this.state.container_details_file_name}</a>
                                                                </div>
                                                                <div className="col-xl-1">
                                                                    <p className={"rnFile_5"} style={{ cursor: "pointer" }}
                                                                        onClick={() => this.downloadRingiFileoneTime5()}
                                                                    ><i title="Download" className="fa fa-download"></i></p>

                                                                    {/* <a target="_blank" href={require("../../../../api/routes/uploads/"+(item.invoice_file))}>Test </a> */}
                                                                </div>
                                                                <div className="col-xl-1">
                                                                    <p className={"rnFile_5"} style={{ cursor: "pointer" }}
                                                                        onClick={() => this.handleRemoveRingiFile5()}
                                                                    ><i title="Delete" className="fa fa-trash"></i></p>
                                                                </div>
                                                                <input type="hidden" id={"hiddenRingiFile_5"} value={this.state.container_details_file_name} />
                                                            </div>
                                                            :
                                                            ""
                                                        }
                                                    </div>

                                                </div>
                                                <div class={"hr-dashed"}></div>
                                            </>
                                        }

                                        {this.state.container_issued == 1 &&
                                            <>
                                                <div className="row col-xl-12">
                                                    <div className="col-xl-4">
                                                        <label className="col-xl-12 col-lg-12 form-input-text">*Container No</label>
                                                        <input
                                                            type="text"
                                                            name="container_no"
                                                            id="container_no"
                                                            className="form-control form-input-box"
                                                            value={this.state.container_no}
                                                            onChange={this.onChangeBookingDetails.bind(this, "container_no")} />
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <label className="col-xl-12 col-lg-12 form-input-text">*Vessel</label>
                                                        <input
                                                            type="text"
                                                            name="vessel"
                                                            id="vessel"
                                                            className="form-control form-input-box"
                                                            value={this.state.vessel}
                                                            onChange={this.onChangeBookingDetails.bind(this, "vessel")} />
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <label className="col-xl-12 col-lg-12 form-input-text">*Expected Date Of Dispatch</label>
                                                        {/* <input 
                                            type="text" 
                                            name="etd_onetime" 
                                            id="etd_onetime" 
                                           autoComplete='off'
                                           className="datetimepicker_date form-control" /> */}
                                                        <Datetime
                                                            value={this.state.etd_onetime}
                                                            disableCloseOnClickOutside={false}
                                                            closeOnSelect={true}
                                                            inputProps={{ className:'form-control form-input-box',placeholder: 'Estimated Time Of Dispatch', autoComplete: 'off' }}
                                                            dateFormat="YYYY-MM-DD"
                                                            inputFormat="YYYY-MM-DD"
                                                            name="etd_onetime"
                                                            timeFormat={false}
                                                            onChange={this.handlerETD.bind(this)} />
                                                    </div>

                                                </div>
                                                <div class={"hr-dashed"}></div>
                                            </>
                                        }

                                        {this.state.truck_no_issued == 1 &&
                                            <>
                                                <div className="row col-xl-12">
                                                    <div className="col-xl-4">
                                                        <label className="col-xl-12 col-lg-12 form-input-text">*Vehicle No</label>
                                                        <input
                                                            type="text"
                                                            name="truck_no"
                                                            id="truck_no"
                                                            className="form-control form-input-box"
                                                            value={this.state.truck_no}
                                                            onChange={this.onChangeBookingDetails.bind(this, "truck_no")} />
                                                    </div>


                                                </div>
                                                <div class={"hr-dashed"}></div>
                                            </>
                                        }

                                        <div className="row col-xl-12">
                                            {this.state.shipping_on_board.value.length > 0 &&
                                                <div className="col-xl-4">
                                                    <label className="col-xl-12 col-lg-12 form-input-text">*Shipping On Board</label>
                                                    <Select
                                                        placeholder={"Select Shipping On Board"}
                                                        closeMenuOnSelect={true}
                                                        value={this.state.shipping_on_board}

                                                        onChange={this.SOBchangeHandler.bind(this)}
                                                        styles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                borderRadius: "6px",
                                                                backgroundColor: "#fff",
                                                                boxShadow: "0 0 8px #d9d9d9",
                                                                border: "1px solid #d1d3e2",
                                                            }),
        
                                                        }}
                                                        options={[{ label: "Yes", value: "Yes" },
                                                        { label: "No", value: "No" }]} required
                                                    />
                                                </div>
                                            }
                                            {this.state.bill_of_lading_file_name.length > 0 &&
                                                <div className="col-xl-4 col-lg-4">
                                                    <label className="col-xl-12 col-lg-12 form-input-text">*Bill Of Lading<span className={this.state.showUpopts}></span></label>
                                                    <input type="file" name="bill_of_lading_file" id="bill_of_lading_file"
                                                        //  value={this.state.msds_file_name} 
                                                        className="form-control requestFields form-input-box" onChange={this.uploadBOLFile} />
                                                    {/* <FileBase64
                                          onDone={ this.changeFileHandler.bind(this) } /> */}
                                                    {((this.state.bill_of_lading_file_name != "") && (this.state.bill_of_lading_file_name != undefined)) ?
                                                        <div className="row col-xl-12">
                                                            <div className="col-xl-10">
                                                                <a className={"rnFile_6"}>{this.state.bill_of_lading_file_name}</a>
                                                            </div>
                                                            <div className="col-xl-1">
                                                                <p className={"rnFile_6"} style={{ cursor: "pointer" }}
                                                                    onClick={() => this.downloadRingiFileoneTime6()}
                                                                ><i title="Download" className="fa fa-download"></i></p>

                                                                {/* <a target="_blank" href={require("../../../../api/routes/uploads/"+(item.invoice_file))}>Test </a> */}
                                                            </div>
                                                            <div className="col-xl-1">
                                                                <p className={"rnFile_6"} style={{ cursor: "pointer" }}
                                                                    onClick={() => this.handleRemoveRingiFile6()}
                                                                ><i title="Delete" className="fa fa-trash"></i></p>
                                                            </div>
                                                            <input type="hidden" id={"hiddenRingiFile_6"} value={this.state.bill_of_lading_file_name} />
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            }

                                        </div>
                                        <div className="row col-xl-12 col-lg-12 form-group">
                                            <button type="submit" className="btn btn-booking" style={{ marginLeft: "45%", width: "192px", marginTop: "20px" }}>Submit</button>
                                        </div>
                                    </form>
                                }
                            </div>
                        </div>
                    </div>

                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}


function addDaysToDate(originalDate, daysToAdd) {
    // Parse the original date string into a JavaScript Date object
    var parts = originalDate.split('-');
    var originalDateObject = new Date(parts[2], parts[1] - 1, parts[0]); // Months are 0-indexed

    // Add the specified number of days
    var newDateObject = new Date(originalDateObject);
    newDateObject.setDate(originalDateObject.getDate() + daysToAdd);

    return newDateObject;
}


function parseDate(dateString) {
    var parts = dateString.split('-');
    if (parts.length === 3) {
        var day = parseInt(parts[0], 10);
        var month = parseInt(parts[1], 10) - 1; // Months are 0-indexed
        var year = parseInt(parts[2], 10);

        if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
            return new Date(year, month, day);
        }
    }
    // If parsing fails, return null or handle the error accordingly
    return null;
}

function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
        closeOnDateSelect: true,
        scrollMonth: false,
        scrollInput: false
    });

    $('.cargo_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
    });

    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    script.crossOrigin = "anonymous"
    index.parentNode.insertBefore(script, index)
}

// $('body').on('focus',".datetimepicker_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false
// 	});
// });

// $('body').on('focus',".cargo_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false,

// 	});
// });

$(document).on("input", ".num-cls", function () {
    this.value = this.value.replace(/\D/g, '');
})
$(document).on("input", ".mobnum-cls", function () {
    this.value = this.value.replace(/^\d{11}$/g, '');
})
$(document).on("input", ".alfa-cls", function () {
    this.value = this.value.replace(/[^a-z\s]+$/gi, '');
});
$(document).on("input", ".dept-cls", function () {
    this.value = this.value.replace(/[^a-z-\s]/gi, '');
});
$(document).on("input", ".ringi-cls", function () {
    this.value = this.value.toUpperCase();
    this.value = this.value.replace(/[^\d[A-Z]-\s]/gi, '');
});
$(document).on("change", "#cargoDate", function (e) {
    e.preventDefault();
    var bookingDate = $("#dateField").val();
    var cargoDate = $("#cargoDate").val();
    var bookingDate = bookingDate.split("-")[2] + "-" + bookingDate.split("-")[1] + "-" + bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2] + "-" + cargoDate.split("-")[1] + "-" + cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime, endTime);
    if (startTime >= endTime) {
        $("#cargoDate").val("");
        alert("Date should be greater than Booking Date");
    }
})