import React, { Component } from 'react';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY } from '../common/utils';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ExportsTrackingCountersComponent from "./exportstrackingcounterComponent";
import SweetAlert from 'react-bootstrap-sweetalert';
import UpdateButton from "./updatelmstates";

import CountUp from 'react-countup';
import ForceCloseButton from "./exportsforcecloseaction";
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class GpsStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageTitle: "Exports Container Tracking",
      eventGridAction: "gridAction",
      gridtitle:'',
      eventCounterAction: "counterAction",
      eventFormAction: "formAction",
      loadshow: 'show-n',
      showDiv: 'show-n',
      alertshow: 'fade',
      alertmg: 'show-n',
      alerterrmg: 'show-n',
      overly: 'show-n',
      alerterrshow: 'fade',
      alert: null,
      show: false,
      basicTitle: '',
      basicType: "default",
      modules: AllModules,
      sidebarSubHeader: '',
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      showGrid: false,
      rowData: [],
      value: '',
      gps_inst_pending: [],
      prev_week_df: [],
      data: [],
      gps_inst_pending_count: 0,
      prev_week_df_count: 0,
      no_of_trucks: 0,
      rowSelection: 'multiple',
      rowModelhighchartoptionsType: 'enterprise',
      rowGroupPanelShow: 'always',
      overlayNoRowsTemplate: "",
      frameworkComponents: {
        UpdateButton: UpdateButton,
        ForceCloseButton: ForceCloseButton,
      },
      animateRows: true,
      debug: true,
      showToolPanel: false,
      suppressAggFuncInHeader: true,
      groupDefaultExpanded: -1,
      childconfs: [],
      childrow: [],
      rowModelType: "serverSide",
      paginationPageSize: 1000,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
      returnPathName:'',
    };

    this.allColumns = [
      {
        headerName: "Consignment No",
        field: "consignment_code",
        width: 150,
        editable: false,
        pinned: "left",
        resizable: true,
      },
      {
        headerName: "Truck No",
        field: "truck_no",
        width: 150,
        editable: true,
        pinned: "left",
        resizable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.state.polList,
        },
      },
      {
        headerName: "Consignee City",
        field: "consignee_city",
        width: 180,
        editable: false,
        resizable: true,
      },
      {
        headerName: "Gate Out Time",
        field: "gate_out_time",
        width: 200,
        editable: false,
        resizable: true,
        valueGetter: function (params) {
          if (params.data.gate_out_time != "") {
            return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
          } else {
            return "";
          }
        },
      },

    ];

    this.truckNoOnlyColumns = [
      {
        headerName: "Truck No",
        field: "truck_no",
        width: 150,
        editable: true,
        pinned: "left",
        resizable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.state.polList,
        },
      },

    ];
    this.truckNoOnlyColumnsRows = [
      {
        headerName: "Truck No",
        field: "truck_no",
        width: 150,
        editable: true,
        pinned: "left",
        resizable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.state.polList,
        },
      },
      {
        headerName: "Last Packet Time",
        field: "timestamp",
        width: 200,
        editable: false,
        resizable: true,
        valueGetter: function (params) {
          if (params.data.timestamp != "") {
            return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Last Packet Latitude",
        field: "latitude",
        width: 150,
        editable: true,

        resizable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.state.polList,
        },
      },
      {
        headerName: "Last Packet Longitude",
        field: "longitude",
        width: 150,
        editable: true,

        resizable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.state.polList,
        },
      },
      {
        headerName: "Last Packet Address",
        field: "last_known_address",
        width: 150,
        editable: true,

        resizable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.state.polList,
        },
      },

    ];
  }

  componentDidMount() {
    const { transporterCode } = this.props.match.params;
    const searchParams = this.props.location.search;
    if (searchParams.includes('lspceodashboardDemo')) {
       var returnPathName = "lspceodashboardDemo";
    }
    // console.log(transporterCode, 'transporterCode');
    this.setState({
      transporter_code: transporterCode,
      returnPathName:returnPathName
    });

    var params = {
      transporter_code: transporterCode
    };

    redirectURL.post("/consignments/fetchNoGpsForLSP", params)
      .then(async (resp) => {
        // console.log(resp, 'resp');

        if (resp && resp.data && resp.data.data) {
          const gpsInstPending = JSON.parse(resp.data.data.gps_inst_pending) || [];
          const prevWeekDF = JSON.parse(resp.data.data.prev_week_df) || [];

          this.setState({
            gps_inst_pending: gpsInstPending,
            gps_inst_pending_count: gpsInstPending.length,
            prev_week_df: prevWeekDF,
            prev_week_df_count: prevWeekDF.length
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data for LSP:", error);
        // Handle the error as needed (e.g., display an error message)
      });

    redirectURL.post("/consignments/fetchNoGpsForIntransit", params)
      .then(async (resp) => {
        // console.log("resp2 ", resp.data);

        if (resp && resp.data && resp.data.data) {
          const jsonData = JSON.parse(resp.data.data.data) || [];

          this.setState({
            data: jsonData,
            no_of_trucks: resp.data.data.no_of_trucks || 0
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data for Intransit:", error);
        // Handle the error as needed (e.g., display an error message)
      });
  }


  onGridReady = params => {
    this.gridApi = params.api;

    this.gridColumnApi = params.columnApi;
  };

  onGridState = () => {
    this.colState = this.gridColumnApi.getColumnState();
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
  }

  onClickHideAll() {
    this.setState({
      loadshow: 'show-n',
      overly: 'show-n',
      sliderRouteTranslate: '',
      tabsliderTranslate: '',
      openUpdatedModal: false
    });
  }

  closeAlert = () => {
    this.setState({
      show: false
    });
  }

  onClickCounter(value) {
    this.setState({ showGrid: true });
    // console.log(value, "clickedValue")
    if (value === "gps_inst_pending") {
      if (this.state.gps_inst_pending !== undefined && this.state.gps_inst_pending !== null) {

        const truckNos = this.state.gps_inst_pending.map(item => ({ truck_no: item.truck_no }));
        this.setState({
          rowData: truckNos,
          gridtitle:'GPS Installation Pending',
          value: value,
          columnDefs: this.truckNoOnlyColumns, // Show only the "Truck No" column
        });
      }
    } 
    // else if (value === "prev_week_df") {
    //   if (this.state.prev_week_df !== undefined && this.state.prev_week_df !== null) {

    //     const truckNos = this.state.prev_week_df.map(item => ({ truck_no: item.truck_no, timestamp: item.timestamp, latitude: item.latitude, longitude: item.longitude, last_known_address: item.last_known_address }));
    //     this.setState({
    //       rowData: truckNos,
    //       value: value,
    //       columnDefs: this.truckNoOnlyColumnsRows, // Show only the "Truck No" column
    //     });
    //   }
    // }
     else if (value === "data") {
      if (this.state.data !== undefined && this.state.data !== null) {

        this.setState({
          rowData: this.state.data,
          value: value,
          gridtitle:"GPS NA In Transit Carrier",
          columnDefs: this.allColumns, // Show all columns
        });
      }
    }
  }

  closethegrid =()=>{
    this.setState({
      showGrid: false,
    })
}

  render() {
    const columnDefsToUse = this.state.value === "data" ? this.allColumns : (this.state.value === "prev_week_df" ? this.truckNoOnlyColumnsRows : this.truckNoOnlyColumns)
    const { showGrid } = this.state;
    const gridComponent = showGrid ? (
      <div className="card-body">
        <h5>
        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.gridtitle}</span>
                                      
                        <span >
                     <button style={{position:"absolute",right:"1%",marginTop:'-20px'}} onClick={this.closethegrid.bind(this)}>X</button></span>
                 </h5>
        <div className="col-xl-12 col-lg-12">
          <div id="myGrid" style={{ height: "500px", width: "100%" }} className="ag-theme-balham">
            <AgGridReact
              modules={this.state.modules}
              columnDefs={columnDefsToUse}
              defaultColDef={this.state.defaultColDef}
              rowData={this.state.rowData}
              enableCharts={false}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              onGridReady={this.onGridReady}
              onGridState={this.onGridState}
              statusBar={this.state.statusBar}
              sideBar={this.state.sideBar}
              frameworkComponents={this.state.frameworkComponents}
              paginationPageSize={this.state.paginationPageSize}
              pagination={true}
              enableRangeSelection={true}
              components={this.state.components}
              rowSelection={this.state.rowSelection}
              suppressRowClickSelection={true}
              gridOptions={{
                context: { componentParent: this }
              }}
            // onCellValueChanged={this.onCellRowClicked.bind(this)}
            />
          </div>
        </div>
      </div>
    ) : null;
    return (
      <div className="row">

        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div style={{ textAlign: "center", background: "#171C8F", width: '100%', margin: "5px", borderRadius: "5px", height: '70px', paddingBottom: '25px' }}>
          <h6 class="txt-dark f40" style={{ marginTop: "0px" }}>
            <span style={{ fontSize: "20px", color: "#FFFFFF" }}>
              <span>GPS Status</span>

            </span>
          </h6>
        </div>
        {
       this.state.returnPathName == "lspceodashboardDemo"?
       <div className="col-xl-12 col-lg-12">
          <h5>

            <span className="float-right f12 ">
              <a className="btn btn-danger" style={{ float: "left", marginLeft: "5%", marginBottom: "20px" }} href="/lspceodashboardDemo">Back</a>
            </span>
          </h5>
        </div>
     :
     <div className="col-xl-12 col-lg-12">
     <h5>

       <span className="float-right f12 ">
         <a className="btn btn-danger" style={{ float: "left", marginLeft: "5%", marginBottom: "20px" }} href="/lspceodashboard">Back</a>
       </span>
     </h5>
   </div>
        } 
        <div className="col-xl-12 col-lg-12">
          <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
            <div className="card">
              <div className="card-body" style={{ padding: "10px" }}>
                <div className="crm-numbers pb-0">
                  <div className="row">

                    <div className="col cirlce-d cpointer" style={{ cursor: 'pointer' }} onClick={this.onClickCounter.bind(this, "gps_inst_pending")}>
                      <span className="f13">GPS Installation Pending</span>
                      <h4 className="txt-info f50"><span className="counter"><CountUp end={(this.state.gps_inst_pending_count != '') ? this.state.gps_inst_pending_count : 0} /></span></h4>
                    </div>
                    {/* <div className="col cirlce-d cpointer" style={{ cursor: 'pointer' }} onClick={this.onClickCounter.bind(this, "prev_week_df")}>
                      <span className="f13">GPS Not Available for Last 7 Days</span>
                      <h4 className="txt-secondary-dark f50"><span className="counter"><CountUp end={(this.state.prev_week_df_count != '') ? this.state.prev_week_df_count : 0} /></span></h4>
                    </div> */}
                    <div className="col cirlce-d cpointer" style={{ cursor: 'pointer' }} onClick={this.onClickCounter.bind(this, "data")}>
                      <span className="f13">GPS NA In Transit Carrier</span>
                      <h4 className="txt-pink-light f50"><span className="counter"><CountUp end={(this.state.no_of_trucks != '') ? this.state.no_of_trucks : 0} /></span></h4>
                    </div>



                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="card">

            {gridComponent}
          </div>
        </div>
      </div>
    );
  }
}






