'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
// const $ = window.$;
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class stagewiseRailTTDashboardFy extends Component {
  constructor(props) {
    super(props);
      this.state = {
        
        //components : {datepicker:getDatePicker()}
    }
  }

componentDidMount(){
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}
 
  render() {
    return (
      <div class="container-fluid">
        <div className="card col-xl-12">
            <div className="card-header">
                <h5>
                    <i className="icofont icofont-map-pins cus-i"></i> <span>Stage Wise Rail TT Dashboard FY(2022-2023)</span>
                </h5>
            </div>
            <div className="card-body col-xl-12 col-lg-12">
                {/* <iframe 
                    style={{overflow:"hidden",border:"0px", margin:"0px", width:"100%", height:"100vh"}} 
                    width="100%" 
                    frameborder="0"
                    height="100vh" 
                    src={"https://app.powerbi.com/links/ICKt1UGzCN?ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5&pbi_source=linkShare"}>
                    
                </iframe> */}
                {/* <iframe title="(CDP-VS-2223-1092) Stage wise Rail TT MIS2 (TVPIN)" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=74b14979-b72c-4b16-9b77-b11e5ea2ff42&autoAuth=true&ctid=d78a8218-4135-4026-a3a8-1cdd7223b4d5" frameborder="0" allowFullScreen="true"></iframe> */}
                <iframe 
                    style={{overflow:"hidden",border:"0px", margin:"0px", width:"100%", height:"100vh"}} 
                    width="100%" 
                    frameborder="0"
                    height="100vh"
                    allowFullScreen="true" 
                    src={"https://app.powerbi.com/view?r=eyJrIjoiNWUzNjUxYTgtYTM0OC00ZTM5LWE5NmEtYTIzZTE3NDk2N2M1IiwidCI6IjJiNzlhMzk1LWUwNDEtNDcyMy04YzBkLWJlMTA1YTY1NmI1YiJ9"}>
                    
                </iframe>
            </div>
        </div>
      </div>

    );
  }
}